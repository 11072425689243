import types from "@/type"
import ajaxApi from "../../../../config/request"
import { post } from "@/utils/request"
let state = {
  activeTab: {
    // 激活tab页
    type: 1, // 默认按品规+企业查看
    moduleName: "按品规+企业查看",
  },
  filterParams: {
    // 左侧筛选条件
  },
  // 筛选条件回显
  echoParams: [],
}
let actions = {
  // 查询表格数据
  [types.ACTIONS_GLOBALDRUGPATENTS_GETTABLEDATA]: (store, params) => {
    let url = ajaxApi.GlobalDrugPatents.GetTableList
    return post(url, params)
  },
  // 保存查询条件
  [types.ACTIONS_GLOBALDRUGPATENTS_SAVEFAMILYORDER]: (store, params) => {
    let url = ajaxApi.GlobalDrugPatents.SaveFamilyOrder
    return post(url, params)
  },
  // 导出表格数据
  [types.ACTIONS_GLOBALDRUGPATENTS_DOWNLOADTABLEDATA]: (store, params) => {
    let url = ajaxApi.GlobalDrugPatents.DownloadTableData
    return post(url, params)
  },
  // 模糊查询下拉框
  [types.ACTIONS_GLOBALDRUGPATENTS_RECOMMENDEDWORDS]: (store, params) => {
    let url = ajaxApi.GlobalDrugPatents.RecommendedWords
    return post(url, params)
  },
  // select下拉框查询
  [types.ACTIONS_GLOBALDRUGPATENTS_GETOPTIONSLIST]: (store, params) => {
    let url = ajaxApi.GlobalDrugPatents.GetFilterOptions
    return post(url, params)
  },
  // 详情-获取基本信息
  [types.ACTIONS_GLOBALDRUGPATENTS_BasicInformation]: (store, params) => {
    let url = ajaxApi.GlobalDrugPatents.BasicInformation
    return post(url, params)
  },
  // 详情-获取简单同族相关号
  [types.ACTIONS_GLOBALDRUGPATENTS_GetPatentsNos]: (store, params) => {
    let url = ajaxApi.GlobalDrugPatents.GetPatentsNos
    return post(url, params)
  },
  // 详情-获取时间轴
  [types.ACTIONS_GLOBALDRUGPATENTS_GetTimeLine]: (store, params) => {
    let url = ajaxApi.GlobalDrugPatents.GetTimeLine
    return post(url, params)
  },
  // 详情-获取同族引用
  [types.ACTIONS_GLOBALDRUGPATENTS_SameFamilyReference]: (store, params) => {
    let url = ajaxApi.GlobalDrugPatents.SameFamilyReference
    return post(url, params)
  },
  // 详情-同族专利在各国或地区的当前法律状态
  [types.ACTIONS_GLOBALDRUGPATENTS_CountriesLegalStatus]: (store, params) => {
    let url = ajaxApi.GlobalDrugPatents.CountriesLegalStatus
    return post(url, params)
  },
  // 详情-同族专利的法律事件
  [types.ACTIONS_GLOBALDRUGPATENTS_LegalEvents]: (store, params) => {
    let url = ajaxApi.GlobalDrugPatents.LegalEvents
    return post(url, params)
  },
  // 详情-同族专利
  [types.ACTIONS_GLOBALDRUGPATENTS_PatentFamily]: (store, params) => {
    let url = ajaxApi.GlobalDrugPatents.PatentFamily
    return post(url, params)
  },
  // 数量
  [types.ACTIONS_GLOBALDRUGPATENTS_GlobalDrugPatentCount]: (store, params) => {
    let url = ajaxApi.GlobalDrugPatents.GlobalDrugPatentCount
    return post(url, params)
  },
}
let getters = {
  // 激活tab页参数
  [types.GETTERS_GLOBALDRUGPATENTS_ACTIVETAB]: (state) => {
    return state.activeTab
  },
  [types.GETTERS_GLOBALDRUGPATENTS_FILTERPARAMS]: (state) => {
    return state.filterParams
  },
  [types.GETTERS_GLOBALDRUGPATENTS_ECHOPARAMS]: (state) => {
    return state.echoParams
  },
}
let mutations = {
  // 激活tab页参数
  [types.MUTATION_GLOBALDRUGPATENTS_ACTIVETAB]: (state, payload) => {
    state.activeTab = {
      ...state.activeTab,
      ...payload,
    }
  },
  [types.MUTATION_GLOBALDRUGPATENTS_FILTERPARAMS]: (state, payload) => {
    if (!payload) {
      state.filterParams = {}
    } else if (payload === 'reset') {
      state.filterParams = {}
    } else {
      state.filterParams = {
        ...state.filterParams,
        ...payload,
      }
    }
  },
  [types.MUTATION_GLOBALDRUGPATENTS_ECHOPARAMS]: (state, payload) => {
    state.echoParams = payload
  },
}

export default {
  module: "GlobalDrugPatents",
  state,
  actions,
  getters,
  mutations,
}
