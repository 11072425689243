import types from "../../../type"
import axios from "../../../../http"
import ajaxApi from "../../../../config/request"
const Qs = require("qs")
let state = {
  reasonOptions: {},
  provinceOptions: {},
  approvalOptions: {},
  drugOptions: {},
  dosageOptions: {},
  technologyOption: {},
  getTreatmentType: {},
  getAtc: {},
  registClassificationOptions: {},
  columnsData: [], //数据表数据
  registrationDetail: {}, //申报详情
  timeLineDate: {},
  urgentlyOptions: [],
  researchDevelopmentSameVariety: [], //点击受理号数字的弹框数据
}
let actions = {
  // 枚举
  [types.ACTIONS_GET_RECOMMENDEDWORD]: ({ commit, state }, value) => {
    let url = ajaxApi.Declare.RecommendedWord
    if (value.word == "") {
      return
    }
    let params = {
      ...value,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          return res.data
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  [types.ACTIONS_GET_DECLARECATEGORY]: ({ commit, state }, value) => {
    let url = ajaxApi.Declare.DeclareCategory + `?category=${value.category}`
    if (value == "") {
      return
    }
    let params = {
      ...value,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          return res
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },

  // 优先评审理由
  [types.ACTIONS_GET_ASSESSREASON]: ({ commit, state }, value) => {
    let url = ajaxApi.Declare.GetAssessReason
    return axios
      .post(url)
      .then((res) => {
        if (res.code === 200) {
          let valueRest = {}
          if (res.data) {
            valueRest = { data: res.data[0].children }
          }

          commit(types.MUTATION_SET_ASSESSREASON, valueRest)
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  // 临床急需品种理由
  [types.ACTIONS_GET_URGENTREASON]: ({ commit, state }, value) => {
    let url = ajaxApi.Declare.GetUrgentReasons
    return axios
      .post(url)
      .then((res) => {
        if (res.code === 200) {
          let valueRest = {}
          if (res.data) {
            valueRest = { data: res.data[0].children }
          }

          commit(types.MUTATION_SET_URGENTREASON, valueRest)
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  // 获取给药途径数据
  [types.ACTIONS_GET_ADMINISTRATIONROUTE]: ({ commit, state }, value) => {
    let url = ajaxApi.Declare.GetAdministrationRoute
    return axios
      .post(url)
      .then((res) => {
        if (res.code === 200) {
          commit(types.MUTATION_SET_ADMINISTRATIONROUTE, res)
          return res
        } else {
          return res.message
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  // 获取剂型数据
  [types.ACTIONS_GET_DOSAGEDATA]: ({ commit, state }, value) => {
    let url = ajaxApi.Declare.GetdosageData + `?level=1`
    return axios
      .post(url)
      .then((res) => {
        if (res.code === 200) {
          commit(types.MUTATION_SET_DOSAGEDATA, res)
          return res
        } else {
          return res.message
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //获取注册分类二级联动数据
  [types.ACTIONS_GET_REGISTERCLASSIFICATION]: ({ commit, state }, value) => {
    let url = ajaxApi.Declare.GetRegisterClassification
    return axios
      .post(url)
      .then((res) => {
        if (res.code === 200) {
          if (res.data) {
            //当数据为空时，把[]转换为null
            //增加判断，增加弹出框
            for (var i = 0; i < res.data.length; i++) {
              if (res.data[i].name == "化学药品") {
                res.data[i].tooltip = `化学药品新注册分类（2016和2020版）
1类：境内外均未上市的创新药。指含有新的结构明确的、具有药理作用的化合物，且具有临床价值的药品。
2类：境内外均未上市的改良型新药
    2.1类：含有用拆分或者合成等方法制得的已知活性成份的光学异构体，或者对已知活性成份成酯，或者对已知活性成份成盐（包括含有氢键或配位键的盐），或者改变已知盐类活性成份的酸根、碱基或金属元素，或者形成其他非共价键衍生物（如络合物、螯合物或包合物），且具有明显临床优势的原料药及其制剂。
    2.2类：含有已知活性成份的新剂型（包括新的给药系统）、新处方工艺、新给药途径，且具有明显临床优势的制剂。
    2.3类：含有已知活性成份的新复方制剂，且具有明显临床优势。
    2.4类：含有已知活性成份的新适应症的制剂。
3类：仿制境外上市但境内未上市原研药品的药品。
4类：仿制境内已上市原研药品的药品。
5类：境外上市的药品申请在境内上市。
    5.1类：境外上市的原研药品（包括原料药及其制剂）申请在境内上市。
    5.2类：境外上市的非原研药品（包括原料药及其制剂）申请在境内上市。
                
化学药品旧注册分类（2007版）
1类：未在国内外上市销售的药品。
    1.1类：通过合成或者半合成的方法制得的原料药及其制剂。
    1.2类：天然物质中提取或者通过发酵提取的新的有效单体及其制剂。
    1.3类：用拆分或者合成等方法制得的已知药物中的光学异构体及其制剂。
    1.4类：由已上市销售的多组份药物制备为较少组份的药物。
    1.5类：新的复方制剂。
    1.6类：已在国内上市销售的制剂增加国内外均未批准的新适应症。
2类：改变给药途径且尚未在国内外上市销售的制剂。
3类：已在国外上市销售但尚未在国内上市销售的药品。
    3.1类：已在国外上市销售的制剂及其原料药，和/或改变该制剂的剂型，但不改变给药途径的制剂。
    3.2类：已在国外上市销售的复方制剂，和/或改变该制剂的剂型，但不改变给药途径的制剂。
    3.3类：改变给药途径并已在国外上市销售的制剂。
    3.4类：国内上市销售的制剂增加已在国外批准的新适应症。
4类：改变已上市销售盐类药物的酸根、碱基（或者金属元素），但不改变其药理作用的原料药及其制剂。
5类：改变国内已上市销售药品的剂型，但不改变给药途径的制剂。 
6类：已有国家药品标准的原料药或者制剂。 `
              } else if (res.data[i].name == "治疗用生物制品") {
                res.data[i].tooltip = `治疗用生物制品新注册分类（2020版）
1类：创新型生物制品：境内外均未上市的治疗用生物制品。
2类：改良型生物制品：对境内或境外已上市制品进行改良，使新产品的安全性、有效性、质量可控性有改进，且具有明显优势的治疗用生物制品。
    2.1类：在已上市制品基础上，对其剂型、给药途径等进行优化，且具有明显临床优势的生物制品。
    2.2类：增加境内外均未获批的新适应症和/或改变用药人群。
    2.3类：已有同类制品上市的生物制品组成新的复方制品。
    2.4类：在已上市制品基础上，具有重大技术改进的生物制品，如重组技术替代生物组织提取技术；较已上市制品改变氨基酸位点或表达系统、宿主细胞后具有明显临床优势等。
3类：境内或境外已上市生物制品。
    3.1类：境外生产的境外已上市、境内未上市的生物制品申报上市。
    3.2类：境外已上市、境内未上市的生物制品申报在境内生产上市。
    3.3类：生物类似药。
    3.4类：其他生物制品。
                
治疗用生物制品旧注册分类（2007版）
1类：未在国内外上市销售的生物制品。
2类：单克隆抗体。
3类：基因治疗、体细胞治疗及其制品。
4类：变态反应原制品。
5类：由人的、动物的组织或者体液提取的，或者通过发酵制备的具有生物活性的多组份制品。
6类：由已上市销售生物制品组成新的复方制品。
7类：已在国外上市销售但尚未在国内上市销售的生物制品。
8类：含未经批准菌种制备的微生态制品。
9类：与已上市销售制品结构不完全相同且国内外均未上市销售的制品（包括氨基酸位点突变、缺失，因表达系统不同而产生、消除或者改变翻译后修饰，对产物进行化学修饰等）。
10类：与已上市销售制品制备方法不同的制品（例如采用不同表达体系、宿主细胞等）。
11类：首次采用DNA重组技术制备的制品（例如以重组技术替代合成技术、生物组织提取或者发酵技术等）。
12类：国内外尚未上市销售的由非注射途径改为注射途径给药，或者由局部用药改为全身给药的制品。
13类：改变已上市销售制品的剂型但不改变给药途径的生物制品。
14类：改变给药途径的生物制品。
15类：已有国家药品标准的生物制品。`
              } else if (res.data[i].name == "预防用生物制品") {
                res.data[i].tooltip = `预防用生物制品新注册分类（2020版）
1类：创新型疫苗：境内外均未上市的疫苗。
    1.1类：无有效预防手段疾病的疫苗。
    1.2类：在已上市疫苗基础上开发的新抗原形式，如新基因重组疫苗、新核酸疫苗、已上市多糖疫苗基础上制备的新的结合疫苗等。
    1.3类：含新佐剂或新佐剂系统的疫苗。
    1.4类：含新抗原或新抗原形式的多联/多价疫苗。
2类：改良型疫苗：对境内或境外已上市疫苗产品进行改良，使新产品的安全性、有效性、质量可控性有改进，且具有明显优势的疫苗。
    2.1类：在境内或境外已上市产品基础上改变抗原谱或型别，且具有明显临床优势的疫苗。
    2.2类：具有重大技术改进的疫苗，包括对疫苗菌毒种/细胞基质/生产工艺/剂型等的改进。（如更换为其他表达体系或细胞基质的疫苗；更换菌毒株或对已上市菌毒株进行改造；对已上市细胞基质或目的基因进行改造；非纯化疫苗改进为纯化疫苗；全细胞疫苗改进为组分疫苗等）。
    2.3类：已有同类产品上市的疫苗组成的新的多联/多价疫苗。
    2.4类：改变给药途径，且具有明显临床优势的疫苗。
    2.5类：改变免疫剂量或免疫程序，且新免疫剂量或免疫程序具有明显临床优势的疫苗。
    2.6类：改变适用人群的疫苗。
3类：境内或境外已上市的疫苗。
    3.1类：境外生产的境外已上市、境内未上市的疫苗申报上市。
    3.2类：境外已上市、境内未上市的疫苗申报在境内生产上市。
    3.3类：境内已上市疫苗。
                
预防用生物制品旧注册分类（2007版）
1类：未在国内外上市销售的疫苗。
2类：DNA疫苗。
3类：已上市销售疫苗变更新的佐剂，偶合疫苗变更新的载体。
4类：由非纯化或全细胞（细菌、病毒等）疫苗改为纯化或者组份疫苗。
5类：采用未经国内批准的菌毒种生产的疫苗（流感疫苗、钩端螺旋体疫苗等除外）。
6类：已在国外上市销售但未在国内上市销售的疫苗。
7类：采用国内已上市销售的疫苗制备的结合疫苗或者联合疫苗。
8类：与已上市销售疫苗保护性抗原谱不同的重组疫苗。
9类：更换其他已批准表达体系或者已批准细胞基质生产的疫苗；采用新工艺制备并且实验室研究资料证明产品安全性和有效性明显提高的疫苗。
10类：改变灭活剂（方法）或者脱毒剂（方法）的疫苗。
11类：改变给药途径的疫苗。
12类：改变国内已上市销售疫苗的剂型，但不改变给药途径的疫苗。
13类：改变免疫剂量或者免疫程序的疫苗。
14类：扩大使用人群（增加年龄组）的疫苗。
15类：已有国家药品标准的疫苗。`
              } else if (res.data[i].name == "中药") {
                res.data[i].tooltip = `中药新注册分类（2020年版）
1类：中药创新药。指处方未在国家药品标准、药品注册标准及国家中医药主管部门发布的《古代经典名方目录》中收载，具有临床价值，且未在境外上市的中药新处方制剂。
    1.1类：中药复方制剂，系指由多味饮片、提取物等在中医药理论指导下组方而成的制剂。
    1.2类：从单一植物、动物、矿物等物质中提取得到的提取物及其制剂。
    1.3类：新药材及其制剂，即未被国家药品标准、药品注册标准以及省、自治区、直辖市药材标准收载的药材及其制剂，以及具有上述标准药材的原动、植物新的药用部位及其制剂。
2类：中药改良型新药。指改变已上市中药的给药途径、剂型，且具有临床应用优势和特点，或增加功能主治等的制剂。
    2.1类：改变已上市中药给药途径的制剂，即不同给药途径或不同吸收部位之间相互改变的制剂。
    2.2类：改变已上市中药剂型的制剂，即在给药途径不变的情况下改变剂型的制剂。
    2.3类：中药增加功能主治。
    2.4类：已上市中药生产工艺或辅料等改变引起药用物质基础或药物吸收、利用明显改变的。
3类：古代经典名方中药复方制剂。古代经典名方是指符合《中华人民共和国中医药法》规定的，至今仍广泛应用、疗效确切、具有明显特色与优势的古代中医典籍所记载的方剂。古代经典名方中药复方制剂是指来源于古代经典名方的中药复方制剂。
    3.1类：按古代经典名方目录管理的中药复方制剂。
    3.2类：其他来源于古代经典名方的中药复方制剂。包括未按古代经典名方目录管理的古代经典名方中药复方制剂和基于古代经典名方加减化裁的中药复方制剂。
4类：同名同方药。指通用名称、处方、剂型、功能主治、用法及日用饮片量与已上市中药相同，且在安全性、有效性、质量可控性方面不低于该已上市中药的制剂。
                
中药旧注册分类（2007版）
1类：未在国内上市销售的从植物、动物、矿物等物质中提取的有效成份及其制剂。
2类：新发现的药材及其制剂。
3类：新的中药材代用品。
4类：药材新的药用部位及其制剂。
5类：未在国内上市销售的从植物、动物、矿物等物质中提取的有效部位及其制剂。
6类：未在国内上市销售的中药、天然药物复方制剂。
    6.1类：中药复方制剂。
    6.2类：天然药物复方制剂。
    6.3类：中药、天然药物和化学药品组成的复方制剂。
7类：改变国内已上市销售中药、天然药物给药途径的制剂。
8类：改变国内已上市销售中药、天然药物剂型的制剂。
9类：已有国家标准的中药、天然药物。`
              } else if (res.data[i].name == "体外诊断试剂") {
                res.data[i].tooltip = `体外诊断试剂新注册分类（2020年版）
1类：创新型体外诊断试剂。
2类：境内外已上市的体外诊断试剂。`
              }
            }
            dataHasEmpty(res.data, 1)
          }
          commit(types.MUTATION_SET_REGISTERCLASSIFICATION, res)
          return res
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //治疗领域PHIIC二级联动数据接口
  [types.ACTIONS_GET_THERAPEUTICFIRLDPHIIC]: ({ commit, state }, value) => {
    let url = ajaxApi.Declare.GetTherapeuticFieldPHIIC
    return axios
      .post(url)
      .then((res) => {
        if (res.code === 200) {
          //  if (res.data) {
          //    //当数据为空时，把[]转换为null
          //    dataHasEmpty(res.data,1);
          //  }
          commit(types.MUTATION_SET_THERAPEUTICFIRLDPHIIC, res)
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //治疗领域ATC多级联动数据接口
  [types.ACTIONS_GET_THERAPEUTICFIRLDATC]: ({ commit, state }, value) => {
    let url = ajaxApi.Declare.GetTherapeuticFieldAtc
    return axios
      .post(url)
      .then((res) => {
        if (res.code === 200) {
          // if (res.data) {
          //   //当数据为空时，把[]转换为null
          //   dataHasEmpty(res.data,1);
          // }
          commit(types.MUTATION_SET_THERAPEUTICFIRLDATC, res)
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  // 热门标签
  [types.ACTIONS_GET_TECHNOLOGYLABEL]: ({ commit, state }, value) => {
    let url = ajaxApi.Declare.GetTechnologyLabel
    return axios
      .post(url)
      .then((res) => {
        if (res.code === 200) {
          commit(types.MUTATION_SET_TECHNOLOGYLABEL, res)
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //数据表数据
  [types.ACTIONS_GET_DECLARE_COLUMNSDATA]: (store, value) => {
    let url = ajaxApi.Declare.QueryTableData
    const { commit, dispatch, state, rootState } = store
    if (value.orderBy == null || value.orderBy == undefined) {
      value.orderBy = "undertakingDate"
      value.isOrderDesc = true
    }
    let filterData = rootState.Target.filterSortCondition
    let params = {
      pageSize: value.pageSize,
      currentPage: value.currentPage,
      orderBy: value.orderBy,
      isOrderDesc: value.isOrderDesc,
      ...filterData,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          commit(types.MUTATION_SET_DECLARE_COLUMNSDATA, res.data)
          return res
        } else {
          return res
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //数据表下载数据
  [types.ACTIONS_GET_DECLARE_COLUMNSDATADOWNLOAD]: (store, value) => {
    let url = ajaxApi.Declare.QueryTableDataDownload
    const { commit, dispatch, state, rootState } = store
    let filterData = rootState.Target.filterSortCondition
    let params = {
      pageSize: value.pageSize,
      currentPage: value.currentPage,
      orderBy: value.orderBy,
      isOrderDesc: value.isOrderDesc,
      ...filterData,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          return res
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //申报详情 基础信息
  [types.ACTIONS_GET_DECLAREDETAIL]: ({ commit, state }, options) => {
    let url = ajaxApi.Declare.GetBasicData + `?id=${options}`
    return axios
      .post(url)
      .then((res) => {
        if (res.code === 200) {
          commit(types.MUTATION_SET_DECLAREDETAIL, res.data)
          return Promise.resolve()
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //申报详情 时间轴
  [types.ACTIONS_GET_DECLAREDETAILTIMELINE]: ({ commit, state }, options) => {
    let url = ajaxApi.Declare.GetTimeLine + `?id=${options}`
    return axios
      .post(url)
      .then((res) => {
        if (res.code === 200) {
          commit(types.MUTATION_SET_TIMELINE, res.data)
          return Promise.resolve()
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //受理号申报信息的表格
  [types.ACTIONS_GET_RESEARCHDEVELOPMENTSAMEVARIETY]: (store, value) => {
    let url = ajaxApi.Declare.ResearchDevelopmentSameVariety
    const { commit, dispatch, state, rootState } = store
    if (value.orderBy == null || value.orderBy == undefined) {
      value.orderBy = "undertakingDate"
      value.isOrderDesc = true
    }
    let params = {
      pageSize: value.pageSize,
      currentPage: value.currentPage,
      orderBy: value.orderBy,
      isOrderDesc: value.isOrderDesc,
      drugFormId: value.drugFormId,
      commonNameId: value.commonNameId,
    }

    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          commit(types.MUTATION_SET_DECLARE_RESEARCHDEVELOPMENTSAMEVARIETY, res.data)
          return res
        } else {
          return res
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //受理号申报信息的表格下载数据
  [types.ACTIONS_GET_DOWNLOADRESEARCHDEVELOPMENTSAMEVARIETY]: (store, value) => {
    let url = ajaxApi.Declare.DownloadResearchDevelopmentSameVarietyDataTable
    const { commit, dispatch, state, rootState } = store
    let params = {
      pageSize: value.pageSize,
      currentPage: value.currentPage,
      orderBy: value.orderBy,
      isOrderDesc: value.isOrderDesc,
      drugFormId: value.drugFormId,
      commonNameId: value.commonNameId,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          return res
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
}
let getters = {
  /**
     * 读取属性用法
     * methods:{
         name:function(){
      return this.store.getters.GETTERS_GLOBAL_HOME();
    },
   }
     */
  [types.GETTERS_DECLARE_URGENTREASON]: (state) => {
    return state.urgentlyOptions
  },
  [types.GETTERS_DECLARE_ASSESSREASON]: (state) => {
    return state.reasonOptions
  },
  [types.GETTERS_DECLARE_GETPROVINCE]: (state) => {
    return state.provinceOptions
  },
  [types.GETTERS_DECLARE_APPROVALCONCLUSION]: (state) => {
    return state.approvalOptions
  },
  [types.GETTERS_DECLARE_TECHNOLOGYLABEL]: (state) => {
    return state.technologyOption
  },
  [types.GETTERS_DECLARE_ADMINISTRATIONROUTE]: (state) => {
    return state.drugOptions
  },
  [types.GETTERS_DECLARE_DOSAGEDATA]: (state) => {
    return state.dosageOptions
  },
  [types.GETTERS_DECLARE_THERAPEUTICFIRLDPHIIC]: (state) => {
    return state.getTreatmentType
  },
  [types.GETTERS_DECLARE_THERAPEUTICFIRLDATC]: (state) => {
    initDrug2(state.getAtc.data)
    return state.getAtc
  },
  [types.GETTERS_DECLARE_REGISTERCLASSIFICATION]: (state) => {
    return state.registClassificationOptions
  },

  [types.GETTERS_DECLARE_DECLAREDETAIL]: (state) => {
    return state.registrationDetail
  },
  [types.GETTERS_DECLARE_TIMELINE]: (state) => {
    return state.timeLineDate
  },
  [types.GETTERS_DECLARE_RESEARCHDEVELOPMENTSAMEVARIETY]: (state) => {
    return state.researchDevelopmentSameVariety
  },
}
let mutations = {
  [types.MUTATION_SET_URGENTREASON]: (state, payload) => {
    state.urgentlyOptions = {
      ...state.urgentlyOptions,
      ...payload,
    }
  },
  [types.MUTATION_SET_ASSESSREASON]: (state, payload) => {
    state.reasonOptions = {
      ...state.reasonOptions,
      ...payload,
    }
  },
  [types.MUTATION_SET_GETPROVINCE]: (state, payload) => {
    state.provinceOptions = {
      ...state.provinceOptions,
      ...payload,
    }
  },
  [types.MUTATION_SET_APPROVALCONCLUSION]: (state, payload) => {
    state.approvalOptions = {
      ...state.approvalOptions,
      ...payload,
    }
  },
  [types.MUTATION_SET_TECHNOLOGYLABEL]: (state, payload) => {
    state.technologyOption = {
      ...state.technologyOption,
      ...payload,
    }
  },
  [types.MUTATION_SET_ADMINISTRATIONROUTE]: (state, payload) => {
    state.drugOptions = {
      ...state.drugOptions,
      ...payload,
    }
  },
  // 剂型
  [types.MUTATION_SET_DOSAGEDATA]: (state, payload) => {
    state.dosageOptions = {
      ...state.dosageOptions,
      ...payload,
    }
  },
  [types.MUTATION_SET_THERAPEUTICFIRLDPHIIC]: (state, payload) => {
    state.getTreatmentType = {
      ...state.getTreatmentType,
      ...payload,
    }
  },
  [types.MUTATION_SET_THERAPEUTICFIRLDATC]: (state, payload) => {
    state.getAtc = {
      ...state.getAtc,
      ...payload,
    }
  },
  [types.MUTATION_SET_SCREENCONDITION]: (state, payload) => {
    state.screenCondition = payload
  },
  [types.MUTATION_SET_REGISTERCLASSIFICATION]: (state, payload) => {
    state.registClassificationOptions = payload
  },
  [types.MUTATION_SET_DECLARE_COLUMNSDATA]: (state, payload) => {
    state.columnsData = {
      ...state.columnsData,
      ...payload,
    }
  },
  [types.MUTATION_SET_DECLAREDETAIL]: (state, payload) => {
    state.registrationDetail = {
      ...state.registrationDetail,
      ...payload,
    }
  },
  [types.MUTATION_SET_TIMELINE]: (state, payload) => {
    state.timeLineDate = {
      ...state.timeLineDate,
      ...payload,
    }
  },
  [types.MUTATION_SET_DECLARE_RESEARCHDEVELOPMENTSAMEVARIETY]: (state, payload) => {
    state.researchDevelopmentSameVariety = {
      ...state.researchDevelopmentSameVariety,
      ...payload,
    }
  },
}

//做一个递归处理，每一个有子元素的加一个不限
function initDrug(value) {
  if (value) {
    for (var i = 0; i < value.length; i++) {
      initDrug(value[i].children)
    }
    value.unshift({
      name: "不限",
      code: null,
      children: null,
      value: "",
    })
  } else {
    return
  }
}

function initDrug2(value) {
  if (value) {
    for (var i = 0; i < value.length; i++) {
      value[i].name = value[i].code + "-" + value[i].name
      initDrug2(value[i].children)
    }
    // value.unshift({
    //   name: "不限",
    //   code: null,
    //   children: null,
    //   value: "",
    // });
  } else {
    return
  }
}

//对数据为空的处理
function dataHasEmpty(value, number, getName) {
  if (value) {
    for (var i = 0; i < value.length; i++) {
      //展示文字
      value[i].showName = getName ? `${getName}-${value[i].name}` : `${value[i].name}`
      if (value[i].children.length != 0) {
        dataHasEmpty(value[i].children, number + 1, value[i].showName)
      } else {
        value[i].children = null
      }
      value[i].level = number
    }
  } else {
    return
  }
}

export default {
  module: "Declare",
  state,
  actions,
  getters,
  mutations,
}
