/**
 * zyg
 * 2020/12/15
 */
import types from "../../../type"
import axios from "../../../../http"
import ajaxApi from "../../../../config/request"
let state = {
  userProvince: {},
  authShow: { show: false },
  applyShow: { show: false },
  developShow: { show: false },
  calladdress: {},
  userInfo: {},
  loginWx: {},
}
const timestamp = new Date().getTime();
let actions = {
  //发送验证码
  [types.ACTIONS_GET_AUTH_SENDVERIFICATIONCODEBYMOBILE]: ({ commit, state }, options) => {
    let url = ajaxApi.Auth.SendVerificationCodeByMobile
    let params = options
    return axios
      .post(url, params)
      .then((res) => {
        return res
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //设置锁定状态
  [types.ACTIONS_GET_AUTH_SETLOCKTAB]: ({ commit, state }, options) => {
    let url = ajaxApi.Auth.SetLockTab
    let params = options
    return axios
      .post(url, params)
      .then((res) => {
        return res
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  [types.ACTIONS_GET_AUTH_SENDVERIFICATIONCODEBYMAIL]: ({ commit, state }, options) => {
    let url = ajaxApi.Auth.SendVerificationCodeByMail + `?mail=${options}`
    let params = {}
    return axios
      .post(url, params)
      .then((res) => {
        return res
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //获取下载code
  [types.ACTIONS_GET_GETDOWNLOADLIMIT]: () => {
    let url = ajaxApi.Util.GetDownloadLimit
    return axios
      .post(url)
      .then((res) => {
        return res
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //获取上市模块JSON
  [types.ACTIONS_GET_GETMARKETDICTIONARYJSON]: () => {
    let url = ajaxApi.Util.GetMarketDictionaryJson + `?t=${timestamp}`
    return axios
      .get(url)
      .then((res) => {
        return res
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //获取上招标模块JSON
  [types.ACTIONS_GET_GETDRUGBIDDINGDICTIONARYJSON]: () => {
    let url = ajaxApi.Util.GetDrugBiddingDictionaryJson + `?t=${timestamp}`
    return axios
      .get(url)
      .then((res) => {
        return res
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //获取专利模块JSON
  [types.ACTIONS_GET_GETGLOBALDRUGPATENTSICTIONARYJSON]: () => {
    let url = ajaxApi.Util.GetGlobalDrugpatentsDictionaryJson + `?t=${timestamp}`
    return axios
      .get(url)
      .then((res) => {
        return res
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //获取上临床模块JSON
  [types.ACTIONS_GET_GETCLINICALDICTIONARYJSON]: () => {
    let url = ajaxApi.Util.GetClinicalDictionaryJson + `?t=${timestamp}`
    return axios
      .get(url)
      .then((res) => {
        return res
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },

  //获取上新药模块JSON
  [types.ACTIONS_GET_GETGLOBALNEWDRUGDICTIONARYJSON]: () => {
    let url = ajaxApi.Util.GetGlobalNewDrugDictionaryJson + `?t=${timestamp}`
    return axios
      .get(url)
      .then((res) => {
        return res
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //获取上新药模块JSON
  [types.ACTIONS_GET_GETNDCDICTIONARYJSON]: () => {
    let url = ajaxApi.Util.GetNDCDictionaryJson + `?t=${timestamp}`
    return axios
      .get(url)
      .then((res) => {
        return res
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //获取上临床指南模块JSON
  [types.ACTIONS_GET_GETPATHWAYDICTIONARYJSON]: () => {
    let url = ajaxApi.Util.GetClinicalPathwayDictionaryJson + `?t=${timestamp}`
    return axios
      .get(url)
      .then((res) => {
        return res
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //获取上疾病指南模块JSON
  [types.ACTIONS_GET_GETTREATGUIDEDICTIONARYJSON]: () => {
    let url = ajaxApi.Util.GetTreatGuideJson + `?t=${timestamp}`
    return axios
      .get(url)
      .then((res) => {
        return res
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //获取企业年报模块JSON  AnnualReports
  [types.ACTIONS_GET_GETANNUALREPORTSDICTIONARYJSON]: () => {
    let url = ajaxApi.Util.GetNDCDictionaryJson + `?t=${timestamp}`
    return axios
      .get(url)
      .then((res) => {
        return res
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //获取通用模块JSON 
  [types.ACTIONS_GET_GETGENERALDICTIONARYJSON]: () => {
    let url = ajaxApi.Util.GetGeneralDictionaryJson + `?t=${timestamp}`
    return axios
      .get(url)
      .then((res) => {
        return res
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //获取医保模块JSON 
  [types.ACTIONS_GET_GETMEDICALINSURANCEDICTIONARYJSON]: () => {
    let url = ajaxApi.Util.GetMedicalInsuranceInsuranceDictionaryJson + `?t=${timestamp}`
    return axios
      .get(url)
      .then((res) => {
        return res
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //获取基药模块JSON 
  [types.ACTIONS_GET_GETBASICDRUGDICTIONARYJSON]: () => {
    let url = ajaxApi.Util.GetBasicDrugDictionaryJson + `?t=${timestamp}`
    return axios
      .get(url)
      .then((res) => {
        return res
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //获取通用药物模块JSON 
  [types.ACTIONS_GET_GETMEDICINEDICTIONARYJSON]: () => {
    let url = ajaxApi.Util.GetMedicineDictionaryJson + `?t=${timestamp}`
    return axios
      .get(url)
      .then((res) => {
        return res
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //获取用户省份
  [types.ACTIONS_GET_AUTH_USERPROVINCE]: ({ commit, state }, options) => {
    let url = ajaxApi.Auth.UserProvince
    let params = {}
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          commit(types.MUTATION_SET_AUTH_USERPROVINCE, res)
          return Promise.resolve()
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //用户的注册
  [types.ACTIONS_GET_AUTH_REGISTER]: ({ commit, state }, options) => {
    let url = ajaxApi.Auth.Register
    let params = {
      ...options,
    }
    return axios
      .post(url, params)
      .then((res) => {
        return res
        //  if (res.code === 200) {
        //    commit(types.MUTATION_SET_DRUGFORMJOINTCOMMONNAME, res.data);
        //    return res
        //  } else {
        //    return res
        //  }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //用户的登录
  [types.ACTIONS_GET_AUTH_LOGIN]: ({ commit, state }, options) => {
    localStorage.setItem("isSetCheckIpHeader", "")
    let url = ajaxApi.Auth.Login
    let params = {
      ...options,
    }
    return axios
      .post(url, params)
      .then((res) => {
        commit(types.MUTATION_SET_AUTH_USERINFO, res.data)
        return res
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //判断用户是否需要校验IP
  [types.ACTIONS_GET_AUTH_ISCHECKIP]: ({ commit, state }) => {
    let url = ajaxApi.Auth.IsCheckIp
    return axios
      .get(url)
      .then((res) => {
        localStorage.setItem("isSetCheckIpHeader", res.data)
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //用户的登出功能
  [types.ACTIONS_GET_AUTH_LOGOUT]: ({ commit, state }, options) => {
    let url = ajaxApi.Auth.Logout
    let params = {
      //...options
    }
    return axios
      .post(url, params)
      .then((res) => {
        return res
        //  if (res.code === 200) {
        //    commit(types.MUTATION_SET_DRUGFORMJOINTCOMMONNAME, res.data);
        //    return res
        //  } else {
        //    return res
        //  }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },

  //用户通过手机号找回密码功能
  [types.ACTIONS_GET_AUTH_VERIFYMOBILECODEFORRETRIEVEPWD]: ({ commit, state }, options) => {
    let url = ajaxApi.Auth.VerifyMobileCodeForRetrievePwd
    let params = {
      ...options,
    }
    return axios
      .post(url, params)
      .then((res) => {
        return res
        //  if (res.code === 200) {
        //    commit(types.MUTATION_SET_DRUGFORMJOINTCOMMONNAME, res.data);
        //    return res
        //  } else {
        //    return res
        //  }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //用户通过邮箱验证码找回密码功能
  [types.ACTIONS_GET_AUTH_VERIFYMAILCODEFORRETRIEVEPWD]: ({ commit, state }, options) => {
    let url = ajaxApi.Auth.VerifyMailCodeForRetrievePwd
    let params = {
      ...options,
    }
    return axios
      .post(url, params)
      .then((res) => {
        return res
        //  if (res.code === 200) {
        //    commit(types.MUTATION_SET_DRUGFORMJOINTCOMMONNAME, res.data);
        //    return res
        //  } else {
        //    return res
        //  }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //用户通过验证码找回密码功能   VerifyCodeForRegister
  [types.ACTIONS_GET_AUTH_VERIFYCODEFORREGISTER]: ({ commit, state }, options) => {
    let url = ajaxApi.Auth.VerifyCodeForRegister
    let params = {
      ...options,
    }
    return axios
      .post(url, params)
      .then((res) => {
        return res
        //  if (res.code === 200) {
        //    commit(types.MUTATION_SET_DRUGFORMJOINTCOMMONNAME, res.data);
        //    return res
        //  } else {
        //    return res
        //  }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },

  //用户的找回密码功能
  [types.ACTIONS_GET_AUTH_RETRIEVEPWD]: ({ commit, state }, options) => {
    let url = ajaxApi.Auth.RetrievePwd
    let params = {
      ...options,
    }
    return axios
      .post(url, params)
      .then((res) => {
        return res
        //  if (res.code === 200) {
        //    commit(types.MUTATION_SET_DRUGFORMJOINTCOMMONNAME, res.data);
        //    return res
        //  } else {
        //    return res
        //  }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //w微信登录
  [types.ACTIONS_GET_AUTH_LOGINWX]: ({ commit, state }, options) => {
    localStorage.setItem("isSetCheckIpHeader", "")
    let url = ajaxApi.Auth.loginWx + `?code=${options.code}`
    let params = {
      ...options,
    }
    return axios
      .post(url, params)
      .then((res) => {
        return res
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //微信的绑定功能
  [types.ACTIONS_GET_AUTH_BINDWX]: ({ commit, state }, options) => {
    let url = ajaxApi.Auth.bindWx + `?code=${options.code}`
    let params = {
      ...options,
    }
    return axios
      .post(url, params)
      .then((res) => {
        return res
        //  if (res.code === 200) {
        //    commit(types.MUTATION_SET_DRUGFORMJOINTCOMMONNAME, res.data);
        //    return res
        //  } else {
        //    return res
        //  }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //微信的绑定功能
  [types.ACTIONS_GET_AUTH_UNBINDWX]: ({ commit, state }, options) => {
    let url = ajaxApi.Auth.unbindWx + `?code=${options.code}`
    let params = {
      ...options,
    }
    return axios
      .post(url, params)
      .then((res) => {
        return res
        //  if (res.code === 200) {
        //    commit(types.MUTATION_SET_DRUGFORMJOINTCOMMONNAME, res.data);
        //    return res
        //  } else {
        //    return res
        //  }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //判断用户登录状态
  [types.ACTIONS_GET_AUTH_ISUSER]: ({ commit, state }) => {
    let url = ajaxApi.Auth.IsUser
    return axios
      .get(url)
      .then((res) => {
        return res
        //  if (res.code === 200) {
        //    commit(types.MUTATION_SET_DRUGFORMJOINTCOMMONNAME, res.data);
        //    return res
        //  } else {
        //    return res
        //  }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
}
let getters = {
  //  [types.GETTERS_DRUG_COUNTRY]: (state) => {
  //   return state.country;
  // },
  [types.GETTERS_AUTH_USERINFO]: (state) => {
    return state.userInfo
  },
  [types.GETTERS_AUTH_USERPROVINCE]: (state) => {
    return state.userProvince
  },
  [types.GETTERS_AUTHSHOW]: (state) => {
    return state.authShow
  },
  [types.GETTERS_DEVELOPSHOW]: (state) => {
    return state.developShow
  },
  [types.GETTERS_APPLYSHOW]: (state) => {
    return state.applyShow
  },
  [types.GETTERS_CALLADDRESS]: (state) => {
    return state.calladdress
  },
  [types.GETTERS_LOGINWX]: (state) => {
    return state.loginWx
  },
}

let mutations = {
  //  [types.MUTATION_SET_COUNTRY]: (state, payload) => {
  //   state.country = { ...state.country, ...payload };
  // },
  [types.MUTATION_SET_AUTH_USERINFO]: (state, payload) => {
    state.userInfo = { ...state.userInfo, ...payload }
  },

  [types.MUTATION_SET_AUTH_USERPROVINCE]: (state, payload) => {
    state.userProvince = { ...state.userProvince, ...payload }
  },
  [types.MUTATION_SET_AUTHSHOW]: (state, payload) => {
    state.authShow = { ...state.authShow, ...payload }
  },
  [types.MUTATION_SET_DEVELOPSHOW]: (state, payload) => {
    state.developShow = { ...state.developShow, ...payload }
  },
  [types.MUTATION_SET_APPLYSHOW]: (state, payload) => {
    state.applyShow = { ...state.applyShow, ...payload }
  },
  [types.MUTATION_SET_CALLADDRESS]: (state, payload) => {
    state.calladdress = payload
  },
}
export default {
  module: "Auth",
  state,
  actions,
  getters,
  mutations,
}
