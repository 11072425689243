import types from "@/type"
import ajaxApi from "../../../../config/request"
import { post } from "@/utils/request"
let state = {
  filterParams: {
    // 左侧筛选条件
  },
  // 筛选条件回显
  echoParams: [],
  // 下拉框
  options: {},
}
let actions = {
  // 查询表格数据
  [types.ACTIONS_MATERIAL_GETTABLEDATA]: (store, params) => {
    let url = ajaxApi.Material.GetTableList
    return post(url, params)
  },
  // 导出表格数据
  [types.ACTIONS_MATERIAL_DOWNLOADTABLEDATA]: (store, params) => {
    let url = ajaxApi.Material.DownloadTableData
    return post(url, params)
  },
  // 文件下载前的校验
  [types.ACTIONS_MATERIAL_CHECKDOWNLOADFILE]: (store, params) => {
    let url = ajaxApi.Material.CheckDownloadFile
    return post(url, params)
  },
  // 模糊查询下拉框
  [types.ACTIONS_MATERIAL_RECOMMENDEDWORDS]: (store, params) => {
    let url = ajaxApi.Material.RecommendedWords
    return post(url, params)
  },
  // select下拉框查询
  [types.ACTIONS_MATERIAL_GETOPTIONSLIST]: (store, params) => {
    let url = ajaxApi.Material.GetFilterOptions
    return post(url, params)
  },
  // 点击数字弹框查询
  [types.ACTIONS_MATERIAL_SHOWCOUNTOPENDATA]: (store, params) => {
    let url = ajaxApi.Material.ShowCountOpenData
    return post(url, params)
  },
}
let getters = {
  [types.GETTERS_MATERIAL_FILTERPARAMS]: (state) => {
    return state.filterParams
  },
  [types.GETTERS_MATERIAL_ECHOPARAMS]: (state) => {
    return state.echoParams
  },
}
let mutations = {
  // 激活tab页参数
  [types.MUTATION_MATERIAL_ACTIVETAB]: (state, payload) => {
    state.activeTab = {
      ...state.activeTab,
      ...payload,
    }
  },
  [types.MUTATION_MATERIAL_FILTERPARAMS]: (state, payload) => {
    if (!payload) {
      state.filterParams = {}
    } else {
      state.filterParams = {
        ...state.filterParams,
        ...payload,
      }
    }
  },
  [types.MUTATION_MATERIAL_ECHOPARAMS]: (state, payload) => {
    state.echoParams = payload
  },
  [types.MUTATION_MATERIAL_OPTIONS]: (state, payload) => {
    state.options = { ...state.options, ...payload }
  },
}

export default {
  module: "Material",
  state,
  actions,
  getters,
  mutations,
}
