import types from "@/type"
import ajaxApi from "../../../../config/request"
import { post } from "@/utils/request"
let state = {
  filterParams: {
    // 左侧筛选条件
  },
  // 筛选条件回显
  echoParams: [],
}
let actions = {
  // 查询表格数据
  [types.ACTIONS_AMERICANDC_GETTABLEDATA]: (store, params) => {
    let url = ajaxApi.AmericaNDC.GetTableList
    return post(url, params)
  },
  // 导出表格数据
  [types.ACTIONS_AMERICANDC_DOWNLOADTABLEDATA]: (store, params) => {
    let url = ajaxApi.AmericaNDC.DownloadTableData
    return post(url, params)
  },
  // 模糊查询下拉框
  [types.ACTIONS_AMERICANDC_RECOMMENDEDWORDS]: (store, params) => {
    let url = ajaxApi.AmericaNDC.RecommendedWords
    return post(url, params)
  },
  // select下拉框查询
  [types.ACTIONS_AMERICANDC_GETOPTIONSLIST]: (store, params) => {
    let url = ajaxApi.AmericaNDC.GetFilterOptions
    return post(url, params)
  },
}
let getters = {
  // 激活tab页参数
  [types.GETTERS_AMERICANDC_ACTIVETAB]: (state) => {
    return state.activeTab
  },
  [types.GETTERS_AMERICANDC_FILTERPARAMS]: (state) => {
    return state.filterParams
  },
  [types.GETTERS_AMERICANDC_ECHOPARAMS]: (state) => {
    return state.echoParams
  },
}
let mutations = {
  // 激活tab页参数
  [types.MUTATION_AMERICANDC_ACTIVETAB]: (state, payload) => {
    state.activeTab = {
      ...state.activeTab,
      ...payload,
    }
  },
  [types.MUTATION_AMERICANDC_FILTERPARAMS]: (state, payload) => {
    if (!payload) {
      state.filterParams = {}
    } else if (payload === 'reset') {
      state.filterParams = {}
    } else {
      state.filterParams = {
        ...state.filterParams,
        ...payload,
      }
    }
  },
  [types.MUTATION_AMERICANDC_ECHOPARAMS]: (state, payload) => {
    state.echoParams = payload
  },
}

export default {
  module: "AmericaNDC",
  state,
  actions,
  getters,
  mutations,
}
