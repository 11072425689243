let state = {
  testMsg: "Test Message",
  sysInfo: {
    currSys: {
      name: "PharmaOne",
      id: "PharmaOne",
      currRoute: "/",
    },
    sysMap: [],
  },
  userInfo: {
    loginStatus: {
      token: "",
    },
    baseInfo: {
      userName: "",
      userId: "",
      lastLoginDate: "",
      lastLoginIp: "",
      avatar: "",
      desc: "",
    },
  },
  breadList: [], //面包屑导航
  viewType: "guigeSpan",
  commonCollectParams: {},
  tabBar: "medicine", //当前tabbar
  tabBarList: [
    {
      "id": 1,
      "title": "首页",
      "routerPath": "home",
      "auth": false,
      "children": []
    },
    {
      "id": 2,
      "title": "研发",
      "routerPath": "",
      "auth": false,
      "children": [
        {
          "id": 3,
          "title": "全球药物研发",
          "routerPath": "globalNewDrug",
          "auth": false,
          "children": []
        },
        {
          "id": 5,
          "title": "全球临床试验",
          "routerPath": "clinical",
          "auth": false,
          "children": []
        },
        {
          "id": 6,
          "title": "中国药品注册申报",
          "routerPath": "registration",
          "auth": false,
          "children": []
        },
        {
          "id": 7,
          "title": "中国原辅包登记",
          "routerPath": "rawMaterial",
          "auth": false,
          "children": []
        }
      ]
    },
    {
      "id": 8,
      "title": "专利",
      "routerPath": "",
      "auth": false,
      "children": [
        {
          "id": 9,
          "title": "全球药物专利",
          "routerPath": "globalDrugPatents",
          "auth": false,
          "children": []
        }
      ]
    },
    {
      "id": 10,
      "title": "一致性",
      "routerPath": "",
      "auth": false,
      "children": [
        {
          "id": 11,
          "title": "一致性评价进度",
          "routerPath": "equivalence",
          "auth": false,
          "children": []
        },
        {
          "id": 12,
          "title": "参比制剂目录",
          "routerPath": "referListedDrug",
          "auth": false,
          "children": []
        }
      ]
    },
    {
      "id": 13,
      "title": "上市",
      "routerPath": "",
      "auth": false,
      "children": [
        {
          "id": 14,
          "title": "全球上市药品",
          "routerPath": "market?module=global",
          "auth": false,
          "children": []
        },
        {
          "id": 15,
          "title": "中国上市药品",
          "routerPath": "market?module=china",
          "auth": false,
          "children": []
        },
        {
          "id": 16,
          "title": "美国上市药品",
          "routerPath": "market?module=america",
          "auth": false,
          "children": []
        },
        {
          "id": 17,
          "title": "欧盟集中上市",
          "routerPath": "market?module=ema",
          "auth": false,
          "children": []
        },
        {
          "id": 18,
          "title": "欧盟互认上市",
          "routerPath": "market?module=hma",
          "auth": false,
          "children": []
        },
        {
          "id": 19,
          "title": "日本上市药品",
          "routerPath": "market?module=japan",
          "auth": false,
          "children": []
        },
        {
          "id": 20,
          "title": "美国NDC药品",
          "routerPath": "americaNDC",
          "auth": false,
          "children": []
        },
        {
          "id": 36,
          "title": "企业年报",
          "routerPath": "annualReports",
          "auth": false,
          "children": []
        }
      ]
    },
    {
      "id": 22,
      "title": "准入",
      "routerPath": "",
      "auth": false,
      "children": [
        {
          "id": 23,
          "title": "药品招标",
          "routerPath": "drugBidding",
          "auth": false,
          "children": []
        },
        {
          "id": 24,
          "title": "药品招标分析",
          "routerPath": "drugBiddingAnalysis",
          "auth": false,
          "children": []
        },
        {
          "id": 25,
          "title": "医保目录",
          "routerPath": "medicareCatalog",
          "auth": false,
          "children": []
        },
        {
          "id": 26,
          "title": "基药目录",
          "routerPath": "essentialDrug",
          "auth": false,
          "children": []
        }
      ]
    },
    {
      "id": 27,
      "title": "用药",
      "routerPath": "",
      "auth": false,
      "children": [
        {
          "id": 28,
          "title": "临床指南",
          "routerPath": "treatmentGuide",
          "auth": false,
          "children": []
        },
        {
          "id": 29,
          "title": "临床路径",
          "routerPath": "clinicalPathway",
          "auth": false,
          "children": []
        }
      ]
    },
    {
      "id": 31,
      "title": "分析",
      "routerPath": "",
      "auth": false,
      "children": [
        {
          "id": 34,
          "title": "药物",
          "routerPath": "medicine",
          "auth": false,
          "children": []
        },
        {
          "id": 35,
          "title": "公司",
          "routerPath": "company",
          "auth": false,
          "children": []
        },
        {
          "id": 32,
          "title": "疾病",
          "routerPath": "disease",
          "auth": false,
          "children": []
        },
        {
          "id": 33,
          "title": "靶点",
          "routerPath": "target",
          "auth": false,
          "children": []
        }
      ]
    },
    {
      "id": 37,
      "title": "ONE",
      "routerPath": "",
      "auth": false,
      "children": [
        {
          "id": 38,
          "title": "研发立项",
          "routerPath": "",
          "auth": false,
          "children": [
            {
              "id": 41,
              "title": "新药研发",
              "routerPath": "",
              "auth": false,
              "children": [
                {
                  "id": 45,
                  "title": "热门领域研发全景",
                  "routerPath": "oneRAndDhotAreas",
                  "auth": false,
                  "children": []
                },
                {
                  "id": 46,
                  "title": "药品获批领域地图",
                  "routerPath": "oneRAndDmapApproved",
                  "auth": false,
                  "children": []
                },
                {
                  "id": 47,
                  "title": "新兴靶点研发热度",
                  "routerPath": "oneRAndDEmergingTargets",
                  "auth": false,
                  "children": []
                }
              ]
            },
            {
              "id": 42,
              "title": "仿制药研发",
              "routerPath": "",
              "auth": false,
              "children": [
                {
                  "id": 48,
                  "title": "挖掘仿药老品种",
                  "routerPath": "oneRAndDImitativeMedicine",
                  "auth": false,
                  "children": []
                }
              ]
            }
          ]
        },
        {
          "id": 39,
          "title": "市场分析",
          "routerPath": "",
          "auth": false,
          "children": [
            {
              "id": 43,
              "title": "市场分析工具",
              "routerPath": "",
              "auth": false,
              "children": [
                {
                  "id": 49,
                  "title": "产品对比分析",
                  "routerPath": "oneMarketComparison",
                  "auth": false,
                  "children": []
                },
                {
                  "id": 50,
                  "title": "医保放量拉动分析",
                  "routerPath": "oneMarketInsurance",
                  "auth": false,
                  "children": []
                },
                {
                  "id": 51,
                  "title": "集采放量拉动分析",
                  "routerPath": "oneMarketCentralized",
                  "auth": false,
                  "children": []
                }
              ]
            }
          ]
        },
        {
          "id": 40,
          "title": "投资并购",
          "routerPath": "",
          "auth": false,
          "children": [
            {
              "id": 44,
              "title": "投资并购工具",
              "routerPath": "",
              "auth": false,
              "children": [
                {
                  "id": 52,
                  "title": "国产创新药估值",
                  "routerPath": "oneTargetValuationInnovative",
                  "auth": false,
                  "children": []
                },
                {
                  "id": 53,
                  "title": "目标公司大事件",
                  "routerPath": "oneTargetCompany",
                  "auth": false,
                  "children": []
                },
                {
                  "id": 54,
                  "title": "公司模拟合并",
                  "routerPath": "oneTargetSimulationMerge",
                  "auth": false,
                  "children": []
                }
              ]
            }
          ]
        }
      ]
    }
  ], //tab栏
  menuList: [
    {
      "id": 1,
      "title": "首页",
      "routerPath": "home",
      "auth": false,
      "children": []
    },
    {
      "id": 2,
      "title": "研发",
      "routerPath": "",
      "auth": false,
      "children": [
        {
          "id": 3,
          "title": "全球药物研发",
          "routerPath": "globalNewDrug",
          "auth": false,
          "children": []
        },
        {
          "id": 5,
          "title": "全球临床试验",
          "routerPath": "clinical",
          "auth": false,
          "children": []
        },
        {
          "id": 6,
          "title": "中国药品注册申报",
          "routerPath": "registration",
          "auth": false,
          "children": []
        },
        {
          "id": 7,
          "title": "中国原辅包登记",
          "routerPath": "rawMaterial",
          "auth": false,
          "children": []
        }
      ]
    },
    {
      "id": 8,
      "title": "专利",
      "routerPath": "",
      "auth": false,
      "children": [
        {
          "id": 9,
          "title": "全球药物专利",
          "routerPath": "globalDrugPatents",
          "auth": false,
          "children": []
        }
      ]
    },
    {
      "id": 10,
      "title": "一致性",
      "routerPath": "",
      "auth": false,
      "children": [
        {
          "id": 11,
          "title": "一致性评价进度",
          "routerPath": "equivalence",
          "auth": false,
          "children": []
        },
        {
          "id": 12,
          "title": "参比制剂目录",
          "routerPath": "referListedDrug",
          "auth": false,
          "children": []
        }
      ]
    },
    {
      "id": 13,
      "title": "上市",
      "routerPath": "",
      "auth": false,
      "children": [
        {
          "id": 14,
          "title": "全球上市药品",
          "routerPath": "market?module=global",
          "auth": false,
          "children": []
        },
        {
          "id": 15,
          "title": "中国上市药品",
          "routerPath": "market?module=china",
          "auth": false,
          "children": []
        },
        {
          "id": 16,
          "title": "美国上市药品",
          "routerPath": "market?module=america",
          "auth": false,
          "children": []
        },
        {
          "id": 17,
          "title": "欧盟集中上市",
          "routerPath": "market?module=ema",
          "auth": false,
          "children": []
        },
        {
          "id": 18,
          "title": "欧盟互认上市",
          "routerPath": "market?module=hma",
          "auth": false,
          "children": []
        },
        {
          "id": 19,
          "title": "日本上市药品",
          "routerPath": "market?module=japan",
          "auth": false,
          "children": []
        },
        {
          "id": 20,
          "title": "美国NDC药品",
          "routerPath": "americaNDC",
          "auth": false,
          "children": []
        },
        {
          "id": 36,
          "title": "企业年报",
          "routerPath": "annualReports",
          "auth": false,
          "children": []
        }
      ]
    },
    {
      "id": 22,
      "title": "准入",
      "routerPath": "",
      "auth": false,
      "children": [
        {
          "id": 23,
          "title": "药品招标",
          "routerPath": "drugBidding",
          "auth": false,
          "children": []
        },
        {
          "id": 24,
          "title": "药品招标分析",
          "routerPath": "drugBiddingAnalysis",
          "auth": false,
          "children": []
        },
        {
          "id": 25,
          "title": "医保目录",
          "routerPath": "medicareCatalog",
          "auth": false,
          "children": []
        },
        {
          "id": 26,
          "title": "基药目录",
          "routerPath": "essentialDrug",
          "auth": false,
          "children": []
        }
      ]
    },
    {
      "id": 27,
      "title": "用药",
      "routerPath": "",
      "auth": false,
      "children": [
        {
          "id": 28,
          "title": "临床指南",
          "routerPath": "treatmentGuide",
          "auth": false,
          "children": []
        },
        {
          "id": 29,
          "title": "临床路径",
          "routerPath": "clinicalPathway",
          "auth": false,
          "children": []
        }
      ]
    },
    {
      "id": 31,
      "title": "分析",
      "routerPath": "",
      "auth": false,
      "children": [
        {
          "id": 34,
          "title": "药物",
          "routerPath": "medicine",
          "auth": false,
          "children": []
        },
        {
          "id": 35,
          "title": "公司",
          "routerPath": "company",
          "auth": false,
          "children": []
        },
        {
          "id": 32,
          "title": "疾病",
          "routerPath": "disease",
          "auth": false,
          "children": []
        },
        {
          "id": 33,
          "title": "靶点",
          "routerPath": "target",
          "auth": false,
          "children": []
        }
      ]
    },
    {
      "id": 37,
      "title": "ONE",
      "routerPath": "",
      "auth": false,
      "children": [
        {
          "id": 38,
          "title": "研发立项",
          "routerPath": "",
          "auth": false,
          "children": [
            {
              "id": 41,
              "title": "新药研发",
              "routerPath": "",
              "auth": false,
              "children": [
                {
                  "id": 45,
                  "title": "热门领域研发全景",
                  "routerPath": "oneRAndDhotAreas",
                  "auth": false,
                  "children": []
                },
                {
                  "id": 46,
                  "title": "药品获批领域地图",
                  "routerPath": "oneRAndDmapApproved",
                  "auth": false,
                  "children": []
                },
                {
                  "id": 47,
                  "title": "新兴靶点研发热度",
                  "routerPath": "oneRAndDEmergingTargets",
                  "auth": false,
                  "children": []
                }
              ]
            },
            {
              "id": 42,
              "title": "仿制药研发",
              "routerPath": "",
              "auth": false,
              "children": [
                {
                  "id": 48,
                  "title": "挖掘仿药老品种",
                  "routerPath": "oneRAndDImitativeMedicine",
                  "auth": false,
                  "children": []
                }
              ]
            }
          ]
        },
        {
          "id": 39,
          "title": "市场分析",
          "routerPath": "",
          "auth": false,
          "children": [
            {
              "id": 43,
              "title": "市场分析工具",
              "routerPath": "",
              "auth": false,
              "children": [
                {
                  "id": 49,
                  "title": "产品对比分析",
                  "routerPath": "oneMarketComparison",
                  "auth": false,
                  "children": []
                },
                {
                  "id": 50,
                  "title": "医保放量拉动分析",
                  "routerPath": "oneMarketInsurance",
                  "auth": false,
                  "children": []
                },
                {
                  "id": 51,
                  "title": "集采放量拉动分析",
                  "routerPath": "oneMarketCentralized",
                  "auth": false,
                  "children": []
                }
              ]
            }
          ]
        },
        {
          "id": 40,
          "title": "投资并购",
          "routerPath": "",
          "auth": false,
          "children": [
            {
              "id": 44,
              "title": "投资并购工具",
              "routerPath": "",
              "auth": false,
              "children": [
                {
                  "id": 52,
                  "title": "国产创新药估值",
                  "routerPath": "oneTargetValuationInnovative",
                  "auth": false,
                  "children": []
                },
                {
                  "id": 53,
                  "title": "目标公司大事件",
                  "routerPath": "oneTargetCompany",
                  "auth": false,
                  "children": []
                },
                {
                  "id": 54,
                  "title": "公司模拟合并",
                  "routerPath": "oneTargetSimulationMerge",
                  "auth": false,
                  "children": []
                }
              ]
            }
          ]
        }
      ]
    }
  ], //tab栏
  loading: false,
  //统一的错误提示弹框
  tipModlus: {
    tipShow: false,
    tipMessage: "",
  },
}

export default state
