import types from "@/type"
import ajaxApi from "../../../../config/request"
import { post } from "@/utils/request"
let state = {
  filterParams: {
    // 左侧筛选条件
  },
  // 筛选条件回显
  echoParams: [],
}
let actions = {
  // 查询表格数据
  [types.ACTIONS_REFERLISTEDDRUG_GETTABLEDATA]: (store, params) => {
    let url = ajaxApi.ReferListedDrug.GetTableList
    return post(url, params)
  },
  // 导出表格数据
  [types.ACTIONS_REFERLISTEDDRUG_DOWNLOADTABLEDATA]: (store, params) => {
    let url = ajaxApi.ReferListedDrug.DownloadTableData
    return post(url, params)
  },
  // 文件下载前的校验
  [types.ACTIONS_REFERLISTEDDRUG_CHECKDOWNLOADFILE]: (store, params) => {
    let url = ajaxApi.ReferListedDrug.CheckDownloadFile
    return post(url, params)
  },
  // 模糊查询下拉框
  [types.ACTIONS_REFERLISTEDDRUG_RECOMMENDEDWORDS]: (store, params) => {
    let url = ajaxApi.ReferListedDrug.RecommendedWords
    return post(url, params)
  },
  // select下拉框查询
  [types.ACTIONS_REFERLISTEDDRUG_GETOPTIONSLIST]: (store, params) => {
    let url = ajaxApi.ReferListedDrug.GetFilterOptions
    return post(url, params)
  },
  // 根据批次号查询编号
  [types.ACTIONS_REFERLISTEDDRUG_GETNUMBERBYBATCH]: (store, params) => {
    let url = ajaxApi.ReferListedDrug.GetNumberByBatch
    return post(url, params)
  },
}
let getters = {
  [types.GETTERS_REFERLISTEDDRUG_FILTERPARAMS]: (state) => {
    return state.filterParams
  },
  [types.GETTERS_REFERLISTEDDRUG_ECHOPARAMS]: (state) => {
    return state.echoParams
  },
}
let mutations = {
  [types.MUTATION_REFERLISTEDDRUG_FILTERPARAMS]: (state, payload) => {
    if (!payload) {
      state.filterParams = {}
    } else {
      state.filterParams = {
        ...state.filterParams,
        ...payload,
      }
    }
  },
  [types.MUTATION_REFERLISTEDDRUG_ECHOPARAMS]: (state, payload) => {
    state.echoParams = payload
  },
}

export default {
  module: "ReferListedDrug",
  state,
  actions,
  getters,
  mutations,
}
