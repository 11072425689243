/**
 * zyg
 * 2020/12/15
 */
import types from "../../../type"
import axios from "../../../../http"
import ajaxApi from "../../../../config/request"
let state = {
  commonNameId: 9, //药物ID
  durgAnalysis: {}, //药物分析
  domesticSalesAnalysis: {}, //中国药物销售分析
  globalSalesAnalysis: {}, //全球药物销售分析
  enterpriseAnalysis: {}, //公司分析
  enterpriseSalesAnalysis: {}, //药品销售分析使用通用名查企业
  globaldiseaseAnalysis: {}, //全球疾病阶段分析
  domesticdiseaseAnalysis: {}, //全球疾病阶段分析
  domestictargetAnalysis: {}, //全球药物靶点阶段分析
  globaltargetAnalysis: {}, //全球药物靶点阶段分析
  countryRDMapAnalysis: {}, //全球药物全国研究分析
  drugRDAnalysis: {}, //药品研发获取分析
  drugDetail: {}, //药品详情
  companyChinaRDAnalysis: [], //研发获批分析-按公司中国-按公司全球-按疾病中国-按疾病全球
  companyGlobalRDAnalysis: [], //按公司全球
  diseaseChinaRDAnalysis: [], //按疾病中国
  diseaseGlobalRDAnalysis: [], //按疾病全球
  chinaSalesAndForecast: {}, //销售总额分析与预测
  globalSalesAndForecast: {}, //销售总额分析与预测
  potentialMarket: {}, //市场潜力分析
  chinaCompanySales: {}, //公司实力
  globalCompanySales: {}, //公司实力
  clinical: {}, //获取临床信息
  drugFormSales: {}, //剂型实力
  replaceRiskAnalysis: {}, //代替风险
  departmentAnalysis: {}, //科室分布
  patientFlow: {}, //患者流
  adverseReactions: {}, //不良反应报告
  disease: {}, //获取疾病列表
  phase: {}, //获取阶段列表
  target: {}, //获取靶点列表
  company: {}, //获取公司列表
  country: {}, //获取阶段列表
  drugFormJointCommonName: {},
  drugType: {},
  biologicalDrugClassification: {},
  treatmentType: {},
}

let actions = {
  //药物分析
  [types.ACTIONS_GET_DURGANALYSIS]: ({ commit, state }, value) => {
    let url = ajaxApi.Drug.DurgAnalysis
    let params = {
      ...value,
      // ...state.searchForm,
      // pageSize: state.PrizePagination.pageSize,
      // pageNum: state.PrizePagination.pageNum,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          commit(types.MUTATION_SET_DURGANALYSIS, res.data)
          return Promise.resolve()
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //中国药物销售分析
  [types.ACTIONS_GET_DOMESTICSALESANALYSIS]: ({ commit, state }, value) => {
    let url = ajaxApi.Drug.DomesticSalesAnalysis
    let params = {
      ...value,
      // ...state.searchForm,
      // pageSize: state.PrizePagination.pageSize,
      // pageNum: state.PrizePagination.pageNum,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          commit(types.MUTATION_SET_DOMESTICSALESANALYSIS, res.data)
          return Promise.resolve()
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //全球药物销售分析
  [types.ACTIONS_GET_GLOBALSALESANALYSIS]: ({ commit, state }, value) => {
    let url = ajaxApi.Drug.GlobalSalesAnalysis
    let params = {
      ...value,
      // ...state.searchForm,
      // pageSize: state.PrizePagination.pageSize,
      // pageNum: state.PrizePagination.pageNum,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          commit(types.MUTATION_SET_GLOBALSALESANALYSIS, res.data)
          return Promise.resolve()
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //药品销售分析使用通用名查企业 options为dispatch传入参数
  [types.ACTIONS_GET_ENTERPRISESALESANALYSIS]: ({ commit, state }, options) => {
    let url = ajaxApi.Drug.EnterpriseSalesAnalysis
    let params = {
      ...options,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          commit(types.MUTATION_SET_ENTERPRISESALESANALYSIS, res.data)
          return Promise.resolve()
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //公司分析
  [types.ACTIONS_GET_ENTERPRISEANALYSIS]: ({ commit, state }, value) => {
    let url = ajaxApi.Drug.EnterpriseAnalysis
    let params = {
      ...value,
      // ...state.searchForm,
      // pageSize: state.PrizePagination.pageSize,
      // pageNum: state.PrizePagination.pageNum,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          commit(types.MUTATION_SET_ENTERPRISEANALYSIS, res.data)
          return Promise.resolve()
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //疾病分析 options为是否为全球 true/false
  [types.ACTIONS_GET_DISEASEANALYSIS]: ({ commit, state }, value) => {
    let url = ajaxApi.Drug.DiseaseAnalysis
    let params = {
      ...value,
      // ...state.searchForm,
      // pageSize: state.PrizePagination.pageSize,
      // pageNum: state.PrizePagination.pageNum,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          if (value.isGlobal) {
            commit(types.MUTATION_SET_GLOBALDISEASEANALYSIS, res.data)
          } else {
            commit(types.MUTATION_SET_DOMESTICDISEASEANALYSIS, res.data)
          }

          return Promise.resolve()
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },

  //靶点分析 options为是否为全球 true/false
  [types.ACTIONS_GET_TARGETANALYSIS]: ({ commit, state }, value) => {
    let url = ajaxApi.Drug.TargetAnalysis
    let params = {
      ...value,
      // ...state.searchForm,
      // pageSize: state.PrizePagination.pageSize,
      // pageNum: state.PrizePagination.pageNum,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          if (value.isGlobal) {
            commit(types.MUTATION_SET_GLOBALTARGETANALYSIS, res.data)
          } else {
            commit(types.MUTATION_SET_DOMESTICTARGETANALYSIS, res.data)
          }

          return Promise.resolve()
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //研发地图 全球药物全国研究分析
  [types.ACTIONS_GET_COUNTRYRDMAPANALYSIS]: ({ commit, state }, value) => {
    let url = ajaxApi.Drug.CountryRDMapAnalysis
    let params = {
      ...value,
      // ...state.searchForm,
      // pageSize: state.PrizePagination.pageSize,
      // pageNum: state.PrizePagination.pageNum,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          commit(types.MUTATION_SET_COUNTRYRDMAPANALYSIS, res.data)
          return Promise.resolve()
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //数据表 药品研发获取分析
  [types.ACTIONS_GET_DRUGRDANALYSIS]: (store, value) => {
    let url = ajaxApi.Drug.DrugRDAnalysis
    const { commit, dispatch, state, rootState } = store
    let filterData = rootState.Target.filterSortCondition
    if (value.orderBy == null || value.orderBy == undefined) {
      value.orderBy = "salesOfChina"
      value.isOrderDesc = true
    }
    let params = {
      pageSize: value.pageSize,
      currentPage: value.currentPage,
      orderBy: value.orderBy,
      isOrderDesc: value.isOrderDesc,
      ...filterData,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          commit(types.MUTATION_SET_DRUGRDANALYSIS, res.data)
          return Promise.resolve()
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //药物数据表下载
  [types.ACTIONS_GET_DOWNLOADDRUGRDANALYSIS]: (store, value) => {
    let url = ajaxApi.Drug.DownloadDrugDataTable
    const { commit, dispatch, state, rootState } = store
    let filterData = rootState.Target.filterSortCondition
    let params = {
      pageSize: value.pageSize,
      currentPage: value.currentPage,
      orderBy: value.orderBy,
      isOrderDesc: value.isOrderDesc,
      ...filterData,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          return res
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //不良反应的下载
  [types.ACTIONS_GET_DOWNLOADCREATETOKENADVERSEREACTIONS]: (store, value) => {
    let url = ajaxApi.Drug.DownloadCreateTokenAdverseReactions
    const { commit, dispatch, state, rootState } = store

    let params = {
      pageSize: value.pageSize,
      currentPage: value.currentPage,
      orderBy: value.orderBy,
      isOrderDesc: value.isOrderDesc,
      drugId: value.drugId,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          return res
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },

  //不良反应的下载
  [types.ACTIONS_GET_DOWNLOADCREATETOKENCLINICAL]: (store, value) => {
    let url
    if (value.path == "disease") {
      url = ajaxApi.Disease.DownloadCreateTokenClinical
    }
    if (value.path == "target") {
      url = ajaxApi.Target.DownloadCreateTokenClinical
    }
    if (value.path == "company") {
      url = ajaxApi.Enterprise.DownloadCreateTokenClinical
    }
    if (value.path == "commonName") {
      url = ajaxApi.Drug.DownloadCreateTokenClinical
    }
    const { commit, dispatch, state, rootState } = store
    let params = {
      pageSize: value.pageSize,
      currentPage: value.currentPage,
      orderBy: value.orderBy,
      isOrderDesc: value.isOrderDesc,
      diseaseId: value.diseaseId,
      enterpriseId: value.enterpriseId,
      commonNameId: value.commonNameId,
      targetId: value.commonNameId,
      isGlobal: value.isGlobal,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          return res
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //药品研发获批分析下载
  [types.ACTIONS_GET_DRUG_DOWNLOADRDANALYSIS]: (store, value) => {
    let url = ajaxApi.Drug.DownloadRDAnalysis
    let params = {
      ...value,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          return res
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //获取公司列表
  [types.ACTIONS_GET_COMPANY]: ({ commit, state }, value) => {
    let url = ajaxApi.Drug.Company
    let params = value
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          return res.data
        } else {
          return res.message
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //获取疾病列表
  [types.ACTIONS_GET_DISEASE]: ({ commit, state }, value) => {
    let url = ajaxApi.Drug.Disease
    let params = value
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          return res.data
        } else {
          return res.message
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //获取剂型列表
  [types.ACTIONS_GET_DRUGFORM]: ({ commit, state }, value) => {
    let url = ajaxApi.Drug.DrugForm
    let params = value
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          return res.data
        } else {
          return res.message
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //获取药物类型
  [types.ACTIONS_GET_DRUGTYPE]: ({ commit, state }) => {
    let url = ajaxApi.Drug.DrugType
    let params = {}
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          commit(types.MUTATION_SET_DRUGTYPE, res.data)
          return res.data
        } else {
          return res.message
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //获取技术类型
  [types.ACTIONS_GET_BIOLOGICALDRUGCLASSIFICATION]: ({ commit, state }, value) => {
    if (!value) {
      value = {}
    }
    let url = ajaxApi.Drug.BiologicalDrugClassification
    let params = {
      ...value,
      pid: value.id,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          commit(types.MUTATION_SET_BIOLOGICALDRUGCLASSIFICATION, res.data)
          return res.data
        } else {
          return res.message
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //获取治疗类别
  [types.ACTIONS_GET_TREATMENTTYPE]: ({ commit, state }, value) => {
    let url = ajaxApi.Drug.TreatmentType
    if (!value) {
      value = {}
    }
    let params = {
      ...value,
      pid: value.id,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          commit(types.MUTATION_SET_TREATMENTTYPE, res.data)
          return res.data
        } else {
          return res.message
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //获取国家列表
  [types.ACTIONS_GET_COUNTRY]: ({ commit, state }, value) => {
    let url = ajaxApi.Drug.Country
    let params = {}
    if (value) {
      params = value
    } else {
      params = {}
    }

    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          commit(types.MUTATION_SET_COUNTRY, res.data)
          return res.data
        } else {
          return res.message
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },

  //研发获批分析
  [types.ACTIONS_GET_RDANALYSIS]: ({ commit, state }, value) => {
    let url = ajaxApi.Drug.RDAnalysis
    let params = filterObj(value)
    let type = value.type
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          state[type] = res.data
          return Promise.resolve()
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //研发临床信息
  [types.ACTIONS_GET_CLINICAL]: ({ commit, state }, value) => {
    let url
    if (value.path == "disease") {
      url = ajaxApi.Disease.Clinical
    }
    if (value.path == "target") {
      url = ajaxApi.Target.Clinical
    }
    if (value.path == "company") {
      url = ajaxApi.Enterprise.Clinical
    }
    if (value.path == "commonName") {
      url = ajaxApi.Drug.Clinical
    }
    delete value.path
    let params = value
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          commit(types.MUTATION_SET_CLINICAL, res.data)
          return Promise.resolve()
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //不良反应报告
  [types.ACTIONS_GET_ADVERSEREACTIONS]: ({ commit, state }, value) => {
    let url = ajaxApi.Drug.AdverseReactions
    let params = value
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          // commit(types.MUTATION_SET_ADVERSEREACTIONS, res.data);
          state.adverseReactions = res.data
          return Promise.resolve()
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },

  //药物详情 基础信息
  [types.ACTIONS_GET_DRUGDETAIL]: ({ commit, state }, options) => {
    let url = ajaxApi.Drug.DrugDetail + `?drugId=${options.drugId}`
    let params = {
      ...options,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          commit(types.MUTATION_SET_DRUGDETAIL, res.data)
          return Promise.resolve()
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },

  //药物详情 销售总额分析与预测
  [types.ACTIONS_GET_DRUG_SALESANDFORECAST]: ({ commit, state }, options) => {
    let url = ajaxApi.Drug.SalesAndForecast + `?id=${options.id}`
    let params = {
      ...options,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          if (options.isGlobal) {
            commit(types.MUTATION_SET_DRUG_GLOBALSALESANDFORECAST, res.data)
          } else {
            commit(types.MUTATION_SET_DRUG_CHINASALESANDFORECAST, res.data)
          }

          return Promise.resolve()
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },

  //药物详情 市场潜力
  [types.ACTIONS_GET_POTENTIALMARKET]: ({ commit, state }, options) => {
    let url = ajaxApi.Drug.PotentialMarket + `?drugId=${options.drugId}`
    let params = {
      ...options,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          commit(types.MUTATION_SET_POTENTIALMARKET, res.data)
          return Promise.resolve()
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },

  //药物详情 公司实力
  [types.ACTIONS_GET_DRUG_COMPANYSALES]: ({ commit, state }, options) => {
    let url = ajaxApi.Drug.CompanySales + `?id=${options.id}`
    let params = {
      ...options,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          if (options.isGlobal) {
            commit(types.MUTATION_SET_DRUG_GLOBALCOMPANYSALES, res.data)
          } else {
            commit(types.MUTATION_SET_DRUG_CHINACOMPANYSALES, res.data)
          }
          return Promise.resolve()
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },

  //药物详情 剂型实力
  [types.ACTIONS_GET_DRUGFORMSALES]: ({ commit, state }, options) => {
    let url = ajaxApi.Drug.DrugFormSales + `?drugId=${options.drugId}`
    let params = {
      ...options,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          commit(types.MUTATION_SET_DRUGFORMSALES, res.data)
          return Promise.resolve()
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },

  //药物详情 代替风险
  [types.ACTIONS_GET_REPLACERISKANALYSIS]: ({ commit, state }, options) => {
    let url = ajaxApi.Drug.ReplaceRiskAnalysis
    let params = {
      ...options,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          commit(types.MUTATION_SET_REPLACERISKANALYSIS, res.data)
          return Promise.resolve()
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //药物详情 科室分析
  [types.ACTIONS_GET_DEPARTMENTANALYSIS]: ({ commit, state }, options) => {
    let url = ajaxApi.Drug.DepartmentAnalysis
    let params = {
      ...options,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          commit(types.MUTATION_SET_DEPARTMENTANALYSIS, res)
          return Promise.resolve()
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //药物详情 患者流页面
  [types.ACTIONS_GET_PATIENTFLOW]: ({ commit, state }, options) => {
    let url = ajaxApi.Drug.PatientFlow
    let params = {
      ...options,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          return res
          commit(types.MUTATION_SET_PATIENTFLOW, res.data)
          return Promise.resolve()
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //药品销售总额分析
  [types.ACTIONS_GET_DRUG_DOWNLOADSALESANDFORECAST]: (store, value) => {
    let url = ajaxApi.Drug.DownloadSalesAndForecast
    let params = {
      ...value,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          return res
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //下载剂型分析
  [types.ACTIONS_GET_DRUG_DOWNLOADDRUGFORMSALES]: (store, value) => {
    let url = ajaxApi.Drug.DownloadDrugFormSales
    let params = {
      ...value,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          return res
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //各公司实力分析
  [types.ACTIONS_GET_DRUG_DOWNLOADCOMPANYSALES]: (store, value) => {
    let url = ajaxApi.Drug.DownloadCompanySales
    let params = {
      ...value,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          return res
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //药物详情 获通用名获取药品剂型（产品名称）
  [types.ACTIONS_GET_DRUGFORMJOINTCOMMONNAME]: ({ commit, state }, options) => {
    let url = ajaxApi.Drug.DrugFormJointCommonName
    let params = {
      ...options,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          commit(types.MUTATION_SET_DRUGFORMJOINTCOMMONNAME, res.data)
          return Promise.resolve()
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
}
let getters = {
  [types.GETTERS_DRUG_COMMONNAMEID]: (state) => {
    return state.commonNameId
  },
  [types.GETTERS_DRUG_DURGANALYSIS]: (state) => {
    return state.durgAnalysis
  },
  [types.GETTERS_DRUG_DOMESTICSALESANALYSIS]: (state) => {
    return state.domesticSalesAnalysis
  },
  [types.GETTERS_DRUG_GLOBALSALESANALYSIS]: (state) => {
    return state.globalSalesAnalysis
  },
  [types.GETTERS_DRUG_ENTERPRISESALESANALYSIS]: (state) => {
    return state.enterpriseSalesAnalysis
  },
  [types.GETTERS_DRUG_ENTERPRISEANALYSIS]: (state) => {
    return state.enterpriseAnalysis
  },
  [types.GETTERS_DRUG_GLOBALDISEASEANALYSIS]: (state) => {
    return state.globaldiseaseAnalysis
  },
  [types.GETTERS_DRUG_DOMESTICDISEASEANALYSIS]: (state) => {
    return state.domesticdiseaseAnalysis
  },
  [types.GETTERS_DRUG_DOMESTICTARGETANALYSIS]: (state) => {
    return state.domestictargetAnalysis
  },
  [types.GETTERS_DRUG_GLOBALTARGETANALYSIS]: (state) => {
    return state.globaltargetAnalysis
  },
  [types.GETTERS_DRUG_COUNTRYRDMAPANALYSIS]: (state) => {
    return state.countryRDMapAnalysis
  },
  [types.GETTERS_DRUG_DRUGRDANALYSIS]: (state) => {
    return state.drugRDAnalysis
  },
  [types.GETTERS_DRUG_RDANALYSIS]: (state) => {
    return state.rDAnalysis
  },
  [types.GETTERS_DRUG_DRUGDETAIL]: (state) => {
    return state.drugDetail
  },

  [types.GETTERS_DRUG_CHINASALESANDFORECAST]: (state) => {
    return state.chinaSalesAndForecast
  },
  [types.GETTERS_DRUG_GLOBALSALESANDFORECAST]: (state) => {
    return state.globalSalesAndForecast
  },
  [types.GETTERS_DRUG_POTENTIALMARKET]: (state) => {
    return state.potentialMarket
  },
  [types.GETTERS_DRUG_CHINACOMPANYSALES]: (state) => {
    return state.chinaCompanySales
  },
  [types.GETTERS_DRUG_GLOBALCOMPANYSALES]: (state) => {
    return state.globalCompanySales
  },
  [types.GETTERS_DRUG_DRUGFORMSALES]: (state) => {
    return state.drugFormSales
  },
  [types.GETTERS_DRUG_REPLACERISKANALYSIS]: (state) => {
    return state.replaceRiskAnalysis
  },
  [types.GETTERS_DRUG_DEPARTMENTANALYSIS]: (state) => {
    return state.departmentAnalysis
  },
  [types.GETTERS_DRUG_PATIENTFLOW]: (state) => {
    return state.patientFlow
  },
  [types.GETTERS_DRUG_COMPANYCHINARDANALYSIS]: (state) => {
    return state.companyChinaRDAnalysis
  },
  [types.GETTERS_DRUG_COMPANYGLOBALRDANALYSIS]: (state) => {
    return state.companyGlobalRDAnalysis
  },
  [types.GETTERS_DRUG_DISEASECHINARDANALYSIS]: (state) => {
    return state.diseaseChinaRDAnalysis
  },
  [types.GETTERS_DRUG_DISEASEGLOBALRDANALYSIS]: (state) => {
    return state.diseaseGlobalRDAnalysis
  },
  [types.GETTERS_DRUG_CLINICAL]: (state) => {
    return state.clinical
  },
  [types.GETTERS_DRUG_ADVERSEREACTIONS]: (state) => {
    return state.adverseReactions
  },
  [types.GETTERS_DRUG_DEPARTMENTANALYSIS]: (state) => {
    return state.departmentAnalysis
  },
  [types.GETTERS_DRUG_DRUGFORMJOINTCOMMONNAME]: (state) => {
    return state.drugFormJointCommonName
  },
  [types.GETTERS_DRUG_DRUGTYPE]: (state) => {
    return state.drugType
  },
  [types.GETTERS_DRUG_BIOLOGICALDRUGCLASSIFICATION]: (state) => {
    return state.biologicalDrugClassification
  },
  [types.GETTERS_DRUG_TREATMENTTYPE]: (state) => {
    return state.treatmentType
  },
  [types.GETTERS_DRUG_COUNTRY]: (state) => {
    return state.country
  },
}

let mutations = {
  [types.MUTATION_SET_COMMONNAMEID]: (state, payload) => {
    state.commonNameId = payload
  },
  [types.MUTATION_SET_DURGANALYSIS]: (state, payload) => {
    state.durgAnalysis = { ...state.durgAnalysis, ...payload }
  },
  [types.MUTATION_SET_DOMESTICSALESANALYSIS]: (state, payload) => {
    state.domesticSalesAnalysis = {
      ...state.domesticSalesAnalysis,
      ...payload,
    }
  },
  [types.MUTATION_SET_GLOBALSALESANALYSIS]: (state, payload) => {
    state.globalSalesAnalysis = { ...state.globalSalesAnalysis, ...payload }
  },
  [types.MUTATION_SET_ENTERPRISESALESANALYSIS]: (state, payload) => {
    state.enterpriseSalesAnalysis = {
      ...state.enterpriseSalesAnalysis,
      ...payload,
    }
  },
  [types.MUTATION_SET_ENTERPRISEANALYSIS]: (state, payload) => {
    state.enterpriseAnalysis = { ...state.enterpriseAnalysis, ...payload }
  },
  [types.MUTATION_SET_DOMESTICDISEASEANALYSIS]: (state, payload) => {
    state.domesticdiseaseAnalysis = {
      ...state.domesticdiseaseAnalysis,
      ...payload,
    }
  },
  [types.MUTATION_SET_GLOBALDISEASEANALYSIS]: (state, payload) => {
    state.globaldiseaseAnalysis = {
      ...state.globaldiseaseAnalysis,
      ...payload,
    }
  },
  [types.MUTATION_SET_DOMESTICTARGETANALYSIS]: (state, payload) => {
    state.domestictargetAnalysis = {
      ...state.domestictargetAnalysis,
      ...payload,
    }
  },
  [types.MUTATION_SET_GLOBALTARGETANALYSIS]: (state, payload) => {
    state.globaltargetAnalysis = { ...state.globaltargetAnalysis, ...payload }
  },
  [types.MUTATION_SET_COUNTRYRDMAPANALYSIS]: (state, payload) => {
    state.countryRDMapAnalysis = { ...state.countryRDMapAnalysis, ...payload }
  },
  [types.MUTATION_SET_DRUGRDANALYSIS]: (state, payload) => {
    state.drugRDAnalysis = { ...state.drugRDAnalysis, ...payload }
  },
  [types.MUTATION_SET_RDANALYSIS]: (state, payload) => {
    state.rDAnalysis = { ...state.rDAnalysis, ...payload }
  },
  [types.MUTATION_SET_DRUGDETAIL]: (state, payload) => {
    state.drugDetail = { ...state.drugDetail, ...payload }
  },
  [types.MUTATION_SET_SALESANDFORECAST]: (state, payload) => {
    state.salesAndForecast = { ...state.salesAndForecast, ...payload }
  },
  [types.MUTATION_SET_DRUG_CHINASALESANDFORECAST]: (state, payload) => {
    state.chinaSalesAndForecast = { ...state.chinaSalesAndForecast, ...payload }
  },
  [types.MUTATION_SET_DRUG_GLOBALSALESANDFORECAST]: (state, payload) => {
    state.globalSalesAndForecast = { ...state.globalSalesAndForecast, ...payload }
  },
  [types.MUTATION_SET_POTENTIALMARKET]: (state, payload) => {
    state.potentialMarket = { ...state.potentialMarket, ...payload }
  },
  [types.MUTATION_SET_DRUG_CHINACOMPANYSALES]: (state, payload) => {
    state.chinaCompanySales = { ...state.chinaCompanySales, ...payload }
  },
  [types.MUTATION_SET_DRUG_GLOBALCOMPANYSALES]: (state, payload) => {
    state.globalCompanySales = { ...state.globalCompanySales, ...payload }
  },
  [types.MUTATION_SET_DRUGFORMSALES]: (state, payload) => {
    state.drugFormSales = { ...state.drugFormSales, ...payload }
  },
  [types.MUTATION_SET_DEPARTMENTANALYSIS]: (state, payload) => {
    state.departmentAnalysis = { ...state.departmentAnalysis, ...payload }
  },
  [types.MUTATION_SET_REPLACERISKANALYSIS]: (state, payload) => {
    state.replaceRiskAnalysis = { ...state.replaceRiskAnalysis, ...payload }
  },
  [types.MUTATION_SET_PATIENTFLOW]: (state, payload) => {
    state.patientFlow = { ...state.patientFlow, ...payload }
  },
  [types.MUTATION_SET_CLINICAL]: (state, payload) => {
    state.clinical = { ...state.clinical, ...payload }
  },
  [types.MUTATION_SET_ADVERSEREACTIONS]: (state, payload) => {
    state.adverseReactions = { ...state.adverseReactions, ...payload }
  },
  [types.MUTATION_SET_DRUGFORMJOINTCOMMONNAME]: (state, payload) => {
    state.drugFormJointCommonName = {
      ...state.drugFormJointCommonName,
      ...payload,
    }
  },
  [types.MUTATION_SET_DRUGFORMJOINTCOMMONNAME]: (state, payload) => {
    state.drugFormJointCommonName = {
      ...state.drugFormJointCommonName,
      ...payload,
    }
  },
  [types.MUTATION_SET_DRUGTYPE]: (state, payload) => {
    state.drugType = { ...state.drugType, ...payload }
  },
  [types.MUTATION_SET_BIOLOGICALDRUGCLASSIFICATION]: (state, payload) => {
    state.biologicalDrugClassification = {
      ...state.biologicalDrugClassification,
      ...payload,
    }
  },
  [types.MUTATION_SET_TREATMENTTYPE]: (state, payload) => {
    state.treatmentType = { ...state.treatmentType, ...payload }
  },
  [types.MUTATION_SET_COUNTRY]: (state, payload) => {
    state.country = { ...state.country, ...payload }
  },
}

function filterObj(value) {
  let obj = {}
  Object.keys(value).forEach((item) => {
    obj[item] = ["[]", "0"].includes(JSON.stringify(value[item])) ? null : value[item]
  })
  return obj
}
export default {
  module: "Drug",
  state,
  actions,
  getters,
  mutations,
}
