/**
 * 根据环境向外抛出URL
 **/

const url = {
  BaseUrl: "/",
}
const env = process.env.VUE_APP_ENV
if (env === "development") {
  url.BaseUrl = "/"
  // url.BaseUrl = "http://pharma1.pharmadl.test/"
}
export default url
