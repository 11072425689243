import types from "@/type"
import ajaxApi from "../../../../config/request"
import { post, get } from "@/utils/request"
let state = {
  activeTab: {
    // 激活tab页
    type: 1, // 默认按品规+企业查看
    moduleName: "按品规+企业查看",
  },
  filterParams: {
    // 左侧筛选条件
  },
  // 筛选条件回显
  echoParams: [],
}
let actions = {
  // 查询表格数据
  [types.ACTIONS_GLOBALNEWDRUG_MEDICALINSURANCELEVEL]: (store, params) => {
    let url = ajaxApi.GlobalNewDrug.MedicalInsuranceLevel
    return get(url, params)
  },
  // 查询医保层次数据
  [types.ACTIONS_GLOBALNEWDRUG_GETTABLEDATA]: (store, params) => {
    let url = ajaxApi.GlobalNewDrug.GetTableList
    return post(url, params)
  },
  // 导出表格数据
  [types.ACTIONS_GLOBALNEWDRUG_DOWNLOADTABLEDATA]: (store, params) => {
    let url = ajaxApi.GlobalNewDrug.DownloadTableData
    return post(url, params)
  },
  // 模糊查询下拉框
  [types.ACTIONS_GLOBALNEWDRUG_RECOMMENDEDWORDS]: (store, params) => {
    let url = ajaxApi.GlobalNewDrug.RecommendedWords
    return post(url, params)
  },
  // select下拉框查询
  [types.ACTIONS_GLOBALNEWDRUG_GETOPTIONSLIST]: (store, params) => {
    let url = ajaxApi.GlobalNewDrug.GetFilterOptions
    return post(url, params)
  },
  // 点击数字弹框查询
  [types.ACTIONS_GLOBALNEWDRUG_SHOWCOUNTOPENDATA]: (store, params) => {
    let url = ajaxApi.GlobalNewDrug.ShowCountOpenData + `?id=${params.id}&type=${params.type}`
    return get(url)
  },
  // 点击获取调整状态
  [types.ACTIONS_GLOBALNEWDRUG_TIMEAXIS]: (store, params) => {
    let url = ajaxApi.GlobalNewDrug.GetTimeAxis + `?id=${params.id}&type=${params.type}`
    return get(url)
  },
}
let getters = {
  // 激活tab页参数
  [types.GETTERS_GLOBALNEWDRUG_ACTIVETAB]: (state) => {
    return state.activeTab
  },
  [types.GETTERS_GLOBALNEWDRUG_FILTERPARAMS]: (state) => {
    return state.filterParams
  },
  [types.GETTERS_GLOBALNEWDRUG_ECHOPARAMS]: (state) => {
    return state.echoParams
  },
}
let mutations = {
  // 激活tab页参数
  [types.MUTATION_GLOBALNEWDRUG_ACTIVETAB]: (state, payload) => {
    state.activeTab = {
      ...state.activeTab,
      ...payload,
    }
  },
  [types.MUTATION_GLOBALNEWDRUG_FILTERPARAMS]: (state, payload) => {
    if (!payload) {
      state.filterParams = {}
    } else {
      state.filterParams = {
        ...state.filterParams,
        ...payload,
      }
    }
  },
  [types.MUTATION_GLOBALNEWDRUG_ECHOPARAMS]: (state, payload) => {
    state.echoParams = payload
  },
}

export default {
  module: "GlobalNewDrug",
  state,
  actions,
  getters,
  mutations,
}
