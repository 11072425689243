import Vue from "vue"
import Vuex from "vuex"
import state from "./state"
import actions from "./actions"
import mutations from "./mutations"
import getters from "./getters"
import modules from "./modules"
Vue.use(Vuex)

let store = new Vuex.Store({
  // strict: true,
  state,
  actions,
  mutations,
  getters,
  modules,
})

export default store

// export default new Vuex.Store({
//   state: {
//     isRouterAlive: true,
//   },
//   mutations: {
//     setIsRouterAlive(state, data) {
//       state.isRouterAlive = data;
//     },
//   },
//   actions: {
//     setIsRouterAlive(context, data) {
//       context.commit("setIsRouterAlive", data);
//     },
//   },
//   modules: {},
// });
