//outside.js

export default {
  bind(el, binding, vnode) {
    // Define a function to handle clicks outside the element
    const handler = (e) => {
      if (!el.contains(e.target)) {
        vnode.context[binding.expression](e);
      }
    };

    // Add the event listener when the directive is bound
    document.addEventListener('click', handler);
    
    // Store the handler function on the element so we can remove it later
    el._clickOutsideHandler = handler;
  },
  
  unbind(el) {
    // Remove the event listener when the directive is unbound
    document.removeEventListener('click', el._clickOutsideHandler);
    
    // Clean up the stored handler function
    delete el._clickOutsideHandler;
  },
}
