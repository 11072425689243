<template>
  <div v-show="visible" class="loading-wrap">
    <div class="loading-box">
      <looping-rhombuses-spinner :animation-duration="2500" :rhombus-size="10" color="#548FC2" />
    </div>
  </div>
</template>

<script>
import { HollowDotsSpinner } from "epic-spinners"
import { LoopingRhombusesSpinner } from "epic-spinners"
export default {
  components: {
    HollowDotsSpinner,
    LoopingRhombusesSpinner,
  },
  data() {
    return {
      visible: true,
    }
  },
}
</script>
<style lang="less" scoped>
.loading-wrap {
  position: absolute;
  z-index: 10;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  // background: rgba(0, 0, 0, 0.1);
}
.loading-box {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100px;
  transform: translate(-50%, -50%);
}
</style>
