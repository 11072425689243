import axios from "../../http"

export function post(url, data = {}) {
  const { params = data, config = {} } = data
  return axios
    .post(url, params, config)
    .then((res) => {
      if (res) {
        return res.data
      }
    })
    .catch((e) => {
      throw new Error(e)
    })
}

export function get(url, params) {
  return axios
    .get(url, { params })
    .then((res) => {
      if (res) {
        return res.data
      }
    })
    .catch((e) => {
      throw new Error(e)
    })
}
