import types from "@/type"
import ajaxApi from "../../../../config/request"
import { post, get } from "@/utils/request"
let state = {
    filterParams: {
        // 左侧筛选条件
    },
    // 筛选条件回显
    echoParams: [],
}
let actions = {
    // 查询表格数据
    [types.ACTIONS_ONEMARKETINSURANCE_GETTABLEDATA]: (store, params) => {
        let url = ajaxApi.OneMarketInsurance.GetTableList
        return post(url, params)
    },
    // 文件下载前的校验
    [types.ACTIONS_ONEMARKETINSURANCE_CHECKDOWNLOADFILE]: (store, params) => {
        let url = ajaxApi.OneMarketInsurance.CheckDownloadFile
        return post(url, params)
    },
    // 导出表格数据
    [types.ACTIONS_ONEMARKETINSURANCE_DOWNLOADTABLEDATA]: (store, params) => {
        let url = ajaxApi.OneMarketInsurance.DownloadTableData
        return post(url, params)
    },
    // 模糊查询下拉框
    [types.ACTIONS_ONEMARKETINSURANCE_RECOMMENDEDWORDS]: (store, params) => {
        let url = ajaxApi.OneMarketInsurance.RecommendedWords
        return post(url, params)
    },
    // select下拉框查询
    [types.ACTIONS_ONEMARKETINSURANCE_GETOPTIONSLIST]: (store, params) => {
        let url = ajaxApi.OneMarketInsurance.GetFilterOptions
        return post(url, params)
    },
}
let getters = {
    [types.GETTERS_ONEMARKETINSURANCE_FILTERPARAMS]: (state) => {
        return state.filterParams
    },
    [types.GETTERS_ONEMARKETINSURANCE_ECHOPARAMS]: (state) => {
        return state.echoParams
    },
}
let mutations = {
    [types.MUTATION_ONEMARKETINSURANCE_FILTERPARAMS]: (state, payload) => {
        if (!payload) {
            state.filterParams = {}
        } else {
            state.filterParams = {
                ...state.filterParams,
                ...payload,
            }
        }
    },
    [types.MUTATION_ONEMARKETINSURANCE_ECHOPARAMS]: (state, payload) => {
        state.echoParams = payload
    },
}

export default {
    module: "OneMarketInsurance",
    state,
    actions,
    getters,
    mutations,
}
