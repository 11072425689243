import types from "@/type"
import axios from "../../../../http"
import ajaxApi from "../../../../config/request"
import { post } from "@/utils/request"
let state = {
  filterParams: {
    // 左侧筛选条件
  },
  // 筛选条件回显
  echoParams: [],
}
let actions = {
  // 查询表格数据
  [types.ACTIONS_TREATMENTGUIDE_GETTABLEDATA]: (store, params) => {
    let url = ajaxApi.TreatmentGuide.GetTableList
    return post(url, params)
  },
  // 导出表格数据
  [types.ACTIONS_TREATMENTGUIDE_DOWNLOADTABLEDATA]: (store, params) => {
    let url = ajaxApi.TreatmentGuide.DownloadTableData
    return post(url, params)
  },
  // select下拉框查询
  [types.ACTIONS_TREATMENTGUIDE_GETOPTIONSLIST]: (store, params) => {
    let url = ajaxApi.TreatmentGuide.GetFilterOptions
    return post(url, params)
  },
  // 文件下载判断是否为空
  [types.ACTIONS_TREATMENTGUIDE_CHECKDOWNLOADFILE]: (store, value) => {
    let url = ajaxApi.TreatmentGuide.CheckDownloadFile
    let params = {
      ...value,
    }
    return axios
      .post(url, params)
      .then((res) => {
        return res
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  // 文件下载
  [types.ACTIONS_TREATMENTGUIDE_DOWNLOADFILE]: (store, value) => {
    let url = ajaxApi.TreatmentGuide.DownloadFile
    let params = {
      ...value,
      need: true,
    }
    return axios
      .post(url, params)
      .then(() => { })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
}
let getters = {
  // 激活tab页参数
  [types.GETTERS_TREATMENTGUIDE_FILTERPARAMS]: (state) => {
    return state.filterParams
  },
  [types.GETTERS_TREATMENTGUIDE_ECHOPARAMS]: (state) => {
    return state.echoParams
  },
}
let mutations = {
  [types.MUTATION_TREATMENTGUIDE_FILTERPARAMS]: (state, payload) => {
    if (!payload) {
      state.filterParams = {}
    } else if (payload === 'reset') {
      state.filterParams = {}
    } else {
      state.filterParams = {
        ...state.filterParams,
        ...payload,
      }
    }
  },
  [types.MUTATION_TREATMENTGUIDE_ECHOPARAMS]: (state, payload) => {
    state.echoParams = payload
  },
}

export default {
  module: "TreatmentGuide",
  state,
  actions,
  getters,
  mutations,
}
