export const ACTIONS_HOME = "ACTIONS_HOME"
export const ACTIONS_GET_SYS_LIST = "ACTIONS_GET_SYS_LIST" //获取权限列表
// global
export const ACTIONS_GET_COMMON_COLLECTS = "ACTIONS_GET_COMMON_COLLECTS" // 获取公共参数
export const ACTIONS_LOGIN = "ACTIONS_LOGIN" // 登录操作
export const ACTIONS_LOGIN_OUT = "ACTION_LOGIN_OUT" // 登出操作
export const ACTIONS_GET_AUTH_ISUSER = "ACTIONS_GET_AUTH_ISUSER" // 判断用户是否在线
export const ACTIONS_GET_SYS_TIME = "ACTIONS_GET_SYS_TIME" // 获取当前系统时间
export const ACTIONS_GLOBAL_TABLIST = "ACTIONS_GLOBAL_TABLIST" //tabbarlist
export const ACTIONS_TARGET_DATASHEETDATA = "ACTIONS_TARGET_DATASHEETDATA" //设置靶点数据表数据
export const ACTIONS_SET_TABLE_COLUMN = "ACTIONS_SET_TABLE_COLUMN" //设置用户当前页面可见列列表
export const ACTIONS_GET_TABLE_COLUMN = "ACTIONS_GET_TABLE_COLUMN" //获取用户当前页面可见列列表
export const ACTIONS_GET_TABLE_COLUMNS = "ACTIONS_GET_TABLE_COLUMNS" //获取用户当前页面可见列列表
//首页
export const ACTIONS_GET_HOME_COREDATA = "ACTIONS_GET_HOME_COREDATA" //获取核心数据模块
export const ACTIONS_GET_HOME_INDUSTRYNEWS = "ACTIONS_GET_HOME_INDUSTRYNEWS" //获取行业动态
export const ACTIONS_GET_HOME_RECOMMENDEDWORDS = "ACTIONS_GET_HOME_RECOMMENDEDWORDS" //获取搜索关键字
export const ACTIONS_GET_HOME_HOTWORD = "ACTIONS_GET_HOME_HOTWORD" //获取搜索关键字
//首页一键检索
export const ACTIONS_GET_HOME_GETHOTLIST = "ACTIONS_GET_HOME_GETHOTLIST" //获取热门词汇
export const ACTIONS_GET_HOME_GETLIST = "ACTIONS_GET_HOME_GETLIST" //获取枚举数据
export const ACTIONS_GET_HOME_GETSEARCHDATA = "ACTIONS_GET_HOME_GETSEARCHDATA" //获取数据列表

//靶点
export const ACTIONS_GET_TARGET_TARGETTYPE = "ACTIONS_GET_TARGET_TARGETTYPE" //获取靶点类型
export const ACTIONS_GET_TARGET_TARGETDATATABLE = "ACTIONS_GET_TARGET_TARGETDATATABLE" //获取靶点数据列表
export const ACTIONS_GET_TARGET_DURGANALYSIS = "ACTIONS_GET_TARGET_DURGANALYSIS" //获取药物分析
export const ACTIONS_GET_TARGET_SALESANALYSIS = "ACTIONS_GET_TARGET_SALESANALYSIS" //获取中国药物销售分析
export const ACTIONS_GET_TARGET_DISEASEANALYSIS = "ACTIONS_GET_TARGET_DISEASEANALYSIS" //获取疾病分析
export const ACTIONS_GET_TARGET_COUNTRYRDMAPANALYSIS = "ACTIONS_GET_TARGET_COUNTRYRDMAPANALYSIS" //获取全球药物全国研究分析
export const ACTIONS_GET_TARGET_COMPANYANALYSIS = "ACTIONS_GET_TARGET_COMPANYANALYSIS" //获取公司分析
export const ACTIONS_GET_TARGET_SALESCOMPANY = "ACTIONS_GET_TARGET_SALESCOMPANY" //销售分析按通用名获取Top10公司
export const ACTIONS_GET_TARGET_DISEASEATLAS = "ACTIONS_GET_TARGET_DISEASEATLAS" //疾病分析
export const ACTIONS_GET_TARGET_RDANALYSIS = "ACTIONS_GET_TARGET_RDANALYSIS" //研发获批分析
export const ACTIONS_GET_TARGET_SALESANDFORECAST = "ACTIONS_GET_TARGET_SALESANDFORECAST" //获取销售总额分析与预测
export const ACTIONS_GET_TARGET_POTENTIALMARKET = "ACTIONS_GET_TARGET_POTENTIALMARKET" //获取市场潜力分析
export const ACTIONS_GET_TARGET_COMPANYSALES = "ACTIONS_GET_TARGET_COMPANYSALES" //获取公司实力
export const ACTIONS_GET_TARGET_CLINICAL = "ACTIONS_GET_TARGET_CLINICAL" //获取临床信息
export const ACTIONS_GET_TARGET_DRUGFORMSALES = "ACTIONS_GET_TARGET_DRUGFORMSALES" //获取剂型实力
export const ACTIONS_GET_TARGET_REPLACERISKANALYSIS = "ACTIONS_GET_TARGET_REPLACERISKANALYSIS" //获取代替风险
export const ACTIONS_GET_TARGET_DEPARTMENTANALYSIS = "ACTIONS_GET_TARGET_DEPARTMENTANALYSIS" //获取科室分析

export const ACTIONS_GET_TARGET_DISEASE = "ACTIONS_GET_TARGET_DISEASE" //获取疾病列表
export const ACTIONS_GET_TARGET_DRUGTYPE = "ACTIONS_GET_TARGET_DRUGTYPE" //获取药品列表
export const ACTIONS_GET_TARGET_DRUGFORMJOINTCOMMONNAME = "ACTIONS_GET_TARGET_DRUGFORMJOINTCOMMONNAME" //获通用名获取药品剂型（产品名称）
export const ACTIONS_GET_TARGET_COMMONNAME = "ACTIONS_GET_TARGET_COMMONNAME" //获通用名
export const ACTIONS_GET_TARGET_COUNTRY = "ACTIONS_GET_TARGET_COUNTRY" //获取国家列表
export const ACTIONS_GET_TARGET_COMPANY = "ACTIONS_GET_TARGET_COMPANY" //获取公司列表
export const ACTIONS_GET_TARGET_DOWNLOADTARGETRDANALYSIS = "ACTIONS_GET_TARGET_DOWNLOADTARGETRDANALYSIS" //下载靶点数据表
export const ACTIONS_GET_TARGET_DRUGCOMPARISONANALYSIS = "ACTIONS_GET_TARGET_DRUGCOMPARISONANALYSIS" //药物对比分析
export const ACTIONS_GET_TARGET_TARGETTYPENEW = "ACTIONS_GET_TARGET_TARGETTYPENEW" //获取靶点树
export const ACTIONS_GET_TARGET_RECOMMENDEDWORDS = "ACTIONS_GET_TARGET_RECOMMENDEDWORDS" //获取靶点枚举列表
export const ACTIONS_GET_TARGET_TARGETTYPEBYID = "ACTIONS_GET_TARGET_TARGETTYPEBYID" //根据ID获取靶点树

//疾病
export const ACTIONS_GET_DISEASE_DISEASETYPE = "ACTIONS_GET_DISEASE_DISEASETYPE" //获取疾病类型
export const ACTIONS_GET_DISEASE_DISEASEDATATABLE = "ACTIONS_GET_DISEASE_DISEASEDATATABLE" //获取疾病数据列表
export const ACTIONS_GET_DISEASE_DURGANALYSIS = "ACTIONS_GET_DISEASE_DURGANALYSIS" //获取药物分析
export const ACTIONS_GET_DISEASE_SALESANALYSIS = "ACTIONS_GET_DISEASE_SALESANALYSIS" //获取中国药物销售分析
export const ACTIONS_GET_DISEASE_DISEASEANALYSIS = "ACTIONS_GET_DISEASE_DISEASEANALYSIS" //获取疾病分析

export const ACTIONS_GET_DISEASE_COUNTRYRDMAPANALYSIS = "ACTIONS_GET_DISEASE_COUNTRYRDMAPANALYSIS" //获取全球药物全国研究分析
export const ACTIONS_GET_DISEASE_COMPANYANALYSIS = "ACTIONS_GET_DISEASE_COMPANYANALYSIS" //获取公司分析
export const ACTIONS_GET_DISEASE_TARGETANALYSIS = "ACTIONS_GET_DISEASE_TARGETANALYSIS" //靶点分析
export const ACTIONS_GET_DISEASE_SALESCOMPANY = "ACTIONS_GET_DISEASE_SALESCOMPANY" //靶点分析
export const ACTIONS_GET_DISEASE_SALESANDFORECAST = "ACTIONS_GET_DISEASE_SALESANDFORECAST" //获取销售总额分析与预测
export const ACTIONS_GET_DISEASE_POTENTIALMARKET = "ACTIONS_GET_DISEASE_POTENTIALMARKET" //获取市场潜力分析
export const ACTIONS_GET_DISEASE_COMPANYSALES = "ACTIONS_GET_DISEASE_COMPANYSALES" //获取公司实力
export const ACTIONS_GET_DISEASE_RDANALYSIS = "ACTIONS_GET_DISEASE_RDANALYSIS" //获取疾病研发获批分析
export const ACTIONS_GET_DISEASE_TARGET = "ACTIONS_GET_DISEASE_TARGET" //获取靶点列表
export const ACTIONS_GET_DISEASE_COMPANY = "ACTIONS_GET_DISEASE_COMPANY" //获取公司列表
export const ACTIONS_GET_DISEASE_COUNTRY = "ACTIONS_GET_DISEASE_COUNTRY" //获取国家列表
export const ACTIONS_GET_DISEASE_REPLACERISKANALYSIS = "ACTIONS_GET_DISEASE_REPLACERISKANALYSIS" //获取代替风险
export const ACTIONS_GET_DISEASE_DEPARTMENTANALYSIS = "ACTIONS_GET_DISEASE_DEPARTMENTANALYSIS" //获取科室分析
export const ACTIONS_GET_DISEASE_COMMONNAME = "ACTIONS_GET_DISEASE_COMMONNAME" //获取药品名称
export const ACTIONS_GET_DISEASE_DRUGFORMJOINTCOMMONNAME = "ACTIONS_GET_DISEASE_DRUGFORMJOINTCOMMONNAME" //获取产品名称
export const ACTIONS_GET_DISEASE_DOWNLOADDISEASEDATATABLE = "ACTIONS_GET_DISEASE_DOWNLOADDISEASEDATATABLE" //数据表下载
export const ACTIONS_GET_DISEASE_CLINICAL = "ACTIONS_GET_DISEASE_CLINICAL" //临床试验分析
export const ACTIONS_GET_DISEASE_DRUGCOMPARISONANALYSIS = "ACTIONS_GET_DISEASE_DRUGCOMPARISONANALYSIS" //药物对比分析
export const ACTIONS_GET_DISEASE_DOWNLOADCREATETOKENCLINICAL = "ACTIONS_GET_DISEASE_DOWNLOADCREATETOKENCLINICAL" //药物对比分析
export const ACTIONS_GET_DISEASE_DOWNLOADSALESANDFORECAST = "ACTIONS_GET_DISEASE_DOWNLOADSALESANDFORECAST" //下载销售分析 疾病详情-药物详情
export const ACTIONS_GET_DISEASE_DOWNLOADCOMPANYSALES = "ACTIONS_GET_DISEASE_DOWNLOADCOMPANYSALES" //下载公司实力分析 疾病详情-药物详情
export const ACTIONS_GET_DISEASE_DOWNLOADPOTENTIALMARKET = "ACTIONS_GET_DISEASE_DOWNLOADPOTENTIALMARKET" //销售市场分析下载 疾病详情-药物详情
export const ACTIONS_GET_DISEASE_DISEASETYPENEW = "ACTIONS_GET_DISEASE_DISEASETYPENEW" //获取疾病树
export const ACTIONS_GET_DISEASE_RECOMMENDEDWORDS = "ACTIONS_GET_DISEASE_RECOMMENDEDWORDS" //获取疾病枚举列表
export const ACTIONS_GET_DISEASE_DISEASETYPEBYID = "ACTIONS_GET_DISEASE_DISEASETYPEBYID" //根据ID获取疾病树

//公司
export const ACTIONS_GET_ENTERPRISE_DURGANALYSIS = "ACTIONS_GET_ENTERPRISE_DURGANALYSIS" //获取药物分析
export const ACTIONS_GET_ENTERPRISE_DOMESTICSALESANALYSIS = "ACTIONS_GET_ENTERPRISE_DOMESTICSALESANALYSIS" //获取中国药物销售分析
export const ACTIONS_GET_ENTERPRISE_GLOBALSALESANALYSIS = "ACTIONS_GET_ENTERPRISE_GLOBALSALESANALYSIS" //获取全球药物销售分析
export const ACTIONS_GET_ENTERPRISE_DISEASEANALYSIS = "ACTIONS_GET_ENTERPRISE_DISEASEANALYSIS" //获取疾病分析
export const ACTIONS_GET_ENTERPRISE_TARGETANALYSIS = "ACTIONS_GET_ENTERPRISE_TARGETANALYSIS" //获取靶点分析
export const ACTIONS_GET_ENTERPRISE_COUNTRYRDMAPANALYSIS = "ACTIONS_GET_ENTERPRISE_COUNTRYRDMAPANALYSIS" //获取研发地图

export const ACTIONS_GET_ENTERPRISE_DRUGRDANALYSIS = "ACTIONS_GET_ENTERPRISE_DRUGRDANALYSIS" //获取公司数据表
export const ACTIONS_GET_ENTERPRISE_DRANALYSIS = "ACTIONS_GET_ENTERPRISE_DRANALYSIS" //获取研发获批分析
export const ACTIONS_GET_ENTERPRISE_DISEASE = "ACTIONS_GET_ENTERPRISE_DISEASE" //获取疾病列表
export const ACTIONS_GET_ENTERPRISE_PHASE = "ACTIONS_GET_ENTERPRISE_PHASE" //获取阶段列表
export const ACTIONS_GET_ENTERPRISE_TARGET = "ACTIONS_GET_ENTERPRISE_TARGET" //获取靶点列表
export const ACTIONS_GET_ENTERPRISE_COMPANY = "ACTIONS_GET_ENTERPRISE_COMPANY" //获取公司列表
export const ACTIONS_GET_ENTERPRISE_DRUGFORM = "ACTIONS_GET_ENTERPRISE_DRUGFORM" //获取剂型列表
export const ACTIONS_GET_ENTERPRISE_COUNTRY = "ACTIONS_GET_ENTERPRISE_COUNTRY" //获取国家列表
export const ACTIONS_GET_ENTERPRISE_COMMONNAME = "ACTIONS_GET_ENTERPRISE_COMMONNAME" //获取药物列表
export const ACTIONS_GET_ENTERPRISE_ENTERPRISEPRODUCTSTRUCTURE = "ACTIONS_GET_ENTERPRISE_ENTERPRISEPRODUCTSTRUCTURE" //获取公司产品结构
export const ACTIONS_GET_ENTERPRISE_ENTERPRISEAREASTRUCTURE = "ACTIONS_GET_ENTERPRISE_ENTERPRISEAREASTRUCTURE" //获取公司领域分布结构
export const ACTIONS_GET_ENTERPRISE_GLOBALSTICSALESANALYSIS = "ACTIONS_GET_ENTERPRISE_GLOBALSTICSALESANALYSIS" //获取公司全球药物销售分析
export const ACTIONS_GET_ENTERPRISE_COMPANYSALESPERCENTAGE = "ACTIONS_GET_ENTERPRISE_COMPANYSALESPERCENTAGE" //获取公司销售占比分析
export const ACTIONS_GET_ENTERPRISE_COMPANYYIELD = "ACTIONS_GET_ENTERPRISE_COMPANYYIELD" //获取公司产量分析
export const ACTIONS_GET_ENTERPRISE_COMPANYSTOCK = "ACTIONS_GET_ENTERPRISE_COMPANYSTOCK" //获取公司股票月线
export const ACTIONS_GET_ENTERPRISE_COMPANYEVENT = "ACTIONS_GET_ENTERPRISE_COMPANYEVENT" //获取公司大事件
export const ACTIONS_GET_ENTERPRISE_COMPANYSALES = "ACTIONS_GET_ENTERPRISE_COMPANYSALES" //获取公司实力
export const ACTIONS_GET_ENTERPRISE_ENTERPRISEDETAIL = "ACTIONS_GET_ENTERPRISE_ENTERPRISEDETAIL" //获取公司详情
export const ACTIONS_GET_ENTERPRISE_ENTERPRISERELATION = "ACTIONS_GET_ENTERPRISE_ENTERPRISERELATION" //获取公司关联
export const ACTIONS_GET_ENTERPRISE_ENTERPRISEREFINANCIALREPORT = "ACTIONS_GET_ENTERPRISE_ENTERPRISEREFINANCIALREPORT" //获取公司财务指标
export const ACTIONS_GET_ENTERPRISE_ENTERPRISEREFINANCIALREPORTYEARLY = "ACTIONS_GET_ENTERPRISE_ENTERPRISEREFINANCIALREPORTYEARLY" //获取公司财务年度
export const ACTIONS_GET_ENTERPRISE_DOWNLOADENTERPRISERDANALYSIS = "ACTIONS_GET_ENTERPRISE_DOWNLOADENTERPRISERDANALYSIS" //公司数据表下载
export const ACTIONS_GET_ENTERPRISE_CLINICAL = "ACTIONS_GET_ENTERPRISE_CLINICAL" //临床试验分析
export const ACTIONS_GET_ENTERPRISE_ENTERPRISEANNUALREPORTTAG = "ACTIONS_GET_ENTERPRISE_ENTERPRISEANNUALREPORTTAG" //公司年报数量
//药物
export const ACTIONS_GET_DURGANALYSIS = "ACTIONS_GET_DURGANALYSIS" //获取药物分析
export const ACTIONS_GET_ENTERPRISEANALYSIS = "ACTIONS_GET_ENTERPRISEANALYSIS" //获取公司分析
export const ACTIONS_GET_DOMESTICSALESANALYSIS = "ACTIONS_GET_DOMESTICSALESANALYSIS" //获取中国药物销售分析
export const ACTIONS_GET_GLOBALSALESANALYSIS = "ACTIONS_GET_GLOBALSALESANALYSIS" //获取全球药物销售分析
export const ACTIONS_GET_ENTERPRISESALESANALYSIS = "ACTIONS_GET_ENTERPRISESALESANALYSIS" //获取药品销售分析使用通用名查企业
export const ACTIONS_GET_DISEASEANALYSIS = "ACTIONS_GET_DISEASEANALYSIS" //获取全球疾病阶段分析
export const ACTIONS_GET_TARGETANALYSIS = "ACTIONS_GET_TARGETANALYSIS" //获取全球药物靶点阶段分析
export const ACTIONS_GET_COUNTRYRDMAPANALYSIS = "ACTIONS_GET_COUNTRYRDMAPANALYSIS" //获取全球药物全国研究分析
export const ACTIONS_GET_DRUGRDANALYSIS = "ACTIONS_GET_DRUGRDANALYSIS" //获取药品研发分析
export const ACTIONS_GET_DRUGDETAIL = "ACTIONS_GET_DRUGDETAIL" //获取药品详情
export const ACTIONS_GET_RDANALYSIS = "ACTIONS_GET_RDANALYSIS" //获取研发获批分析
export const ACTIONS_GET_DRUG_SALESANDFORECAST = "ACTIONS_GET_DRUG_SALESANDFORECAST" //获取销售总额分析与预测
export const ACTIONS_GET_POTENTIALMARKET = "ACTIONS_GET_POTENTIALMARKET" //获取市场潜力分析
export const ACTIONS_GET_DRUG_COMPANYSALES = "ACTIONS_GET_DRUG_COMPANYSALES" //获取公司实力
export const ACTIONS_GET_CLINICAL = "ACTIONS_GET_CLINICAL" //获取临床信息
export const ACTIONS_GET_DRUGFORMSALES = "ACTIONS_GET_DRUGFORMSALES" //获取剂型实力
export const ACTIONS_GET_REPLACERISKANALYSIS = "ACTIONS_GET_REPLACERISKANALYSIS" //获取代替风险
export const ACTIONS_GET_DEPARTMENTANALYSIS = "ACTIONS_GET_DEPARTMENTANALYSIS" //获取科室分析
export const ACTIONS_GET_PATIENTFLOW = "ACTIONS_GET_PATIENTFLOW" //获取患者流
export const ACTIONS_GET_ADVERSEREACTIONS = "ACTIONS_GET_ADVERSEREACTIONS" //获取不良反应报告
export const ACTIONS_GET_DISEASE = "ACTIONS_GET_DISEASE" //获取疾病列表
export const ACTIONS_GET_PHASE = "ACTIONS_GET_PHASE" //获取阶段列表
export const ACTIONS_GET_TARGET = "ACTIONS_GET_TARGET" //获取靶点列表
export const ACTIONS_GET_COMPANY = "ACTIONS_GET_COMPANY" //获取公司列表
export const ACTIONS_GET_DRUGFORM = "ACTIONS_GET_DRUGFORM" //获取剂型列表
export const ACTIONS_GET_COUNTRY = "ACTIONS_GET_COUNTRY" //获取国家列表
export const ACTIONS_GET_DRUGFORMJOINTCOMMONNAME = "ACTIONS_GET_DRUGFORMJOINTCOMMONNAME" //获通用名获取药品剂型（产品名称）
export const ACTIONS_GET_DRUGTYPE = "ACTIONS_GET_DRUGTYPE" //获取药品分类
export const ACTIONS_GET_BIOLOGICALDRUGCLASSIFICATION = "ACTIONS_GET_BIOLOGICALDRUGCLASSIFICATION" //获取开发药物技术分类
export const ACTIONS_GET_TREATMENTTYPE = "ACTIONS_GET_TREATMENTTYPE" //获取开发药物治疗类别
export const ACTIONS_GET_DOWNLOADDRUGRDANALYSIS = "ACTIONS_GET_DOWNLOADDRUGRDANALYSIS" //下载药物数据表
export const ACTIONS_GET_DOWNLOADCREATETOKENADVERSEREACTIONS = "ACTIONS_GET_DOWNLOADCREATETOKENADVERSEREACTIONS" //下载不良反应的表格
export const ACTIONS_GET_DOWNLOADCREATETOKENCLINICAL = "ACTIONS_GET_DOWNLOADCREATETOKENCLINICAL" //下载药物数据表
export const ACTIONS_GET_DRUG_DOWNLOADSALESANDFORECAST = "ACTIONS_GET_DRUG_DOWNLOADSALESANDFORECAST" //下载销售分析 疾病详情-药物详情
export const ACTIONS_GET_DRUG_DOWNLOADDRUGFORMSALES = "ACTIONS_GET_DRUG_DOWNLOADDRUGFORMSALES" //剂型分析的下载 疾病详情-药物详情
export const ACTIONS_GET_DRUG_DOWNLOADCOMPANYSALES = "ACTIONS_GET_DRUG_DOWNLOADCOMPANYSALES" //下载公司实力分析 疾病详情-药物
export const ACTIONS_GET_DRUG_DOWNLOADRDANALYSIS = "ACTIONS_GET_DRUG_DOWNLOADRDANALYSIS" //下载公司实力分析 疾病详情-研发获批分析

// 申报
export const ACTIONS_GET_RECOMMENDEDWORD = "ACTIONS_GET_RECOMMENDEDWORD" //检索条件枚举字典
export const ACTIONS_GET_DECLARECATEGORY = "ACTIONS_GET_DECLARECATEGORY"
export const ACTIONS_GET_ASSESSREASON = "ACTIONS_GET_ASSESSREASON" //获取优先审评理由
export const ACTIONS_GET_URGENTREASON = "ACTIONS_GET_URGENTREASON" //临床急需品种理由
export const ACTIONS_GET_ADMINISTRATIONROUTE = "ACTIONS_GET_ADMINISTRATIONROUTE" //获取给药途径数据
export const ACTIONS_GET_DOSAGEDATA = "ACTIONS_GET_DOSAGEDATA" //获取剂型数据
export const ACTIONS_GET_REGISTERCLASSIFICATION = "ACTIONS_GET_REGISTERCLASSIFICATION" //获取注册分类二级联动数据
export const ACTIONS_GET_TECHNOLOGYLABEL = "ACTIONS_GET_TECHNOLOGYLABEL" //获取热门热门标签数据
export const ACTIONS_GET_THERAPEUTICFIRLDPHIIC = "ACTIONS_GET_THERAPEUTICFIRLDPHIIC" //治疗领域PHIIC二级联动数据接口
export const ACTIONS_GET_THERAPEUTICFIRLDATC = "ACTIONS_GET_THERAPEUTICFIRLDATC" //治疗领域ATC多级联动数据接口
export const ACTIONS_GET_DECLARE_COLUMNSDATA = "ACTIONS_GET_DECLARE_COLUMNSDATA" //数据表数据
export const ACTIONS_GET_DECLARE_COLUMNSDATADOWNLOAD = "ACTIONS_GET_DECLARE_COLUMNSDATADOWNLOAD" //下载数据表
export const ACTIONS_GET_DECLAREDETAIL = "ACTIONS_GET_DECLAREDETAIL" //申报详情基础信息
export const ACTIONS_GET_DECLAREDETAILTIMELINE = "ACTIONS_GET_DECLAREDETAILTIMELINE" //申报详情时间轴
export const ACTIONS_GET_RESEARCHDEVELOPMENTSAMEVARIETY = "ACTIONS_GET_RESEARCHDEVELOPMENTSAMEVARIETY" //受理号申报信息的表格数据
export const ACTIONS_GET_DOWNLOADRESEARCHDEVELOPMENTSAMEVARIETY = "ACTIONS_GET_DOWNLOADRESEARCHDEVELOPMENTSAMEVARIETY" //受理号申报信息的表格数据下载

// 上市
export const ACTIONS_PRODUCT_GETLICENSENOS = "ACTIONS_PRODUCT_GETLICENSENOS" //批准文号
export const ACTIONS_PRODUCT_GETPATENTNOS = "ACTIONS_PRODUCT_GETPATENTNOS" //专利号
export const ACTIONS_PRODUCT_GETALLHOTLABEL = "ACTIONS_PRODUCT_GETALLHOTLABEL" //热门标签
export const ACTIONS_PRODUCT_HOSPITALDEPARTMENT = "ACTIONS_PRODUCT_HOSPITALDEPARTMENT" //处方科室
export const ACTIONS_PRODUCT_GETDETAILDATA = "ACTIONS_PRODUCT_GETDETAILDATA" //表格数据的获取
export const ACTIONS_PRODUCT_DOWNLOADGETDETAILDATA = "ACTIONS_PRODUCT_DOWNLOADGETDETAILDATA" //表格数据的下载
export const ACTIONS_PRODUCT_CHECKDOWNLOADFILE = "ACTIONS_PRODUCT_CHECKDOWNLOADFILE" //文件下载判断数据是否为空
export const ACTIONS_PRODUCT_DOWNLOADFILE = "ACTIONS_PRODUCT_DOWNLOADFILE" //文件下载
export const ACTIONS_PRODUCT_GETTOTALNUMBER = "ACTIONS_PRODUCT_GETTOTALNUMBER" //获取全部结果数量
export const ACTIONS_PRODUCT_GETPARTTOTALNUMBER = "ACTIONS_PRODUCT_GETPARTTOTALNUMBER" //获取筛选的各部分结果数量
export const ACTIONS_PRODUCT_DOWNLOADSAMEAPPROVALNUMBER = "ACTIONS_PRODUCT_DOWNLOADSAMEAPPROVALNUMBER" //同品种批文数列表下载
export const ACTIONS_PRODUCT_DOWNLOADSAMEENTERPRISESNUMBER = "ACTIONS_PRODUCT_DOWNLOADSAMEENTERPRISESNUMBER" //同品种企业数列表下载
export const ACTIONS_PRODUCT_DOWNLOADSAMEEVALUATIONENTERPRISESNUMBER = "ACTIONS_PRODUCT_DOWNLOADSAMEEVALUATIONENTERPRISESNUMBER" //通过一致性评价企业数列表下载
export const ACTIONS_PRODUCT_SAMEPATENTNUMBER = "ACTIONS_PRODUCT_SAMEPATENTNUMBER" //登记专利数列表
export const ACTIONS_PRODUCT_DOWNLOADSAMEPATENTNUMBER = "ACTIONS_PRODUCT_DOWNLOADSAMEPATENTNUMBER" //登记专利数列表下载
export const ACTIONS_PRODUCT_PULLDOWNCATEGORY = "ACTIONS_PRODUCT_PULLDOWNCATEGORY" //下拉框选择项
export const ACTIONS_PRODUCT_MEDICALINSURANCE = "ACTIONS_PRODUCT_MEDICALINSURANCE" //医保目录
export const ACTIONS_PRODUCT_PURCHASEVARIETIES = "ACTIONS_PRODUCT_PURCHASEVARIETIES" //带量采购品种
export const ACTIONS_PRODUCT_COUNTINGBULLETBOX = "ACTIONS_PRODUCT_COUNTINGBULLETBOX" //列表数字点击展示数据
export const ACTIONS_PRODUCT_GETDRUGNAMEDATA = "ACTIONS_PRODUCT_GETDRUGNAMEDATA" //药品名称枚举
export const ACTIONS_PRODUCT_GETCOMPANYDATA = "ACTIONS_PRODUCT_GETCOMPANYDATA" //公司/持证商多选列表
export const ACTIONS_PRODUCT_ADMINISTRATIONROUTES = "ACTIONS_PRODUCT_ADMINISTRATIONROUTES" //给药途径
export const ACTIONS_PRODUCT_DOSAGEDATA = "ACTIONS_PRODUCT_DOSAGEDATA" //剂型
export const ACTIONS_PRODUCT_GETDRUGTYPE = "ACTIONS_PRODUCT_GETDRUGTYPE" //药品类型
export const ACTIONS_PRODUCT_SIMPLIFYCONSISTENCYEVALUATION = "ACTIONS_PRODUCT_SIMPLIFYCONSISTENCYEVALUATION" //豁免或简化一致性评价品种
export const ACTIONS_PRODUCT_RECOMMENDEDWORDS = "ACTIONS_PRODUCT_RECOMMENDEDWORDS" //检索条件枚举字典
export const ACTIONS_PRODUCT_SHOWCOUNTOPENDATA = "ACTIONS_PRODUCT_SHOWCOUNTOPENDATA" //点击数字弹窗
export const ACTIONS_PRODUCT_AUTOCOMPLETE = "ACTIONS_PRODUCT_AUTOCOMPLETE" // 下拉框联想查询
//新版上市
export const ACTIONS_MARKET_GETTABLELIST = "ACTIONS_MARKET_GETTABLELIST" //表格数据
export const ACTIONS_MARKET_DOWNLOADTABLEDATA = "ACTIONS_MARKET_DOWNLOADTABLEDATA" // 下载表格数据
export const ACTIONS_MARKET_CHECKDOWNLOADFILE = "ACTIONS_MARKET_CHECKDOWNLOADFILE" // 文件下载判断数据是否为空
export const ACTIONS_MARKET_RECOMMENDEDWORDS = "ACTIONS_MARKET_RECOMMENDEDWORDS" //检索条件枚举字典
export const ACTIONS_MARKET_GETOPTIONSLIST = "ACTIONS_MARKET_GETOPTIONSLIST" // 查询下拉框
export const ACTIONS_MARKET_SHOWCOUNTOPENDATA = "ACTIONS_MARKET_SHOWCOUNTOPENDATA" // 点击数字弹框列表
export const ACTIONS_MARKET_GETTOTALNUMBER = "ACTIONS_MARKET_GETTOTALNUMBER" // 查询国别TAB上数量
export const ACTIONS_MARKET_GETBELISTTYPE = "ACTIONS_MARKET_GETBELISTTYPE" // 查询豁免或简化一致性评价品种
// 临床
export const ACTIONS_CLINICAL_GETTABLEDATA = "ACTIONS_CLINICAL_GETTABLEDATA" // 查询表格数据
export const ACTIONS_CLINICAL_DOWNLOADTABLEDATA = "ACTIONS_CLINICAL_DOWNLOADTABLEDATA" // 下载表格数据
export const ACTIONS_CLINICAL_CHECKDOWNLOADFILE = "ACTIONS_CLINICAL_CHECKDOWNLOADFILE" // 文件下载判断数据是否为空
export const ACTIONS_CLINICAL_DOWNLOADFILE = "ACTIONS_CLINICAL_DOWNLOADFILE" // 文件下载
export const ACTIONS_CLINICAL_RECOMMENDEDWORDS = "ACTIONS_CLINICAL_RECOMMENDEDWORDS" //检索条件枚举字典
export const ACTIONS_CLINICAL_GETOPTIONSLIST = "ACTIONS_CLINICAL_GETOPTIONSLIST" // 查询下拉框
export const ACTIONS_CLINICAL_SHOWCOUNTOPENDATA = "ACTIONS_CLINICAL_SHOWCOUNTOPENDATA" // 点击数字弹框列表
export const ACTIONS_CLINICAL_GETTOTALNUMBER = "ACTIONS_CLINICAL_GETTOTALNUMBER" // 查询TAB上数量
export const ACTIONS_CLINICAL_BASICINFO = "ACTIONS_CLINICAL_BASICINFO" // 详情页-基本信息
export const ACTIONS_CLINICAL_TIMEAXIS = "ACTIONS_CLINICAL_TIMEAXIS" // 详情页- 时光轴
export const ACTIONS_CLINICAL_TRIALGROUP = "ACTIONS_CLINICAL_TRIALGROUP" // 详情页-试验设计分组
export const ACTIONS_CLINICAL_TRIALINTRODUCTION = "ACTIONS_CLINICAL_TRIALINTRODUCTION" // 详情页-试验介绍
export const ACTIONS_CLINICAL_ENDTARGET = "ACTIONS_CLINICAL_ENDTARGET" // 详情页-终点指标
export const ACTIONS_CLINICAL_SUBJECTSTANDARD = "ACTIONS_CLINICAL_SUBJECTSTANDARD" // 详情页-受试者标准
export const ACTIONS_CLINICAL_PARTICIPATECOMPANY = "ACTIONS_CLINICAL_PARTICIPATECOMPANY" // 详情页-参与机构
export const ACTIONS_CLINICAL_ARTICLE = "ACTIONS_CLINICAL_ARTICLE" // 详情页-文献
// 医保目录
export const ACTIONS_MEDICARECATALOG_GETTABLEDATA = "ACTIONS_MEDICARECATALOG_GETTABLEDATA" // 查询表格数据
export const ACTIONS_MEDICARECATALOG_DOWNLOADTABLEDATA = "ACTIONS_MEDICARECATALOG_DOWNLOADTABLEDATA" // 下载表格数据
export const ACTIONS_MEDICARECATALOG_CHECKDOWNLOADFILE = "ACTIONS_MEDICARECATALOG_CHECKDOWNLOADFILE" // 文件下载判断数据是否为空
export const ACTIONS_MEDICARECATALOG_DOWNLOADFILE = "ACTIONS_MEDICARECATALOG_DOWNLOADFILE" // 文件下载
export const ACTIONS_MEDICARECATALOG_RECOMMENDEDWORDS = "ACTIONS_MEDICARECATALOG_RECOMMENDEDWORDS" //检索条件枚举字典
export const ACTIONS_MEDICARECATALOG_GETOPTIONSLIST = "ACTIONS_MEDICARECATALOG_GETOPTIONSLIST" // 查询下拉框
export const ACTIONS_MEDICARECATALOG_SHOWCOUNTOPENDATA = "ACTIONS_MEDICARECATALOG_SHOWCOUNTOPENDATA" // 点击数字弹框列表
export const ACTIONS_MEDICARECATALOG_TIMEAXIS = "ACTIONS_MEDICARECATALOG_TIMEAXIS" // 详情页- 时光轴
export const ACTIONS_MEDICARECATALOG_MEDICALINSURANCELEVEL = "ACTIONS_MEDICARECATALOG_MEDICALINSURANCELEVEL" // 获取医保版本
// 全球新药
export const ACTIONS_GLOBALNEWDRUG_GETTABLEDATA = "ACTIONS_GLOBALNEWDRUG_GETTABLEDATA" // 查询表格数据
export const ACTIONS_GLOBALNEWDRUG_DOWNLOADTABLEDATA = "ACTIONS_GLOBALNEWDRUG_DOWNLOADTABLEDATA" // 下载表格数据
export const ACTIONS_GLOBALNEWDRUG_CHECKDOWNLOADFILE = "ACTIONS_GLOBALNEWDRUG_CHECKDOWNLOADFILE" // 文件下载判断数据是否为空
export const ACTIONS_GLOBALNEWDRUG_DOWNLOADFILE = "ACTIONS_GLOBALNEWDRUG_DOWNLOADFILE" // 文件下载
export const ACTIONS_GLOBALNEWDRUG_RECOMMENDEDWORDS = "ACTIONS_GLOBALNEWDRUG_RECOMMENDEDWORDS" //检索条件枚举字典
export const ACTIONS_GLOBALNEWDRUG_GETOPTIONSLIST = "ACTIONS_GLOBALNEWDRUG_GETOPTIONSLIST" // 查询下拉框
export const ACTIONS_GLOBALNEWDRUG_SHOWCOUNTOPENDATA = "ACTIONS_GLOBALNEWDRUG_SHOWCOUNTOPENDATA" // 点击数字弹框列表
export const ACTIONS_GLOBALNEWDRUG_TIMEAXIS = "ACTIONS_GLOBALNEWDRUG_TIMEAXIS" // 详情页- 时光轴
export const ACTIONS_GLOBALNEWDRUG_MEDICALINSURANCELEVEL = "ACTIONS_GLOBALNEWDRUG_MEDICALINSURANCELEVEL" // 获取医保版本
// 基药目录
export const ACTIONS_ESSENTIALDRUG_GETTABLEDATA = "ACTIONS_ESSENTIALDRUG_GETTABLEDATA" // 查询表格数据
export const ACTIONS_ESSENTIALDRUG_DOWNLOADTABLEDATA = "ACTIONS_ESSENTIALDRUG_DOWNLOADTABLEDATA" // 下载表格数据
export const ACTIONS_ESSENTIALDRUG_CHECKDOWNLOADFILE = "ACTIONS_ESSENTIALDRUG_CHECKDOWNLOADFILE" // 文件下载判断数据是否为空
export const ACTIONS_ESSENTIALDRUG_DOWNLOADFILE = "ACTIONS_ESSENTIALDRUG_DOWNLOADFILE" // 文件下载
export const ACTIONS_ESSENTIALDRUG_RECOMMENDEDWORDS = "ACTIONS_ESSENTIALDRUG_RECOMMENDEDWORDS" //检索条件枚举字典
export const ACTIONS_ESSENTIALDRUG_GETOPTIONSLIST = "ACTIONS_ESSENTIALDRUG_GETOPTIONSLIST" // 查询下拉框
export const ACTIONS_ESSENTIALDRUG_SHOWCOUNTOPENDATA = "ACTIONS_ESSENTIALDRUG_SHOWCOUNTOPENDATA" // 点击数字弹框列表
export const ACTIONS_ESSENTIALDRUG_TIMEAXIS = "ACTIONS_ESSENTIALDRUG_TIMEAXIS" // 详情页- 时光轴
export const ACTIONS_ESSENTIALDRUG_ESSENTIALDRUGLEVEL = "ACTIONS_ESSENTIALDRUG_ESSENTIALDRUGLEVEL" // 获取医保版本
// 原辅包
export const ACTIONS_MATERIAL_GETTABLEDATA = "ACTIONS_MATERIAL_GETTABLEDATA" // 查询表格数据
export const ACTIONS_MATERIAL_DOWNLOADTABLEDATA = "ACTIONS_MATERIAL_DOWNLOADTABLEDATA" // 下载表格数据
export const ACTIONS_MATERIAL_RECOMMENDEDWORDS = "ACTIONS_MATERIAL_RECOMMENDEDWORDS" //检索条件枚举字典
export const ACTIONS_MATERIAL_GETOPTIONSLIST = "ACTIONS_MATERIAL_GETOPTIONSLIST" // 查询下拉框
export const ACTIONS_MATERIAL_SHOWCOUNTOPENDATA = "ACTIONS_MATERIAL_SHOWCOUNTOPENDATA" // 点击数字弹框列表

// 参比制剂目录
export const ACTIONS_REFERLISTEDDRUG_GETTABLEDATA = "ACTIONS_REFERLISTEDDRUG_GETTABLEDATA" // 查询表格数据
export const ACTIONS_REFERLISTEDDRUG_DOWNLOADTABLEDATA = "ACTIONS_REFERLISTEDDRUG_DOWNLOADTABLEDATA" // 下载表格数据
export const ACTIONS_REFERLISTEDDRUG_RECOMMENDEDWORDS = "ACTIONS_REFERLISTEDDRUG_RECOMMENDEDWORDS" //检索条件枚举字典
export const ACTIONS_REFERLISTEDDRUG_GETOPTIONSLIST = "ACTIONS_REFERLISTEDDRUG_GETOPTIONSLIST" // 查询下拉框
export const ACTIONS_REFERLISTEDDRUG_CHECKDOWNLOADFILE = "ACTIONS_REFERLISTEDDRUG_CHECKDOWNLOADFILE" // 下载前校验
export const ACTIONS_REFERLISTEDDRUG_GETNUMBERBYBATCH = "ACTIONS_REFERLISTEDDRUG_GETNUMBERBYBATCH" // 根据批次号查询编号
// 一致性评价
export const ACTIONS_EQUIVALENCE_GETTABLEDATA = "ACTIONS_EQUIVALENCE_GETTABLEDATA" // 查询表格数据
export const ACTIONS_EQUIVALENCE_DOWNLOADTABLEDATA = "ACTIONS_EQUIVALENCE_DOWNLOADTABLEDATA" // 下载表格数据
export const ACTIONS_EQUIVALENCE_RECOMMENDEDWORDS = "ACTIONS_EQUIVALENCE_RECOMMENDEDWORDS" //检索条件枚举字典
export const ACTIONS_EQUIVALENCE_GETOPTIONSLIST = "ACTIONS_EQUIVALENCE_GETOPTIONSLIST" // 查询下拉框
export const ACTIONS_EQUIVALENCE_SHOWCOUNTOPENDATA = "ACTIONS_EQUIVALENCE_SHOWCOUNTOPENDATA" // 点击数字弹框列表
export const ACTIONS_EQUIVALENCE_GETTOTALNUMBER = "ACTIONS_EQUIVALENCE_GETTOTALNUMBER" // 查询TAB上数量
// 招标
export const ACTIONS_DRUGBIDDING_GETTABLEDATA = "ACTIONS_DRUGBIDDING_GETTABLEDATA" // 查询表格数据
export const ACTIONS_DRUGBIDDING_DOWNLOADTABLEDATA = "ACTIONS_DRUGBIDDING_DOWNLOADTABLEDATA" // 下载表格数据
export const ACTIONS_DRUGBIDDING_RECOMMENDEDWORDS = "ACTIONS_DRUGBIDDING_RECOMMENDEDWORDS" //检索条件枚举字典
export const ACTIONS_DRUGBIDDING_GETOPTIONSLIST = "ACTIONS_DRUGBIDDING_GETOPTIONSLIST" // 查询下拉框
export const ACTIONS_DRUGBIDDING_SHOWCOUNTOPENDATA = "ACTIONS_DRUGBIDDING_SHOWCOUNTOPENDATA" // 点击数字弹框列表
export const ACTIONS_DRUGBIDDING_GETTOTALNUMBER = "ACTIONS_DRUGBIDDING_GETTOTALNUMBER" // 查询TAB上数量
export const ACTIONS_DRUGBIDDING_BIOLOGICDRUGCLASSIFICATION = "ACTIONS_DRUGBIDDING_BIOLOGICDRUGCLASSIFICATION"
export const ACTIONS_DRUGBIDDING_TCM = "ACTIONS_DRUGBIDDING_TCM"
export const ACTIONS_DRUGBIDDING_TCMOFDISEASE = "ACTIONS_DRUGBIDDING_TCMOFDISEASE"
// 美国NDC
export const ACTIONS_AMERICANDC_GETTABLEDATA = "ACTIONS_AMERICANDC_GETTABLEDATA" // 查询表格数据
export const ACTIONS_AMERICANDC_DOWNLOADTABLEDATA = "ACTIONS_AMERICANDC_DOWNLOADTABLEDATA" // 下载表格数据
export const ACTIONS_AMERICANDC_RECOMMENDEDWORDS = "ACTIONS_AMERICANDC_RECOMMENDEDWORDS" //检索条件枚举字典
export const ACTIONS_AMERICANDC_GETOPTIONSLIST = "ACTIONS_AMERICANDC_GETOPTIONSLIST" // 查询下拉框
// 企业年报
export const ACTIONS_ANNUALREPORTS_GETTABLEDATA = "ACTIONS_ANNUALREPORTS_GETTABLEDATA" // 查询表格数据
export const ACTIONS_ANNUALREPORTS_DOWNLOADTABLEDATA = "ACTIONS_ANNUALREPORTS_DOWNLOADTABLEDATA" // 下载表格数据
export const ACTIONS_ANNUALREPORTS_RECOMMENDEDWORDS = "ACTIONS_ANNUALREPORTS_RECOMMENDEDWORDS" //检索条件枚举字典
export const ACTIONS_ANNUALREPORTS_GETOPTIONSLIST = "ACTIONS_ANNUALREPORTS_GETOPTIONSLIST" // 查询下拉框
export const ACTIONS_GET_GETANNUALREPORTSDICTIONARYJSON = "ACTIONS_GET_GETANNUALREPORTSDICTIONARYJSON"
export const ACTIONS_ANNUALREPORTS_CHECKDOWNLOADFILE = "ACTIONS_ANNUALREPORTS_CHECKDOWNLOADFILE" //文件下载判断数据是否为空
export const ACTIONS_ANNUALREPORTS_DOWNLOADFILE = "ACTIONS_ANNUALREPORTS_DOWNLOADFILE" //文件下载
// 临床路径
export const ACTIONS_CLINICALPATHWAY_GETTABLEDATA = "ACTIONS_CLINICALPATHWAY_GETTABLEDATA" // 查询表格数据
export const ACTIONS_CLINICALPATHWAY_DOWNLOADTABLEDATA = "ACTIONS_CLINICALPATHWAY_DOWNLOADTABLEDATA" // 下载表格数据
export const ACTIONS_CLINICALPATHWAY_GETOPTIONSLIST = "ACTIONS_CLINICALPATHWAY_GETOPTIONSLIST" // 查询下拉框
export const ACTIONS_CLINICALPATHWAY_CHECKDOWNLOADFILE = "ACTIONS_CLINICALPATHWAY_CHECKDOWNLOADFILE"
export const ACTIONS_CLINICALPATHWAY_DOWNLOADFILE = "ACTIONS_CLINICALPATHWAY_DOWNLOADFILE"
// 临床指南
export const ACTIONS_TREATMENTGUIDE_GETTABLEDATA = "ACTIONS_TREATMENTGUIDE_GETTABLEDATA" // 查询表格数据
export const ACTIONS_TREATMENTGUIDE_DOWNLOADTABLEDATA = "ACTIONS_TREATMENTGUIDE_DOWNLOADTABLEDATA" // 下载表格数据
export const ACTIONS_TREATMENTGUIDE_GETOPTIONSLIST = "ACTIONS_TREATMENTGUIDE_GETOPTIONSLIST" // 查询下拉框
export const ACTIONS_TREATMENTGUIDE_CHECKDOWNLOADFILE = "ACTIONS_TREATMENTGUIDE_CHECKDOWNLOADFILE"
export const ACTIONS_TREATMENTGUIDE_DOWNLOADFILE = "ACTIONS_TREATMENTGUIDE_DOWNLOADFILE"
// 专利
export const ACTIONS_GLOBALDRUGPATENTS_GETTABLEDATA = "ACTIONS_GLOBALDRUGPATENTS_GETTABLEDATA" // 查询表格数据
export const ACTIONS_GLOBALDRUGPATENTS_SAVEFAMILYORDER = "ACTIONS_GLOBALDRUGPATENTS_SAVEFAMILYORDER" // 保存查询条件
export const ACTIONS_GLOBALDRUGPATENTS_DOWNLOADTABLEDATA = "ACTIONS_GLOBALDRUGPATENTS_DOWNLOADTABLEDATA" // 下载表格数据
export const ACTIONS_GLOBALDRUGPATENTS_RECOMMENDEDWORDS = "ACTIONS_GLOBALDRUGPATENTS_RECOMMENDEDWORDS" //检索条件枚举字典
export const ACTIONS_GLOBALDRUGPATENTS_GETOPTIONSLIST = "ACTIONS_GLOBALDRUGPATENTS_GETOPTIONSLIST" // 查询下拉框
export const ACTIONS_GLOBALDRUGPATENTS_BasicInformation = "ACTIONS_GLOBALDRUGPATENTS_BasicInformation"
export const ACTIONS_GLOBALDRUGPATENTS_GetPatentsNos = "ACTIONS_GLOBALDRUGPATENTS_GetPatentsNos"
export const ACTIONS_GLOBALDRUGPATENTS_GetTimeLine = "ACTIONS_GLOBALDRUGPATENTS_GetTimeLine"
export const ACTIONS_GLOBALDRUGPATENTS_SameFamilyReference = "ACTIONS_GLOBALDRUGPATENTS_SameFamilyReference"
export const ACTIONS_GLOBALDRUGPATENTS_CountriesLegalStatus = "ACTIONS_GLOBALDRUGPATENTS_CountriesLegalStatus"
export const ACTIONS_GLOBALDRUGPATENTS_LegalEvents = "ACTIONS_GLOBALDRUGPATENTS_LegalEvents"
export const ACTIONS_GLOBALDRUGPATENTS_PatentFamily = "ACTIONS_GLOBALDRUGPATENTS_PatentFamily"
export const ACTIONS_GLOBALDRUGPATENTS_GlobalDrugPatentCount = "ACTIONS_GLOBALDRUGPATENTS_GlobalDrugPatentCount"
// 招标分析
export const ACTIONS_DRUGBIDDINGANALYSIS_GETTABLEDATA = "ACTIONS_DRUGBIDDINGANALYSIS_GETTABLEDATA" // 查询表格数据
export const ACTIONS_DRUGBIDDINGANALYSIS_DOWNLOADTABLEDATA = "ACTIONS_DRUGBIDDINGANALYSIS_DOWNLOADTABLEDATA" // 下载表格数据
export const ACTIONS_DRUGBIDDINGANALYSIS_RECOMMENDEDWORDS = "ACTIONS_DRUGBIDDINGANALYSIS_RECOMMENDEDWORDS" //检索条件枚举字典
export const ACTIONS_DRUGBIDDINGANALYSIS_SECONDSEARCHFILTER = "ACTIONS_DRUGBIDDINGANALYSIS_SECONDSEARCHFILTER" //二次检索
export const ACTIONS_DRUGBIDDINGANALYSIS_GETOPTIONSLIST = "ACTIONS_DRUGBIDDINGANALYSIS_GETOPTIONSLIST" // 查询下拉框
export const ACTIONS_DRUGBIDDINGANALYSIS_SHOWCOUNTOPENDATA = "ACTIONS_DRUGBIDDINGANALYSIS_SHOWCOUNTOPENDATA" // 点击数字弹框列表
export const ACTIONS_DRUGBIDDINGANALYSIS_GETTOTALNUMBER = "ACTIONS_DRUGBIDDINGANALYSIS_GETTOTALNUMBER" // 查询TAB上数量
export const ACTIONS_DRUGBIDDINGANALYSIS_BIOLOGICDRUGCLASSIFICATION = "ACTIONS_DRUGBIDDINGANALYSIS_BIOLOGICDRUGCLASSIFICATION"
export const ACTIONS_DRUGBIDDINGANALYSIS_TCM = "ACTIONS_DRUGBIDDINGANALYSIS_TCM"
export const ACTIONS_DRUGBIDDINGANALYSIS_TCMOFDISEASE = "ACTIONS_DRUGBIDDINGANALYSIS_TCMOFDISEASE"
//申报复用
export const ACTIONS_REGISTRATION_GETTABLEDATA = "ACTIONS_REGISTRATION_GETTABLEDATA" // 查询表格数据
export const ACTIONS_REGISTRATION_DOWNLOADTABLEDATA = "ACTIONS_REGISTRATION_DOWNLOADTABLEDATA" // 下载表格数据
export const ACTIONS_REGISTRATION_RECOMMENDEDWORDS = "ACTIONS_REGISTRATION_RECOMMENDEDWORDS" //检索条件枚举字典
export const ACTIONS_REGISTRATION_GETOPTIONSLIST = "ACTIONS_REGISTRATION_GETOPTIONSLIST" // 查询下拉框
export const ACTIONS_REGISTRATION_SHOWCOUNTOPENDATA = "ACTIONS_REGISTRATION_SHOWCOUNTOPENDATA" // 点击数字弹框列表
export const ACTIONS_REGISTRATION_GETTOTALNUMBER = "ACTIONS_REGISTRATION_GETTOTALNUMBER" // 查询TAB上数量
//个人中心/登录注册
export const ACTIONS_GET_AUTH_SENDVERIFICATIONCODEBYMOBILE = "ACTIONS_GET_AUTH_SENDVERIFICATIONCODEBYMOBILE" //发生手机验证码
export const ACTIONS_GET_AUTH_SENDVERIFICATIONCODEBYMAIL = "ACTIONS_GET_AUTH_SENDVERIFICATIONCODEBYMAIL" //发生手机验证码
export const ACTIONS_GET_AUTH_USERPROVINCE = "ACTIONS_GET_AUTH_USERPROVINCE" //获取省份列表
export const ACTIONS_GET_AUTH_REGISTER = "ACTIONS_GET_AUTH_REGISTER" //用户的注册
export const ACTIONS_GET_AUTH_LOGIN = "ACTIONS_GET_AUTH_LOGIN" //用户的登录
export const ACTIONS_GET_AUTH_ISCHECKIP = "ACTIONS_GET_AUTH_ISCHECKIP" //用户的ip校验
export const ACTIONS_GET_AUTH_VERIFYMOBILECODEFORRETRIEVEPWD = "ACTIONS_GET_AUTH_VERIFYMOBILECODEFORRETRIEVEPWD" //通过手机号找回密码功能
export const ACTIONS_GET_AUTH_VERIFYMAILCODEFORRETRIEVEPWD = "ACTIONS_GET_AUTH_VERIFYMAILCODEFORRETRIEVEPWD" //通过邮箱的找回密码功能
export const ACTIONS_GET_AUTH_RETRIEVEPWD = "ACTIONS_GET_AUTH_RETRIEVEPWD" //用户的找回密码功能
export const ACTIONS_GET_AUTH_LOGOUT = "ACTIONS_GET_AUTH_LOGOUT" //登出功能
export const ACTIONS_GET_AUTH_VERIFYCODEFORREGISTER = "ACTIONS_GET_AUTH_VERIFYCODEFORREGISTER" //验证code
export const ACTIONS_GET_AUTH_SETLOCKTAB = "ACTIONS_GET_AUTH_SETLOCKTAB" //设置锁定状态

//权限列表
export const ACTIONS_GET_MENU_LIST = "ACTIONS_GET_MENU_LIST" //用户的权限列表
export const ACTIONS_GET_AUTHSHOW = "ACTIONS_GET_AUTHSHOW" //无权限弹窗
export const ACTIONS_GET_HOME_SALESSTATISTICSYEAR = "ACTIONS_GET_HOME_SALESSTATISTICSYEAR" //无权限弹窗
//新兴靶点研发热度
export const ACTIONS_GET_RISINGTARGET = "ACTIONS_GET_RISINGTARGET" //获取新兴靶点研发热度
export const ACTIONS_GET_RISINGTARGETTREATMENTTYPE = "ACTIONS_GET_RISINGTARGETTREATMENTTYPE" //获取新兴靶点研发热度
export const ACTIONS_GET_APPROVEDAREASMAP = "ACTIONS_GET_APPROVEDAREASMAP" //药品获批领域地图
export const ACTIONS_GET_EXPLOREIMITATEDRUG = "ACTIONS_GET_EXPLOREIMITATEDRUG" //挖掘仿药老品种
export const ACTIONS_GET_EXPLOREIMITATEDRUGDATATABLE = "ACTIONS_GET_EXPLOREIMITATEDRUGDATATABLE" //挖掘仿药老品种
export const ACTIONS_GET_DRUGFORMTREE = "ACTIONS_GET_DRUGFORMTREE" //剂型
export const ACTIONS_GET_COMMONNAME = "ACTIONS_GET_COMMONNAME" //药品通用名
export const ACTIONS_GET_DRUGFORMCOMMONNAME = "ACTIONS_GET_DRUGFORMCOMMONNAME" //根据通用名获取剂型
export const ACTIONS_GET_ENTERPRISEQUERY = "ACTIONS_GET_ENTERPRISEQUERY" //获取公司列表
export const ACTIONS_GET_GETCONTRASTPRODUCTGROUPLIST = "ACTIONS_GET_GETCONTRASTPRODUCTGROUPLIST" //对比分组的列表
export const ACTIONS_GET_OFFCONTRASTPRODUCT = "ACTIONS_GET_OFFCONTRASTPRODUCT" //删除产品
export const ACTIONS_GET_RESTORECONTRASTPRODUCT = "ACTIONS_GET_RESTORECONTRASTPRODUCT" //删除产品
export const ACTIONS_GET_REMOVECONTRASTPRODUCT = "ACTIONS_GET_REMOVECONTRASTPRODUCT" //删除产品
export const ACTIONS_GET_GETCONTRASTPRODUCT = "ACTIONS_GET_GETCONTRASTPRODUCT" //产品详情
export const ACTIONS_GET_SAVECONTRASTPRODUCT = "ACTIONS_GET_SAVECONTRASTPRODUCT" //产品的保存
export const ACTIONS_GET_PRODUCTCONTRASTANALYSISDATATABLE = "ACTIONS_GET_PRODUCTCONTRASTANALYSISDATATABLE" //获取基础对比信息的表格
export const ACTIONS_GET_SALESCONTRAST = "ACTIONS_GET_SALESCONTRAST" //销售额对比
export const ACTIONS_GET_DEPARTMENTCONTRAST = "ACTIONS_GET_DEPARTMENTCONTRAST" //科室分布
export const ACTIONS_GET_DRUGTREATMENTTYPESALES = "ACTIONS_GET_DRUGTREATMENTTYPESALES" //同小类销售额
export const ACTIONS_GET_REPLACEPISKANALYSIS = "ACTIONS_GET_REPLACEPISKANALYSIS" //同类药品
export const ACTIONS_GET_COLLECTIONANALYSISDATATABLE = "ACTIONS_GET_COLLECTIONANALYSISDATATABLE" //集采放量拉动分析的表格
export const ACTIONS_GET_MEDICALINSURANCEANALYSIS = "ACTIONS_GET_MEDICALINSURANCEANALYSIS" //集采放量拉动分析的表格
export const ACTIONS_GET_REGISTEREDTYPE = "ACTIONS_GET_REGISTEREDTYPE" //药品注册类别
export const ACTIONS_GET_INNOVATEDRUGDATATABLE = "ACTIONS_GET_INNOVATEDRUGDATATABLE" //国产创新药列表
export const ACTIONS_GET_DISEASEOFINNOVATEDRUG = "ACTIONS_GET_DISEASEOFINNOVATEDRUG" //国产创新药列表
export const ACTIONS_GET_INNOVATEDRUGDETAIL = "ACTIONS_GET_INNOVATEDRUGDETAIL" //对标分析
export const ACTIONS_GET_HOTAREASRDPANORAMA = "ACTIONS_GET_HOTAREASRDPANORAMA" //热门领域研发全景
export const ACTIONS_GET_GETCOLLECTROUND = "ACTIONS_GET_GETCOLLECTROUND" //集采轮次
export const ACTIONS_GET_AUTH_LOGINWX = "ACTIONS_GET_AUTH_LOGINWX" //微信登录
export const ACTIONS_GET_AUTH_BINDWX = "ACTIONS_GET_AUTH_BINDWX" //微信绑定
export const ACTIONS_GET_AUTH_UNBINDWX = "ACTIONS_GET_AUTH_UNBINDWX" //微信绑定
//ONE
export const ACTIONS_GET_ONE_COMPANY = "ACTIONS_GET_ONE_COMPANY" //获取公司列表
export const ACTIONS_GET_ONE_COMPANYSTOCK = "ACTIONS_GET_ONE_COMPANYSTOCK" //获取公司股票月线
export const ACTIONS_GET_ONE_COMPANYEVENT = "ACTIONS_GET_ONE_COMPANYEVENT" //获取公司大事件
export const ACTIONS_GET_ONE_ENTERPRISEREFINANCIALREPORTYEARLY = "ACTIONS_GET_ONE_ENTERPRISEREFINANCIALREPORTYEARLY" //获取公司财务年度
export const ACTIONS_GET_ONE_ENTERPRISEDETAIL = "ACTIONS_GET_ONE_ENTERPRISEDETAIL" //获取公司是否上市
export const ACTIONS_GET_ONE_ENTERPRISEDATA = "ACTIONS_GET_ONE_ENTERPRISEDATA" //获取公司基本信息
export const ACTIONS_GET_DOWNLOADCREATETOKENPRODUCTCONTRASTANALYSIS = "ACTIONS_GET_DOWNLOADCREATETOKENPRODUCTCONTRASTANALYSIS" //获取公司基本信息
export const ACTIONS_GET_DOWNLOADHYPERLINK = "ACTIONS_GET_DOWNLOADHYPERLINK" //医保和集采的下载
export const ACTIONS_GET_MEDICALINSURANCEANALYSISYEAR = "ACTIONS_GET_MEDICALINSURANCEANALYSISYEAR" //医保年份
export const ACTIONS_GET_COLLECTIONANALYSISYEAR = "ACTIONS_GET_COLLECTIONANALYSISYEAR" //集采的年份
//ONE 医保拉动分析
export const ACTIONS_ONEMARKETINSURANCE_GETTABLEDATA = "ACTIONS_ONEMARKETINSURANCE_GETTABLEDATA" // 查询表格数据
export const ACTIONS_ONEMARKETINSURANCE_DOWNLOADTABLEDATA = "ACTIONS_ONEMARKETINSURANCE_DOWNLOADTABLEDATA" // 下载表格数据
export const ACTIONS_ONEMARKETINSURANCE_CHECKDOWNLOADFILE = "ACTIONS_ONEMARKETINSURANCE_CHECKDOWNLOADFILE" // 文件下载判断数据是否为空
export const ACTIONS_ONEMARKETINSURANCE_DOWNLOADFILE = "ACTIONS_ONEMARKETINSURANCE_DOWNLOADFILE" // 文件下载
export const ACTIONS_ONEMARKETINSURANCE_RECOMMENDEDWORDS = "ACTIONS_ONEMARKETINSURANCE_RECOMMENDEDWORDS" //检索条件枚举字典
export const ACTIONS_ONEMARKETINSURANCE_GETOPTIONSLIST = "ACTIONS_ONEMARKETINSURANCE_GETOPTIONSLIST" // 查询下拉框
//ONE 集采拉动分析
export const ACTIONS_ONEMARKETCENTRALIZED_GETTABLEDATA = "ACTIONS_ONEMARKETCENTRALIZED_GETTABLEDATA" // 查询表格数据
export const ACTIONS_ONEMARKETCENTRALIZED_DOWNLOADTABLEDATA = "ACTIONS_ONEMARKETCENTRALIZED_DOWNLOADTABLEDATA" // 下载表格数据
export const ACTIONS_ONEMARKETCENTRALIZED_CHECKDOWNLOADFILE = "ACTIONS_ONEMARKETCENTRALIZED_CHECKDOWNLOADFILE" // 文件下载判断数据是否为空
export const ACTIONS_ONEMARKETCENTRALIZED_DOWNLOADFILE = "ACTIONS_ONEMARKETCENTRALIZED_DOWNLOADFILE" // 文件下载
export const ACTIONS_ONEMARKETCENTRALIZED_RECOMMENDEDWORDS = "ACTIONS_ONEMARKETCENTRALIZED_RECOMMENDEDWORDS" //检索条件枚举字典
export const ACTIONS_ONEMARKETCENTRALIZED_GETOPTIONSLIST = "ACTIONS_ONEMARKETCENTRALIZED_GETOPTIONSLIST" // 查询下拉框
//UTILS
export const ACTIONS_GET_GETDOWNLOADLIMIT = "ACTIONS_GET_GETDOWNLOADLIMIT" //获取下载code
export const ACTIONS_GET_GETMARKETDICTIONARYJSON = "ACTIONS_GET_GETMARKETDICTIONARYJSON" //获取上市模块JSON
export const ACTIONS_GET_GETDRUGBIDDINGDICTIONARYJSON = "ACTIONS_GET_GETDRUGBIDDINGDICTIONARYJSON" //获取招标模块JSON
export const ACTIONS_GET_GETGLOBALDRUGPATENTSICTIONARYJSON = "ACTIONS_GET_GETGLOBALDRUGPATENTSICTIONARYJSON" //获取专利模块JSON
export const ACTIONS_GET_GETCLINICALDICTIONARYJSON = "ACTIONS_GET_GETCLINICALDICTIONARYJSON" //获取临床模块JSON
export const ACTIONS_GET_GETGLOBALNEWDRUGDICTIONARYJSON = "ACTIONS_GET_GETGLOBALNEWDRUGDICTIONARYJSON" //获取新药模块JSON
export const ACTIONS_GET_GETNDCDICTIONARYJSON = "ACTIONS_GET_GETNDCDICTIONARYJSON" //获取NDC模块JSON
export const ACTIONS_GET_GETPATHWAYDICTIONARYJSON = "ACTIONS_GET_GETPATHWAYDICTIONARYJSON" //获取临床路径模块JSON
export const ACTIONS_GET_GETTREATGUIDEDICTIONARYJSON = "ACTIONS_GET_GETTREATGUIDEDICTIONARYJSON" //获取疾病指南模块JSON
export const ACTIONS_GET_GETGENERALDICTIONARYJSON = "ACTIONS_GET_GETGENERALDICTIONARYJSON" //获取通用模块模块JSON
export const ACTIONS_GET_GETMEDICINEDICTIONARYJSON = "ACTIONS_GET_GETMEDICINEDICTIONARYJSON" //获取通用药物模块JSON
export const ACTIONS_GET_GETMEDICALINSURANCEDICTIONARYJSON = "ACTIONS_GET_GETMEDICALINSURANCEDICTIONARYJSON" //获取医保模块JSON
export const ACTIONS_GET_GETBASICDRUGDICTIONARYJSON = "ACTIONS_GET_GETBASICDRUGDICTIONARYJSON" //获取基药模块JSON
