/**
 * zyg
 * 2021/3/24
 */
import types from "../../../type"
import axios from "../../../../http"
import ajaxApi from "../../../../config/request"
let state = {
  risingTarget: [], //新兴靶点研发热度
  approvedAreasMap: [], //药品获批领域地图
  exploreImitateDrug: [], //挖掘仿药老品种的柱状图
  exploreImitateDrugDataTable: [], //挖掘仿药老品种表格
  drugFormTree: [], //剂型
  commonName: [], //获取通用名
  drugFormOfCommonName: [], //获取剂型
  enterpriseQuery: [], //获取公司名

  getContrastProductGroupList: [], //获取创建对比产品分组列表
  getContrastProduct: {}, //获取产品详情
  productContrastAnalysisDataTable: {}, //基本信息对比的表格

  salesContrast: [], //销售额对比
  departmentContrast: [], //科室分布

  drugTreatmentTypeSales: [], //同小类销售额
  replaceRiskAnalysis: [], //同类药品
  collectionAnalysisDataTable: [], //集采放量拉动分析的表格
  medicalInsuranceAnalysisDataTable: [], //集采放量拉动分析的表格

  comparisonTitle: "", //产品对比的值
  registeredType: [], //药品注册分类

  innovateDrugDataTable: [], //国产创新药列表

  diseaseOfInnovateDrug: [], //国产创新药列表
  innovateDrugDetail: {}, //对标分析
  hotAreasRdPanorama: [], //热门领域研发全景
  getCollectRound: [], //集采轮次
  companyData: {
    id: 25585,
    hasStock: true,
    marketType: "CN",
  }, //公司基本信息
  medicalInsuranceAnalysisYear: [2022], //医保政策分析的年份
  collectionAnalysisYear: [2022], //集采放量拉动分析的年份

}

let actions = {
  //新兴靶点研发热度
  [types.ACTIONS_GET_RISINGTARGET]: ({ commit, state }, value) => {
    let url = ajaxApi.One.RisingTarget
    let params = {
      ...value,
      // ...state.searchForm,
      // pageSize: state.PrizePagination.pageSize,
      // pageNum: state.PrizePagination.pageNum,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          state.risingTarget = res.data
          return Promise.resolve()
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //新兴靶点研发热度筛选的数据列表
  [types.ACTIONS_GET_RISINGTARGETTREATMENTTYPE]: ({ commit, state }, value) => {
    let url = ajaxApi.One.RisingTargetTreatmentType
    return axios
      .post(url, {})
      .then((res) => {
        if (res.code === 200) {
          return res.data
        } else {
          return res.message
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //药品获批领域地图
  [types.ACTIONS_GET_APPROVEDAREASMAP]: ({ commit, state }, value) => {
    let url = ajaxApi.One.ApprovedAreasMap
    let params = {
      ...value,
      // ...state.searchForm,
      // pageSize: state.PrizePagination.pageSize,
      // pageNum: state.PrizePagination.pageNum,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          state.approvedAreasMap = res.data
          return Promise.resolve()
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //挖掘仿药老品种柱状图
  [types.ACTIONS_GET_EXPLOREIMITATEDRUG]: ({ commit, state }, value) => {
    let url = ajaxApi.One.ExploreImitateDrug
    let params = {
      ...value,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          let sourceData = res.data
          if (sourceData) {
            state.exploreImitateDrug = sortRange(sourceData)
          } else {
            state.exploreImitateDrug = null
          }

          return Promise.resolve()
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //挖掘仿药老品种
  [types.ACTIONS_GET_EXPLOREIMITATEDRUGDATATABLE]: ({ commit, state }, value) => {
    let url = ajaxApi.One.ExploreImitateDrugDataTable
    let params = {
      ...value,
      // ...state.searchForm,
      // pageSize: state.PrizePagination.pageSize,
      // pageNum: state.PrizePagination.pageNum,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          state.exploreImitateDrugDataTable = res.data
          return Promise.resolve()
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //剂型
  [types.ACTIONS_GET_DRUGFORMTREE]: ({ commit, state }, value) => {
    let url = ajaxApi.One.ExploreImitateDrugForm
    let params = {
      ...value,
      // ...state.searchForm,
      // pageSize: state.PrizePagination.pageSize,
      // pageNum: state.PrizePagination.pageNum,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          state.drugFormTree = res.data
          return Promise.resolve()
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //挖掘防药老品种
  //获取通用名
  [types.ACTIONS_GET_COMMONNAME]: ({ commit, state }, value) => {
    let url = ajaxApi.One.CommonName
    let params = {
      ...value,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          return res
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //获取剂型
  [types.ACTIONS_GET_DRUGFORMCOMMONNAME]: ({ commit, state }, value) => {
    let url = ajaxApi.One.DrugFormOfCommonName + `?drugId=${value.drugId}`
    let params = {
      ...value,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          return res
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //获取one公司列表
  [types.ACTIONS_GET_ONE_COMPANY]: ({ commit, state }, value) => {
    let url = ajaxApi.One.Company
    let params = {
      ...value,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          return res
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //ONE公司大事件
  [types.ACTIONS_GET_ONE_COMPANYEVENT]: ({ commit, state }, options) => {
    let url = ajaxApi.One.EnterpriseEvent
    let params = options
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          return res.data
        } else {
          return res.message
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //ONE公司K线图
  [types.ACTIONS_GET_ONE_COMPANYSTOCK]: ({ commit, state }, options) => {
    let url = ajaxApi.One.EnterpriseStock + `?enterpriseId=${options.id}&market=${options.market}`
    let params = options
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          return res.data
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //ONE公司详情 公司财务指标年度
  [types.ACTIONS_GET_ONE_ENTERPRISEREFINANCIALREPORTYEARLY]: ({ commit, state }, options) => {
    let url = ajaxApi.One.EnterpriseFinancialReportYearly
    let params = options
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          return res.data
        } else {
          return res.message
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //ONE公司是否上市
  [types.ACTIONS_GET_ONE_ENTERPRISEDETAIL]: ({ commit, state }, options) => {
    let url = ajaxApi.One.EnterpriseDetail + "?enterpriseId=" + options
    return axios
      .post(url)
      .then((res) => {
        if (res.code === 200) {
          state.companyData.id = options
          state.companyData.hasStock = res.data.stockInfo.hasStock
          if (res.data.stockInfo.hasStock) {
            state.companyData.marketType = res.data.stockInfo.detail[0].market
          }
          return res.data
        } else {
          return res.message
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //获取公司列表
  [types.ACTIONS_GET_ENTERPRISEQUERY]: ({ commit, state }, value) => {
    let url = ajaxApi.One.EnterpriseQuery
    let params = {
      ...value,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          return res
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //获取公司列表
  [types.ACTIONS_GET_GETCONTRASTPRODUCTGROUPLIST]: ({ commit, state }, value) => {
    let url = ajaxApi.One.GetContrastProductGroupList + `?isHistory=${value.isHistory}`
    let params = {
      ...value,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          state.getContrastProductGroupList = res.data
          return Promise.resolve()
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //删除产品
  [types.ACTIONS_GET_OFFCONTRASTPRODUCT]: ({ commit, state }, value) => {
    let url = ajaxApi.One.OffContrastProduct
    if (value.length > 0) {
      value.forEach((item, index) => {
        if (index == 0) {
          url += `?ids=${item.id}`
        } else {
          url += `&ids=${item.id}`
        }
      })
    }
    let params = {
      ...value,
    }
    return axios
      .post(url, params)
      .then((res) => {
        return res
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //还原产品
  [types.ACTIONS_GET_RESTORECONTRASTPRODUCT]: ({ commit, state }, value) => {
    let url = ajaxApi.One.RestoreContrastProduct + `?ids=${value.id}`
    let params = {
      ...value,
    }
    return axios
      .post(url, params)
      .then((res) => {
        return res
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //彻底删除产品
  [types.ACTIONS_GET_REMOVECONTRASTPRODUCT]: ({ commit, state }, value) => {
    let url = ajaxApi.One.RemoveContrastProduct
    if (value.length > 0) {
      value.forEach((item, index) => {
        if (index == 0) {
          url += `?ids=${item.id}`
        } else {
          url += `&ids=${item.id}`
        }
      })
    }

    let params = {
      ...value,
    }
    return axios
      .post(url, params)
      .then((res) => {
        return res
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //获取产品的详细
  [types.ACTIONS_GET_GETCONTRASTPRODUCT]: ({ commit, state }, value) => {
    let url = ajaxApi.One.GetContrastProduct + `?id=${value.id}`
    let params = {
      ...value,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          state.getContrastProduct = res.data
          return Promise.resolve()
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //产品的保存
  [types.ACTIONS_GET_SAVECONTRASTPRODUCT]: ({ commit, state }, value) => {
    let url = ajaxApi.One.SaveContrastProduct
    let params = {
      ...value,
    }
    return axios
      .post(url, params)
      .then((res) => {
        return res
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //获取基本信息对比的表格
  [types.ACTIONS_GET_PRODUCTCONTRASTANALYSISDATATABLE]: ({ commit, state }, value) => {
    let url = ajaxApi.One.ProductContrastAnalysisDataTable
    let params = {
      ...value,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          state.productContrastAnalysisDataTable = res
          return Promise.resolve()
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //集采放量拉动分析的表格

  //数据表 药品研发获取分析
  [types.ACTIONS_GET_COLLECTIONANALYSISDATATABLE]: (store, value) => {
    let url = ajaxApi.One.CollectionAnalysisDataTable
    const { commit, dispatch, state, rootState } = store
    let filterData = rootState.Target.filterSortCondition
    if (value.orderBy == null || value.orderBy == undefined) {
      value.isOrderDesc = true
      value.orderBy = "Amount"
      value.orderByYear = 2021
    }
    let params = {
      // ...state.searchForm,
      // pageSize: state.PrizePagination.pageSize,
      // pageNum: state.PrizePagination.pageNum,
      pageSize: value.pageSize,
      currentPage: value.currentPage,
      orderBy: value.orderBy,
      orderByYear: value.orderByYear,
      isOrderDesc: value.isOrderDesc,
      ...filterData,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          state.collectionAnalysisDataTable = res.data
          return Promise.resolve()
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },

  //数据表 药品研发获取分析  medicalInsuranceAnalysisDataTable
  [types.ACTIONS_GET_MEDICALINSURANCEANALYSIS]: (store, value) => {
    let url = ajaxApi.One.MedicalInsuranceAnalysisDataTable
    const { commit, dispatch, state, rootState } = store
    let filterData = rootState.Target.filterSortCondition
    if (value.orderBy == null || value.orderBy == undefined) {
      value.isOrderDesc = true
      value.orderBy = "Amount"
      value.orderByYear = 2021
    }
    let params = {
      // ...state.searchForm,
      // pageSize: state.PrizePagination.pageSize,
      // pageNum: state.PrizePagination.pageNum,
      pageSize: value.pageSize,
      currentPage: value.currentPage,
      orderBy: value.orderBy,
      orderByYear: value.orderByYear,
      isOrderDesc: value.isOrderDesc,
      ...filterData,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          state.medicalInsuranceAnalysisDataTable = res.data
          return Promise.resolve()
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },

  //销售额对比
  [types.ACTIONS_GET_SALESCONTRAST]: ({ commit, state }, value) => {
    let url = ajaxApi.One.SalesContrast
    let params = {
      ...value,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          state.salesContrast = res
          return Promise.resolve()
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //科室分布
  [types.ACTIONS_GET_DEPARTMENTCONTRAST]: ({ commit, state }, value) => {
    let url = ajaxApi.One.DepartmentContrast
    let params = {
      ...value,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          state.departmentContrast = res
          return Promise.resolve()
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //同小类销售额
  [types.ACTIONS_GET_DRUGTREATMENTTYPESALES]: ({ commit, state }, value) => {
    let url = ajaxApi.One.DrugTreatmentTypeSales
    let params = {
      ...value,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          state.drugTreatmentTypeSales = res.data
          return Promise.resolve()
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //同类药品
  [types.ACTIONS_GET_REPLACEPISKANALYSIS]: ({ commit, state }, value) => {
    let url = ajaxApi.One.ReplaceRiskAnalysis
    let params = {
      ...value,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          state.replaceRiskAnalysis = res.data
          return Promise.resolve()
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //药品注册分类
  [types.ACTIONS_GET_REGISTEREDTYPE]: ({ commit, state }, value) => {
    let url = ajaxApi.One.RegisteredType
    let params = {
      ...value,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          state.registeredType = res.data
          return Promise.resolve()
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //国产创新药列表
  [types.ACTIONS_GET_INNOVATEDRUGDATATABLE]: (store, value) => {
    let url = ajaxApi.One.InnovateDrugDataTable
    const { commit, dispatch, state, rootState } = store
    let filterData = rootState.Target.filterSortCondition
    if (value.orderBy == null || value.orderBy == undefined) {
      value.isOrderDesc = true

      value.orderBy = "cdeUndertakeTime"
    }
    let params = {
      // ...state.searchForm,
      // pageSize: state.PrizePagination.pageSize,
      // pageNum: state.PrizePagination.pageNum,
      pageSize: value.pageSize,
      currentPage: value.currentPage,
      orderBy: value.orderBy,
      orderByYear: value.orderByYear,
      isOrderDesc: value.isOrderDesc,
      ...filterData,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          state.innovateDrugDataTable = res.data
          return Promise.resolve()
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },

  [types.ACTIONS_GET_DISEASEOFINNOVATEDRUG]: ({ commit, state }, value) => {
    let url = ajaxApi.One.DiseaseOfInnovateDrug
    let params = {
      ...value,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          state.diseaseOfInnovateDrug = res.data
          return Promise.resolve()
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //对标分析
  [types.ACTIONS_GET_INNOVATEDRUGDETAIL]: ({ commit, state }, value) => {
    let url = ajaxApi.One.InnovateDrugDetail
    let params = {
      ...value,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          state.innovateDrugDetail = res.data
          return Promise.resolve()
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //获取基本信息对比的表格
  [types.ACTIONS_GET_PRODUCTCONTRASTANALYSISDATATABLE]: ({ commit, state }, value) => {
    let url = ajaxApi.One.ProductContrastAnalysisDataTable
    let params = {
      ...value,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          state.productContrastAnalysisDataTable = res
          return Promise.resolve()
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },

  //热门领域研发全景的数据
  [types.ACTIONS_GET_HOTAREASRDPANORAMA]: ({ commit, state }, value) => {
    let url = ajaxApi.One.HotAreasRdPanorama
    let params = {
      ...value,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          state.hotAreasRdPanorama = res
          return Promise.resolve()
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },

  //集采轮次
  [types.ACTIONS_GET_GETCOLLECTROUND]: ({ commit, state }, value) => {
    let url = ajaxApi.One.GetCollectRound
    let params = {
      ...value,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          state.getCollectRound = res.data.content
          return Promise.resolve()
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //基础信息的下载
  [types.ACTIONS_GET_DOWNLOADCREATETOKENPRODUCTCONTRASTANALYSIS]: (store, value) => {
    let url = ajaxApi.One.DownloadCreateTokenProductContrastAnalysis
    const { commit, dispatch, state, rootState } = store
    let params = {
      ...value,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          return res
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },

  //基础信息的下载
  [types.ACTIONS_GET_DOWNLOADHYPERLINK]: (store, value) => {
    let url
    if (value.path === "/oneMarketCentralized") {
      url = ajaxApi.One.DownloadCreateTokenCollectionAnalysis
    } else {
      url = ajaxApi.One.DownloadCreateTokenMedicalInsuranceAnalysis
    }

    const { commit, dispatch, state, rootState } = store
    let filterData = rootState.Target.filterSortCondition
    if (value.orderBy == null || value.orderBy == undefined) {
      value.isOrderDesc = true
      value.orderBy = "Amount"
      value.orderByYear = 2020
    }
    let params = {
      // ...state.searchForm,
      // pageSize: state.PrizePagination.pageSize,
      // pageNum: state.PrizePagination.pageNum,
      pageSize: value.pageSize,
      currentPage: value.currentPage,
      orderBy: value.orderBy,
      orderByYear: value.orderByYear,
      isOrderDesc: value.isOrderDesc,
      ...filterData,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          return res
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //医保年份
  [types.ACTIONS_GET_MEDICALINSURANCEANALYSISYEAR]: ({ commit, state }, value) => {
    let url = ajaxApi.One.MedicalInsuranceAnalysisYear
    let params = {
      ...value,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          state.medicalInsuranceAnalysisYear = res.data
          return Promise.resolve()
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //集采轮次
  [types.ACTIONS_GET_COLLECTIONANALYSISYEAR]: ({ commit, state }, value) => {
    let url = ajaxApi.One.CollectionAnalysisYear
    let params = {
      ...value,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          state.collectionAnalysisYear = res.data
          return Promise.resolve()
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
}
let getters = {
  [types.GETTERS_RISINGTARGET]: (state) => {
    return state.risingTarget
  },
  [types.GETTERS_APPROVEDAREASMAP]: (state) => {
    return state.approvedAreasMap
  },
  [types.GETTERS_EXPLOREIMITATEDRUG]: (state) => {
    return state.exploreImitateDrug
  },
  [types.GETTERS_EXPLOREIMITATEDRUGDATATABLE]: (state) => {
    return state.exploreImitateDrugDataTable
  },
  [types.GETTERS_DRUGFORMTREE]: (state) => {
    return state.drugFormTree
  },
  [types.GETTERS_COMMONNAME]: (state) => {
    return state.commonName
  },
  [types.GETTERS_DRUGFORMCOMMONNAME]: (state) => {
    return state.drugFormOfCommonName
  },
  [types.GETTERS_GETCONTRASTPRODUCTGROUPLIST]: (state) => {
    return state.getContrastProductGroupList
  },
  [types.GETTERS_GETCONTRASTPRODUCT]: (state) => {
    return state.getContrastProduct
  },
  [types.GETTERS_PRODUCTCONTRASTANALYSISDATATABLE]: (state) => {
    return state.productContrastAnalysisDataTable
  },
  [types.GETTERS_SALESCONTRAST]: (state) => {
    return state.salesContrast
  },
  [types.GETTERS_DEPARTMENTCONTRAST]: (state) => {
    return state.departmentContrast
  },
  [types.GETTERS_DRUGTREATMENTTYPESALES]: (state) => {
    return state.drugTreatmentTypeSales
  },
  [types.GETTERS_REPLACEPISKANALYSIS]: (state) => {
    return state.replaceRiskAnalysis
  },
  [types.GETTERS_COLLECTIONANALYSISDATATABLE]: (state) => {
    return state.collectionAnalysisDataTable
  },
  [types.GETTERS_MEDICALINSURANCEANALYSIS]: (state) => {
    return state.medicalInsuranceAnalysisDataTable
  },
  [types.GETTERS_MEDICALINSURANCEANALYSIS]: (state) => {
    return state.medicalInsuranceAnalysisDataTable
  },
  [types.GETTERS_MEDICALINSURANCEANALYSIS]: (state) => {
    return state.medicalInsuranceAnalysisDataTable
  },
  [types.GETTERS_COMPARISONTITLE]: (state) => {
    return state.comparisonTitle
  },
  [types.GETTERS_REGISTEREDTYPE]: (state) => {
    return state.registeredType
  },
  [types.GETTERS_INNOVATEDRUGDATATABLE]: (state) => {
    return state.innovateDrugDataTable
  },
  [types.GETTERS_DISEASEOFINNOVATEDRUG]: (state) => {
    return state.diseaseOfInnovateDrug
  },
  [types.GETTERS_INNOVATEDRUGDETAIL]: (state) => {
    return state.innovateDrugDetail
  },
  [types.GETTERS_HOTAREASRDPANORAMA]: (state) => {
    return state.hotAreasRdPanorama
  },
  [types.GETTERS_GETCOLLECTROUND]: (state) => {
    return state.getCollectRound
  },
  [types.GETTERS_ONE_COMPANYDATA]: (state) => {
    return state.companyData
  },
  [types.GETTERS_ONE_MEDICALINSURANCEANALYSISYEAR]: (state) => {
    return state.medicalInsuranceAnalysisYear
  },
  [types.GETTERS_ONE_COLLECTIONANALYSISYEAR]: (state) => {
    return state.collectionAnalysisYear
  },
}

let mutations = {
  [types.MUTATION_SET_RISINGTARGET]: (state, payload) => {
    state.risingTarget = payload
  },
  [types.MUTATION_SET_APPROVEDAREASMAP]: (state, payload) => {
    state.approvedAreasMap = payload
  },
  [types.MUTATION_SET_EXPLOREIMITATEDRUG]: (state, payload) => {
    state.exploreImitateDrug = payload
  },
  [types.MUTATION_SET_EXPLOREIMITATEDRUGDATATABLE]: (state, payload) => {
    state.exploreImitateDrugDataTable = payload
  },
  [types.MUTATION_SET_DRUGFORMTREE]: (state, payload) => {
    state.drugFormTree = payload
  },
  [types.MUTATION_SET_COMMONNAME]: (state, payload) => {
    state.commonName = payload
  },
  [types.MUTATION_SET_DRUGFORMCOMMONNAME]: (state, payload) => {
    state.drugFormOfCommonName = payload
  },
  [types.MUTATION_SET_GETCONTRASTPRODUCTGROUPLIST]: (state, payload) => {
    state.getContrastProductGroupList = payload
  },
  [types.MUTATION_SET_GETCONTRASTPRODUCT]: (state, payload) => {
    state.getContrastProduct = payload
  },
  [types.MUTATION_SET_PRODUCTCONTRASTANALYSISDATATABLE]: (state, payload) => {
    state.productContrastAnalysisDataTable = payload
  },
  [types.MUTATION_SET_COLLECTIONANALYSISDATATABLE]: (state, payload) => {
    state.collectionAnalysisDataTable = payload
  },
  [types.MUTATION_SET_MEDICALINSURANCEANALYSIS]: (state, payload) => {
    state.medicalInsuranceAnalysisDataTable = payload
  },
  [types.MUTATION_SET_COMPARISONTITLE]: (state, payload) => {
    state.comparisonTitle = payload
  },
  [types.MUTATION_SET_REGISTEREDTYPE]: (state, payload) => {
    state.registeredType = payload
  },
  [types.MUTATION_SET_ONE_MEDICALINSURANCEANALYSISYEAR]: (state, payload) => {
    state.medicalInsuranceAnalysisYear = payload
  },
  [types.MUTATION_SET_ONE_COLLECTIONANALYSISYEAR]: (state, payload) => {
    state.collectionAnalysisYear = payload
  },
}
function sortRange(source) {
  let arr1 = source.filter((item) => item.name === "所有参与一致性评价厂商"),
    arr2 = source.filter((item) => item.name === "通过一致性评价厂商")

  let baseSortArr = ["大于10家", "6-10家", "1-5家", "0家"]

  let newArr1 = []
  for (let i = 0; i < baseSortArr.length; i++) {
    for (let j = 0; j < arr1.length; j++) {
      if (baseSortArr[i] === arr1[j].range) {
        newArr1.push(arr1[j])
      }
    }
  }
  return newArr1.concat(arr2)
}
export default {
  module: "One",
  state,
  actions,
  getters,
  mutations,
}
