import types from "../type"
import axios from "../../http"
import { BaseLoginUrl } from "../../config"
import ajaxApi from "../../config/request"
let actions = {
  [types.ACTIONS_GET_MENU_LIST]: (context, payload) => {
    let url = ajaxApi.Menu.List
    let params = {}
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          context.commit(types.MUTATION_SET_MENU_LIST, res.data || [])
          return Promise.resolve(res.data)
        } else {
          return Promise.resolve([])
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  [types.ACTIONS_TEST]: ({ state, commit, dispatch }, payload) => {
    let promise = new Promise((resolve) => {
      if (payload) {
        commit(types.MUTATION_GLOBAL_TEST, payload)
        resolve(true)
      } else {
        resolve(false)
      }
    })
    return promise.then((res) => {
      return !!res
    })
  },
  [types.ACTIONS_GET_SYS_LIST]: ({ state, commit, dispatch }, payload) => {
    let promise = new Promise((resolve) => {
      if (payload) {
        commit(types.ACTIONS_SET_SYS_LIST, payload)
        resolve(true)
      } else {
        resolve(false)
      }
    })
    return promise.then((res) => {
      return res
    })
  },
  //登录
  [types.ACTIONS_LOGIN]: ({ state, commit, dispatch }, payload) => {
    let url = `${BaseLoginUrl}/usercenter/api/user/json/manage/login`
    let params = {
      ...payload,
      authSystem: "2",
    }
    return axios.post(url, params, { withoutToken: true }).then((res) => {
      let list = []
      res.data.authList.map((item) => {
        if (item.authName !== "登录" && item.authName !== "首页") {
          list.push(item)
        }
      })
      return { tokenId: res.data.tokenId, loginName: res.data.userInfo.loginName, list: list }
    })
  },
  //登出
  [types.ACTION_LOGIN_OUT]: () => {
    let url = `${BaseLoginUrl}/usercenter/api/user/json/manage/logout`
    let params = {}
    return axios
      .post(url, params)
      .then(() => {
        sessionStorage.setItem("menuList", JSON.stringify([]))
        return { type: "success", message: "退出成功!" }
      })
      .catch((e) => {
        return { type: "error", message: `退出失败${e}` }
      })
  },
  [types.ACTIONS_GET_SYS_TIME]: () => {
    let url = "/activity/common/querySystemDate"
    return axios.post(url).then((res) => {
      return res
    })
  },
}

export default actions
