import types from "../../type"
import { post } from "@/utils/request"
import axios from "../../../http"
import ajaxApi from "../../../config/request"

const state = {
  user: {},
  login: false,
  customTableColumns: [],
  customTableColumnsList: [],
  pageCodes: [
    "pharmaONE_registration_approveno",//申报
    "pharmaONE_registration_product",//申报
    "pharmaONE_registration_common",//申报
    "pharmaONE_registration_target",//申报
    "pharmaONE_registration_lecense",//申报
    "pharmaONE_clinical_approveno",//临床
    "pharmaONE_clinical_common",//临床
    "pharmaONE_clinical_target",//临床
    "pharmaONE_clinical_lecense",//临床
    "pharmaONE_equivalence_product",//一致性评价
    "pharmaONE_equivalence_specification",//一致性评价
    "pharmaONE_equivalence_common",//一致性评价
    "pharmaONE_equivalence_lecense",//一致性评价
    "pharmaONE_rawMaterial",//原辅包
    "pharmaONE_referlistdrug",//参比制剂
    "pharmaONE_market_global_trade",//上市商品查看
    "pharmaONE_market_china_trade",//上市商品查看
    "pharmaONE_market_americ_trade",//上市商品查看
    "pharmaONE_market_ema_trade",//上市商品查看
    "pharmaONE_market_hma_trade",//上市商品查看
    "pharmaONE_market_japan_trade",//上市商品查看

    "pharmaONE_market_global_product",//上市商品查看
    "pharmaONE_market_china_product",//上市商品查看
    "pharmaONE_market_americ_product",//上市商品查看
    "pharmaONE_market_ema_product",//上市商品查看
    "pharmaONE_market_hma_product",//上市商品查看
    "pharmaONE_market_japan_product",//上市商品查看

    "pharmaONE_market_global_common",//上市商品查看
    "pharmaONE_market_china_common",//上市商品查看
    "pharmaONE_market_americ_common",//上市商品查看
    "pharmaONE_market_ema_common",//上市商品查看
    "pharmaONE_market_hma_common",//上市商品查看
    "pharmaONE_market_japan_common",//上市商品查看

    "pharmaONE_market_global_lecense",//上市商品查看
    "pharmaONE_market_china_lecense",//上市商品查看
    "pharmaONE_market_americ_lecense",//上市商品查看
    "pharmaONE_market_ema_lecense",//上市商品查看
    "pharmaONE_market_hma_lecense",//上市商品查看
    "pharmaONE_market_japan_lecense",//上市商品查看
    "pharmaONE_medicareCatalog",//医保
    "pharmaONE_essentialDrug",//基药
    "pharmaONE_globalNewDrug",//新药研发
    "pharmaONE_drugBidding",//药品招标
    "pharmaONE_americaNDC",//美国NDC药品
    "pharmaONE_globalDrugPatents",//专利
    "pharmaONE_annualReports",// 企业年报
    "pharmaONE_oneMarketInsurance",
    "pharmaONE_oneMarketCentralized",
  ],
}

const mutations = {
  userInfo: (state, user) => {
    state.user = user
  },
  userLogin: (state, isLogin) => {
    state.login = isLogin
  },
  [types.MUTATION_SET_TABLE_COLUMN]: (state, customTableColumnsList) => {
    state.customTableColumns = customTableColumnsList
  },
  [types.MUTATION_SET_TABLE_COLUMNS]: (state, customTableColumnsList) => {
    state.customTableColumnsList = customTableColumnsList
  },
}

let getters = {
  [types.GETTERS_TABLE_COLUMN]: (state) => {
    return state.customTableColumns
  },
  [types.GETTERS_TABLE_COLUMNS]: (state,) => {

    return state.customTableColumnsList
  },
}

let actions = {
  [types.ACTIONS_GET_TABLE_COLUMN]: ({ commit, state }, value) => {
    let url = ajaxApi.Custom.GetCustomTableColumns
    let params = {
      ...value,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          commit(types.MUTATION_SET_TABLE_COLUMN, JSON.parse(res.data))
          return Promise.resolve()
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  [types.ACTIONS_GET_TABLE_COLUMNS]: ({ commit, state }, value) => {
    if (!localStorage.getItem('token')) {
      return
    }
    let url = ajaxApi.Custom.GetUserSelectedColumnsMultiple
    let params = { tableCodes: state.pageCodes }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          const result = res.data.map(obj => {
            if (obj.columns !== null) {
              obj.columns = JSON.parse(obj.columns);
            }
            return obj;
          });
          commit(types.MUTATION_SET_TABLE_COLUMNS, result)
          return Promise.resolve()
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  [types.ACTIONS_SET_TABLE_COLUMN]: (context, value) => {
    let url = ajaxApi.Custom.SavCustomTableColumns
    let params = {
      ...value,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          context.dispatch('ACTIONS_GET_TABLE_COLUMNS')
          return Promise.resolve()
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  // [types.ACTIONS_SET_TABLE_COLUMN]: async ({ commit }, params = {}) => {
  //   await post(ajaxApi.Custom.SavCustomTableColumns, params)

  // },
}

export default {
  module: "User",
  state,
  actions,
  getters,
  mutations,
}


