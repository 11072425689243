import types from "../../../type"
import axios from "../../../../http"
import ajaxApi from "../../../../config/request"
import { post } from "@/utils/request"
let state = {
  //疾病分类
  diseaseType: [],
  durgAnalysis: {}, //药物分析
  salesAnalysis: {}, //中国药物销售分析
  companyAnalysis: {}, //公司分析
  enterpriseSalesAnalysis: {}, //药品销售分析使用通用名查企业
  diseaseAnalysis: {}, //全球疾病阶段分析
  countryRDMapAnalysis: {}, //全球药物全国研究分析
  drugRDAnalysis: {}, //药品研发获取分析

  targetAnalysis: {}, //靶点分析
  salesCompany: {}, //销售分析按通用名获取Top10公司
  chinaSalesAndForecast: {}, //销售总额分析与预测
  globalSalesAndForecast: {}, //销售总额分析与预测
  potentialMarket: {}, //市场潜力分析
  chinaCompanySales: {}, //公司实力
  globalCompanySales: {}, //公司实力
  diseaseChina: {}, //研发获批分析中国
  diseaseGlobal: {}, //研发获批分析全球

  replaceRiskAnalysis: {},
  departmentAnalysis: {},
  commonName: {},
  drugFormJointCommonName: {},
  drugComparisonAnalysis: {}, //药物对比分析
}
let actions = {
  /**
   * 异步修改用法
   *addBooks:function(){
     this.$store.dispatch('ACTIONS_HOME')
  },
   *
   */
  [types.ACTIONS_HOME]: ({ commit, state }) => {
    let url = "/activity/eventAudit/queryEventAuditList"
    let params = {
      ...state.searchForm,
      pageSize: state.checkPagination.pageSize,
      pageNum: state.checkPagination.pageNum,
    }
    axios
      .post(url, params)
      .then((res) => {
        commit(types.MUTATION_EM_SET_CHECK_LIST, res.data.objs)
        commit(types.MUTATION_EM_SET_CHECK_PAGINATION, {
          total: res.data.total,
        })
      })
      .catch((e) => {
        return e
      })
  },
  /**获取疾病类型数据 */
  [types.ACTIONS_GET_DISEASE_DISEASETYPE]: ({ commit, state }, value) => {
    let url
    if (value.type != undefined) {
      if (value.type == "id") {
        url = ajaxApi.Disease.DiseaseType + `?id=${value.id}`
      } else {
        url = ajaxApi.Disease.DiseaseType + `?name=${value.name}`
      }
    } else {
      url = ajaxApi.Disease.DiseaseType
    }

    let params = value
    return axios
      .post(url, params)
      .then((res) => {
        return res.data
      })
      .catch((e) => {
        return e
      })
  },
  //获取疾病数据列表
  [types.ACTIONS_GET_DISEASE_DISEASEDATATABLE]: (store, value) => {
    let url = ajaxApi.Disease.DiseaseDataTable
    const { commit, dispatch, state, rootState } = store
    let filterData = Object.assign({}, rootState.Target.filterSortCondition)
    //查询子表时删除这个条件
    if (value.parentId !== "000") {
      if (filterData.disease) {
        delete filterData.disease
      }
    }
    if (value.orderBy == null || value.orderBy == undefined) {
      value.orderBy = "totalGlobal"
      value.isOrderDesc = true
    }

    let params = {
      // ...state.searchForm,
      // pageSize: state.PrizePagination.pageSize,
      // pageNum: state.PrizePagination.pageNum,
      parentId: value.parentId,
      pageSize: value.pageSize,
      currentPage: value.currentPage,
      orderBy: value.orderBy,
      isOrderDesc: value.isOrderDesc,
      ...filterData,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          return res.data
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  // 获取疾病数据表列表数据
  [types.ACTIONS_GET_DISEASE_RECOMMENDEDWORDS]: ({ commit, state }, params) => {
    let url = ajaxApi.Disease.RecommendedWords
    return post(url, params)
  },
  // 根据ID获取疾病树
  [types.ACTIONS_GET_DISEASE_DISEASETYPEBYID]: ({ commit, state }, params) => {
    let url = ajaxApi.Disease.DiseaseTypeById + params
    return post(url, {})
  },
  // 获取疾病树
  [types.ACTIONS_GET_DISEASE_DISEASETYPENEW]: ({ commit, state }, params) => {
    let url = params !== "001" ? ajaxApi.Disease.DiseaseTypeNew + `?combinationId=${params}` : ajaxApi.Disease.DiseaseTypeNew
    return post(url, {})
  },
  //疾病数据表下载
  [types.ACTIONS_GET_DISEASE_DOWNLOADDISEASEDATATABLE]: (store, value) => {
    let url = ajaxApi.Disease.DownloadDiseaseDataTable
    const { commit, dispatch, state, rootState } = store
    let filterData = rootState.Target.filterSortCondition
    let params = {
      pageSize: value.pageSize,
      currentPage: value.currentPage,
      orderBy: value.orderBy,
      isOrderDesc: value.isOrderDesc,
      parentId: value.parentId,
      ...filterData,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          return res
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //药品销售总额分析
  [types.ACTIONS_GET_DISEASE_DOWNLOADSALESANDFORECAST]: (store, value) => {
    let url = ajaxApi.Disease.DownloadSalesAndForecast
    let params = {
      ...value,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          return res
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //药品市场潜力分析下载
  [types.ACTIONS_GET_DISEASE_DOWNLOADPOTENTIALMARKET]: (store, value) => {
    let url = ajaxApi.Disease.DownloadPotentialMarket
    let params = {
      ...value,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          return res
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //各公司实力分析
  [types.ACTIONS_GET_DISEASE_DOWNLOADCOMPANYSALES]: (store, value) => {
    let url = ajaxApi.Disease.DownloadCompanySales
    let params = {
      ...value,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          return res
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //药物分析
  [types.ACTIONS_GET_DISEASE_DURGANALYSIS]: ({ commit, state }, value) => {
    let url = ajaxApi.Disease.DrugAnalysis
    let params = {
      ...value,
      // ...state.searchForm,
      // pageSize: state.PrizePagination.pageSize,
      // pageNum: state.PrizePagination.pageNum,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          commit(types.MUTATION_SET_DISEASE_DURGANALYSIS, res.data)
          return Promise.resolve()
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //中国药物销售分析
  [types.ACTIONS_GET_DISEASE_SALESANALYSIS]: ({ commit, state }, value) => {
    let url = ajaxApi.Disease.SalesAnalysis
    let params = {
      ...value,
      // ...state.searchForm,
      // pageSize: state.PrizePagination.pageSize,
      // pageNum: state.PrizePagination.pageNum,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          commit(types.MUTATION_SET_DISEASE_SALESANALYSIS, res.data)
          return Promise.resolve()
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },

  //靶点分析
  [types.ACTIONS_GET_DISEASE_TARGETANALYSIS]: ({ commit, state }, value) => {
    let url = ajaxApi.Disease.TargetAnalysis
    let params = {
      ...value,
      // ...state.searchForm,
      // pageSize: state.PrizePagination.pageSize,
      // pageNum: state.PrizePagination.pageNum,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          commit(types.MUTATION_SET_DISEASE_TARGETANALYSIS, res.data)

          return Promise.resolve()
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //公司分析
  //公司分析
  [types.ACTIONS_GET_DISEASE_COMPANYANALYSIS]: ({ commit, state }, value) => {
    let url = ajaxApi.Disease.CompanyAnalysis
    let params = {
      ...value,
      // ...state.searchForm,
      // pageSize: state.PrizePagination.pageSize,
      // pageNum: state.PrizePagination.pageNum,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          commit(types.MUTATION_SET_DISEASE_COMPANYANALYSIS, res.data)
          return Promise.resolve()
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //公司分析
  [types.ACTIONS_GET_DISEASE_COUNTRYRDMAPANALYSIS]: ({ commit, state }, value) => {
    let url = ajaxApi.Disease.RdMap
    let params = {
      ...value,
      // ...state.searchForm,
      // pageSize: state.PrizePagination.pageSize,
      // pageNum: state.PrizePagination.pageNum,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          commit(types.MUTATION_SET_DISEASE_COUNTRYRDMAPANALYSIS, res.data)

          return Promise.resolve()
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //销售分析按通用名获取Top10公司
  [types.ACTIONS_GET_DISEASE_SALESCOMPANY]: ({ commit, state }, options) => {
    let url = ajaxApi.Disease.SalesCompany + `?id=${options.id}&isGlobal=${options.isGlobal}`
    let params = {
      ...options,
      // ...state.searchForm,
      // pageSize: state.PrizePagination.pageSize,
      // pageNum: state.PrizePagination.pageNum,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          commit(types.MUTATION_SET_DISEASE_SALESCOMPANY, res.data)

          return Promise.resolve()
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  // 销售总额分析与预测
  [types.ACTIONS_GET_DISEASE_SALESANDFORECAST]: ({ commit, state }, options) => {
    let url = ajaxApi.Disease.SalesAndForecast + `?id=${options.id}`
    let params = {
      ...options,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          if (options.isGlobal) {
            commit(types.MUTATION_SET_DISEASE_GLOBALSALESANDFORECAST, res.data)
          } else {
            commit(types.MUTATION_SET_DISEASE_CHINASALESANDFORECAST, res.data)
          }

          return Promise.resolve()
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },

  //药物详情 市场潜力
  [types.ACTIONS_GET_DISEASE_POTENTIALMARKET]: ({ commit, state }, options) => {
    let url = ajaxApi.Disease.PotentialMarket
    let params = {
      ...options,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          return res.data
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },

  //药物详情 公司实力
  [types.ACTIONS_GET_DISEASE_COMPANYSALES]: ({ commit, state }, options) => {
    let url = ajaxApi.Disease.CompanySales + `?id=${options.id}`
    let params = {
      ...options,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          if (options.isGlobal) {
            commit(types.MUTATION_SET_DISEASE_GLOBALCOMPANYSALES, res.data)
          } else {
            commit(types.MUTATION_SET_DISEASE_CHINACOMPANYSALES, res.data)
          }
          return Promise.resolve()
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //获取国家列表
  [types.ACTIONS_GET_DISEASE_COUNTRY]: ({ commit, state }, value) => {
    let url = ajaxApi.Disease.Country
    let params = value
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          return res.data
        } else {
          return res.message
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //获取靶点列表
  [types.ACTIONS_GET_DISEASE_TARGET]: ({ commit, state }, value) => {
    let url = ajaxApi.Disease.Target
    let params = value
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          return res.data
        } else {
          return res.message
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //获取公司列表
  [types.ACTIONS_GET_DISEASE_COMPANY]: ({ commit, state }, value) => {
    let url = ajaxApi.Disease.Company
    let params = value
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          return res.data
        } else {
          return res.message
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //研发获批分析
  [types.ACTIONS_GET_DISEASE_RDANALYSIS]: ({ commit, state }, value) => {
    let url = ajaxApi.Disease.RDAnalysis
    let params = filterObj(value)
    let type = value.type
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          state[type] = res.data
          return Promise.resolve()
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },

  //药物详情 代替风险
  [types.ACTIONS_GET_DISEASE_REPLACERISKANALYSIS]: ({ commit, state }, options) => {
    let url = ajaxApi.Disease.ReplaceRiskAnalysis
    let params = {
      ...options,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          commit(types.MUTATION_SET_DISEASE_REPLACERISKANALYSIS, res.data)
          return Promise.resolve()
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },

  //科室分布
  [types.ACTIONS_GET_DISEASE_DEPARTMENTANALYSIS]: ({ commit, state }, options) => {
    let url = ajaxApi.Disease.DepartmentAnalysis
    let params = {
      ...options,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          commit(types.MUTATION_SET_DISEASE_DEPARTMENTANALYSIS, res)
          return Promise.resolve()
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //获取药物通用名
  [types.ACTIONS_GET_DISEASE_COMMONNAME]: ({ commit, state }, options) => {
    let url = ajaxApi.Disease.CommonName
    let params = {
      ...options,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          commit(types.MUTATION_SET_DISEASE_COMMONNAME, res.data)
          return Promise.resolve()
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //获取疾病列表
  [types.ACTIONS_GET_DISEASE_DRUGFORMJOINTCOMMONNAME]: ({ commit, state }, options) => {
    let url = ajaxApi.Disease.DrugFormJointCommonName
    let params = {
      ...options,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          commit(types.MUTATION_SET_DISEASE_DRUGFORMJOINTCOMMONNAME, res.data)
          return Promise.resolve()
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //获取药物对比分析
  [types.ACTIONS_GET_DISEASE_DRUGCOMPARISONANALYSIS]: ({ commit, state }, options) => {
    let url = ajaxApi.Disease.DrugComparisonAnalysis
    let params = {
      ...options,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          state.drugComparisonAnalysis = res.data
          return Promise.resolve()
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
}
let getters = {
  /**
     * 读取属性用法
     * methods:{
         name:function(){
      return this.store.getters.GETTERS_GLOBAL_HOME();
    },
   }
     */
  [types.GETTERS_DISEASE_DURGANALYSIS]: (state) => {
    return state.durgAnalysis
  },
  [types.GETTERS_DISEASE_SALESANALYSIS]: (state) => {
    return state.salesAnalysis
  },
  [types.GETTERS_DISEASE_DISEASEANALYSIS]: (state) => {
    return state.diseaseAnalysis
  },
  [types.GETTERS_DISEASE_COUNTRYRDMAPANALYSIS]: (state) => {
    return state.countryRDMapAnalysis
  },
  [types.GETTERS_DISEASE_COMPANYANALYSIS]: (state) => {
    return state.companyAnalysis
  },
  [types.GETTERS_DISEASE_TARGETANALYSIS]: (state) => {
    return state.targetAnalysis
  },
  [types.GETTERS_DISEASE_SALESCOMPANY]: (state) => {
    return state.salesCompany
  },
  [types.GETTERS_DISEASE_CHINASALESANDFORECAST]: (state) => {
    return state.chinaSalesAndForecast
  },
  [types.GETTERS_DISEASE_GLOBALSALESANDFORECAST]: (state) => {
    return state.globalSalesAndForecast
  },
  [types.GETTERS_DISEASE_POTENTIALMARKET]: (state) => {
    return state.potentialMarket
  },
  [types.GETTERS_DISEASE_CHINACOMPANYSALES]: (state) => {
    return state.chinaCompanySales
  },
  [types.GETTERS_DISEASE_GLOBALCOMPANYSALES]: (state) => {
    return state.globalCompanySales
  },
  [types.GETTERS_DISEASE_REPLACERISKANALYSIS]: (state) => {
    return state.replaceRiskAnalysis
  },
  [types.GETTERS_DISEASE_DEPARTMENTANALYSIS]: (state) => {
    return state.departmentAnalysis
  },
  [types.GETTERS_DISEASE_COMMONNAME]: (state) => {
    return state.commonName
  },
  [types.GETTERS_DISEASE_DRUGFORMJOINTCOMMONNAME]: (state) => {
    return state.drugFormJointCommonName
  },
  [types.GETTERS_DISEASE_DRUGCOMPARISONANALYSIS]: (state) => {
    return state.drugComparisonAnalysis
  },
}
let mutations = {
  /**
   * 同步修改属性用法
   *this.$store.commit('MUTATION_GLOBAL_HOME','跟的参数')
   */
  [types.MUTATION_SET_DISEASE_DURGANALYSIS]: (state, payload) => {
    state.durgAnalysis = { ...state.durgAnalysis, ...payload }
  },
  [types.MUTATION_SET_DISEASE_SALESANALYSIS]: (state, payload) => {
    state.salesAnalysis = { ...state.salesAnalysis, ...payload }
  },
  [types.MUTATION_SET_DISEASE_DISEASEANALYSIS]: (state, payload) => {
    state.diseaseAnalysis = { ...state.diseaseAnalysis, ...payload }
  },
  [types.MUTATION_SET_DISEASE_COUNTRYRDMAPANALYSIS]: (state, payload) => {
    state.countryRDMapAnalysis = { ...state.countryRDMapAnalysis, ...payload }
  },
  [types.MUTATION_SET_DISEASE_COMPANYANALYSIS]: (state, payload) => {
    state.companyAnalysis = { ...state.companyAnalysis, ...payload }
  },
  [types.MUTATION_SET_DISEASE_TARGETANALYSIS]: (state, payload) => {
    state.targetAnalysis = { ...state.targetAnalysis, ...payload }
  },
  [types.MUTATION_SET_DISEASE_SALESCOMPANY]: (state, payload) => {
    state.salesCompany = { ...state.salesCompany, ...payload }
  },
  [types.MUTATION_SET_DISEASE_CHINASALESANDFORECAST]: (state, payload) => {
    state.chinaSalesAndForecast = { ...state.chinaSalesAndForecast, ...payload }
  },
  [types.MUTATION_SET_DISEASE_GLOBALSALESANDFORECAST]: (state, payload) => {
    state.globalSalesAndForecast = { ...state.globalSalesAndForecast, ...payload }
  },
  [types.MUTATION_SET_DISEASE_POTENTIALMARKET]: (state, payload) => {
    state.potentialMarket = { ...state.potentialMarket, ...payload }
  },
  [types.MUTATION_SET_DISEASE_CHINACOMPANYSALES]: (state, payload) => {
    state.chinaCompanySales = { ...state.chinaCompanySales, ...payload }
  },
  [types.MUTATION_SET_DISEASE_GLOBALCOMPANYSALES]: (state, payload) => {
    state.globalCompanySales = { ...state.globalCompanySales, ...payload }
  },
  [types.MUTATION_SET_DISEASE_REPLACERISKANALYSIS]: (state, payload) => {
    state.replaceRiskAnalysis = { ...state.replaceRiskAnalysis, ...payload }
  },
  [types.MUTATION_SET_DISEASE_DEPARTMENTANALYSIS]: (state, payload) => {
    state.departmentAnalysis = { ...state.departmentAnalysis, ...payload }
  },
  [types.MUTATION_SET_DISEASE_COMMONNAME]: (state, payload) => {
    state.commonName = { ...state.commonName, ...payload }
  },
  [types.MUTATION_SET_DISEASE_DRUGFORMJOINTCOMMONNAME]: (state, payload) => {
    state.drugFormJointCommonName = {
      ...state.drugFormJointCommonName,
      ...payload,
    }
  },
}
function filterObj(value) {
  let obj = {}
  Object.keys(value).forEach((item) => {
    obj[item] = ["[]", "0"].includes(JSON.stringify(value[item])) ? null : value[item]
  })
  return obj
}
export default {
  module: "Disease",
  state,
  actions,
  getters,
  mutations,
}
