/**
 * 监听自定义事件或者监听MUTATION的commit
 * @param {Object} param0 {mutationType: 监听的MUTATION类型，eventType：payload中自定义的事件类型，vm: VUE实例,callback:回调函数}
 */
export const listenEvent = ({ mutationType = "MUTATION_EVENT", eventType, vm, callback }) => {
  const unsubscribeFn = vm.$store.subscribe((mutation, state) => {
    if (mutation.type === mutationType) {
      if (!mutation.payload) return
      const { type, data = {} } = mutation.payload
      if (eventType && type === eventType) {
        callback(data)
      } else if (eventType === undefined) {
        callback(data)
      }
    }
  })
  return unsubscribeFn
}
/**
 * 防抖函数
 * @param {*} fn 
 * @param {*} delay 
 * @returns 
 */
export const debounce = (fn, delay) => {
  var time = null
  return function () {
    let context = this;//记录一下this指向
    let args = arguments;
    //清除定时任务
    if (time) clearTimeout(time);
    time = setTimeout(function () {
      time = null;
      fn.apply(context, args)
    }, delay)
  }
}
/**
 * 清除筛选条件空数据
 * @param {*} fn 
 * @param {*} delay 
 * @returns 
 */
export const compareAndDelete = (arr, obj) => {
  let result = [];
  arr.forEach(_obj => {
    if (_obj.formItem) {
      // 判断是否为数组，如果是，则将数组内的对象加入到结果中
      if (Array.isArray(_obj.formItem)) {
        result.push(..._obj.formItem);
      } else {
        result.push(_obj.formItem);
      }
    } else {
      result = arr
    }
  });
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      let targetParams = obj[key];
      let found = result.find(item => item.key === key && JSON.stringify(item.value.params) === JSON.stringify(targetParams));
      if (found) {
        delete obj[key];
      }
    }
  }

  return obj;
}
/**
 * 二层对象平铺返回
 * @param {*} obj 
 */
export const flattenNestedObject = (obj) => {
  const result = {};

  function flatten(obj, prefix = '') {
    for (let key in obj) {
      if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
        flatten(obj[key]);
      } else {
        result[`${prefix}${key}`] = obj[key];
      }
    }
  }

  flatten(obj);

  return result;
}
//合并json
export const mergeJsonObjects = (a, b, c) => {
  // 创建一个新的空对象，用来存放合并后的结果
  let merged = {}

  // 首先复制对象 a 的所有属性到 merged 中
  for (let key in a) {
    if (a.hasOwnProperty(key)) {
      merged[key] = a[key]
    }
  }
  // 然后复制对象 b 的所有属性到 merged 中
  for (let key in b) {
    if (b.hasOwnProperty(key)) {
      merged[key] = b[key]
    }
  }
  if (!c) {
    return merged
  }
  // 然后复制对象 b 的所有属性到 merged 中
  for (let key in c) {
    if (b.hasOwnProperty(key)) {
      merged[key] = c[key]
    }
  }
  return merged
}
//首字母转大写
export const capitalizeFirstLetter = (str) => {
  if (!str) return str; // 如果字符串为空，直接返回
  return str.charAt(0).toUpperCase() + str.slice(1);
}