import types from "@/type"
import ajaxApi from "../../../../config/request"
import axios from "../../../../http"
import { post } from "@/utils/request"
let state = {
  activeTab: {
    // 激活tab页
    type: 1, // 默认按登记号查看
    moduleName: "按登记号查看",
  },
  filterParams: {
    // 左侧筛选条件
    commonCondition: {},
    chinaCondition: {},
    americaCondition: {},
    emaCondition: {},
    globalCondition: {},
    hmaCondition: {},
    japanCondition: {},
  },
  // 筛选条件回显
  echoParams: [],
}
let actions = {
  // 查询表格数据
  [types.ACTIONS_MARKET_GETTABLELIST]: (store, params) => {
    let url = ajaxApi.Market.GetTableList
    return post(url, params)
  },
  // 导出表格数据
  [types.ACTIONS_MARKRT_DOWNLOADTABLEDATA]: (store, params) => {
    let url = ajaxApi.Market.DownloadTableData
    return post(url, params)
  },
  // 文件下载前的校验
  [types.ACTIONS_MARKET_CHECKDOWNLOADFILE]: (store, params) => {
    let url = ajaxApi.Market.CheckDownloadFile
    return post(url, params)
  },
  // 单个文件下载
  [types.ACTIONS_MARKET_DOWNLOADFILE]: (store, params) => {
    let url = ajaxApi.Market.DownloadFile
    return post(url, params)
  },
  // 模糊查询下拉框
  [types.ACTIONS_MARKET_RECOMMENDEDWORDS]: (store, params) => {
    let url = ajaxApi.Market.RecommendedWords
    return post(url, params)
  },
  // select下拉框查询
  [types.ACTIONS_MARKET_GETOPTIONSLIST]: (store, params) => {
    let url = ajaxApi.Market.GetFilterOptions
    return post(url, params)
  },
  // 点击数字弹框查询
  [types.ACTIONS_MARKET_SHOWCOUNTOPENDATA]: (store, params) => {
    let url = ajaxApi.Market.ShowCountOpenData
    return post(url, params)
  },
  // 查询数量
  [types.ACTIONS_MARKET_GETTOTALNUMBER]: (store, params) => {
    let url = ajaxApi.Market.GetAllViewDataCount
    return post(url, params)
  },
  // 查询豁免数据
  [types.ACTIONS_MARKET_GETBELISTTYPE]: (store, params) => {
    let url = ajaxApi.Market.GetBEListType
    return axios
      .get(url)
      .then((res) => {
        if (res.code === 200) {
          return res.data
        } else {
          return Promise.resolve()
        }
      })
    // return post(url, params)
  },

}
let getters = {
  // 激活tab页参数
  [types.GETTERS_MARKRT_ACTIVETAB]: (state) => {
    return state.activeTab
  },
  [types.GETTERS_MARKET_FILTERPARAMS]: (state) => {
    return state.filterParams
  },
  [types.GETTERS_PRODUCT_ECHOPARAMS]: (state) => {
    return state.echoParams
  },
}
let mutations = {
  // 激活tab页参数
  [types.MUTATION_MARKET_ACTIVETAB]: (state, payload) => {
    state.activeTab = {
      ...state.activeTab,
      ...payload,
    }
  },
  [types.MUTATION_MARKET_FILTERPARAMS]: (state, payload) => {
    if (!payload) {
      state.filterParams = {}
    } else {
      state.filterParams = {
        ...state.filterParams,
        ...payload,
      }
    }
  },
  [types.MUTATION_PRODUCT_ECHOPARAMS]: (state, payload) => {
    state.echoParams = payload
  },
}

export default {
  module: "Market",
  state,
  actions,
  getters,
  mutations,
}
