import types from "@/type"
import ajaxApi from "../../../../config/request"
import { post } from "@/utils/request"
let state = {
  activeTab: {
    // 激活tab页
    type: 1, // 默认按品规+企业查看
    moduleName: "按品规+企业查看",
  },
  filterParams: {
    // 左侧筛选条件
  },
  // 筛选条件回显
  echoParams: [],
}
let actions = {
  // 查询表格数据
  [types.ACTIONS_DRUGBIDDINGANALYSIS_GETTABLEDATA]: (store, params) => {
    let url = ajaxApi.DrugBiddingAnalysis.GetTableList
    return post(url, params)
  },
  // 导出表格数据
  [types.ACTIONS_DRUGBIDDINGANALYSIS_DOWNLOADTABLEDATA]: (store, params) => {
    let url = ajaxApi.DrugBiddingAnalysis.DownloadTableData
    return post(url, params)
  },
  // 模糊查询下拉框
  [types.ACTIONS_DRUGBIDDINGANALYSIS_RECOMMENDEDWORDS]: (store, params) => {
    let url = ajaxApi.DrugBiddingAnalysis.RecommendedWords
    return post(url, params)
  },
  // 二次搜索查询下拉框
  [types.ACTIONS_DRUGBIDDINGANALYSIS_SECONDSEARCHFILTER]: (store, params) => {
    let url = ajaxApi.DrugBiddingAnalysis.SecondSearchFilter
    return post(url, params)
  },
  // select下拉框查询
  [types.ACTIONS_DRUGBIDDINGANALYSIS_GETOPTIONSLIST]: (store, params) => {
    let url = ajaxApi.DrugBiddingAnalysis.GetFilterOptions
    return post(url, params)
  },
  // 点击数字弹框查询
  [types.ACTIONS_DRUGBIDDINGANALYSIS_SHOWCOUNTOPENDATA]: (store, params) => {
    let url = ajaxApi.DrugBiddingAnalysis.ShowCountOpenData
    return post(url, params)
  },
  // 查询数量
  [types.ACTIONS_DRUGBIDDINGANALYSIS_GETTOTALNUMBER]: (store, params) => {
    let url = ajaxApi.DrugBiddingAnalysis.GetAllViewDataCount
    return post(url, params)
  },
  [types.ACTIONS_DRUGBIDDINGANALYSIS_BIOLOGICDRUGCLASSIFICATION]: (store, params) => {
    let url = ajaxApi.DrugBiddingAnalysis.BiologicDrugClassification
    return post(url, params)
  },
  [types.ACTIONS_DRUGBIDDINGANALYSIS_TCM]: (store, params) => {
    let url = ajaxApi.DrugBiddingAnalysis.Tcm
    return post(url, params)
  },
  [types.ACTIONS_DRUGBIDDINGANALYSIS_TCMOFDISEASE]: (store, params) => {
    let url = ajaxApi.DrugBiddingAnalysis.TcmOfDisease
    return post(url, params)
  },
}
let getters = {
  // 激活tab页参数
  [types.GETTERS_DRUGBIDDINGANALYSIS_ACTIVETAB]: (state) => {
    return state.activeTab
  },
  [types.GETTERS_DRUGBIDDINGANALYSIS_FILTERPARAMS]: (state) => {
    return state.filterParams
  },
  [types.GETTERS_DRUGBIDDINGANALYSIS_ECHOPARAMS]: (state) => {
    return state.echoParams
  },
}
let mutations = {
  // 激活tab页参数
  [types.MUTATION_DRUGBIDDINGANALYSIS_ACTIVETAB]: (state, payload) => {
    state.activeTab = {
      ...state.activeTab,
      ...payload,
    }
  },
  [types.MUTATION_DRUGBIDDINGANALYSIS_FILTERPARAMS]: (state, payload) => {
    if (!payload) {
      state.filterParams = {}
    } else if (payload === "reset") {
      state.filterParams = {}
    } else {
      state.filterParams = {
        ...state.filterParams,
        ...payload,
      }
    }
  },
  [types.MUTATION_DRUGBIDDINGANALYSIS_ECHOPARAMS]: (state, payload) => {
    state.echoParams = payload
  },
}

export default {
  module: "DrugBiddingAnalysis",
  state,
  actions,
  getters,
  mutations,
}
