import types from "../../../type"
import axios from "../../../../http"
import ajaxApi from "../../../../config/request"
import { post } from "@/utils/request"
let state = {
  filterType: 1, //研发获批分析，按药物查看(0)还是按疾病查看(1)
  screenCondition: [], //筛选数据对象
  filterSortCondition: {}, //页面传参的数据
  changescreenCondition: {
    status: 0,
    deletecode: "",
  }, //点击筛选按钮后的数据status为状态0是初始状态，1是查询，2是所以清空所有查询条件，3是删除某个查询条件，deletecode是删除条件的code
  //靶点页面的图表数据
  targetchartdata: {},

  //靶点数据表数据
  TargetDataTable: {},
  //靶点分类
  targetType: [],

  durgAnalysis: {}, //药物分析
  salesAnalysis: {}, //中国药物销售分析
  companyAnalysis: {}, //公司分析
  enterpriseSalesAnalysis: {}, //药品销售分析使用通用名查企业
  diseaseAnalysis: {}, //全球疾病阶段分析
  countryRDMapAnalysis: {}, //全球药物全国研究分析
  drugRDAnalysis: {}, //药品研发获取分析
  salesCompany: {}, //销售分析按通用名获取Top10公司
  diseaseAtlas: {}, //疾病图谱
  RDAnalysis: {}, //研发获批分析
  chinaSalesAndForecast: {}, //销售总额分析与预测
  globalSalesAndForecast: {}, //销售总额分析与预测
  potentialMarket: {}, //市场潜力分析
  chinaCompanySales: {}, //公司实力
  globalCompanySales: {}, //公司实力
  clinical: {}, //获取临床信息
  drugFormSales: {}, //剂型实力
  replaceRiskAnalysis: {}, //代替风险
  departmentAnalysis: {}, //科室分布
  disease: {}, //获取疾病列表
  drugType: {}, //获取药品列表
  drugFormJointCommonName: {}, //获通用名获取药品剂型（产品名称）
  commonName: {}, //获取通用名列表
  targetMedicineChina: {},
  targetMedicineGlobal: {},
  targetDiseaseChina: {},
  targetDiseaseGlobal: {},
  drugComparisonAnalysis: [], //药物对比分析
}
let actions = {
  /**
   * 异步修改用法
   *addBooks:function(){
     this.$store.dispatch('ACTIONS_HOME')
  },
   *
   */
  [types.ACTIONS_HOME]: ({ commit, state }) => {
    let url = "/activity/eventAudit/queryEventAuditList"
    let params = {
      ...state.searchForm,
      pageSize: state.checkPagination.pageSize,
      pageNum: state.checkPagination.pageNum,
    }
    axios
      .post(url, params)
      .then((res) => {
        commit(types.MUTATION_EM_SET_CHECK_LIST, res.data.objs)
        commit(types.MUTATION_EM_SET_CHECK_PAGINATION, {
          total: res.data.total,
        })
      })
      .catch((e) => {
        return e
      })
  },
  /**获取靶点枚举列表 */
  // 获取靶点数据表列表数据
  [types.ACTIONS_GET_TARGET_TARGETDATATABLE]: ({ commit, state }, value) => {
    let url = ajaxApi.Target.TargetDataTable
    let filterData = state.filterSortCondition
    if (value.orderBy == null || value.orderBy == undefined) {
      value.orderBy = "listedGlobal"
      value.isOrderDesc = true
    }
    let params = {
      // ...state.searchForm,
      // pageSize: state.PrizePagination.pageSize,
      // pageNum: state.PrizePagination.pageNum,
      pageSize: value.pageSize,
      currentPage: value.currentPage,
      orderBy: value.orderBy,
      isOrderDesc: value.isOrderDesc,
      ...filterData,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          commit(types.MUTATION_SET_TARGET_TARGETDATATABLE, res.data)
          return Promise.resolve()
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },

  [types.ACTIONS_GET_TARGET_RECOMMENDEDWORDS]: ({ commit, state }, value) => {
    let url
    if (value.type != undefined) {
      if (value.type == "id") {
        url = ajaxApi.Target.TargetType + `?id=${value.id}`
      } else {
        url = ajaxApi.Target.TargetType + `?name=${value.name}`
      }
    } else {
      url = ajaxApi.Target.TargetType
    }
    return axios
      .post(url)
      .then((res) => {
        return res.data
      })
      .catch((e) => {
        return e
      })
  },
  /**获取靶点类型数据 */
  [types.ACTIONS_GET_TARGET_TARGETTYPE]: ({ commit, state }, value) => {
    let url
    if (value.type != undefined) {
      if (value.type == "id") {
        url = ajaxApi.Target.TargetType + `?id=${value.id}`
      } else {
        url = ajaxApi.Target.TargetType + `?name=${value.name}`
      }
    } else {
      url = ajaxApi.Target.TargetType
    }

    return axios
      .post(url)
      .then((res) => {
        return res.data
      })
      .catch((e) => {
        return e
      })
  },
  // 获取靶点数据表列表数据
  [types.ACTIONS_GET_TARGET_RECOMMENDEDWORDS]: ({ commit, state }, params) => {
    let url = ajaxApi.Target.RecommendedWords
    return post(url, params)
  },
  // 根据ID获取靶点树
  [types.ACTIONS_GET_TARGET_TARGETTYPEBYID]: ({ commit, state }, params) => {
    let url = ajaxApi.Target.TargetTypeById + params
    return post(url, {})
  },
  // 获取靶点树
  [types.ACTIONS_GET_TARGET_TARGETTYPENEW]: ({ commit, state }, params) => {
    let url = params !== "001" ? ajaxApi.Target.TargetTypeNew + `?combinationId=${params}` : ajaxApi.Target.TargetTypeNew
    return post(url, {})
  },
  //靶点数据表下载
  [types.ACTIONS_GET_TARGET_DOWNLOADTARGETRDANALYSIS]: (store, value) => {
    let url = ajaxApi.Target.DownloadTargetDataTable
    const { commit, dispatch, state, rootState } = store
    let filterData = rootState.Target.filterSortCondition
    let params = {
      pageSize: value.pageSize,
      currentPage: value.currentPage,
      orderBy: value.orderBy,
      isOrderDesc: value.isOrderDesc,
      ...filterData,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          return res
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //药物分析
  [types.ACTIONS_GET_TARGET_DURGANALYSIS]: ({ commit, state }, value) => {
    let url = ajaxApi.Target.DrugAnalysis
    let params = {
      ...value,
      // ...state.searchForm,
      // pageSize: state.PrizePagination.pageSize,
      // pageNum: state.PrizePagination.pageNum,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          commit(types.MUTATION_SET_TARGET_DURGANALYSIS, res.data)
          return Promise.resolve()
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //中国药物销售分析
  [types.ACTIONS_GET_TARGET_SALESANALYSIS]: ({ commit, state }, value) => {
    let url = ajaxApi.Target.SalesAnalysis
    let params = {
      ...value,
      // ...state.searchForm,
      // pageSize: state.PrizePagination.pageSize,
      // pageNum: state.PrizePagination.pageNum,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          commit(types.MUTATION_SET_TARGET_SALESANALYSIS, res.data)
          return Promise.resolve()
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //疾病分析
  [types.ACTIONS_GET_TARGET_DISEASEANALYSIS]: ({ commit, state }, value) => {
    let url = ajaxApi.Target.DiseaseAnalysis
    let params = {
      ...value,
      // ...state.searchForm,
      // pageSize: state.PrizePagination.pageSize,
      // pageNum: state.PrizePagination.pageNum,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          commit(types.MUTATION_SET_TARGET_DISEASEANALYSIS, res.data)

          return Promise.resolve()
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },

  //公司分析
  [types.ACTIONS_GET_TARGET_COMPANYANALYSIS]: ({ commit, state }, value) => {
    let url = ajaxApi.Target.CompanyAnalysis
    let params = {
      ...value,
      // ...state.searchForm,
      // pageSize: state.PrizePagination.pageSize,
      // pageNum: state.PrizePagination.pageNum,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          commit(types.MUTATION_SET_TARGET_COMPANYANALYSIS, res.data)

          return Promise.resolve()
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //根据药品获取前十的公司
  [types.ACTIONS_GET_TARGET_COUNTRYRDMAPANALYSIS]: ({ commit, state }, value) => {
    let url = ajaxApi.Target.RdMap
    let params = {
      ...value,
      // ...state.searchForm,
      // pageSize: state.PrizePagination.pageSize,
      // pageNum: state.PrizePagination.pageNum,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          commit(types.MUTATION_SET_TARGET_COUNTRYRDMAPANALYSIS, res.data)

          return Promise.resolve()
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //销售分析按通用名获取Top10公司
  [types.ACTIONS_GET_TARGET_SALESCOMPANY]: ({ commit, state }, options) => {
    let url = ajaxApi.Target.SalesCompany + `?id=${options.id}&isGlobal=${options.isGlobal}`
    let params = {
      ...options,

      // ...state.searchForm,
      // pageSize: state.PrizePagination.pageSize,
      // pageNum: state.PrizePagination.pageNum,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          commit(types.MUTATION_SET_TARGET_SALESCOMPANY, res.data)
          return Promise.resolve()
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //疾病图谱
  [types.ACTIONS_GET_TARGET_DISEASEATLAS]: ({ commit, state }, value) => {
    let url = ajaxApi.Target.DiseaseAtlas
    let params = {
      ...value,
      // ...state.searchForm,
      // pageSize: state.PrizePagination.pageSize,
      // pageNum: state.PrizePagination.pageNum,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          commit(types.MUTATION_SET_TARGET_DISEASEATLAS, res)
          return Promise.resolve()
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },

  // 销售总额分析与预测
  [types.ACTIONS_GET_TARGET_SALESANDFORECAST]: ({ commit, state }, options) => {
    let url = ajaxApi.Target.SalesAndForecast + `?id=${options.id}`
    let params = {
      ...options,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          if (options.isGlobal) {
            commit(types.MUTATION_SET_TARGET_GLOBALSALESANDFORECAST, res.data)
          } else {
            commit(types.MUTATION_SET_TARGET_CHINASALESANDFORECAST, res.data)
          }

          return Promise.resolve()
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //药物详情 市场潜力
  [types.ACTIONS_GET_TARGET_POTENTIALMARKET]: ({ commit, state }, options) => {
    let url = ajaxApi.Target.PotentialMarket + `?id=${options.id}`
    let params = {
      ...options,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          commit(types.MUTATION_SET_TARGET_POTENTIALMARKET, res.data)
          return Promise.resolve()
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //药物详情 公司实力
  [types.ACTIONS_GET_TARGET_COMPANYSALES]: ({ commit, state }, options) => {
    let url = ajaxApi.Target.CompanySales + `?id=${options.id}`
    let params = {
      ...options,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          if (options.isGlobal) {
            commit(types.MUTATION_SET_TARGET_GLOBALCOMPANYSALES, res.data)
          } else {
            commit(types.MUTATION_SET_TARGET_CHINACOMPANYSALES, res.data)
          }
          return Promise.resolve()
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //药物详情 代替风险
  [types.ACTIONS_GET_TARGET_REPLACERISKANALYSIS]: ({ commit, state }, options) => {
    let url = ajaxApi.Target.ReplaceRiskAnalysis
    let params = {
      ...options,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          commit(types.MUTATION_SET_TARGET_REPLACERISKANALYSIS, res.data)
          return Promise.resolve()
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //获取疾病列表
  [types.ACTIONS_GET_TARGET_DISEASE]: ({ commit, state }, value) => {
    let url = ajaxApi.Target.Disease
    let params = value
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          return res.data
        } else {
          return res.message
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //获取疾病列表
  [types.ACTIONS_GET_TARGET_DRUGTYPE]: ({ commit, state }, options) => {
    let url = ajaxApi.Target.DrugType
    let params = {}
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          commit(types.MUTATION_SET_TARGET_DRUGTYPE, res.data)
          return Promise.resolve()
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //获取疾病列表
  [types.ACTIONS_GET_TARGET_DRUGFORMJOINTCOMMONNAME]: ({ commit, state }, options) => {
    let url = ajaxApi.Target.DrugFormJointCommonName
    let params = {
      ...options,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          commit(types.MUTATION_SET_TARGET_DRUGFORMJOINTCOMMONNAME, res.data)
          return Promise.resolve()
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  [types.ACTIONS_GET_TARGET_COMMONNAME]: ({ commit, state }, options) => {
    let url = ajaxApi.Target.CommonName
    let params = {
      ...options,
      type: 1,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          commit(types.MUTATION_SET_TARGET_COMMONNAME, res.data)
          return Promise.resolve()
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //获取国家列表
  [types.ACTIONS_GET_TARGET_COUNTRY]: ({ commit, state }, value) => {
    let url = ajaxApi.Target.Country
    let params = value
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          return res.data
        } else {
          return res.message
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //获取靶点列表
  [types.ACTIONS_GET_TARGET_TARGET]: ({ commit, state }, value) => {
    let url = ajaxApi.Target.Target
    let params = value
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          return res.data
        } else {
          return res.message
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //获取公司列表
  [types.ACTIONS_GET_TARGET_COMPANY]: ({ commit, state }, value) => {
    let url = ajaxApi.Target.Company
    let params = value
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          return res.data
        } else {
          return res.message
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //研发获批分析
  [types.ACTIONS_GET_TARGET_RDANALYSIS]: ({ commit, state }, value) => {
    let url = ajaxApi.Target.RDAnalysis
    let params = filterObj(value)
    let type = value.type
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          state[type] = res.data
          return Promise.resolve()
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //获取药物对比分析
  [types.ACTIONS_GET_TARGET_DRUGCOMPARISONANALYSIS]: ({ commit, state, rootState }, options) => {
    let url = ajaxApi.Target.DrugComparisonAnalysis
    let params = {
      ...options,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          rootState.Disease.drugComparisonAnalysis = res.data
          return Promise.resolve()
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
}
let getters = {
  /**
     * 读取属性用法
     * methods:{
         name:function(){
      return this.store.getters.GETTERS_GLOBAL_HOME();
    },
   }
     */
  [types.GETTERS_TARGET_SCREENCONDITION]: (state) => {
    return state.screenCondition
  },
  [types.GETTERS_TARGET_CHANGESCREENCONDITION]: (state) => {
    return state.changescreenCondition
  },
  [types.GETTERS_TARGET_FILTERSORTCONDITION]: (state) => {
    return state.filterSortCondition
  },
  [types.GETTERS_TARGET_TARGETCHARTDATA]: (state) => {
    return state.targetchartdata
  },

  [types.GETTERS_TARGET_DATASHEETDATA]: (state) => {
    return state.tableData
  },
  [types.GETTERS_TARGET_TARGETDATATABLE]: (state) => {
    return state.TargetDataTable
  },
  [types.GETTERS_TARGET_DURGANALYSIS]: (state) => {
    return state.durgAnalysis
  },
  [types.GETTERS_TARGET_SALESANALYSIS]: (state) => {
    return state.salesAnalysis
  },
  [types.GETTERS_TARGET_DISEASEANALYSIS]: (state) => {
    return state.diseaseAnalysis
  },
  [types.GETTERS_TARGET_COUNTRYRDMAPANALYSIS]: (state) => {
    return state.countryRDMapAnalysis
  },
  [types.GETTERS_TARGET_COMPANYANALYSIS]: (state) => {
    return state.companyAnalysis
  },
  [types.GETTERS_TARGET_SALESCOMPANY]: (state) => {
    return state.salesCompany
  },
  [types.GETTERS_TARGET_DISEASEATLAS]: (state) => {
    return state.diseaseAtlas
  },
  [types.GETTERS_TARGET_CHINASALESANDFORECAST]: (state) => {
    return state.chinaSalesAndForecast
  },
  [types.GETTERS_TARGET_GLOBALSALESANDFORECAST]: (state) => {
    return state.globalSalesAndForecast
  },
  [types.GETTERS_TARGET_POTENTIALMARKET]: (state) => {
    return state.potentialMarket
  },
  [types.GETTERS_TARGET_CHINACOMPANYSALES]: (state) => {
    return state.chinaCompanySales
  },
  [types.GETTERS_TARGET_GLOBALCOMPANYSALES]: (state) => {
    return state.globalCompanySales
  },
  [types.GETTERS_TARGET_DRUGFORMSALES]: (state) => {
    return state.drugFormSales
  },
  [types.GETTERS_TARGET_REPLACERISKANALYSIS]: (state) => {
    return state.replaceRiskAnalysis
  },
  [types.GETTERS_TARGET_DEPARTMENTANALYSIS]: (state) => {
    return state.departmentAnalysis
  },
  [types.GETTERS_TARGET_RDANALYSIS]: (state) => {
    return state.RDAnalysis
  },
  [types.GETTERS_TARGET_RDANALYSIS]: (state) => {
    return state.RDAnalysis
  },
  [types.GETTERS_TARGET_DISEASE]: (state) => {
    return state.disease
  },
  [types.GETTERS_TARGET_DRUGTYPE]: (state) => {
    return state.drugType
  },
  [types.GETTERS_TARGET_DRUGFORMJOINTCOMMONNAME]: (state) => {
    return state.drugFormJointCommonName
  },
  [types.GETTERS_TARGET_COMMONNAME]: (state) => {
    return state.commonName
  },
  [types.GETTERS_TARGET_DRUGCOMPARISONANALYSIS]: (state) => {
    return state.drugComparisonAnalysis
  },
}
let mutations = {
  /**
   * 同步修改属性用法
   *this.$store.commit('MUTATION_GLOBAL_HOME','跟的参数')
   */
  [types.MUTATION_SET_TARGET_SCREENCONDITION]: (state, payload) => {
    state.screenCondition = payload
  },
  [types.MUTATION_SET_TARGET_CHANGESCREENCONDITION]: (state, payload) => {
    state.changescreenCondition = payload
  },
  [types.MUTATION_SET_TARGET_FILTERSORTCONDITION]: (state, payload) => {
    state.filterSortCondition = payload
  },
  [types.MUTATION_TARGET_TARGETCHARTDATA]: (state, payload) => {
    state.targetchartdata = payload
  },
  [types.MUTATION_SET_TARGET_TARGETDATATABLE]: (state, payload) => {
    state.TargetDataTable = payload
  },
  [types.MUTATION_SET_TARGET_DURGANALYSIS]: (state, payload) => {
    state.durgAnalysis = {
      ...state.durgAnalysis,
      ...payload,
    }
  },
  [types.MUTATION_SET_TARGET_SALESANALYSIS]: (state, payload) => {
    state.salesAnalysis = {
      ...state.salesAnalysis,
      ...payload,
    }
  },
  [types.MUTATION_SET_TARGET_DISEASEANALYSIS]: (state, payload) => {
    state.diseaseAnalysis = {
      ...state.diseaseAnalysis,
      ...payload,
    }
  },
  [types.MUTATION_SET_TARGET_COUNTRYRDMAPANALYSIS]: (state, payload) => {
    state.countryRDMapAnalysis = {
      ...state.countryRDMapAnalysis,
      ...payload,
    }
  },
  [types.MUTATION_SET_TARGET_COMPANYANALYSIS]: (state, payload) => {
    state.companyAnalysis = {
      ...state.companyAnalysis,
      ...payload,
    }
  },
  [types.MUTATION_SET_TARGET_SALESCOMPANY]: (state, payload) => {
    state.salesCompany = {
      ...state.salesCompany,
      ...payload,
    }
  },
  [types.MUTATION_SET_TARGET_DISEASEATLAS]: (state, payload) => {
    state.diseaseAtlas = {
      ...state.diseaseAtlas,
      ...payload,
    }
  },
  [types.MUTATION_SET_TARGET_CHINASALESANDFORECAST]: (state, payload) => {
    state.chinaSalesAndForecast = { ...state.chinaSalesAndForecast, ...payload }
  },
  [types.MUTATION_SET_TARGET_GLOBALSALESANDFORECAST]: (state, payload) => {
    state.globalSalesAndForecast = { ...state.globalSalesAndForecast, ...payload }
  },
  [types.MUTATION_SET_TARGET_POTENTIALMARKET]: (state, payload) => {
    state.potentialMarket = {
      ...state.potentialMarket,
      ...payload,
    }
  },
  [types.MUTATION_SET_TARGET_CHINACOMPANYSALES]: (state, payload) => {
    state.chinaCompanySales = { ...state.chinaCompanySales, ...payload }
  },
  [types.MUTATION_SET_TARGET_GLOBALCOMPANYSALES]: (state, payload) => {
    state.globalCompanySales = { ...state.globalCompanySales, ...payload }
  },
  [types.MUTATION_SET_TARGET_DRUGFORMSALES]: (state, payload) => {
    state.drugFormSales = {
      ...state.drugFormSales,
      ...payload,
    }
  },
  [types.MUTATION_SET_TARGET_DEPARTMENTANALYSIS]: (state, payload) => {
    state.departmentAnalysis = {
      ...state.departmentAnalysis,
      ...payload,
    }
  },
  [types.MUTATION_SET_TARGET_REPLACERISKANALYSIS]: (state, payload) => {
    state.replaceRiskAnalysis = {
      ...state.replaceRiskAnalysis,
      ...payload,
    }
  },
  [types.MUTATION_SET_TARGET_RDANALYSIS]: (state, payload) => {
    state.RDAnalysis = {
      ...state.RDAnalysis,
      ...payload,
    }
  },
  [types.MUTATION_SET_TARGET_DISEASE]: (state, payload) => {
    state.disease = {
      ...state.disease,
      ...payload,
    }
  },
  [types.MUTATION_SET_TARGET_DRUGTYPE]: (state, payload) => {
    state.drugType = {
      ...state.drugType,
      ...payload,
    }
  },
  [types.MUTATION_SET_TARGET_DRUGFORMJOINTCOMMONNAME]: (state, payload) => {
    state.drugFormJointCommonName = {
      ...state.drugFormJointCommonName,
      ...payload,
    }
  },
  [types.MUTATION_SET_TARGET_COMMONNAME]: (state, payload) => {
    state.commonName = {
      ...state.commonName,
      ...payload,
    }
  },
}
function filterObj(value) {
  let obj = {}
  Object.keys(value).forEach((item) => {
    obj[item] = ["[]", "0"].includes(JSON.stringify(value[item])) ? null : value[item]
  })
  return obj
}
export default {
  module: "Target",
  state,
  actions,
  getters,
  mutations,
}
