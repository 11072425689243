import types from "@/type"
import ajaxApi from "../../../../config/request"
import { post } from "@/utils/request"
let state = {
  activeTab: {
    // 激活tab页
    type: 1, // 默认按品规+企业查看
    moduleName: "按品规+企业查看",
  },
  filterParams: {
    // 左侧筛选条件
  },
  // 筛选条件回显
  echoParams: [],
}
let actions = {
  // 查询表格数据
  [types.ACTIONS_REGISTRATION_GETTABLEDATA]: (store, params) => {
    let url = ajaxApi.Registration.GetTableList
    return post(url, params)
  },
  // 导出表格数据
  [types.ACTIONS_REGISTRATION_DOWNLOADTABLEDATA]: (store, params) => {
    let url = ajaxApi.Registration.DownloadTableData
    return post(url, params)
  },
  // 模糊查询下拉框
  [types.ACTIONS_REGISTRATION_RECOMMENDEDWORDS]: (store, params) => {
    let url = ajaxApi.Registration.RecommendedWords
    return post(url, params)
  },
  // select下拉框查询
  [types.ACTIONS_REGISTRATION_GETOPTIONSLIST]: (store, params) => {
    let url = ajaxApi.Registration.GetFilterOptions
    return post(url, params)
  },
  // 点击数字弹框查询
  [types.ACTIONS_REGISTRATION_SHOWCOUNTOPENDATA]: (store, params) => {
    let url = ajaxApi.Registration.ShowCountOpenData
    return post(url, params)
  },
  // 查询数量
  [types.ACTIONS_REGISTRATION_GETTOTALNUMBER]: (store, params) => {
    let url = ajaxApi.Registration.GetAllViewDataCount
    return post(url, params)
  },
}
let getters = {
  // 激活tab页参数
  [types.GETTERS_REGISTRATION_ACTIVETAB]: (state) => {
    return state.activeTab
  },
  [types.GETTERS_REGISTRATION_FILTERPARAMS]: (state) => {
    return state.filterParams
  },
  [types.GETTERS_REGISTRATION_ECHOPARAMS]: (state) => {
    return state.echoParams
  },
}
let mutations = {
  // 激活tab页参数
  [types.MUTATION_REGISTRATION_ACTIVETAB]: (state, payload) => {
    state.activeTab = {
      ...state.activeTab,
      ...payload,
    }
  },
  [types.MUTATION_REGISTRATION_FILTERPARAMS]: (state, payload) => {
    if (!payload) {
      state.filterParams = {}
    } else {
      state.filterParams = {
        ...state.filterParams,
        ...payload,
      }
    }
  },
  [types.MUTATION_REGISTRATION_ECHOPARAMS]: (state, payload) => {
    state.echoParams = payload
  },
}

export default {
  module: "Registration",
  state,
  actions,
  getters,
  mutations,
}
