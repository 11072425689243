// CustomDialog.js
import Vue from 'vue';
import { Dialog, Button } from 'element-ui';
const DialogPlugin = {
    install(Vue) {
        // 注册Element UI的Dialog组件和Button组件
        Vue.component(Dialog.name, Dialog);
        Vue.component(Button.name, Button);
        // 在Vue原型上添加全局方法
        Vue.prototype.$myDialog = function (options = {}) {
            const defaultOptions = {
                title: '下载限制提示',
                content: '',
                confirmButtonText: '继续下载',
                cancelButtonText: '修改检索条件',
                onConfirm: null,
                onCancel: null
            };
            const mergedOptions = { ...defaultOptions, ...options };
            return new Promise((resolve, reject) => {
                const dialogComponent = new Vue({
                    render: (h) => h(Dialog, {
                        props: {
                            title: mergedOptions.title,
                            visible: true
                        },
                        on: {
                            'update:visible': (newVisible) => {
                                if (!newVisible) {
                                    dialogComponent.$destroy();
                                    document.body.removeChild(dialogComponent.$el);
                                }
                            }
                        }
                    }, [
                        mergedOptions.content,
                        h('div', { slot: 'footer' }, [
                            h(Button, {
                                props: { type: 'text', size: 'small' },
                                on: {
                                    click: () => {
                                        //取消
                                        if (mergedOptions.onCancel) {
                                            mergedOptions.onCancel();
                                        }
                                        dialogComponent.$destroy();
                                        document.body.removeChild(dialogComponent.$el);
                                        resolve(false);
                                    }
                                }
                            }, mergedOptions.cancelButtonText),
                            h(Button, {
                                props: { type: 'primary', size: 'small' },
                                on: {
                                    click: () => {
                                        //确定
                                        if (mergedOptions.onConfirm) {
                                            mergedOptions.onConfirm();
                                        }
                                        dialogComponent.$destroy();
                                        document.body.removeChild(dialogComponent.$el);
                                        resolve(true);
                                    }
                                }
                            }, mergedOptions.confirmButtonText)
                        ])
                    ])
                });
                const vm = dialogComponent.$mount();
                document.body.appendChild(vm.$el);
            });
        };
    }
};
export default DialogPlugin;