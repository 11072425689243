<!-- hover弹框组件 -->
<template>
  <div class="custom-popover-wrapper">
    <el-popover placement="right" trigger="hover" :visible-arrow="false" popper-class="custom-popper">
      <slot></slot>
      <span slot="reference">
        <slot name="reference"></slot>
      </span>
    </el-popover>
  </div>
</template>

<script>
export default {
  name: "Popover",
  components: {},
  props: {},
  data() {
    return {}
  },
  computed: {},
  watch: {},
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  methods: {},
}
</script>
<style lang="scss">
body {
  .el-popover {
    min-width: 0;
  }
  .custom-popper {
    max-height: 400px;
    overflow-y: auto;
    padding: 5px 10px;
    background: #E3E9F6;
    color: #606266;
    width: auto;
    max-width: 300px;
    box-sizing: border-box;
    &::-webkit-scrollbar {
      width: 6px;
      height: 13px;
      border-radius: 6px;
    }
  }
}
</style>
