import router from "@/router/index"

export const eventTrack = function ({ type, eventAction }) {
  const module = moduleName(this.$store?.state)
  let eventActionData = { module }
  switch (type) {
    case "phiic_search_keyword":
      eventActionData.keywords = eventAction
      break
    case "phiic_download":
      eventActionData = { download_ok: eventAction.phiic_download_ok, count: eventAction.event_count, module, event_obj: "下载" }
      break
    case "phiic_search":
      eventActionData.event_obj = "查询"
      break
    default:
      // 通用情况，phiic_login和event_log
      eventActionData = { ...eventAction, ...eventActionData }
      break
  }

  this.$phiicLog.eventTrack({ type, eventAction: eventActionData })
}

//获取模块名称
function moduleName(stateMap) {
  let marketModule = "上市"
  const routersValue = [
    { code: "Disease", name: "疾病" },
    { code: "Home", name: "首页" },
    { code: "Personal", name: "个人中心" },
    { code: "Login", name: "登录" },
    { code: "Wechat", name: "微信扫码登录" },
    { code: "Register", name: "注册" },
    { code: "forgetMima", name: "忘记密码" },
    { code: "Target", name: "靶点" },
    { code: "Medicine", name: "药品" },
    { code: "Company", name: "公司" },
    { code: "Registration", name: "申报" },
    { code: "Market", name: "上市" },
    { code: "Clinical", name: "临床" },
    { code: "RawMaterial", name: "原辅包" },
    { code: "ReferListedDrug", name: "参比制剂目录" },
    { code: "Equivalence", name: "一致性评价进度" },
    { code: "TargetDetail", name: "靶点详情" },
    { code: "medicareCatalog", name: "医保目录" },
    { code: "essentialDrug", name: "基药目录" },
    { code: "drugBidding", name: "招标" },
    { code: "drugBiddingAnalysis", name: "招标分析" },
    { code: "AmericaNDC", name: "美国NDC药品" },
    { code: "globalDrugPatents", name: "全球药物专利" },
    { code: "DiseaseDetail", name: "疾病详情" },
    { code: "CompanyDetail", name: "公司详情" },
    { code: "registrationDetail", name: "申报详情" },
    { code: "medicineDetail", name: "药品详情" },
    { code: "oneMarketComparison", name: "产品对比模块" },
    { code: "oneMarketInsurance", name: "医保放量拉动分析模块" },
    { code: "oneMarketCentralized", name: "集采放量拉动分析" },
    { code: "oneTargetCompany", name: "目标公司大事件" },
    { code: "oneTargetSimulationMerge", name: "公司模拟合并" },
    {
      code: "oneTargetValuationInnovative",
      name: "国产创新药估值",
    },
    { code: "oneRAndDmapApproved", name: "药品获批领域地图" },
    { code: "oneRAndDImitativeMedicine", name: "挖掘仿药老品种" },
    { code: "oneRAndDEmergingTargets", name: "新兴靶点研发热度" },
    { code: "oneRAndDhotAreas", name: "新兴靶点研发热度" },
    { code: "clinicalDetail", name: "临床详情页" },
    { code: "globalNewDrug", name: "全球药物研发" },
    { code: "globalNewDrugDetail", name: "全球药物研发详情" },
    { code: "AmericaNDC", name: "美国NDC药品" },
    { code: "AnnualReports", name: "企业年报" },
    { code: "ClinicalPathway", name: "临床用药路径" },
    { code: "TreatmentGuide", name: "临床用药路径" },
  ]
  const routersItem = routersValue.find((item) => item.code.toLowerCase() === router.currentRoute.name.toLowerCase())
  if (stateMap) {
    marketModule = stateMap.Product?.activeTabAndSubTab?.tab?.moduleName
  }
  return routersItem?.name === "上市" ? marketModule : routersItem?.name
}
