import Vue from "vue"
import Popover from "@/components/customComponents/Popover"
import _ from "lodash"
import apiMap from "../../config/request"
Vue.component("Popover", Popover)

/**
 *  判断值的类型
 * @param {any} str
 * @returns 'Array' 'Object'...
 */
export const judgeType = (str) => {
  const type = Object.prototype.toString.call(str)
  return type.replace(/\[object|]/g, "").trim()
}

/**
 * 点击打开新标签页并跳转
 * @param {*} data
 * @param {*} VueComponent
 * @param {*} paramKeys // { path: 'medicineDetail', style: { maxWidth: '150px' }, click: Function ,id: 'id', name: 'name' }
 */
export const skipPage = (data, vm, paramKeys) => {
  let { path } = paramKeys
  const { row } = data
  const param = {}
  if (path === "targetDetail") {
    // 做特殊处理
    param["id"] = data["id"]
    if (data["code"]) {
      param["name"] = `${data["code"]}`
      if (data["eName"]) {
        param["name"] += `(${data["eName"]})`
      }
    } else if (!data["name"]) {
      param["name"] = data["eName"]
    } else {
      param["name"] = data["name"]
    }
  } else if (path === "globalDrugPatentsDetail") {
    param["parentFamilyId"] = row["parentFamilyId"]
    param["publicationNumber"] = row["publicationNumber"]
    param["patentsTitle"] = row["patentsTitle"]
  } else {
    const excludeKeys = ["path", "style", "click"]
    Object.keys(paramKeys).forEach((key) => {
      if (!excludeKeys.includes(key)) {
        const val = paramKeys[key] // val 作为 data取值的key
        param[key] = data && data[val] !== undefined ? data[val] : row[val]
      }
    })
  }
  const routeUrl = vm.$router.resolve({
    path,
    query: {
      ...param,
    },
  })
  window.open(routeUrl.href, "_blank")
}

/**
 * 创建表格中hover显示和点击跳转
 * @param {row, rowIndex, $rowIndex, column, columnIndex, $columnIndex, _columnIndex} params
 * @param {CreateElement} h
 * @param {VueComponent} vm
 * @param {String} key // 如从对象中读取值展示，需要的key值
 * @param {Object} routeParam // { path: 'medicineDetail', style: { maxWidth: '150px' }, click: Function , id: 'id', name: 'name', isShowLabel:true }
 * @returns
 */
export const createPopoverByRender = (_params, h, vm, key = "", routeParam) => {
  const {
    row,
    column: { field },
  } = _params
  const globalColumn = ["globalRDIndications", "domesticRDIndications", "abroadRDIndications", "expeditedReviews"]
  const drugPatentsColumn = ["simpleSameClanLegalStates", "simpleSameClanLegalPhases", "simpleSameEarliestExpiryDates"]
  const columnData = row[field]
  const columnType = judgeType(columnData)
  const columnBooleanData = row[field] ? "是" : "否"
  const ifClick = routeParam !== undefined && (routeParam.path || routeParam.click) ? true : false
  const customStyle = { ...(routeParam && routeParam.style ? routeParam.style : {}) }
  const { isShowLabel = false } = routeParam || {}
  const canClickStyle = { color: "#548fc2", cursor: "pointer" }
  const centerStyle = { display: "inline-flex", justifyContent: "center", alignItems: "center" }
  const deleteTagStyle = {
    display: "inline-block",
    fontSize: "12px",
    color: "#59b9ff",
    border: "1px solid #59b9ff",
    padding: "0 2px",
    margin: "0 0 0 3px",
    borderRadius: "10px",
    height: "20px",
    lineHeight: " 18px",
  }
  const addTagStyle = {
    display: "inline-block",
    fontSize: "12px",
    color: "#f5683b",
    border: "1px solid #f5683b",
    padding: "0 2px",
    margin: "0 0 0 3px",
    borderRadius: "10px",
    height: "20px",
    lineHeight: " 18px",
  }

  // 增加圆点标识的字段
  const pointLabel = "● "

  const clickFn = (skipParams) => {
    const callback = routeParam && typeof routeParam.click === "function" ? routeParam.click : ""
    if (callback) {
      return callback(skipParams)
    } else if (ifClick) {
      return skipPage(skipParams, vm, routeParam)
    }
  }
  //判断是否是项目更新日期
  if (field === "projectUpdateDate") {
    return [
      h(
        "div",
        {
          style: {
            ...customStyle,
          },
        },
        columnData
      ),
      h("Popover", {
        scopedSlots: {
          reference: () => {
            return h(
              "span",
              {
                style: { color: "rgb(84, 143, 194)" },
              },
              "更新内容"
            )
          },
          default: () => {
            return h(
              "div",
              {
                style: {
                  color: "#626d85",
                  fontSize: "12px",
                  minWidth: "60px",
                  textAlign: "left",
                  ...(ifClick ? canClickStyle : ""),
                },
              },
              row.projectUpdateContent
            )
          },
        },
      }),
    ]
  }
  // 判断是否是专利信息
  if (drugPatentsColumn.includes(field)) {
    if (!columnData) {
      return
    }
    return columnData.map((form, i) => {
      return h("div", [
        h(
          "span",
          {
            style: {
              display: "inline-block",
              width: "35px",
              textAlign: "right",
            },
          },
          `${form.item1}：`
        ),
        h(
          "span",
          {
            style: {},
          },
          `${form.item2 !== null ? form.item2 : ""}`
        ),
      ])
    })
  }
  //判断是否是新药研发特殊展示
  if (globalColumn.includes(field)) {
    //是否是上市通道
    if (field === "expeditedReviews") {
      return columnData.map((form, i) => {
        return h(
          "div",
          {
            style: {
              whiteSpace: "pre-wrap",
            },
          },
          [
            `${form.country}：`,
            form.reviews.map((review, j) => {
              return h("span", review.name + "；")
            }),
          ]
        )
      })
    }
    // console.log(field, JSON.stringify(columnData), '---------', _params.column, row)
    return columnData.map((form, i) => {
      return h("div", [
        `${form.phase}：`,
        form.indications.map((indication, j) => {
          return h(
            "span",
            {
              style: {
                ...customStyle,
                ...(ifClick ? canClickStyle : ""),
                whiteSpace: "pre-wrap",
              },
              on: {
                click: () => clickFn(indication),
              },
            },
            indication.name + "；"
          )
        }),
      ])
    })
  } else if (columnType === "String" || columnType === "Number") {
    return [
      h(
        "span",
        {
          on: {
            click: () => clickFn({ row, column: _params.column }),
          },
          style: {
            ...customStyle,
            ...(ifClick ? canClickStyle : ""),
          },
        },
        columnData
      ),
    ]
  } else if (columnType === "Object" && !columnData.values) {
    let label = columnData[key] || ""
    if (key === "fileTypeName" && !columnData.url) {
      label = ""
    }
    return [
      h(
        "span",
        {
          on: {
            click: () => clickFn(columnData),
          },
          style: {
            ...customStyle,
            ...(ifClick ? canClickStyle : ""),
          },
        },
        label
      ),
    ]
  } else if (columnType === "Object" && columnData.values) {
    if (columnData.values.length > 1) {
      //需要打tag
      if (columnData.tag !== "" && !columnData.tag && columnData.tag !== null) {
        let _style = columnData.tag.includes("删除") ? deleteTagStyle : addTagStyle
        return [
          h("Popover", {
            scopedSlots: {
              reference: () => {
                return h(
                  "span",
                  {
                    style: { ...centerStyle },
                  },
                  [
                    h(
                      "span",
                      {
                        on: {
                          click: () => clickFn(columnData.values[0]),
                        },
                        style: {
                          display: "inline-block",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          ...customStyle,
                          ...(ifClick ? canClickStyle : ""),
                        },
                        directives: [
                          {
                            name: "adapt-self-width",
                            value: "vxe-cell",
                          },
                        ],
                      },
                      judgeType(columnData.values[0]) === "Object" ? columnData.values[0][key] || "" : columnData.values[0] || ""
                    ),
                    h(
                      "span",
                      {
                        style: {
                          display: "inline-block",
                          fontSize: "13px",
                          color: "#0aba9c",
                        },
                      },
                      `+${columnData.values.length - 1}`
                    ),
                    h(
                      "span",
                      {
                        style: {
                          ..._style,
                        },
                      },
                      `${columnData.tag}`
                    ),
                  ]
                )
              },
              // 默认插槽
              default: () => {
                return h(
                  "div",
                  columnData.values.map((item, index) =>
                    h(
                      "div",
                      {
                        on: {
                          click: () => clickFn(item),
                        },
                        style: {
                          color: "#3c3f45",
                          fontSize: "12px",
                          minWidth: "60px",
                          textAlign: "left",
                          ...(ifClick ? canClickStyle : ""),
                        },
                        key: index,
                      },
                      judgeType(item) === "Object" ? (isShowLabel ? pointLabel : "") + item[key] : (isShowLabel ? pointLabel : "") + item
                    )
                  )
                )
              },
            },
          }),
        ]
      } else {
        return [
          h("Popover", {
            scopedSlots: {
              reference: () => {
                return h(
                  "span",
                  {
                    style: { ...centerStyle },
                  },
                  [
                    h(
                      "span",
                      {
                        on: {
                          click: () => clickFn(columnData.values[0]),
                        },
                        style: {
                          display: "inline-block",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          ...customStyle,
                          ...(ifClick ? canClickStyle : ""),
                        },
                        directives: [
                          {
                            name: "adapt-self-width",
                            value: "vxe-cell",
                          },
                        ],
                      },
                      judgeType(columnData.values[0]) === "Object" ? columnData.values[0][key] || "" : columnData.values[0] || ""
                    ),
                    h(
                      "span",
                      {
                        style: {
                          display: "inline-block",
                          fontSize: "13px",
                          color: "#0aba9c",
                        },
                      },
                      `+${columnData.values.length - 1}`
                    ),
                  ]
                )
              },
              // 默认插槽
              default: () => {
                return h(
                  "div",
                  columnData.values.map((item, index) =>
                    h(
                      "div",
                      {
                        on: {
                          click: () => clickFn(item),
                        },
                        style: {
                          color: "#3c3f45",
                          fontSize: "12px",
                          minWidth: "60px",
                          textAlign: "left",
                          ...(ifClick ? canClickStyle : ""),
                        },
                        key: index,
                      },
                      judgeType(item) === "Object" ? (isShowLabel ? pointLabel : "") + item[key] : (isShowLabel ? pointLabel : "") + item
                    )
                  )
                )
              },
            },
          }),
        ]
      }
    } else if (columnData.values.length === 1) {
      if (columnData.tag !== "" && columnData.tag && columnData.tag !== null) {
        let _style = columnData.tag.includes("删除") ? deleteTagStyle : addTagStyle
        return [
          h(
            "span",
            {
              style: {
                ...customStyle,
                ...(ifClick ? canClickStyle : ""),
              },
              on: {
                click: () => clickFn(columnData.values[0]),
              },
            },
            judgeType(columnData.values[0]) === "Object" ? columnData.values[0][key] || "" : columnData.values[0] || ""
          ),
          h(
            "span",
            {
              style: {
                ..._style,
              },
            },
            `${columnData.tag}`
          ),
        ]
      } else {
        return [
          h(
            "span",
            {
              style: {
                ...customStyle,
                ...(ifClick ? canClickStyle : ""),
              },
              on: {
                click: () => clickFn(columnData.values[0]),
              },
            },
            judgeType(columnData.values[0]) === "Object" ? columnData.values[0][key] || "" : columnData.values[0] || ""
          ),
        ]
      }
    } else {
      return [h("span", "")]
    }
  } else if (columnType === "Array") {
    if (columnData.length > 1) {
      return [
        h("Popover", {
          scopedSlots: {
            reference: () => {
              return h(
                "span",
                {
                  style: { ...centerStyle },
                },
                [
                  h(
                    "span",
                    {
                      on: {
                        click: () => clickFn(columnData[0]),
                      },
                      style: {
                        display: "inline-block",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        ...customStyle,
                        ...(ifClick ? canClickStyle : ""),
                      },
                      directives: [
                        {
                          name: "adapt-self-width",
                          value: "vxe-cell",
                        },
                      ],
                    },
                    judgeType(columnData[0]) === "Object" ? columnData[0][key] || "" : columnData[0] || ""
                  ),
                  h(
                    "span",
                    {
                      style: {
                        display: "inline-block",
                        fontSize: "13px",
                        color: "#0aba9c",
                      },
                    },
                    `+${columnData.length - 1}`
                  ),
                ]
              )
            },
            // 默认插槽
            default: () => {
              return h(
                "div",
                columnData.map((item, index) =>
                  h(
                    "div",
                    {
                      on: {
                        click: () => clickFn(item),
                      },
                      style: {
                        color: "#3c3f45",
                        fontSize: "12px",
                        minWidth: "60px",
                        textAlign: "left",
                        ...(ifClick ? canClickStyle : ""),
                      },
                      key: index,
                    },
                    judgeType(item) === "Object" ? (isShowLabel ? pointLabel : "") + item[key] : (isShowLabel ? pointLabel : "") + item
                  )
                )
              )
            },
          },
        }),
      ]
    } else if (columnData.length === 1) {
      return [
        h(
          "span",
          {
            style: {
              ...customStyle,
              ...(ifClick ? canClickStyle : ""),
            },
            on: {
              click: () => clickFn(columnData[0]),
            },
          },
          judgeType(columnData[0]) === "Object" ? columnData[0][key] || "" : columnData[0] || ""
        ),
      ]
    } else {
      return [h("span", "")]
    }
  } else if (columnType === "Boolean") {
    return [h("span", columnBooleanData)]
  }
}
/** 模拟跳转，解决弹框阻止问题 */
export const open = (url) => {
  if (!url) return
  var iframe = document.createElement("iframe")
  iframe.src = url
  iframe.style.display = "none"
  iframe.onload = function () {
    document.body.removeAttribute(iframe)
  }
  document.body.appendChild(iframe)
}
/** 合并可见列配置项 */
export const mergeObjects = (arr1, arr2) => {
  let _arr1 = _.cloneDeep(arr1)
  let _arr2 = _.cloneDeep(arr2)
  // 创建一个空数组用于存储合并后的结果
  let mergedArray = []
  //遍历找到只在arr2中的数组，并删除该数组
  const arr2Filtered = arr2.filter((obj2) => {
    return _arr1.some((obj1) => obj1.field === obj2.field)
  })
  // arr2.length = 0;
  mergedArray.push(...arr2Filtered) //此时是都存在的数组
  //遍历arr1和mergedArray，将arr1的title更新给mergedArray
  mergedArray.forEach((obj2) => {
    const matchingObj1 = arr1.find((obj1) => obj1.field === obj2.field)
    if (matchingObj1) {
      const { visible, field, ...rest } = matchingObj1
      Object.assign(obj2, rest)
    }
  }) //mergedArray是已经合并的都存在且顺序一致的数据
  //遍历arr1中只存在的数据，放在后面并返回
  const uniqueFieldObjects = _arr1.filter((obj1) => !mergedArray.some((obj2) => obj2.field === obj1.field))
  const updatedArr2 = mergedArray.concat(uniqueFieldObjects)
  //判断如果对象没有title属性，删除该对象
  for (let i = updatedArr2.length - 1; i >= 0; i--) {
    if (!updatedArr2[i].hasOwnProperty("title")) {
      updatedArr2.splice(i, 1)
    }
  }
  return updatedArr2
}
export function flattenArray(arr) {
  let result = []
  function flatten(item) {
    result.push(item)
    if (item.children && item.children.length > 0) {
      item.children.forEach((child) => flatten(child))
    }
  }
  arr.forEach((item) => flatten(item))
  return result
}

export const apiMaps = apiMap
export const getType = (value) => {
  const type = typeof value
  if (type === "object") {
    if (value === null) {
      return "null"
    }
    if (Array.isArray(value)) {
      return "array"
    }
    return "object"
  }
  return type
}
export const getDeepValue = (v, key = "name") => {
  const t = getType(v)
  if (t === "object") {
    return v[key] || Object.values[0]
  }
  if (t === "array") {
    if (getType(v[0]) === "object") {
      return v.map((e) => e[key])
    }
  }
  if (t === "string" || t === "number") {
    return v + ""
  }
  return ""
}
