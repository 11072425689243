export const MUTATION_GLOBAL_HOME = "MUTATION_GLOBAL_HOME"
export const MUTATION_SET_CURR_SYS = "MUTATION_SET_CURR_SYS" // 设置当前系统信息
export const MUTATION_GLOBAL_BREADLIST = "MUTATION_GLOBAL_BREADLIST" //设置面包屑导航
export const MUTATION_GLOBAL_VIEWTYPE = "MUTATION_GLOBAL_VIEWTYPE" //设置主页面类型
export const MUTATTON_GLOBAL_TABBAR = "MUTATTON_GLOBAL_TABBAR" //设置tabbar
export const MUTATTON_GLOBAL_LOADING = "MUTATTON_GLOBAL_LOADING" //设置Loading
export const MUTATION_TARGET_TARGETCHARTDATA = "MUTATION_TARGET_TARGETCHARTDATA" //设置查询后的数据（尚未点击查询按钮储存的数据）
export const MUTATION_EVENT = "MUTATION_EVENT" // 全局事件监听触发
export const MUTATTON_GLOBAL_TIPMODLUS = "MUTATTON_GLOBAL_TIPMODLUS" //错误的统一提示弹框
/**
 * 首页
 */
export const MUTATION_SET_HOME_COREDATA = "MUTATION_SET_HOME_COREDATA" //设置核心数据模块
export const MUTATION_SET_HOME_INDUSTRYNEWS = "MUTATION_SET_HOME_INDUSTRYNEWS" //设置行业动态
export const MUTATION_SET_HOME_RECOMMENDEDWORDS = "MUTATION_SET_HOME_RECOMMENDEDWORDS" //设置推荐词
export const MUTATION_SET_HOME_HOTWORD = "MUTATION_SET_HOME_HOTWORD" //热词

/**
 * 疾病
 * */
export const MUTATION_SET_DISEASE_DISEASEDATATABLE = "MUTATION_SET_DISEASE_DISEASEDATATABLE" //设置疾病数据列表
export const MUTATION_SET_DISEASE_DURGANALYSIS = "MUTATION_SET_DISEASE_DURGANALYSIS" //设置药物分析数据
export const MUTATION_SET_DISEASE_SALESANALYSIS = "MUTATION_SET_DISEASE_SALESANALYSIS" //set销售分析数据
export const MUTATION_SET_DISEASE_DISEASEANALYSIS = "MUTATION_SET_DISEASE_DISEASEANALYSIS" //设置中国疾病分析
export const MUTATION_SET_DISEASE_COUNTRYRDMAPANALYSIS = "MUTATION_SET_DISEASE_COUNTRYRDMAPANALYSIS" //设置研发地图
export const MUTATION_SET_DISEASE_COMPANYANALYSIS = "MUTATION_SET_DISEASE_COMPANYANALYSIS" //设置研发地图
export const MUTATION_SET_DISEASE_TARGETANALYSIS = "MUTATION_SET_DISEASE_TARGETANALYSIS" //靶点分析
export const MUTATION_SET_DISEASE_SALESCOMPANY = "MUTATION_SET_DISEASE_SALESCOMPANY" //靶点分析
export const MUTATION_SET_DISEASE_CHINASALESANDFORECAST = "MUTATION_SET_DISEASE_CHINASALESANDFORECAST" //set中国销售总额分析与预测
export const MUTATION_SET_DISEASE_GLOBALSALESANDFORECAST = "MUTATION_SET_DISEASE_GLOBALSALESANDFORECAST" //set全球销售总额分析与预测
export const MUTATION_SET_DISEASE_POTENTIALMARKET = "MUTATION_SET_POTENTIALMARKET" //set市场潜力分析
export const MUTATION_SET_DISEASE_CHINACOMPANYSALES = "MUTATION_SET_DISEASE_CHINACOMPANYSALES" //set公司实力
export const MUTATION_SET_DISEASE_GLOBALCOMPANYSALES = "MUTATION_SET_DISEASE_GLOBALCOMPANYSALES" //set公司实力
export const MUTATION_GET_DISEASE_RDANALYSIS = "MUTATION_GET_DISEASE_RDANALYSIS" //设置疾病研发获批分析
export const MUTATION_GET_DISEASE_TARGET = "MUTATION_GET_DISEASE_TARGET" //设置靶点列表
export const MUTATION_GET_DISEASE_COMPANY = "MUTATION_GET_DISEASE_COMPANY" //设置公司列表
export const MUTATION_GET_DISEASE_COUNTRY = "MUTATION_GET_DISEASE_COUNTRY" //设置国家列表
export const MUTATION_SET_DISEASE_REPLACERISKANALYSIS = "MUTATION_SET_DISEASE_REPLACERISKANALYSIS" //set代替风险
export const MUTATION_SET_DISEASE_DEPARTMENTANALYSIS = "MUTATION_SET_DISEASE_DEPARTMENTANALYSIS" //set科室分析
export const MUTATION_SET_DISEASE_COMMONNAME = "MUTATION_SET_DISEASE_COMMONNAME" //set科室分析
export const MUTATION_SET_DISEASE_DRUGFORMJOINTCOMMONNAME = "MUTATION_SET_DISEASE_DRUGFORMJOINTCOMMONNAME" //set产品名称
export const MUTATION_SET_DISEASE_DRUGCOMPARISONANALYSIS = "MUTATION_SET_DISEASE_DRUGCOMPARISONANALYSIS" //set药物对比分析
/**
 * 靶点
 */
export const MUTATION_SET_TARGET_SCREENCONDITION = "MUTATION_SET_TARGET_SCREENCONDITION" //靶点左侧筛选条件
export const MUTATION_SET_TARGET_TARGETDATATABLE = "MUTATION_SET_TARGET_TARGETDATATABLE" //靶点数据表列表
export const MUTATION_SET_TABLIST = "MUTATION_SET_TABLIST" //设置tablist
export const MUTATION_SET_TARGET_CHANGESCREENCONDITION = "MUTATION_SET_TARGET_CHANGESCREENCONDITION" //靶点左侧筛选条件改变时触发
export const MUTATION_SET_TARGET_FILTERSORTCONDITION = "MUTATION_SET_TARGET_FILTERSORTCONDITION" //页面筛选的数据
export const MUTATION_TARGET_DATASHEETDATA = "MUTATION_TARGET_DATASHEETDATA" //设置靶点数据表数据
export const MUTATION_SET_TARGET_DURGANALYSIS = "MUTATION_SET_TARGET_DURGANALYSIS" //设置药物分析数据
export const MUTATION_SET_TARGET_SALESANALYSIS = "MUTATION_SET_TARGET_SALESANALYSIS" //set销售分析数据
export const MUTATION_SET_TARGET_DISEASEANALYSIS = "MUTATION_SET_TARGET_DISEASEANALYSIS" //设置中国疾病分析
export const MUTATION_SET_TARGET_COUNTRYRDMAPANALYSIS = "MUTATION_SET_TARGET_COUNTRYRDMAPANALYSIS" //设置研发地图
export const MUTATION_SET_TARGET_COMPANYANALYSIS = "MUTATION_SET_TARGET_COMPANYANALYSIS" //设置研发地图
export const MUTATION_SET_TARGET_SALESCOMPANY = "MUTATION_SET_TARGET_SALESCOMPANY" //销售分析按通用名设置Top10公司
export const MUTATION_SET_TARGET_DISEASEATLAS = "MUTATION_SET_TARGET_DISEASEATLAS" //疾病分析
export const MUTATION_SET_TARGET_RDANALYSIS = "MUTATION_SET_TARGET_RDANALYSIS" //疾病分析
export const MUTATION_SET_TARGET_CHINASALESANDFORECAST = "MUTATION_SET_TARGET_CHINASALESANDFORECAST" //set中国销售总额分析与预测
export const MUTATION_SET_TARGET_GLOBALSALESANDFORECAST = "MUTATION_SET_TARGET_GLOBALSALESANDFORECAST" //set全球销售总额分析与预测
export const MUTATION_SET_TARGET_POTENTIALMARKET = "MUTATION_SET_POTENTIALMARKET" //set市场潜力分析
export const MUTATION_SET_TARGET_CHINACOMPANYSALES = "MUTATION_SET_TARGET_CHINACOMPANYSALES" //set公司实力
export const MUTATION_SET_TARGET_GLOBALCOMPANYSALES = "MUTATION_SET_TARGET_GLOBALCOMPANYSALES" //set公司实力
export const MUTATION_SET_TARGET_CLINICAL = "MUTATION_SET_CLINICAL" //set临床信息
export const MUTATION_SET_TARGET_DRUGFORMSALES = "MUTATION_SET_DRUGFORMSALES" //set剂型实力
export const MUTATION_SET_TARGET_REPLACERISKANALYSIS = "MUTATION_SET_TARGET_REPLACERISKANALYSIS" //set代替风险
export const MUTATION_SET_TARGET_DEPARTMENTANALYSIS = "MUTATION_SET_TARGET_DEPARTMENTANALYSIS" //set科室分析
export const MUTATION_SET_TARGET_DISEASE = "MUTATION_SET_TARGET_DISEASE" //设置疾病列表
export const MUTATION_SET_TARGET_DRUGTYPE = "MUTATION_SET_TARGET_DRUGTYPE" //设置疾病列表
export const MUTATION_SET_TARGET_DRUGFORMJOINTCOMMONNAME = "MUTATION_SET_TARGET_DRUGFORMJOINTCOMMONNAME" //获通用名设置药品剂型（产品名称）
export const MUTATION_SET_TARGET_COMMONNAME = "MUTATION_SET_TARGET_COMMONNAME" //获通用名设置药品剂型（产品名称）
export const MUTATION_SET_TARGET_COUNTRY = "MUTATION_SET_TARGET_COUNTRY" //获取国家列表
export const MUTATION_SET_TARGET_COMPANY = "MUTATION_SET_TARGET_COMPANY" //获取公司列表
export const MUTATION_SET_TARGET_DRUGCOMPARISONANALYSIS = "MUTATION_SET_TARGET_DRUGCOMPARISONANALYSIS" //获取药物对比分析

/**
 * 公司
 *
 * */
export const MUTATION_SET_ENTERPRISE_DRUGRDANALYSIS = "MUTATION_SET_ENTERPRISE_DRUGRDANALYSIS" //set公司数据表

export const MUTATION_SET_ENTERPRISE_DISEASE = "MUTATION_SET_ENTERPRISE_DISEASE" //set疾病列表
export const MUTATION_SET_ENTERPRISE_PHASE = "MUTATION_SET_ENTERPRISE_PHASE" //set阶段列表
export const MUTATION_SET_ENTERPRISE_TARGET = "MUTATION_SET_ENTERPRISE_TARGET" //set靶点列表
export const MUTATION_SET_ENTERPRISE_COMPANY = "MUTATION_SET_ENTERPRISE_COMPANY" //set公司列表
export const MUTATION_SET_ENTERPRISE_COUNTRY = "MUTATION_SET_ENTERPRISE_COUNTRY" //set国家列表
export const MUTATION_SET_ENTERPRISE_COMMONNAME = "MUTATION_SET_ENTERPRISE_COMMONNAME" //set药物列表

export const MUTATION_SET_ENTERPRISE_DURGANALYSIS = "MUTATION_SET_ENTERPRISE_DURGANALYSIS" //设置药物分析数据

export const MUTATION_SET_ENTERPRISE_DOMESTICSALESANALYSIS = "MUTATION_SET_ENTERPRISE_DOMESTICSALESANALYSIS" //设置中国药物销售分析
export const MUTATION_SET_ENTERPRISE_GLOBALSALESANALYSIS = "MUTATION_SET_ENTERPRISE_GLOBALSALESANALYSIS" //设置全球药物销售分析

export const MUTATION_SET_ENTERPRISE_DOMESTICDISEASEANALYSIS = "MUTATION_SET_ENTERPRISE_DOMESTICDISEASEANALYSIS" //设置中国疾病分析
export const MUTATION_SET_ENTERPRISE_GLOBALDISEASEANALYSIS = "MUTATION_SET_ENTERPRISE_GLOBALDISEASEANALYSIS" //设置全球疾病分析

export const MUTATION_SET_ENTERPRISE_DOMESTICTARGETANALYSIS = "MUTATION_SET_ENTERPRISE_DOMESTICTARGETANALYSIS" //设置中国靶点分析
export const MUTATION_SET_ENTERPRISE_GLOBALTARGETANALYSIS = "MUTATION_SET_ENTERPRISE_GLOBALTARGETANALYSIS" //设置全球靶点分析

export const MUTATION_SET_ENTERPRISE_COUNTRYRDMAPANALYSIS = "MUTATION_SET_ENTERPRISE_COUNTRYRDMAPANALYSIS" //设置研发地图

export const MUTATION_SET_ENTERPRISE_ENTERPRISEPRODUCTSTRUCTURE = "MUTATION_SET_ENTERPRISE_ENTERPRISEPRODUCTSTRUCTURE" //设置公司产品结构
export const MUTATION_SET_ENTERPRISE_ENTERPRISEAREASTRUCTURE = "MUTATION_SET_ENTERPRISE_ENTERPRISEAREASTRUCTURE" //设置公司领域分布结构
export const MUTATION_SET_ENTERPRISE_GLOBALSTICSALESANALYSIS = "MUTATION_SET_ENTERPRISE_GLOBALSTICSALESANALYSIS" //设置公司全球药物销售分析
export const MUTATION_SET_ENTERPRISE_COMPANYSALESPERCENTAGE = "MUTATION_SET_ENTERPRISE_COMPANYSALESPERCENTAGE" //设置公司销售占比分析
export const MUTATION_SET_ENTERPRISE_COMPANYYIELD = "MUTATION_SET_ENTERPRISE_COMPANYYIELD" //设置公司产量分析
export const MUTATION_SET_ENTERPRISE_COMPANYSTOCK = "MUTATION_SET_ENTERPRISE_COMPANYSTOCK" //设置公司股票月线
export const MUTATION_SET_ENTERPRISE_COMPANYEVENT = "MUTATION_SET_ENTERPRISE_COMPANYEVENT" //设置公司大事件
export const MUTATION_SET_ENTERPRISE_CHINACOMPANYSALES = "MUTATION_SET_ENTERPRISE_CHINACOMPANYSALES" //设置公司实力
export const MUTATION_SET_ENTERPRISE_GLOBALCOMPANYSALES = "MUTATION_SET_ENTERPRISE_GLOBALCOMPANYSALES" //设置公司实力

export const MUTATION_SET_ENTERPRISE_ENTERPRISEDETAIL = "MUTATION_SET_ENTERPRISE_ENTERPRISEDETAIL" //设置公司详情
export const MUTATION_SET_ENTERPRISE_ENTERPRISERELATION = "MUTATION_SET_ENTERPRISE_ENTERPRISERELATION" //设置公司关联
export const MUTATION_SET_ENTERPRISE_ACTIONS_GET_ENTERPRISE_ENTERPRISEANNUALREPORTTAG =
  "MUTATION_SET_ENTERPRISE_ACTIONS_GET_ENTERPRISE_ENTERPRISEANNUALREPORTTAG" //设置公司年报
/**
 * 药物
 */
export const MUTATION_SET_COMMONNAMEID = "MUTATION_SET_COMMONNAMEID" //set药物ID
export const MUTATION_SET_DURGANALYSIS = "MUTATION_SET_DURGANALYSIS" //set药物分析数据
export const MUTATION_SET_ENTERPRISEANALYSIS = "MUTATION_SET_ENTERPRISEANALYSIS" //set公司分析数据
export const MUTATION_SET_DOMESTICSALESANALYSIS = "MUTATION_SET_DOMESTICSALESANALYSIS" //set中国药物销售分析数据
export const MUTATION_SET_GLOBALSALESANALYSIS = "MUTATION_SET_GLOBALSALESANALYSIS" //set全球药物销售分析数据
export const MUTATION_SET_ENTERPRISESALESANALYSIS = "MUTATION_SET_ENTERPRISESALESANALYSIS" //set药品销售分析使用通用名查企业
export const MUTATION_SET_DOMESTICDISEASEANALYSIS = "MUTATION_SET_DOMESTICDISEASEANALYSIS" //set全球疾病阶段分析
export const MUTATION_SET_GLOBALDISEASEANALYSIS = "MUTATION_SET_GLOBALDISEASEANALYSIS" //set全球疾病阶段分析
export const MUTATION_SET_DOMESTICTARGETANALYSIS = "MUTATION_SET_DOMESTICTARGETANALYSIS" //set全球药物靶点阶段分析
export const MUTATION_SET_GLOBALTARGETANALYSIS = "MUTATION_SET_GLOBALTARGETANALYSIS" //set全球药物靶点阶段分析
export const MUTATION_SET_COUNTRYRDMAPANALYSIS = "MUTATION_SET_COUNTRYRDMAPANALYSIS" //set全球药物全国研究分析
export const MUTATION_SET_DRUGRDANALYSIS = "MUTATION_SET_DRUGRDANALYSIS" //set药品研发分析
export const MUTATION_SET_DRUGDETAIL = "MUTATION_SET_DRUGDETAIL" //set药品详情
export const MUTATION_SET_RDANALYSIS = "MUTATION_SET_RDANALYSIS" //set研发获批分析
export const MUTATION_SET_DRUG_CHINASALESANDFORECAST = "MUTATION_SET_DRUG_CHINASALESANDFORECAST" //set销售总额分析与预测
export const MUTATION_SET_DRUG_GLOBALSALESANDFORECAST = "MUTATION_SET_DRUG_GLOBALSALESANDFORECAST" //set销售总额分析与预测
export const MUTATION_SET_POTENTIALMARKET = "MUTATION_SET_POTENTIALMARKET" //set市场潜力分析
export const MUTATION_SET_DRUG_CHINACOMPANYSALES = "MUTATION_SET_DRUG_CHINACOMPANYSALES" //set公司实力
export const MUTATION_SET_DRUG_GLOBALCOMPANYSALES = "MUTATION_SET_DRUG_GLOBALCOMPANYSALES" //set公司实力
export const MUTATION_SET_CLINICAL = "MUTATION_SET_CLINICAL" //set临床信息
export const MUTATION_SET_DRUGFORMSALES = "MUTATION_SET_DRUGFORMSALES" //set剂型实力
export const MUTATION_SET_REPLACERISKANALYSIS = "MUTATION_SET_REPLACERISKANALYSIS" //set代替风险
export const MUTATION_SET_DEPARTMENTANALYSIS = "MUTATION_SET_DEPARTMENTANALYSIS" //set科室分析
export const MUTATION_SET_PATIENTFLOW = "MUTATION_SET_PATIENTFLOW" //set患者流
export const MUTATION_SET_ADVERSEREMUTATION = "MUTATION_SET_ADVERSEREMUTATION" //set不良反应报告
export const MUTATION_SET_DISEASE = "MUTATION_SET_DISEASE" //set疾病列表
export const MUTATION_SET_PHASE = "MUTATION_SET_PHASE" //set阶段列表
export const MUTATION_SET_TARGET = "MUTATION_SET_TARGET" //set靶点列表
export const MUTATION_SET_COMPANY = "MUTATION_SET_COMPANY" //set公司列表
export const MUTATION_SET_COUNTRY = "MUTATION_SET_COUNTRY" //set国家列表
export const MUTATION_SET_DRUGFORMJOINTCOMMONNAME = "MUTATION_SET_DRUGFORMJOINTCOMMONNAME" //set获通用名设置药品剂型（产品名称）
export const MUTATION_SET_DRUGTYPE = "MUTATION_SET_DRUGTYPE" //设置药品分类
export const MUTATION_SET_BIOLOGICALDRUGCLASSIFICATION = "MUTATION_SET_BIOLOGICALDRUGCLASSIFICATION" //设置开发药物技术分类
export const MUTATION_SET_TREATMENTTYPE = "MUTATION_SET_TREATMENTTYPE" //设置阶段列表

// 申报
export const MUTATION_SET_URGENTREASON = "MUTATION_SET_URGENTREASON" //临床急需品种理由
export const MUTATION_SET_ASSESSREASON = "MUTATION_SET_ASSESSREASON" //获取优先审评理由
export const MUTATION_SET_GETPROVINCE = "MUTATION_SET_GETPROVINCE" //获取申请省份数据
export const MUTATION_SET_APPROVALCONCLUSION = "MUTATION_SET_APPROVALCONCLUSION" //获取审批结论数据
export const MUTATION_SET_ADMINISTRATIONROUTE = "MUTATION_SET_ADMINISTRATIONROUTE" //获取给药途径数据
export const MUTATION_SET_DOSAGEDATA = "MUTATION_SET_DOSAGEDATA" //获取剂型数据
export const MUTATION_SET_REGISTERCLASSIFICATION = " MUTATION_SET_REGISTERCLASSIFICATION" //获取注册分类二级联动数据
export const MUTATION_SET_TECHNOLOGYLABEL = "MUTATION_SET_TECHNOLOGYLABEL" //获取热门热门标签数据
export const MUTATION_SET_THERAPEUTICFIRLDPHIIC = "MUTATION_SET_THERAPEUTICFIRLDPHIIC" //治疗领域PHIIC二级联动数据接口
export const MUTATION_SET_THERAPEUTICFIRLDATC = "MUTATION_SET_THERAPEUTICFIRLDATC" //治疗领域ATC多级联动数据接口
export const MUTATION_SET_DECLARE_COLUMNSDATA = "MUTATION_SET_DECLARE_COLUMNSDATA" //数据表数据
export const MUTATION_SET_DECLARE_COLUMNSDATADOWNLOAD = "MUTATION_SET_DECLARE_COLUMNSDATADOWNLOAD" //下载数据表
export const MUTATTON_DECLARE_TABLECOLUMN = "MUTATTON_DECLARE_TABLECOLUMN" //设置当前可见列
export const MUTATION_SET_DECLAREDETAIL = "MUTATION_SET_DECLAREDETAIL" //详情信息
export const MUTATION_SET_TIMELINE = "MUTATION_SET_TIMELINE" //时光轴信息
export const MUTATION_SET_DECLARE_RESEARCHDEVELOPMENTSAMEVARIETY = "MUTATION_SET_DECLARE_RESEARCHDEVELOPMENTSAMEVARIETY" //受理号申报信息的表格数据

// 上市
export const MUTATION_PRODUCT_GETCOMPANYDATA = "MUTATION_PRODUCT_GETCOMPANYDATA" //公司/持证商多选列表
export const MUTATION_PRODUCT_GETDRUGTYPE = "MUTATION_PRODUCT_GETDRUGTYPE" //药品类型
export const MUTATION_PRODUCT_ADMINISTRATIONROUTE = "MUTATION_PRODUCT_ADMINISTRATIONROUTE" //给药途径
export const MUTATION_PRODUCT_DOSAGEDATA = "MUTATION_PRODUCT_DOSAGEDATA" //剂型
export const MUTATION_PRODUCT_MEDICALINSURANCE = "MUTATION_PRODUCT_MEDICALINSURANCE" //医保目录
export const MUTATION_PRODUCT_SIMPLIFYCONSISTENCYEVALUATION = "MUTATION_PRODUCT_SIMPLIFYCONSISTENCYEVALUATION" //豁免或简化一致性评价品种
export const MUTATION_PRODUCT_PURCHASEVARIETIES = "MUTATION_PRODUCT_PURCHASEVARIETIES" //带量采购品种
export const MUTATION_SET_PRODUCT_CHINASCREENCONDITION = "MUTATION_SET_PRODUCT_CHINASCREENCONDITION"
export const MUTATION_SET_PRODUCT_CHINACHANGESCREENCONDITION = "MUTATION_SET_PRODUCT_CHINACHANGESCREENCONDITION"
export const MUTATION_SET_PRODUCT_CHINAFILTERSORTCONDITION = "MUTATION_SET_PRODUCT_CHINAFILTERSORTCONDITION"
export const MUTATION_PRODUCT_SHOWCOUNTOPENDATA = "MUTATION_PRODUCT_SHOWCOUNTOPENDATA"
export const MUTATION_PRODUCT_TABANDSUBTAB = "MUTATION_PRODUCT_TABANDSUBTAB" // MUTATION_MARKET_ACTIVETAB

//新版上市
export const MUTATION_MARKET_GETTABLELIST = "MUTATION_MARKET_GETTABLELIST" //表格数据
export const MUTATION_MARKET_DOWNLOADTABLEDATA = "MUTATION_MARKET_DOWNLOADTABLEDATA" // 下载表格数据
export const MUTATION_MARKET_CHECKDOWNLOADFILE = "MUTATION_MARKET_CHECKDOWNLOADFILE" // 文件下载判断数据是否为空
export const MUTATION_MARKET_RECOMMENDEDWORDS = "MUTATION_MARKET_RECOMMENDEDWORDS" //检索条件枚举字典
export const MUTATION_MARKET_GETOPTIONSLIST = "MUTATION_MARKET_GETOPTIONSLIST" // 查询下拉框
export const MUTATION_MARKET_ACTIVETAB = "MUTATION_MARKET_ACTIVETAB" // 查询下拉框
export const MUTATION_MARKET_FILTERPARAMS = "MUTATION_MARKET_FILTERPARAMS" // 激活tab页参数
export const MUTATION_MARKET_SHOWCOUNTOPENDATA = "MUTATION_MARKET_SHOWCOUNTOPENDATA" // 点击数字弹框列表
export const MUTATION_PRODUCT_ECHOPARAMS = "MUTATION_PRODUCT_ECHOPARAMS" // 存储回显筛选条件
export const MUTATION_MARKET_GETTOTALNUMBER = "MUTATION_MARKET_GETTOTALNUMBER" // 查询国别TAB上数量
export const MUTATION_MARKET_GETBELISTTYPE = "MUTATION_MARKET_GETBELISTTYPE" // 查询豁免或简化一致性评价品种
// 临床
export const MUTATION_CLINICAL_ACTIVETAB = "MUTATION_CLINICAL_ACTIVETAB" // 激活tab页参数
export const MUTATION_CLINICAL_FILTERPARAMS = "MUTATION_CLINICAL_FILTERPARAMS" // 存储左侧筛选条件
export const MUTATION_CLINICAL_ECHOPARAMS = "MUTATION_CLINICAL_ECHOPARAMS" // 存储回显筛选条件
// 医保目录
export const MUTATION_MEDICARECATALOG_FILTERPARAMS = "MUTATION_MEDICARECATALOG_FILTERPARAMS" // 存储左侧筛选条件
export const MUTATION_MEDICARECATALOG_ECHOPARAMS = "MUTATION_MEDICARECATALOG_ECHOPARAMS" // 存储回显筛选条件
export const MUTATION_MEDICARECATALOG_GETTABLEDATA = "MUTATION_MEDICARECATALOG_GETTABLEDATA" // 查询表格数据
export const MUTATION_MEDICARECATALOG_DOWNLOADTABLEDATA = "MUTATION_MEDICARECATALOG_DOWNLOADTABLEDATA" // 下载表格数据
export const MUTATION_MEDICARECATALOG_CHECKDOWNLOADFILE = "MUTATION_MEDICARECATALOG_CHECKDOWNLOADFILE" // 文件下载判断数据是否为空
export const MUTATION_MEDICARECATALOG_DOWNLOADFILE = "MUTATION_MEDICARECATALOG_DOWNLOADFILE" // 文件下载
export const MUTATION_MEDICARECATALOG_RECOMMENDEDWORDS = "MUTATION_MEDICARECATALOG_RECOMMENDEDWORDS" //检索条件枚举字典
export const MUTATION_MEDICARECATALOG_GETOPTIONSLIST = "MUTATION_MEDICARECATALOG_GETOPTIONSLIST" // 查询下拉框
export const MUTATION_MEDICARECATALOG_SHOWCOUNTOPENDATA = "MUTATION_MEDICARECATALOG_SHOWCOUNTOPENDATA" // 点击数字弹框列表
export const MUTATION_MEDICARECATALOG_TIMEAXIS = "MUTATION_MEDICARECATALOG_TIMEAXIS" // 详情页- 时光轴
export const MUTATION_MEDICARECATALOG_MEDICALINSURANCELEVEL = "MUTATION_MEDICARECATALOG_MEDICALINSURANCELEVEL" // 获取医保版本
// 全球新药
export const MUTATION_GLOBALNEWDRUG_FILTERPARAMS = "MUTATION_GLOBALNEWDRUG_FILTERPARAMS" // 存储左侧筛选条件
export const MUTATION_GLOBALNEWDRUG_ECHOPARAMS = "MUTATION_GLOBALNEWDRUG_ECHOPARAMS" // 存储回显筛选条件
export const MUTATION_GLOBALNEWDRUG_GETTABLEDATA = "MUTATION_GLOBALNEWDRUG_GETTABLEDATA" // 查询表格数据
export const MUTATION_GLOBALNEWDRUG_DOWNLOADTABLEDATA = "MUTATION_GLOBALNEWDRUG_DOWNLOADTABLEDATA" // 下载表格数据
export const MUTATION_GLOBALNEWDRUG_CHECKDOWNLOADFILE = "MUTATION_GLOBALNEWDRUG_CHECKDOWNLOADFILE" // 文件下载判断数据是否为空
export const MUTATION_GLOBALNEWDRUG_DOWNLOADFILE = "MUTATION_GLOBALNEWDRUG_DOWNLOADFILE" // 文件下载
export const MUTATION_GLOBALNEWDRUG_RECOMMENDEDWORDS = "MUTATION_GLOBALNEWDRUG_RECOMMENDEDWORDS" //检索条件枚举字典
export const MUTATION_GLOBALNEWDRUG_GETOPTIONSLIST = "MUTATION_GLOBALNEWDRUG_GETOPTIONSLIST" // 查询下拉框
export const MUTATION_GLOBALNEWDRUG_SHOWCOUNTOPENDATA = "MUTATION_GLOBALNEWDRUG_SHOWCOUNTOPENDATA" // 点击数字弹框列表
export const MUTATION_GLOBALNEWDRUG_TIMEAXIS = "MUTATION_GLOBALNEWDRUG_TIMEAXIS" // 详情页- 时光轴
export const MUTATION_GLOBALNEWDRUG_MEDICALINSURANCELEVEL = "MUTATION_GLOBALNEWDRUG_MEDICALINSURANCELEVEL" // 获取医保版本
// 基药目录
export const MUTATION_ESSENTIALDRUG_FILTERPARAMS = "MUTATION_ESSENTIALDRUG_FILTERPARAMS" // 存储左侧筛选条件
export const MUTATION_ESSENTIALDRUG_ECHOPARAMS = "MUTATION_ESSENTIALDRUG_ECHOPARAMS" // 存储回显筛选条件
export const MUTATION_ESSENTIALDRUG_GETTABLEDATA = "MUTATION_ESSENTIALDRUG_GETTABLEDATA" // 查询表格数据
export const MUTATION_ESSENTIALDRUG_DOWNLOADTABLEDATA = "MUTATION_ESSENTIALDRUG_DOWNLOADTABLEDATA" // 下载表格数据
export const MUTATION_ESSENTIALDRUG_CHECKDOWNLOADFILE = "MUTATION_ESSENTIALDRUG_CHECKDOWNLOADFILE" // 文件下载判断数据是否为空
export const MUTATION_ESSENTIALDRUG_DOWNLOADFILE = "MUTATION_ESSENTIALDRUG_DOWNLOADFILE" // 文件下载
export const MUTATION_ESSENTIALDRUG_RECOMMENDEDWORDS = "MUTATION_ESSENTIALDRUG_RECOMMENDEDWORDS" //检索条件枚举字典
export const MUTATION_ESSENTIALDRUG_GETOPTIONSLIST = "MUTATION_ESSENTIALDRUG_GETOPTIONSLIST" // 查询下拉框
export const MUTATION_ESSENTIALDRUG_SHOWCOUNTOPENDATA = "MUTATION_ESSENTIALDRUG_SHOWCOUNTOPENDATA" // 点击数字弹框列表
export const MUTATION_ESSENTIALDRUG_TIMEAXIS = "MUTATION_ESSENTIALDRUG_TIMEAXIS" // 详情页- 时光轴
export const MUTATION_ESSENTIALDRUG_MEDICALINSURANCELEVEL = "MUTATION_ESSENTIALDRUG_MEDICALINSURANCELEVEL" // 获取医保版本
// 原辅包
export const MUTATION_MATERIAL_FILTERPARAMS = "MUTATION_MATERIAL_FILTERPARAMS" // 存储左侧筛选条件
export const MUTATION_MATERIAL_ECHOPARAMS = "MUTATION_MATERIAL_ECHOPARAMS" // 存储回显筛选条件
export const MUTATION_MATERIAL_OPTIONS = "MUTATION_MATERIAL_OPTIONS" // 存储回显筛选条件
// 参比制剂目录
export const MUTATION_REFERLISTEDDRUG_FILTERPARAMS = "MUTATION_REFERLISTEDDRUG_FILTERPARAMS" // 存储左侧筛选条件
export const MUTATION_REFERLISTEDDRUG_ECHOPARAMS = "MUTATION_REFERLISTEDDRUG_ECHOPARAMS" // 存储回显筛选条件
// 一致性评价
export const MUTATION_EQUIVALENCE_ACTIVETAB = "MUTATION_EQUIVALENCE_ACTIVETAB" // 激活tab页参数
export const MUTATION_EQUIVALENCE_FILTERPARAMS = "MUTATION_EQUIVALENCE_FILTERPARAMS" // 存储左侧筛选条件
export const MUTATION_EQUIVALENCE_ECHOPARAMS = "MUTATION_EQUIVALENCE_ECHOPARAMS" // 存储回显筛选条件
//招标
export const MUTATION_DRUGBIDDING_ACTIVETAB = "MUTATION_DRUGBIDDING_ACTIVETAB" // 激活tab页参数
export const MUTATION_DRUGBIDDING_FILTERPARAMS = "MUTATION_DRUGBIDDING_FILTERPARAMS" // 存储左侧筛选条件
export const MUTATION_DRUGBIDDING_ECHOPARAMS = "MUTATION_DRUGBIDDING_ECHOPARAMS" // 存储回显筛选条件
//美国NDC
export const MUTATION_AMERICANDC_FILTERPARAMS = "MUTATION_AMERICANDC_FILTERPARAMS" // 存储左侧筛选条件
export const MUTATION_AMERICANDC_ECHOPARAMS = "MUTATION_AMERICANDC_ECHOPARAMS" // 存储回显筛选条件
//企业年报
export const MUTATION_ANNUALREPORTS_FILTERPARAMS = "MUTATION_ANNUALREPORTS_FILTERPARAMS" // 存储左侧筛选条件
export const MUTATION_ANNUALREPORTS_ECHOPARAMS = "MUTATION_ANNUALREPORTS_ECHOPARAMS" // 存储回显筛选条件
//临床路径
export const MUTATION_CLINICALPATHWAY_FILTERPARAMS = "MUTATION_CLINICALPATHWAY_FILTERPARAMS" // 存储左侧筛选条件
export const MUTATION_CLINICALPATHWAY_ECHOPARAMS = "MUTATION_CLINICALPATHWAY_ECHOPARAMS" // 存储回显筛选条件
//临床指南
export const MUTATION_TREATMENTGUIDE_FILTERPARAMS = "MUTATION_TREATMENTGUIDE_FILTERPARAMS" // 存储左侧筛选条件
export const MUTATION_TREATMENTGUIDE_ECHOPARAMS = "MUTATION_TREATMENTGUIDE_ECHOPARAMS" // 存储回显筛选条件

//招标分析
export const MUTATION_DRUGBIDDINGANALYSIS_ACTIVETAB = "MUTATION_DRUGBIDDINGANALYSIS_ACTIVETAB" // 激活tab页参数
export const MUTATION_DRUGBIDDINGANALYSIS_FILTERPARAMS = "MUTATION_DRUGBIDDINGANALYSIS_FILTERPARAMS" // 存储左侧筛选条件
export const MUTATION_DRUGBIDDINGANALYSIS_ECHOPARAMS = "MUTATION_DRUGBIDDINGANALYSIS_ECHOPARAMS" // 存储回显筛选条件
//专利
export const MUTATION_GLOBALDRUGPATENTS_FILTERPARAMS = "MUTATION_GLOBALDRUGPATENTS_FILTERPARAMS" // 存储左侧筛选条件
export const MUTATION_GLOBALDRUGPATENTS_ECHOPARAMS = "MUTATION_GLOBALDRUGPATENTS_ECHOPARAMS" // 存储回显筛选条件
// 申报复用
export const MUTATION_REGISTRATION_ACTIVETAB = "MUTATION_REGISTRATION_ACTIVETAB" // 激活tab页参数
export const MUTATION_REGISTRATION_FILTERPARAMS = "MUTATION_REGISTRATION_FILTERPARAMS" // 存储左侧筛选条件
export const MUTATION_REGISTRATION_ECHOPARAMS = "MUTATION_REGISTRATION_ECHOPARAMS" // 存储回显筛选条件
//个人中心/登录注册
export const MUTATION_SET_AUTH_USERPROVINCE = "MUTATION_SET_AUTH_USERPROVINCE" //获取省份
export const MUTATION_SET_AUTH_USERINFO = "MUTATION_SET_AUTH_USERINFO" //获取用户信息

//权限列表
export const MUTATION_SET_MENU_LIST = "MUTATION_SET_MENU_LIST" //用户的权限列表
export const MUTATION_SET_AUTHSHOW = "MUTATION_SET_AUTHSHOW" //设置无权限弹窗
export const MUTATION_SET_DEVELOPSHOW = "MUTATION_SET_DEVELOPSHOW" //设置正在开发弹窗
export const MUTATION_SET_APPLYSHOW = "MUTATION_SET_APPLYSHOW" //申请试用弹窗

export const MUTATION_SET_CALLADDRESS = "MUTATION_CALLADDRESS" //储存访问的地址
//新兴靶点研发热度
export const MUTATION_SET_RISINGTARGET = "MUTATION_SET_RISINGTARGET" //设置新兴靶点研发热度
//药品获批领域地图
export const MUTATION_SET_APPROVEDAREASMAP = "MUTATION_SET_APPROVEDAREASMAP" //药品获批领域地图
//挖掘仿药老品种
export const MUTATION_SET_EXPLOREIMITATEDRUG = "MUTATION_SET_EXPLOREIMITATEDRUG"
export const MUTATION_SET_EXPLOREIMITATEDRUGDATATABLE = "MUTATION_SET_EXPLOREIMITATEDRUGDATATABLE" //挖掘仿药老品种
export const MUTATION_SET_DRUGFORMTREE = "MUTATION_SET_DRUGFORMTREE" //剂型
export const MUTATION_SET_COMMONNAME = "MUTATION_SET_COMMONNAME" //药品通用名
export const MUTATION_SET_GETCONTRASTPRODUCTGROUPLIST = "MUTATION_SET_GETCONTRASTPRODUCTGROUPLIST" //剂型
export const MUTATION_SET_GETCONTRASTPRODUCT = "MUTATION_SET_GETCONTRASTPRODUCT" //产品详情
export const MUTATION_SET_PRODUCTCONTRASTANALYSISDATATABLE = "MUTATION_SET_PRODUCTCONTRASTANALYSISDATATABLE" //产品基本信息
export const MUTATION_SET_COLLECTIONANALYSISDATATABLE = "MUTATION_SET_COLLECTIONANALYSISDATATABLE" //集采放量拉动分析的表格
export const MUTATION_SET_MEDICALINSURANCEANALYSIS = "MUTATION_SET_MEDICALINSURANCEANALYSIS" //集采放量拉动分析的表格
export const MUTATION_SET_COMPARISONTITLE = "MUTATION_SET_COMPARISONTITLE" //集采放量拉动分析的表格
export const MUTATION_SET_REGISTEREDTYPE = "MUTATION_SET_REGISTEREDTYPE" //药品注册类别
//ONE
export const MUTATION_SET_ONE_COMPANY = "MUTATION_SET_ONE_COMPANY" //获取公司列表
export const MUTATION_SET_ONE_COMPANYSTOCK = "MUTATION_SET_ONE_COMPANYSTOCK" //获取公司股票月线
export const MUTATION_SET_ONE_COMPANYEVENT = "MUTATION_SET_ONE_COMPANYEVENT" //获取公司大事件
export const MUTATION_SET_ONE_COMPANYDATA = "MUTATION_SET_ONE_COMPANYDATA" //获取公司信息
export const MUTATION_SET_ONE_MEDICALINSURANCEANALYSISYEAR = "MUTATION_SET_ONE_MEDICALINSURANCEANALYSISYEAR" //医保年份
export const MUTATION_SET_ONE_COLLECTIONANALYSISYEAR = "MUTATION_SET_ONE_COLLECTIONANALYSISYEAR" //集采的年份
//ONE 医保放量拉动分析
export const MUTATION_ONEMARKETINSURANCE_FILTERPARAMS = "MUTATION_ONEMARKETINSURANCE_FILTERPARAMS" // 获取筛选查询条件
export const MUTATION_ONEMARKETINSURANCE_ECHOPARAMS = "MUTATION_ONEMARKETINSURANCE_ECHOPARAMS" // 获取筛选回显条件
//ONE 集采放量拉动分析
export const MUTATION_ONEMARKETCENTRALIZED_FILTERPARAMS = "MUTATION_ONEMARKETCENTRALIZED_FILTERPARAMS" // 获取筛选查询条件
export const MUTATION_ONEMARKETCENTRALIZED_ECHOPARAMS = "MUTATION_ONEMARKETCENTRALIZED_ECHOPARAMS" // 获取筛选回显条件
// 通用配置
export const MUTATION_SET_TABLE_COLUMN = "MUTATION_SET_TABLE_COLUMN" //获取用户当前页面可见列列表
export const MUTATION_SET_TABLE_COLUMNS = "MUTATION_SET_TABLE_COLUMNS" //获取用户当前页面可见列列表
