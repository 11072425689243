var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "custom-popover-wrapper" },
    [
      _c(
        "el-popover",
        {
          attrs: {
            placement: "right",
            trigger: "hover",
            "visible-arrow": false,
            "popper-class": "custom-popper",
          },
        },
        [
          _vm._t("default"),
          _c(
            "span",
            { attrs: { slot: "reference" }, slot: "reference" },
            [_vm._t("reference")],
            2
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }