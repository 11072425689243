import Api from "./http.js"
const env = process.env.NODE_ENV
export const BaseUrl = Api.BaseUrl // 基础服务器地址
export const BaseLoginUrl = Api.BaseLoginUrl // 登录接口地址
export const AjaxSuccessCode = [10000, 200, 10023] // ajax成功code
export const ErrorCode = [500, 400, 407, 413] //ajax失败code
export const NotFound = [403] //NotFounde
export const NoErrorToastRquest = [
  'GetFilterOptions',
  'TreatmentTypeDictTree',
  'AtcTypeDictTree',
  'RecommendedWords',
  'TargetTypeNew',
  'TargetTypeById',
  'DiseaseTypeNew',
  'DiseaseType',
  'DiseaseTypeById',
  'GetNumberByBatch',
  'GetBEListType',
  'TreatmentType',
  'DrugType',
  'BiologicalDrugClassification',
  'Country',
  'CollectRound',
  'DurgAnalysis',
  'RisingTargetTreatmentType',
  'ExploreImitateDrugForm',
  'GetContrastProductGroupList',
  'CollectRound',
  'RegisteredType',
  'MedicalInsuranceLevel',
  'List',
] //不提示错误接口
export const NoPower = [403] //无权限
export const AjaxUnLoginCode = [401] // ajax未登录code
export const StateMapping = {
  // 状态映射集(filter,collects,entity)
  useYn: {
    0: "无效",
    1: "有效",
  },
  checkType: {
    0: "未申请",
    1: "未审核",
    2: "审核通过",
    3: "审核驳回",
  },
  couponYn: {
    1: "否",
    2: "是",
  },
}

class VL {
  constructor(value, label) {
    this.value = value
    this.label = label
  }
}

export const constItemAttrCollects = [
  new VL("judgesms_his", "是否验证手机"),
]

const posthogMap = {
  development: {
    api_host: "http://posthog.pharmadl.test",
    api_key: "phc_1Pef85wRTTeAr07iaBo8q3iYdFRrxRKsjAYp0tpZ5vv",
    dsn: "http://201b3bf543476bfb11cb412d304caf29@sentry.pharmadl.product:9080/3",
  },
  testing: {
    api_host: "http://posthog.pharmadl.test",
    api_key: "phc_1Pef85wRTTeAr07iaBo8q3iYdFRrxRKsjAYp0tpZ5vv",
    dsn: "http://201b3bf543476bfb11cb412d304caf29@sentry.pharmadl.product:9080/3",
  },
  uat: {
    api_host: "https://uxanalysis.pharmadl.com",
    api_key: "phc_QHOo8da7FDVPcy6opZz4PoieL91aBNqVMBdtS0uYMTA",
    dsn: "http://201b3bf543476bfb11cb412d304caf29@sentry.pharmadl.product:9080/3",
  },
  production: {
    api_host: "https://uxanalysis.pharmadl.com",
    api_key: "phc_QHOo8da7FDVPcy6opZz4PoieL91aBNqVMBdtS0uYMTA",
    dsn: "http://201b3bf543476bfb11cb412d304caf29@sentry.pharmadl.product:9080/3",
  },
}
export const posthog_config = posthogMap[env] || posthogMap.production
