import Vue from "vue"
export default {
  update: (el) => {
    el.style.whiteSpace = "nowrap"
    Vue.nextTick(() => {
      const scrollWidth = el.scrollWidth
      const clientWidth = el.clientWidth
      if (scrollWidth > clientWidth) {
        el.title = el.textContent
        el.style.textOverflow = "ellipsis"
        el.style.overflow = "hidden"
      }
    })
  },
}
