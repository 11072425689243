// src/directive/index.js
import Loading from "./loading"
import AutoShowTitle from "./autoShowTitle"
import AdaptSelfWidth from "./adaptSelfWidth"
import ClickOutside from './clickOutside';

export default {
  install(Vue) {
    Vue.directive("oneLoading", Loading)
    Vue.directive("autoShowTitle", AutoShowTitle)
    Vue.directive("AdaptSelfWidth", AdaptSelfWidth)
    Vue.directive("ClickOutside", ClickOutside)
  },
}
