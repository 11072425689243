export const GETTERS_GLOBAL_HOME = "GETTERS_GLOBAL_HOME"
export const GETTERS_CURR_SYS_TITLE = "GETTERS_CURR_SYS_TITLE" // 获取系统名称
export const GETTERS_GLOBAL_BREADLIST = "GETTERS_GLOBAL_BREADLIST" //获取面包屑导航
export const GETTERS_GLOBAL_VIEWTYPE = "GETTERS_GLOBAL_VIEWTYPE" //获取页面是数据表还是全景图
export const GETTERS_GLOBAL_TABBAR = "GETTERS_GLOBAL_TABBAR" //获取tabbar当前页面
export const GETTERS_GLOBAL_LOADING = "GETTERS_GLOBAL_LOADING" //获取loading状态
export const GETTERS_GLOBAL_TABLIST = "GETTERS_GLOBAL_TABLIST" //获取tablist
export const GETTERS_GLOBAL_TIPMODLUS = "GETTERS_GLOBAL_TIPMODLUS" //错误的统一提示弹框
/**
 * 首页
 */
export const GETTERS_HOME_COREDATA = "GETTERS_HOME_COREDATA" //获取核心数据模块
export const GETTERS_HOME_INDUSTRYNEWS = "GETTERS_HOME_INDUSTRYNEWS" //获取行业动态
export const GETTERS_HOME_RECOMMENDEDWORDS = "GETTERS_HOME_RECOMMENDEDWORDS" //获取推荐词
export const GETTERS_HOME_HOTWORD = "GETTERS_HOME_HOTWORD" //热词
/**
 * 疾病数据
 */
export const GETTERS_DISEASE_DISEASETYPE = "GETTERS_DISEASE_DISEASETYPE" //获取疾病类型数据
export const GETTERS_DISEASE_DISEASE_DISEASEDATATABLE = "GETTERS_DISEASE_DISEASE_DISEASEDATATABLE" //获取疾病数据列表
export const GETTERS_DISEASE_DURGANALYSIS = "GETTERS_DISEASE_DURGANALYSIS" //获取药物分析
export const GETTERS_DISEASE_SALESANALYSIS = "GETTERS_DISEASE_SALESANALYSIS" //获取销售分析数据
export const GETTERS_DISEASE_DISEASEANALYSIS = "GETTERS_DISEASE_DISEASEANALYSIS" //获取疾病分析
export const GETTERS_DISEASE_COUNTRYRDMAPANALYSIS = "GETTERS_DISEASE_COUNTRYRDMAPANALYSIS" //获取研发地图
export const GETTERS_DISEASE_COMPANYANALYSIS = "GETTERS_DISEASE_COMPANYANALYSIS" //获取研发地
export const GETTERS_DISEASE_TARGETANALYSIS = "GETTERS_DISEASE_TARGETANALYSIS" //靶点分析
export const GETTERS_DISEASE_SALESCOMPANY = "GETTERS_DISEASE_SALESCOMPANY" //靶点分析
export const GETTERS_DISEASE_CHINASALESANDFORECAST = "GETTERS_DISEASE_CHINASALESANDFORECAST" //疾病分析
export const GETTERS_DISEASE_GLOBALSALESANDFORECAST = "GETTERS_DISEASE_GLOBALSALESANDFORECAST" //疾病分析
export const GETTERS_DISEASE_POTENTIALMARKET = "GETTERS_DISEASE_POTENTIALMARKET" //获取市场潜力分析
export const GETTERS_DISEASE_CHINACOMPANYSALES = "GETTERS_DISEASE_CHINACOMPANYSALES" //获取公司实力
export const GETTERS_DISEASE_GLOBALCOMPANYSALES = "GETTERS_DISEASE_GLOBALCOMPANYSALES" //获取公司实力
export const GETTERS_DISEASE_RDANALYSIS = "GETTERS_DISEASE_RDANALYSIS" //获取疾病研发获批分析
export const GETTERS_DISEASE_TARGET = "GETTERS_DISEASE_TARGET" //获取靶点列表
export const GETTERS_DISEASE_COMPANY = "GETTERS_DISEASE_COMPANY" //获取公司列表
export const GETTERS_DISEASE_COUNTRY = "GETTERS_DISEASE_COUNTRY" //获取国家列表
export const GETTERS_DISEASE_REPLACERISKANALYSIS = "GETTERS_DISEASE_REPLACERISKANALYSIS" //获取代替风险
export const GETTERS_DISEASE_DEPARTMENTANALYSIS = "GETTERS_DISEASE_DEPARTMENTANALYSIS" //获取科室分析
export const GETTERS_DISEASE_COMMONNAME = "GETTERS_DISEASE_COMMONNAME" //获取药品
export const GETTERS_DISEASE_DRUGFORMJOINTCOMMONNAME = "GETTERS_DISEASE_DRUGFORMJOINTCOMMONNAME" //获取产品名称
export const GETTERS_DISEASE_CLINICAL = "GETTERS_DISEASE_CLINICAL" //临床试验分析
export const GETTERS_DISEASE_DRUGCOMPARISONANALYSIS = "GETTERS_DISEASE_DRUGCOMPARISONANALYSIS" //药物对比分析
/**
 * 靶点数据
 *
 */
export const GETTERS_TARGET_TARGETTYPE = "GETTERS_TARGET_TARGETTYPE" //获取靶点类型数据
export const GETTERS_TARGET_TARGETDATATABLE = "GETTERS_TARGET_TARGETDATATABLE" //获取靶点数据列表
export const GETTERS_TARGET_TARGETCHARTDATA = "GETTERS_TARGET_TARGETCHARTDATA" //设置靶点查询后的数据
export const GETTERS_TARGET_SCREENCONDITION = "GETTERS_TARGET_SCREENCONDITION" //获取靶点左侧筛选条件
export const GETTERS_TARGET_CHANGESCREENCONDITION = "GETTERS_TARGET_CHANGESCREENCONDITION" //点了筛选按钮后获取到的条件数据
export const GETTERS_TARGET_FILTERSORTCONDITION = "GETTERS_TARGET_FILTERSORTCONDITION" //页面筛选的数据
export const GETTERS_TARGET_DATASHEETDATA = "GETTERS_TARGET_DATASHEETDATA" //获取靶点数据表数据
export const GETTERS_TARGET_DURGANALYSIS = "GETTERS_TARGET_DURGANALYSIS" //获取药物分析
export const GETTERS_TARGET_SALESANALYSIS = "GETTERS_TARGET_SALESANALYSIS" //获取销售分析数据
export const GETTERS_TARGET_DISEASEANALYSIS = "GETTERS_TARGET_DISEASEANALYSIS" //获取疾病分析
export const GETTERS_TARGET_COUNTRYRDMAPANALYSIS = "GETTERS_TARGET_COUNTRYRDMAPANALYSIS" //获取研发地图
export const GETTERS_TARGET_COMPANYANALYSIS = "GETTERS_TARGET_COMPANYANALYSIS" //获取研发地
export const GETTERS_TARGET_SALESCOMPANY = "GETTERS_TARGET_SALESCOMPANY" //销售分析按通用名获取Top10公司
export const GETTERS_TARGET_DISEASEATLAS = "GETTERS_TARGET_DISEASEATLAS" //疾病分析
export const GETTERS_TARGET_RDANALYSIS = "GETTERS_TARGET_RDANALYSIS" //研发获批分析
export const GETTERS_TARGET_CHINASALESANDFORECAST = "GETTERS_TARGET_CHINASALESANDFORECAST" //疾病分析
export const GETTERS_TARGET_GLOBALSALESANDFORECAST = "GETTERS_TARGET_GLOBALSALESANDFORECAST" //疾病分析
export const GETTERS_TARGET_POTENTIALMARKET = "GETTERS_TARGET_POTENTIALMARKET" //获取市场潜力分析
export const GETTERS_TARGET_CHINACOMPANYSALES = "GETTERS_TARGET_CHINACOMPANYSALES" //获取公司实力
export const GETTERS_TARGET_GLOBALCOMPANYSALES = "GETTERS_TARGET_GLOBALCOMPANYSALES" //获取公司实力
export const GETTERS_TARGET_CLINICAL = "GETTERS_TARGET_CLINICAL" //获取临床信息
export const GETTERS_TARGET_DRUGFORMSALES = "GETTERS_TARGET_DRUGFORMSALES" //获取剂型实力
export const GETTERS_TARGET_REPLACERISKANALYSIS = "GETTERS_TARGET_REPLACERISKANALYSIS" //获取代替风险
export const GETTERS_TARGET_DEPARTMENTANALYSIS = "GETTERS_TARGET_DEPARTMENTANALYSIS" //获取科室分析
export const GETTERS_TARGET_DISEASE = "GETTERS_TARGET_DISEASE" //获取疾病列表
export const GETTERS_TARGET_DRUGTYPE = "GETTERS_TARGET_DRUGTYPE" //获取药品列表
export const GETTERS_TARGET_DRUGFORMJOINTCOMMONNAME = "GETTERS_TARGET_DRUGFORMJOINTCOMMONNAME" //获通用名获取药品剂型（产品名称）
export const GETTERS_TARGET_COMMONNAME = "GETTERS_TARGET_COMMONNAME" //获通用名
export const GETTERS_TARGET_COUNTRY = "GETTERS_TARGET_COUNTRY" //获取国家列表
export const GETTERS_TARGET_COMPANY = "GETTERS_TARGET_COMPANY" //获取公司列表
export const GETTERS_TARGET_DRUGCOMPARISONANALYSIS = "GETTERS_TARGET_DRUGCOMPARISONANALYSIS" //药物对比分析

/**
 * 公司
 */
export const GETTERS_ENTERPRISE_DRUGRDANALYSIS = "GETTERS_ENTERPRISE_DRUGRDANALYSIS" //获取公司数据表
export const GETTERS_ENTERPRISE_DISEASE = "GETTERS_ENTERPRISE_DISEASE" //获取疾病列表
export const GETTERS_ENTERPRISE_PHASE = "GETTERS_ENTERPRISE_PHASE" //获取阶段列表
export const GETTERS_ENTERPRISE_TARGET = "GETTERS_ENTERPRISE_TARGET" //获取靶点列表
export const GETTERS_ENTERPRISE_COMPANY = "GETTERS_ENTERPRISE_COMPANY" //获取公司列表
export const GETTERS_ENTERPRISE_COUNTRY = "GETTERS_ENTERPRISE_COUNTRY" //获取国家列表
export const GETTERS_ENTERPRISE_COMMONNAMECHINARDANALYSIS = "GETTERS_ENTERPRISE_COMMONNAMECHINARDANALYSIS" //
export const GETTERS_ENTERPRISE_COMMONNAMEGLOBALRDANALYSIS = "GETTERS_ENTERPRISE_COMMONNAMEGLOBALRDANALYSIS" //
export const GETTERS_ENTERPRISE_COMPANYDISEASECHINARDANALYSIS = "GETTERS_ENTERPRISE_COMPANYDISEASECHINARDANALYSIS" //
export const GETTERS_ENTERPRISE_COMPANYDISEASEGLOBALRDANALYSIS = "GETTERS_ENTERPRISE_COMPANYDISEASEGLOBALRDANALYSIS" //
export const GETTERS_ENTERPRISE_COMMONNAME = "GETTERS_ENTERPRISE_COMMONNAME" //获取药物列表

export const GETTERS_ENTERPRISE_DURGANALYSIS = "GETTERS_ENTERPRISE_DURGANALYSIS" //获取药物分析

export const GETTERS_ENTERPRISE_DOMESTICSALESANALYSIS = "GETTERS_ENTERPRISE_DOMESTICSALESANALYSIS" //获取中国药物销售分析
export const GETTERS_ENTERPRISE_GLOBALSALESANALYSIS = "GETTERS_ENTERPRISE_GLOBALSALESANALYSIS" //获取全球药物销售分析

export const GETTERS_ENTERPRISE_DOMESTICDISEASEANALYSIS = "GETTERS_ENTERPRISE_DOMESTICDISEASEANALYSIS" //获取中国疾病分析
export const GETTERS_ENTERPRISE_GLOBALDISEASEANALYSIS = "GETTERS_ENTERPRISE_GLOBALDISEASEANALYSIS" //获取全球疾病分析

export const GETTERS_ENTERPRISE_DOMESTICTARGETANALYSIS = "GETTERS_ENTERPRISE_DOMESTICTARGETANALYSIS" //获取中国靶点分析
export const GETTERS_ENTERPRISE_GLOBALTARGETANALYSIS = "GETTERS_ENTERPRISE_GLOBALTARGETANALYSIS" //获取全球靶点分析

export const GETTERS_ENTERPRISE_COUNTRYRDMAPANALYSIS = "GETTERS_ENTERPRISE_COUNTRYRDMAPANALYSIS" //获取研发地图

export const GETTERS_ENTERPRISE_ENTERPRISEPRODUCTSTRUCTURE = "GETTERS_ENTERPRISE_ENTERPRISEPRODUCTSTRUCTURE" //获取公司产品结构
export const GETTERS_ENTERPRISE_ENTERPRISEAREASTRUCTURE = "GETTERS_ENTERPRISE_ENTERPRISEAREASTRUCTURE" //获取公司领域分布结构
export const GETTERS_ENTERPRISE_ACTIONS_GET_ENTERPRISE_ENTERPRISEANNUALREPORTTAG =
  "GETTERS_ENTERPRISE_ACTIONS_GET_ENTERPRISE_ENTERPRISEANNUALREPORTTAG" //获取公司年报数据
// export const GETTERS_ENTERPRISE_DOMESTICSALESANALYSIS='GETTERS_ENTERPRISE_DOMESTICSALESANALYSIS';//获取公司中国药物销售分析
export const GETTERS_ENTERPRISE_GLOBALSTICSALESANALYSIS = "GETTERS_ENTERPRISE_GLOBALSTICSALESANALYSIS" //获取公司全球药物销售分析
export const GETTERS_ENTERPRISE_COMPANYSALESPERCENTAGE = "GETTERS_ENTERPRISE_COMPANYSALESPERCENTAGE" //获取公司销售占比分析
export const GETTERS_ENTERPRISE_COMPANYYIELD = "GETTERS_ENTERPRISE_COMPANYYIELD" //获取公司产量分析
export const GETTERS_ENTERPRISE_COMPANYSTOCK = "GETTERS_ENTERPRISE_COMPANYSTOCK" //获取公司股票月线
export const GETTERS_ENTERPRISE_COMPANYEVENT = "GETTERS_ENTERPRISE_COMPANYEVENT" //获取公司大事件
export const GETTERS_ENTERPRISE_CHINACOMPANYSALES = "GETTERS_ENTERPRISE_CHINACOMPANYSALES" //获取公司实力
export const GETTERS_ENTERPRISE_GLOBALCOMPANYSALES = "GETTERS_ENTERPRISE_GLOBALCOMPANYSALES" //获取公司实力
export const GETTERS_ENTERPRISE_ENTERPRISEDETAIL = "GETTERS_ENTERPRISE_ENTERPRISEDETAIL" //获取公司详情
export const GETTERS_ENTERPRISE_ENTERPRISERELATION = "GETTERS_ENTERPRISE_ENTERPRISERELATION" //获取公司关联
export const GETTERS_ENTERPRISE_ENTERPRISEREFINANCIALREPORT = "GETTERS_ENTERPRISE_ENTERPRISEREFINANCIALREPORT" //获取公司财务指标
export const GETTERS_ENTERPRISE_ENTERPRISEREFINANCIALREPORTTEARLY = "GETTERS_ENTERPRISE_ENTERPRISEREFINANCIALREPORTTEARLY" //获取公司财务年度
export const GETTERS_ENTERPRISE_CLINICAL = "GETTERS_ENTERPRISE_CLINICAL" //临床试验分析
/**
 * 药物
 */
export const GETTERS_DRUG_DURGANALYSIS = "GETTERS_DRUG_DURGANALYSIS" //获取药物分析数据
export const GETTERS_DRUG_ENTERPRISEANALYSIS = "GETTERS_DRUG_ENTERPRISEANALYSIS" //获取药物分析数据
export const GETTERS_DRUG_DOMESTICSALESANALYSIS = "GETTERS_DRUG_DOMESTICSALESANALYSIS" //获取中国药物销售分析数据
export const GETTERS_DRUG_GLOBALSALESANALYSIS = "GETTERS_DRUG_GLOBALSALESANALYSIS" //获取全球药物销售分析数据
export const GETTERS_DRUG_ENTERPRISESALESANALYSIS = "GETTERS_DRUG_ENTERPRISESALESANALYSIS" //获取药品销售分析使用通用名查企业
export const GETTERS_DRUG_DOMESTICDISEASEANALYSIS = "GETTERS_DRUG_DOMESTICDISEASEANALYSIS" //获取全球疾病阶段分析
export const GETTERS_DRUG_GLOBALDISEASEANALYSIS = "GETTERS_DRUG_GLOBALDISEASEANALYSIS" //获取全球疾病阶段分析
export const GETTERS_DRUG_DOMESTICTARGETANALYSIS = "GETTERS_DRUG_DOMESTICTARGETANALYSIS" //获取全球药物靶点阶段分析
export const GETTERS_DRUG_GLOBALTARGETANALYSIS = "GETTERS_DRUG_GLOBALTARGETANALYSIS" //获取全球药物靶点阶段分析
export const GETTERS_DRUG_COUNTRYRDMAPANALYSIS = "GETTERS_DRUG_COUNTRYRDMAPANALYSIS" //获取全球药物全国研究分析
export const GETTERS_DRUG_DRUGRDANALYSIS = "GETTERS_DRUG_DRUGRDANALYSIS" //获取药品研发分析
export const GETTERS_DRUG_DRUDETAIL = "GETTERS_DRUG_DRUDETAIL" //获取药品详情
export const GETTERS_DRUG_RDANALYSIS = "GETTERS_DRUG_RDANALYSIS" //获取研发获批分析
export const GETTERS_DRUG_CHINASALESANDFORECAST = "GETTERS_DRUG_CHINASALESANDFORECAST" //获取销售总额分析与预测
export const GETTERS_DRUG_GLOBALSALESANDFORECAST = "GETTERS_DRUG_GLOBALSALESANDFORECAST" //获取销售总额分析与预测
export const GETTERS_DRUG_POTENTIALMARKET = "GETTERS_DRUG_POTENTIALMARKET" //获取市场潜力分析
export const GETTERS_DRUG_CHINACOMPANYSALES = "GETTERS_DRUG_CHINACOMPANYSALES" //获取公司实力
export const GETTERS_DRUG_GLOBALCOMPANYSALES = "GETTERS_DRUG_GLOBALCOMPANYSALES" //获取公司实力
export const GETTERS_DRUG_CLINICAL = "GETTERS_DRUG_CLINICAL" //获取临床信息
export const GETTERS_DRUG_DRUGFORMSALES = "GETTERS_DRUG_DRUGFORMSALES" //获取剂型实力
export const GETTERS_DRUG_REPLACERISKANALYSIS = "GETTERS_DRUG_REPLACERISKANALYSIS" //获取代替风险
export const GETTERS_DRUG_DEPARTMENTANALYSIS = "GETTERS_DRUG_DEPARTMENTANALYSIS" //获取科室分析
export const GETTERS_DRUG_PATIENTFLOW = "GETTERS_DRUG_PATIENTFLOW" //获取患者流
export const GETTERS_DRUG_ADVERSEREACTIONS = "GETTERS_DRUG_ADVERSEREACTIONS" //获取不良反应报告
export const GETTERS_DRUG_DISEASE = "GETTERS_DRUG_DISEASE" //获取疾病列表
export const GETTERS_DRUG_PHASE = "GETTERS_DRUG_PHASE" //获取阶段列表
export const GETTERS_DRUG_TARGET = "GETTERS_DRUG_TARGET" //获取靶点列表
export const GETTERS_DRUG_COMPANY = "GETTERS_DRUG_COMPANY" //获取公司列表
export const GETTERS_DRUG_COUNTRY = "GETTERS_DRUG_COUNTRY" //获取国家列表
export const GETTERS_DRUG_COMMONNAMEID = "GETTERS_DRUG_COMMONNAMEID" //获取药物ID
export const GETTERS_DRUG_COMPANYCHINARDANALYSIS = "GETTERS_DRUG_COMPANYCHINARDANALYSIS" //研发获批分析-按公司中国查看
export const GETTERS_DRUG_COMPANYGLOBALRDANALYSIS = "GETTERS_DRUG_COMPANYGLOBALRDANALYSIS" //研发获批分析-按公司全球查看
export const GETTERS_DRUG_DISEASECHINARDANALYSIS = "GETTERS_DRUG_DISEASECHINARDANALYSIS" //研发获批分析-按疾病中国查看
export const GETTERS_DRUG_DISEASEGLOBALRDANALYSIS = "GETTERS_DRUG_DISEASEGLOBALRDANALYSIS" //研发获批分析-按疾病全球查看
export const GETTERS_DRUG_DRUGFORM = "GETTERS_DRUG_DRUGFORM" //获取剂型列表
export const GETTERS_DRUG_DRUGFORMJOINTCOMMONNAME = "GETTERS_DRUG_DRUGFORMJOINTCOMMONNAME" //获通用名获取药品剂型（产品名称）
export const GETTERS_DRUG_DRUGTYPE = "GETTERS_DRUG_DRUGTYPE" //获取药品分类
export const GETTERS_DRUG_BIOLOGICALDRUGCLASSIFICATION = "GETTERS_DRUG_BIOLOGICALDRUGCLASSIFICATION" //获取开发药物技术分类
export const GETTERS_DRUG_TREATMENTTYPE = "GETTERS_DRUG_TREATMENTTYPE" //获取开发药物技术分类

// 申报
export const GETTERS_DECLARE_URGENTREASON = "GETTERS_DECLARE_URGENTREASON" //临床急需品种理由
export const GETTERS_DECLARE_ASSESSREASON = "GETTERS_DECLARE_ASSESSREASON" //获取优先审评理由
export const GETTERS_DECLARE_GETPROVINCE = "GETTERS_DECLARE_GETPROVINCE" //获取申请省份数据
export const GETTERS_DECLARE_APPROVALCONCLUSION = "GETTERS_DECLARE_APPROVALCONCLUSION" //获取审批结论数据
export const GETTERS_DECLARE_ADMINISTRATIONROUTE = "GETTERS_DECLARE_ADMINISTRATIONROUTE" //获取给药途径数据
export const GETTERS_DECLARE_DOSAGEDATA = "GETTERS_DECLARE_DOSAGEDATA" //获取剂型数据
export const GETTERS_DECLARE_REGISTERCLASSIFICATION = "GETTERS_DECLARE_REGISTERCLASSIFICATION" //获取注册分类二级联动数据
export const GETTERS_DECLARE_TECHNOLOGYLABEL = "GETTERS_DECLARE_TECHNOLOGYLABEL" //获取热门热门标签数据
export const GETTERS_DECLARE_THERAPEUTICFIRLDPHIIC = "GETTERS_DECLARE_THERAPEUTICFIRLDPHIIC" //治疗领域PHIIC二级联动数据接口
export const GETTERS_DECLARE_THERAPEUTICFIRLDATC = "GETTERS_DECLARE_THERAPEUTICFIRLDATC" //治疗领域ATC多级联动数据接口
export const GETTERS_DECLARE_COLUMNS = "GETTERS_DECLARE_COLUMNS" //数据表表头数据
export const GETTERS_DECLARE_COLUMNSDATA = "GETTERS_DECLARE_COLUMNSDATA" //数据表数据
export const GETTERS_DECLARE_COLUMNSDATADOWNLOAD = "GETTERS_DECLARE_COLUMNSDATADOWNLOAD" //下载数据表
export const GETTERS_DECLARE_TABLECOLUMN = "GETTERS_DECLARE_TABLECOLUMN" //设置当前可见列
export const GETTERS_DECLARE_DECLAREDETAIL = "GETTERS_DECLARE_DECLAREDETAIL" //申报详情
export const GETTERS_DECLARE_TIMELINE = "GETTERS_DECLARE_TIMELINE" //时光轴详情
export const GETTERS_DECLARE_RESEARCHDEVELOPMENTSAMEVARIETY = "GETTERS_DECLARE_RESEARCHDEVELOPMENTSAMEVARIETY" //受理号申报信息的表格数据

// 上市
export const GETTERS_PRODUCT_GETCOMPANYDATA = "GETTERS_PRODUCT_GETCOMPANYDATA" //公司/持证商多选列表
export const GETTERS_PRODUCT_GETDRUGTYPE = "GETTERS_PRODUCT_GETDRUGTYPE" //药品类型
export const GETTERS_PRODUCT_ADMINISTRATIONROUTE = "GETTERS_PRODUCT_ADMINISTRATIONROUTE" //给药途径
export const GETTERS_PRODUCT_DOSAGEDATA = "GETTERS_PRODUCT_DOSAGEDATA" //剂型
export const GETTERS_PRODUCT_MEDICALINSURANCE = "GETTERS_PRODUCT_MEDICALINSURANCE" //医保目录
export const GETTERS_PRODUCT_SIMPLIFYCONSISTENCYEVALUATION = "GETTERS_PRODUCT_SIMPLIFYCONSISTENCYEVALUATION" //豁免或简化一致性评价品种
export const GETTERS_PRODUCT_PURCHASEVARIETIES = "GETTERS_PRODUCT_PURCHASEVARIETIES" //带量采购品种
export const GETTERS_PRODUCT_CHINASCREENCONDITION = "GETTERS_PRODUCT_CHINASCREENCONDITION"
export const GETTERS_PRODUCT_CHINACHANGESCREENCONDITION = "GETTERS_PRODUCT_CHINACHANGESCREENCONDITION"
export const GETTERS_PRODUCT_CHINAFILTERSORTCONDITION = "GETTERS_PRODUCT_CHINAFILTERSORTCONDITION"
export const GETTERS_PRODUCT_SHOWCOUNTOPENDATA = "GETTERS_PRODUCT_SHOWCOUNTOPENDATA"
export const GETTERS_PRODUCT_TABANDSUBTAB = "GETTERS_PRODUCT_TABANDSUBTAB" // 激活tab页参数
//新版上市
export const GETTERS_MARKET_GETTABLELIST = "GETTERS_MARKET_GETTABLELIST" //表格数据
export const GETTERS_MARKET_DOWNLOADTABLEDATA = "GETTERS_MARKET_DOWNLOADTABLEDATA" // 下载表格数据
export const GETTERS_MARKET_CHECKDOWNLOADFILE = "GETTERS_MARKET_CHECKDOWNLOADFILE" // 文件下载判断数据是否为空
export const GETTERS_MARKET_RECOMMENDEDWORDS = "GETTERS_MARKET_RECOMMENDEDWORDS" //检索条件枚举字典
export const GETTERS_MARKET_GETOPTIONSLIST = "GETTERS_MARKET_GETOPTIONSLIST" // 查询下拉框
export const GETTERS_MARKET_FILTERPARAMS = "GETTERS_MARKET_FILTERPARAMS" // 激活tab页参数
export const GETTERS_PRODUCT_ECHOPARAMS = "GETTERS_PRODUCT_ECHOPARAMS" // 存储回显筛选条件
export const GETTERS_MARKET_SHOWCOUNTOPENDATA = "GETTERS_MARKET_SHOWCOUNTOPENDATA" // 点击数字弹框列表
export const GETTERS_MARKET_GETTOTALNUMBER = "GETTERS_MARKET_GETTOTALNUMBER" // 查询国别TAB上数量
export const GETTERS_MARKET_GETBELISTTYPE = "GETTERS_MARKET_GETBELISTTYPE" // 查询豁免或简化一致性评价品种
// 临床
export const GETTERS_CLINICAL_ACTIVETAB = "GETTERS_CLINICAL_ACTIVETAB" // 激活tab页参数
export const GETTERS_CLINICAL_FILTERPARAMS = "GETTERS_CLINICAL_FILTERPARAMS" // 获取筛选查询条件
export const GETTERS_CLINICAL_ECHOPARAMS = "GETTERS_CLINICAL_ECHOPARAMS" // 获取筛选回显条件
// 医保目录
export const GETTERS_MEDICARECATALOG_FILTERPARAMS = "GETTERS_MEDICARECATALOG_FILTERPARAMS" // 获取筛选查询条件
export const GETTERS_MEDICARECATALOG_ECHOPARAMS = "GETTERS_MEDICARECATALOG_ECHOPARAMS" // 获取筛选回显条件
export const GETTERS_MEDICARECATALOG_GETTABLEDATA = "GETTERS_MEDICARECATALOG_GETTABLEDATA" // 查询表格数据
export const GETTERS_MEDICARECATALOG_DOWNLOADTABLEDATA = "GETTERS_MEDICARECATALOG_DOWNLOADTABLEDATA" // 下载表格数据
export const GETTERS_MEDICARECATALOG_CHECKDOWNLOADFILE = "GETTERS_MEDICARECATALOG_CHECKDOWNLOADFILE" // 文件下载判断数据是否为空
export const GETTERS_MEDICARECATALOG_DOWNLOADFILE = "GETTERS_MEDICARECATALOG_DOWNLOADFILE" // 文件下载
export const GETTERS_MEDICARECATALOG_RECOMMENDEDWORDS = "GETTERS_MEDICARECATALOG_RECOMMENDEDWORDS" //检索条件枚举字典
export const GETTERS_MEDICARECATALOG_GETOPTIONSLIST = "GETTERS_MEDICARECATALOG_GETOPTIONSLIST" // 查询下拉框
export const GETTERS_MEDICARECATALOG_SHOWCOUNTOPENDATA = "GETTERS_MEDICARECATALOG_SHOWCOUNTOPENDATA" // 点击数字弹框列表
export const GETTERS_MEDICARECATALOG_TIMEAXIS = "GETTERS_MEDICARECATALOG_TIMEAXIS" // 详情页- 时光轴
export const GETTERS_MEDICARECATALOG_MEDICALINSURANCELEVEL = "GETTERS_MEDICARECATALOG_MEDICALINSURANCELEVEL" // 获取医保版本
// 全球新药
export const GETTERS_GLOBALNEWDRUG_FILTERPARAMS = "GETTERS_GLOBALNEWDRUG_FILTERPARAMS" // 获取筛选查询条件
export const GETTERS_GLOBALNEWDRUG_ECHOPARAMS = "GETTERS_GLOBALNEWDRUG_ECHOPARAMS" // 获取筛选回显条件
export const GETTERS_GLOBALNEWDRUG_GETTABLEDATA = "GETTERS_GLOBALNEWDRUG_GETTABLEDATA" // 查询表格数据
export const GETTERS_GLOBALNEWDRUG_DOWNLOADTABLEDATA = "GETTERS_GLOBALNEWDRUG_DOWNLOADTABLEDATA" // 下载表格数据
export const GETTERS_GLOBALNEWDRUG_CHECKDOWNLOADFILE = "GETTERS_GLOBALNEWDRUG_CHECKDOWNLOADFILE" // 文件下载判断数据是否为空
export const GETTERS_GLOBALNEWDRUG_DOWNLOADFILE = "GETTERS_GLOBALNEWDRUG_DOWNLOADFILE" // 文件下载
export const GETTERS_GLOBALNEWDRUG_RECOMMENDEDWORDS = "GETTERS_GLOBALNEWDRUG_RECOMMENDEDWORDS" //检索条件枚举字典
export const GETTERS_GLOBALNEWDRUG_GETOPTIONSLIST = "GETTERS_GLOBALNEWDRUG_GETOPTIONSLIST" // 查询下拉框
export const GETTERS_GLOBALNEWDRUG_SHOWCOUNTOPENDATA = "GETTERS_GLOBALNEWDRUG_SHOWCOUNTOPENDATA" // 点击数字弹框列表
export const GETTERS_GLOBALNEWDRUG_TIMEAXIS = "GETTERS_GLOBALNEWDRUG_TIMEAXIS" // 详情页- 时光轴
export const GETTERS_GLOBALNEWDRUG_MEDICALINSURANCELEVEL = "GETTERS_GLOBALNEWDRUG_MEDICALINSURANCELEVEL" // 获取医保版本
// 基药目录
export const GETTERS_ESSENTIALDRUG_FILTERPARAMS = "GETTERS_ESSENTIALDRUG_FILTERPARAMS" // 获取筛选查询条件
export const GETTERS_ESSENTIALDRUG_ECHOPARAMS = "GETTERS_ESSENTIALDRUG_ECHOPARAMS" // 获取筛选回显条件
export const GETTERS_ESSENTIALDRUG_GETTABLEDATA = "GETTERS_ESSENTIALDRUG_GETTABLEDATA" // 查询表格数据
export const GETTERS_ESSENTIALDRUG_DOWNLOADTABLEDATA = "GETTERS_ESSENTIALDRUG_DOWNLOADTABLEDATA" // 下载表格数据
export const GETTERS_ESSENTIALDRUG_CHECKDOWNLOADFILE = "GETTERS_ESSENTIALDRUG_CHECKDOWNLOADFILE" // 文件下载判断数据是否为空
export const GETTERS_ESSENTIALDRUG_DOWNLOADFILE = "GETTERS_ESSENTIALDRUG_DOWNLOADFILE" // 文件下载
export const GETTERS_ESSENTIALDRUG_RECOMMENDEDWORDS = "GETTERS_ESSENTIALDRUG_RECOMMENDEDWORDS" //检索条件枚举字典
export const GETTERS_ESSENTIALDRUG_GETOPTIONSLIST = "GETTERS_ESSENTIALDRUG_GETOPTIONSLIST" // 查询下拉框
export const GETTERS_ESSENTIALDRUG_SHOWCOUNTOPENDATA = "GETTERS_ESSENTIALDRUG_SHOWCOUNTOPENDATA" // 点击数字弹框列表
export const GETTERS_ESSENTIALDRUG_TIMEAXIS = "GETTERS_ESSENTIALDRUG_TIMEAXIS" // 详情页- 时光轴
export const GETTERS_ESSENTIALDRUG_MEDICALINSURANCELEVEL = "GETTERS_ESSENTIALDRUG_MEDICALINSURANCELEVEL" // 获取医保版本

// 原辅包
export const GETTERS_MATERIAL_FILTERPARAMS = "GETTERS_MATERIAL_FILTERPARAMS" // 获取筛选查询条件
export const GETTERS_MATERIAL_ECHOPARAMS = "GETTERS_MATERIAL_ECHOPARAMS" // 获取筛选回显条件

// 参比制剂目录
export const GETTERS_REFERLISTEDDRUG_FILTERPARAMS = "GETTERS_REFERLISTEDDRUG_FILTERPARAMS" // 获取筛选查询条件
export const GETTERS_REFERLISTEDDRUG_ECHOPARAMS = "GETTERS_REFERLISTEDDRUG_ECHOPARAMS" // 获取筛选回显条件
// 招标
export const GETTERS_DRUGBIDDING_FILTERPARAMS = "GETTERS_DRUGBIDDING_FILTERPARAMS" // 获取筛选查询条件
export const GETTERS_DRUGBIDDING_ECHOPARAMS = "GETTERS_DRUGBIDDING_ECHOPARAMS" // 获取筛选回显条件
// 美国NDC
export const GETTERS_AMERICANDC_FILTERPARAMS = "GETTERS_AMERICANDC_FILTERPARAMS" // 获取筛选查询条件
export const GETTERS_AMERICANDC_ECHOPARAMS = "GETTERS_AMERICANDC_ECHOPARAMS" // 获取筛选回显条件
// 企业年报
export const GETTERS_ANNUALREPORTS_FILTERPARAMS = "GETTERS_ANNUALREPORTS_FILTERPARAMS" // 获取筛选查询条件
export const GETTERS_ANNUALREPORTS_ECHOPARAMS = "GETTERS_ANNUALREPORTS_ECHOPARAMS" // 获取筛选回显条件
// 临床路径
export const GETTERS_CLINICALPATHWAY_FILTERPARAMS = "GETTERS_CLINICALPATHWAY_FILTERPARAMS" // 获取筛选查询条件
export const GETTERS_CLINICALPATHWAY_ECHOPARAMS = "GETTERS_CLINICALPATHWAY_ECHOPARAMS" // 获取筛选回显条件
// 临床指南
export const GETTERS_TREATMENTGUIDE_FILTERPARAMS = "GETTERS_TREATMENTGUIDE_FILTERPARAMS" // 获取筛选查询条件
export const GETTERS_TREATMENTGUIDE_ECHOPARAMS = "GETTERS_TREATMENTGUIDE_ECHOPARAMS" // 获取筛选回显条件
// 专利
export const GETTERS_GLOBALDRUGPATENTS_FILTERPARAMS = "GETTERS_GLOBALDRUGPATENTS_FILTERPARAMS" // 获取筛选查询条件
export const GETTERS_GLOBALDRUGPATENTS_ECHOPARAMS = "GETTERS_GLOBALDRUGPATENTS_ECHOPARAMS" // 获取筛选回显条件

// 招标分析
export const GETTERS_DRUGBIDDINGANALYSIS_FILTERPARAMS = "GETTERS_DRUGBIDDINGANALYSIS_FILTERPARAMS" // 获取筛选查询条件
export const GETTERS_DRUGBIDDINGANALYSIS_ECHOPARAMS = "GETTERS_DRUGBIDDINGANALYSIS_ECHOPARAMS" // 获取筛选回显条件
// 一致性评价
export const GETTERS_EQUIVALENCE_ACTIVETAB = "GETTERS_EQUIVALENCE_ACTIVETAB" // 激活tab页参数
export const GETTERS_EQUIVALENCE_FILTERPARAMS = "GETTERS_EQUIVALENCE_FILTERPARAMS" // 获取筛选查询条件
export const GETTERS_EQUIVALENCE_ECHOPARAMS = "GETTERS_EQUIVALENCE_ECHOPARAMS" // 获取筛选回显条件
//申报复用
export const GETTERS_REGISTRATION_ACTIVETAB = "GETTERS_REGISTRATION_ACTIVETAB" // 激活tab页参数
export const GETTERS_REGISTRATION_FILTERPARAMS = "GETTERS_REGISTRATION_FILTERPARAMS" // 获取筛选查询条件
export const GETTERS_REGISTRATION_ECHOPARAMS = "GETTERS_REGISTRATION_ECHOPARAMS" // 获取筛选回显条件

//个人中心/登录注册
export const GETTERS_AUTH_USERPROVINCE = "GETTERS_AUTH_USERPROVINCE" //获取省份
export const GETTERS_AUTH_USERINFO = "GETTERS_AUTH_USERINFO" //获取用户信息
export const GETTERS_AUTH_LOGINWX = "GETTERS_AUTH_LOGINWX" //微信登录

//权限列表
export const GETTERS_MENU_LIST = "GETTERS_MENU_LIST" //用户的权限列表
export const GETTERS_AUTHSHOW = "GETTERS_AUTHSHOW" //无权限弹窗
export const GETTERS_DEVELOPSHOW = "GETTERS_DEVELOPSHOW" //正在开发弹窗
export const GETTERS_APPLYSHOW = "GETTERS_APPLYSHOW" //申请试用弹窗
export const GETTERS_CALLADDRESS = "GETTERS_CALLADDRESS" //储存访问过的地址
export const GETTERS_SALESSTATISTICSYEAR = "GETTERS_SALESSTATISTICSYEAR" //无权限弹窗
//新兴靶点研发热度
export const GETTERS_RISINGTARGET = "GETTERS_RISINGTARGET" //获取新兴靶点研发热度
//药品获批领域地图
export const GETTERS_APPROVEDAREASMAP = "GETTERS_APPROVEDAREASMAP" //药品获批领域地图
export const GETTERS_EXPLOREIMITATEDRUG = "GETTERS_EXPLOREIMITATEDRUG" //挖掘仿药老品种
export const GETTERS_EXPLOREIMITATEDRUGDATATABLE = "GETTERS_EXPLOREIMITATEDRUGDATATABLE" //挖掘仿药老品种
export const GETTERS_COMMONNAME = "GETTERS_COMMONNAME" //药品通用名
export const GETTERS_DRUGFORMCOMMONNAME = "GETTERS_DRUGFORMCOMMONNAME" //根据通用名获取剂型
export const GETTERS_DRUGFORMTREE = "GETTERS_DRUGFORMTREE" //剂型
export const GETTERS_GETCONTRASTPRODUCTGROUPLIST = "GETTERS_GETCONTRASTPRODUCTGROUPLIST" //剂型
export const GETTERS_GETCONTRASTPRODUCT = "GETTERS_GETCONTRASTPRODUCT" //产品详情
export const GETTERS_PRODUCTCONTRASTANALYSISDATATABLE = "GETTERS_PRODUCTCONTRASTANALYSISDATATABLE" //获取基础对比信息的表格
export const GETTERS_SALESCONTRAST = "GETTERS_SALESCONTRAST" //销售额对比
export const GETTERS_DEPARTMENTCONTRAST = "GETTERS_DEPARTMENTCONTRAST" //科室分布
export const GETTERS_DRUGTREATMENTTYPESALES = "GETTERS_DRUGTREATMENTTYPESALES" //同小类销售额
export const GETTERS_REPLACEPISKANALYSIS = "GETTERS_REPLACEPISKANALYSIS" //同类药品
export const GETTERS_COLLECTIONANALYSISDATATABLE = "GETTERS_COLLECTIONANALYSISDATATABLE" //集采放量拉动分析的表格
export const GETTERS_MEDICALINSURANCEANALYSIS = "GETTERS_MEDICALINSURANCEANALYSIS" //集采放量拉动分析的表格

export const GETTERS_COMPARISONTITLE = "GETTERS_COMPARISONTITLE" //集采放量拉动分析的表格
export const GETTERS_REGISTEREDTYPE = "GETTERS_REGISTEREDTYPE" //药品注册类别
export const GETTERS_INNOVATEDRUGDATATABLE = "GETTERS_INNOVATEDRUGDATATABLE" //国产创新药列表
export const GETTERS_DISEASEOFINNOVATEDRUG = "GETTERS_DISEASEOFINNOVATEDRUG" //国产创新药列表
export const GETTERS_INNOVATEDRUGDETAIL = "GETTERS_INNOVATEDRUGDETAIL" //对标分析
export const GETTERS_HOTAREASRDPANORAMA = "GETTERS_HOTAREASRDPANORAMA" //热门领域研发全景
export const GETTERS_GETCOLLECTROUND = "GETTERS_GETCOLLECTROUND" //集采轮次
//ONE
export const GETTERS_ONE_COMPANY = "GETTERS_ONE_COMPANY" //获取公司列表
export const GETTERS_ONE_COMPANYSTOCK = "GETTERS_ONE_COMPANYSTOCK" //获取公司股票月线
export const GETTERS_ONE_COMPANYEVENT = "GETTERS_ONE_COMPANYEVENT" //获取公司大事件
export const GETTERS_ONE_COMPANYDATA = "GETTERS_ONE_COMPANYDATA" //获取公司信息
export const GETTERS_ONE_MEDICALINSURANCEANALYSISYEAR = "GETTERS_ONE_MEDICALINSURANCEANALYSISYEAR" //医保年份
export const GETTERS_ONE_COLLECTIONANALYSISYEAR = "GETTERS_ONE_COLLECTIONANALYSISYEAR" //集采的年份
//ONE 医保放量拉动分析
export const GETTERS_ONEMARKETINSURANCE_FILTERPARAMS = "GETTERS_ONEMARKETINSURANCE_FILTERPARAMS" // 获取筛选查询条件
export const GETTERS_ONEMARKETINSURANCE_ECHOPARAMS = "GETTERS_ONEMARKETINSURANCE_ECHOPARAMS" // 获取筛选回显条件
//ONE 集采放量拉动分析
export const GETTERS_ONEMARKETCENTRALIZED_FILTERPARAMS = "GETTERS_ONEMARKETCENTRALIZED_FILTERPARAMS" // 获取筛选查询条件
export const GETTERS_ONEMARKETCENTRALIZED_ECHOPARAMS = "GETTERS_ONEMARKETCENTRALIZED_ECHOPARAMS" // 获取筛选回显条件
// 通用配置
export const GETTERS_TABLE_COLUMN = "GETTERS_TABLE_COLUMN" //获取用户当前页面可见列列表
export const GETTERS_TABLE_COLUMNS = "GETTERS_TABLE_COLUMNS" //获取用户当前页面可见列列表
