<template>
  <!-- 对比 -->
  <div class="noData">
    <img src="@/assets/img/error.png" alt="" />
    <p>正在查询</p>
  </div>
</template>
<script>
export default {
  name: "contentCenter",
  components: {},
  props: {},
  data() {
    return {}
  },
  watch: {
    //监听vue中查询数据的改版
    ChartData() {
      this.$nextTick(() => {
        //this.initDrug(this.ChartData.name,true)
        this.changeEchart()
      })
    },
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {},
}
</script>
<style scoped>
.noData {
  text-align: center;
  margin: 40px auto;
}
.noData img {
  width: 53px;
  height: auto;
  margin-top: 40px;
}
.noData p {
  text-align: center;
  font-weight: bold;
  color: dimgray;

  line-height: 8px;
  color: #b1bcc3;
  font-size: 12px;
  margin-top: 7px;
  padding-bottom: 40px;
}
</style>
