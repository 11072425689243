import axios from "../../http"
import types from "@/type"
export const mixin = {
  data() {
    return {}
  },
  mounted() {
    // console.log(this.$route.query.backfill, 'PPPPPPP', this.filterForm)
    if (this.$route.query.backfill) {
      this.$nextTick(() => {
        // 确保在 DOM 更新后执行
        this.backfillToRouter()
      })
    }
  },
  watch: {
    // '$route.query.backfill'(newVal) {
    //   if (newVal) {
    //     this.$nextTick(() => {
    //       // 确保在 DOM 更新后执行
    //       this.backfillToRouter();
    //     });
    //   }
    // },
  },
  methods: {
    async recommendedWords(url, word, callback, type) {
      if (!url) return
      try {
        const { data } = await axios.post(url, { type, word })
        type === 115 && data.forEach((obj) => (obj.name = JSON.parse(obj.name)))
        callback(data)
      } catch (error) {
        console.error("An error occurred:", error)
      }
    },
    async backfillToRouter() {
      // console.log(this.$route.query.backfill, '：：：：打印出来的数据', this.$refs.childRef)
      let _type = this.setValueTo(JSON.parse(this.$route.query.backfill))
      let _list = JSON.parse(this.$route.query.backfill)
      this.updateFilterForm(_type, _list)
      this.$store.commit(types.MUTATION_EVENT, { type: "search" })
    },
    /**
     * 
     * @param {*类型} types 
     * @param {*选中数据} item 
     */
    updateFilterForm(types, item) {

      // 定义一个映射对象，用于简化不同类型的处理逻辑
      const typeHandlers = {
        'Enterprise': {
          paramName: ['name'],
          pushToArray: 'id',
          isAccurate: true
        },
        'Disease': {
          paramName: ['indicationName'],
          pushToArray: 'indicationList',
          isAccurate: true
        },
        'Target': {
          paramName: ['targetName'],
          // 对于 Target 类型，需要特殊处理 name 的值
          getEchoValue: (itemName) => [JSON.parse(itemName).standardName],
          pushToArray: 'targetCombination',
          isAccurate: true
        },
        'CommonName': {
          paramName: ['drugName', 'name', 'varietyName'],
          // 对于 CommonName 类型，需要设置特定的对象结构
          setParams: (itemId) => ({ genericIdArray: [itemId] }),
          isAccurate: true
        },
        // 其他类型只需要简单赋值，可以共用一个处理器
        'ClinicalNum': 'name',
        'ApprovalNo': 'name',
        'NDCCode': 'name',
        'AcceptedNo': 'name',
        'RegistrationNo': 'name',
        'NumberOr': 'name'
      };

      // 处理 formItem 的函数
      const processFormItem = (formItem) => {
        if (Array.isArray(formItem)) {
          formItem.forEach(item => processFormItem(item)); // 递归处理数组中的每个项
          this.$forceUpdate();
          return;
        }

        if (formItem.rst === types) {
          const handler = typeHandlers[item.type];
          if (handler) {
            // 如果类型是对象，则执行特定的逻辑
            if (typeof handler === 'object') {
              if (item.id) {
                if (handler.pushToArray) {
                  if (formItem.value.params[handler.pushToArray] == null) {
                    // 初始化为空数组
                    formItem.value.params[handler.pushToArray] = [];
                  }
                  if (!Array.isArray(formItem.value.params[handler.pushToArray])) {
                    // 如果不是数组，则直接赋值为 item.id
                    formItem.value.params[handler.pushToArray] = item.id;
                  } else {
                    // 如果是数组，则执行 push 操作
                    formItem.value.params[handler.pushToArray].push(item.id);
                  }
                }
                if (handler.setParams) {
                  formItem.value.params.drugList = handler.setParams(item.id);
                }
                formItem.value.isAccurate = handler.isAccurate;
              } else {
                // 确保 formItem.value.params 是一个对象
                if (typeof formItem.value.params !== 'object' || formItem.value.params === null) {
                  formItem.value.params = {};
                }
                // 检查 handler.paramName 是否是数组
                if (Array.isArray(handler.paramName)) {
                  // 遍历数组中的每一个值
                  for (let param of handler.paramName) {
                    if (formItem.key == 'varietyName') {
                      formItem.value.params.name = item.name
                      break
                    }
                    if (param in formItem.value.params) {
                      // 如果某个属性存在于 formItem.value.params 中，进行赋值操作
                      formItem.value.params[param] = item.name;
                      break; // 找到第一个匹配的属性后退出循环
                    } else {
                      formItem.value.params = item.name;
                      break;
                    }
                  }
                } else {
                  // 如果 handler.paramName 不是数组，使用之前的逻辑
                  if (handler.paramName in formItem.value.params) {
                    formItem.value.params[handler.paramName] = item.name;
                  } else {
                    formItem.value.params = item.name;
                  }
                }
                formItem.value.isAccurate = !handler.isAccurate;
              }

              formItem.value.echo = handler.getEchoValue ? handler.getEchoValue(item.name) : [item.name];
            } else {
              // 对于只需要简单赋值的类型
              formItem.value.params.name ? formItem.value.params.name = item.name : formItem.value.params = item.name;
              formItem.value.echo = [item.name];
            }
          } else {
            // 处理未知类型
            console.warn(`Unknown type: ${item.type}`);
          }
        }
      };
      // 遍历 filterForm 中的每个组
      this.filterForm.forEach(group => {
        // 遍历组内的 formItem
        group.formItem.forEach(formItem => processFormItem(formItem));
      });
    },
    setValueTo(item) {
      // console.log(this.$refs, '11111111')
      const name = item.type
      // 检查 this.$refs.childRef 是否存在且不为空
      if (this.$refs.childRef && this.$refs.childRef.length > 0) {
        // 遍历 childRef 数组
        for (const childRef of this.$refs.childRef) {
          // 检查 childRef.$refs[name] 是否存在
          if (childRef.$refs && childRef.$refs[name]) {
            // 如果 childRef.$refs[name] 是数组，调用第一个元素的 setValue 方法
            if (Array.isArray(childRef.$refs[name])) {
              childRef.$refs[name][0].setValue(item);
            } else {
              // 如果 childRef.$refs[name] 不是数组，直接调用 setValue 方法
              childRef.$refs[name].setValue(item);
            }
            return name; // 找到并设置后直接返回
          }
        }
      }
      // 如果 this.$refs.childRef 不存在，检查 this.$refs[name] 是否存在且不为空
      if (this.$refs[name]) {
        // 如果 this.$refs[name] 是数组，调用第一个元素的 setValue 方法
        if (Array.isArray(this.$refs[name])) {
          this.$refs[name][0].setValue(item);
        } else {
          // 如果 this.$refs[name] 不是数组，直接调用 setValue 方法
          this.$refs[name].setValue(item);
        }
        return name;
      }

      // 如果没有找到有效的引用，输出错误信息
      console.error(`No valid ref found for ${name} to call setValue`);
      return name;
    }
  }
}
