import types from "../../../type"
import axios from "../../../../http"
import ajaxApi from "../../../../config/request"
let state = {
  name: "vuex首页",
  coreData: {}, //核心数据
  industryNews: {}, //行业信息
  recommendedWords: [], //推荐词
  hotWord: [], //
  salesStatisticsYear: 2023, //获取年份
}
let actions = {
  /**
   * 异步修改用法
   *addBooks:function(){
       this.$store.dispatch('ACTIONS_HOME')
     },
   *
   */

  [types.ACTIONS_GET_HOME_RECOMMENDEDWORDS]: ({ commit, state }, val) => {
    let url = ajaxApi.Home.RecommendedWords + `?type=${val.type}&word=${val.word}`
    let params = {}
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          return res.data
        } else {
          return res.message
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  [types.ACTIONS_GET_HOME_COREDATA]: ({ commit, state }) => {
    let url = ajaxApi.Home.CoreData
    let params = {}
    axios
      .post(url, params)
      .then((res) => {
        commit(types.MUTATION_SET_HOME_COREDATA, res.data)
      })
      .catch((e) => {
        return e
      })
  },
  [types.ACTIONS_GET_HOME_INDUSTRYNEWS]: ({ commit, state }) => {
    let url = ajaxApi.Home.IndustryNews
    let params = {}
    axios
      .post(url, params)
      .then((res) => {
        commit(types.MUTATION_SET_HOME_INDUSTRYNEWS, res.data)
      })
      .catch((e) => {
        return e
      })
  },
  [types.ACTIONS_GET_HOME_HOTWORD]: ({ commit, state }) => {
    let url = ajaxApi.Home.HotWord
    let params = {}
    axios
      .post(url, params)
      .then((res) => {
        commit(types.MUTATION_SET_HOME_HOTWORD, res.data)
      })
      .catch((e) => {
        return e
      })
  },

  [types.ACTIONS_GET_HOME_HOTWORD]: ({ commit, state }) => {
    let url = ajaxApi.Home.HotWord
    let params = {}
    axios
      .post(url, params)
      .then((res) => {
        commit(types.MUTATION_SET_HOME_HOTWORD, res.data)
      })
      .catch((e) => {
        return e
      })
  },
  //获取年份
  [types.ACTIONS_GET_HOME_SALESSTATISTICSYEAR]: ({ commit, state }) => {
    let url = ajaxApi.Home.SalesStatisticsYear
    let params = {}
    axios
      .get(url, params)
      .then((res) => {
        if (res.code === 200) {
          state.salesStatisticsYear = res.data
          return Promise.resolve()
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return e
      })
  },
  [types.ACTIONS_GET_HOME_GETHOTLIST]: ({ commit, state }, val) => {
    let url = ajaxApi.AnalysisKeyword.GetHotList
    return axios
      .post(url, val)
      .then((res) => {
        if (res.code === 200) {
          return res.data
        } else {
          return res.message
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  [types.ACTIONS_GET_HOME_GETLIST]: ({ commit, state }, val) => {
    let url = ajaxApi.AnalysisKeyword.GetList
    return axios
      .post(url, val)
      .then((res) => {
        if (res.code === 200) {
          return res.data
        } else {
          return res.message
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  [types.ACTIONS_GET_HOME_GETSEARCHDATA]: ({ commit, state }, val) => {
    let url = ajaxApi.AnalysisKeyword.GetSearchData
    return axios
      .post(url, val)
      .then((res) => {
        if (res.code === 200) {
          return res.data
        } else {
          return res.message
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
}
let getters = {
  /**
     * 读取属性用法
     * methods:{
         name:function(){
      return this.store.getters.GETTERS_GLOBAL_HOME();
    },
   }
     */
  [types.GETTERS_HOME_COREDATA]: (state) => {
    return state.coreData
  },
  [types.GETTERS_HOME_INDUSTRYNEWS]: (state) => {
    return state.industryNews
  },
  [types.GETTERS_HOME_HOTWORD]: (state) => {
    return state.hotWord
  },
  [types.GETTERS_SALESSTATISTICSYEAR]: (state) => {
    return state.salesStatisticsYear
  },
}
let mutations = {
  /**
   * 同步修改属性用法
   *this.$store.commit('MUTATION_GLOBAL_HOME','跟的参数')
   */
  [types.MUTATION_SET_HOME_COREDATA]: (state, payload) => {
    state.coreData = payload
  },
  [types.MUTATION_SET_HOME_INDUSTRYNEWS]: (state, payload) => {
    state.industryNews = payload
  },
  [types.MUTATION_SET_HOME_HOTWORD]: (state, payload) => {
    state.hotWord = payload
  },
}
export default {
  module: "Home",
  state,
  actions,
  getters,
  mutations,
}
