//接口映射
const ajaxApi = {
  //首页
  Home: {
    CoreData: "/api/Home/CoreData", //核心数据列表
    IndustryNews: "/api/Home/IndustryNews", //行业最新动态
    CommonName: "/api/Home/CommonName", //获取通用名列表
    Disease: "/api/Home/Disease", //获取疾病列表
    Phase: "/api/Home/Phase", //获取阶段列表
    Target: "/api/Home/Target", //获取靶点列表
    Company: "/api/Home/Company", //获取公司列表
    Country: "/api/Home/Country", //获取国家列表
    DrugForm: "/api/Home/DrugForm", //获取阶段列表
    DrugType: "/api/Home/DrugType", //获取药品分类
    BiologicalDrugClassification: "/api/Home/BiologicalDrugClassification", //获取生物药分类
    TreatmentType: "/api/Home/TreatmentType", //获取阶段列表
    DrugFormJointCommonName: "/api/Home/DrugFormJointCommonName", //获取通用名药品剂型（产品名称）
    RecommendedWords: "/api/Home/RecommendedWords", //获取推荐词
    HotWord: "/api/Home/HotWord", //热词
    SalesStatisticsYear: "/api/DictItem/SalesStatisticsYear", //获取年份
  },
  //一键检索
  AnalysisKeyword: {
    GetHotList: '/api/analysisKeyword/GetHotList',//获取热门词汇
    GetList: '/api/analysisKeyword/GetList',//获取枚举数据
    GetSearchData: '/api/analysisKeyword/GetSearchData',//获取搜索列表
  },
  //靶点
  Target: {
    TargetType: "/api/Target/TargetType", //靶点类型
    TargetDataTable: "/api/Target/TargetDataTable", //靶点数据列表
    DrugAnalysis: "/api/Target/DrugAnalysis", //获取药物分析
    SalesAnalysis: "/api/Target/SalesAnalysis", //获取销售分析
    SalesCompany: "/api/Target/SalesCompany", //销售分析按通用名获取Top10公司
    DiseaseAnalysis: "/api/Target/DiseaseAnalysis", //疾病分析
    CompanyAnalysis: "/api/Target/CompanyAnalysis", //公司分析
    RdMap: "/api/Target/RdMap", //研发地图
    DiseaseAtlas: "/api/Target/DiseaseAtlas", //疾病图谱
    RDAnalysis: "/api/Target/RDAnalysis", //研发获批分析
    SalesAndForecast: "/api/Target/SalesAndForecast", //销售总额分析与预测
    PotentialMarket: "/api/Target/PotentialMarket", //市场潜力分析
    CompanySales: "/api/Target/CompanySales", //公司实力
    ReplaceRiskAnalysis: "/api/Target/ReplaceRiskAnalysis", //代替风险
    DiseaseByTarget: "/api/Target/DiseaseByTarget", //根据同靶点获取相关的疾病
    CommonNameByTarget: "/api/Target/CommonNameByTarget", //根据同靶点获取相关的通用名
    CommonName: "/api/DictItem/ReplaceRiskAnalysisCommonName", //获取通用名列表
    Disease: "/api/Target/Disease", //获取疾病列表
    Phase: "/api/Target/Phase", //获取阶段列表
    Target: "/api/Target/Target", //获取靶点列表
    Company: "/api/Target/Company", //获取公司列表
    Country: "/api/Target/Country", //获取国家列表
    DrugForm: "/api/Target/DrugForm", //获取阶段列表
    DrugType: "/api/Target/DrugType", //获取药品分类
    BiologicalDrugClassification: "/api/Target/BiologicalDrugClassification", //获取生物分类
    TreatmentType: "/api/Target/TreatmentType", //获取阶段列表
    DrugFormJointCommonName: "/api/Target/DrugFormJointCommonName", //获通用名获取药品剂型（产品名称）
    DownloadTargetDataTable: "/api/Target/DownloadTargetDataTable", //数据表下载
    Clinical: "/api/Target/Clinical", //获取临床信息
    DrugComparisonAnalysis: "/api/Target/DrugComparisonAnalysis", //获取药物对比分析
    DownloadCreateTokenClinical: "/api/Target/DownloadCreateTokenClinical", //临床试验分析
    RecommendedWords: "/api/Target/RecommendedWords", //获取靶点枚举列表
    TargetTypeById: "/api/Target/TargetTypeById?id=", //根据ID获取靶点树
    TargetTypeNew: "/api/Target/TargetTypeNew", //获取靶点树
  },
  //疾病
  Disease: {
    DiseaseType: "/api/Disease/DiseaseType", //疾病类型
    DiseaseDataTable: "/api/Disease/DiseaseDataTable ", //疾病数据列表
    DrugAnalysis: "/api/Disease/DrugAnalysis", //药物分析
    SalesAnalysis: "/api/Disease/SalesAnalysis", //销售分析
    SalesCompany: "/api/Disease/SalesCompany", //销售分析按通用名获取Top10公司
    TargetAnalysis: "/api/Disease/TargetAnalysis", //靶点分析
    CompanyAnalysis: "/api/Disease/CompanyAnalysis", //公司分析
    RdMap: "/api/Disease/RdMap", //研发地图
    RDAnalysis: "/api/Disease/RDAnalysis", //研发获批分析
    Clinical: "/api/Disease/Clinical", //获取临床信息
    SalesAndForecast: "/api/Disease/SalesAndForecast", //销售总额分析与预测
    PotentialMarket: "/api/Disease/PotentialMarket", //市场潜力分析
    CompanySales: "/api/Disease/CompanySales", //公司实力
    ReplaceRiskAnalysis: "/api/Disease/ReplaceRiskAnalysis", //代替风险
    CommonNameByDisease: "/api/Disease/CommonNameByDisease", //根据同疾病获取相关的通用名
    CommonName: "/api/DictItem/ReplaceRiskAnalysisCommonName", //根据同疾病获取相关的通用名
    DepartmentAnalysis: "/api/Disease/DepartmentAnalysis", //科室分布
    Disease: "/api/Disease/Disease", //获取疾病列表
    Phase: "/api/Disease/Phase", //获取阶段列表
    Target: "/api/Disease/Target", //获取靶点列表
    Company: "/api/Disease/Company", //获取公司列表
    Country: "/api/Disease/Country", //获取国家列表
    DrugForm: "/api/Disease/DrugForm", //获取阶段列表
    DrugType: "/api/Disease/DrugType", //获取阶段列表
    BiologicalDrugClassification: "/api/Disease/BiologicalDrugClassification", //获取生物药分类
    TreatmentType: "/api/Disease/TreatmentType", //获取阶段列表
    DrugFormJointCommonName: "/api/Disease/DrugFormJointCommonName", //获通用名获取药品剂型（产品名称）
    DownloadDiseaseDataTable: "/api/Disease/DownloadDiseaseDataTable", //数据表下载
    DrugComparisonAnalysis: "/api/Disease/DrugComparisonAnalysis", //药物对比分析
    DownloadCreateTokenClinical: "/api/Disease/DownloadCreateTokenClinical", //临床试验分析
    DownloadSalesAndForecast: "/api/Disease/DownloadSalesAndForecast", //药品销售总额分析
    DownloadCompanySales: "/api/Disease/DownloadCompanySales", //公司实力分析
    DownloadPotentialMarket: "/api/Disease/DownloadPotentialMarket", //销售市场分析下载
    RecommendedWords: "/api/DictItem/RecommendedWords",
    DiseaseTypeById: "/api/Disease/DiseaseTypeById?id=",
    DiseaseTypeNew: "/api/Disease/DiseaseTypeNew",
  },
  //药品
  Drug: {
    DurgAnalysis: "/api/Drug/DurgAnalysis", //药品分析
    EnterpriseAnalysis: "/api/Drug/EnterpriseAnalysis", //公司分析
    DomesticSalesAnalysis: "/api/Drug/DomesticSalesAnalysis", //中国药物销售分析
    GlobalSalesAnalysis: "/api/Drug/GlobalSalesAnalysis", //全国药品销售分析
    EnterpriseSalesAnalysis: "/api/Drug/EnterpriseSalesAnalysis", //药品销售分析使用通用名查企业
    DiseaseAnalysis: "/api/Drug/DiseaseAnalysis", //疾病阶段分析
    TargetAnalysis: "/api/Drug/TargetAnalysis", //全球药物靶点阶段分析
    CountryRDMapAnalysis: "/api/Drug/CountryRDMapAnalysis", //全球药物全国研究分析
    DrugRDAnalysis: "/api/Drug/DrugRDAnalysis", //药品研发获取分析
    DrugDetail: "/api/Drug/DrugDetail", //药品详情
    RDAnalysis: "/api/Drug/RDAnalysis", //研发获批分析
    SalesAndForecast: "/api/Drug/SalesAndForecast", //销售总额分析与预测
    PotentialMarket: "/api/Drug/PotentialMarket", //市场潜力分析
    CompanySales: "/api/Drug/CompanySales", //公司实力
    Clinical: "/api/Drug/Clinical", //获取临床信息
    DrugFormSales: "/api/Drug/DrugFormSales", //剂型实力
    ReplaceRiskAnalysis: "/api/Drug/ReplaceRiskAnalysis", //代替风险
    DepartmentAnalysis: "/api/Drug/DepartmentAnalysis", //科室分布
    PatientFlow: "/api/Drug/PatientFlow", //患者流
    AdverseReactions: "/api/Drug/AdverseReactions", //不良反应报告
    Disease: "/api/Drug/Disease", //获取疾病列表
    Phase: "/api/Drug/Phase", //获取阶段列表
    Target: "/api/Drug/Target", //获取靶点列表
    Company: "/api/Drug/Company", //获取公司列表
    DrugForm: "/api/Drug/DrugForm", //获取剂型列表
    DownloadSalesAndForecast: "/api/Drug/DownloadSalesAndForecast", //药品下载获取key
    DownloadSalesAndForecastHyperlink: "/api/Drug/DownloadSalesAndForecastHyperlink", //药品下载
    DownloadCompanySales: "/api/Drug/DownloadCompanySales", //公司下载获取key
    DownloadCompanySalesHyperlink: "/api/Drug/DownloadCompanySalesHyperlink", //公司下载
    Country: "/api/Drug/Country", //获取估计列表
    DrugFormJointCommonName: "/api/Drug/DrugFormJointCommonName", //获通用名获取药品剂型（产品名称）
    DrugType: "/api/Drug/DrugType", //获取药品分类
    BiologicalDrugClassification: "/api/Drug/BiologicalDrugClassification", //获取生物药分类
    TreatmentType: "/api/Drug/TreatmentType", //获取阶段列表
    DownloadDrugDataTable: "/api/Drug/DownloadDrugDataTable", //数据表下载
    DownloadCreateTokenAdverseReactions: "/api/Drug/DownloadCreateTokenAdverseReactions", //不良反应的下载
    DownloadDrugFormSales: "/api/Drug/DownloadDrugFormSales", //剂型分析的下载
    DownloadCreateTokenClinical: "/api/Drug/DownloadCreateTokenClinical", //临床试验分析
    DownloadRDAnalysis: "/api/Drug/DownloadRDAnalysis", //研发获批分析下载
  },
  //公司
  Enterprise: {
    DrugType: "/api/Enterprise/DrugType", //获取药品分类
    BiologicalDrugClassification: "/api/Enterprise/BiologicalDrugClassification", //获取生物药分类
    TreatmentType: "/api/Enterprise/TreatmentType", //获取阶段列表
    DurgAnalysis: "/api/Enterprise/DurgAnalysis", //药品分析
    DomesticSalesAnalysis: "/api/Enterprise/DomesticSalesAnalysis", //中国药物销售分析
    GlobalSalesAnalysis: "/api/Enterprise/GlobalSalesAnalysis", //全球药物销售分析
    DiseaseAnalysis: "/api/Enterprise/DiseaseAnalysis", //全球疾病阶段分析
    TargetAnalysis: "/api/Enterprise/TargetAnalysis", //全球药物靶点阶段分析
    CountryRDMapAnalysis: "/api/Enterprise/CountryRDMapAnalysis", //全球药物全国研究分析
    DrugRDAnalysis: "/api/Enterprise/DrugRDAnalysis", //药品研发获批分析列表
    EnterpriseDetail: "/api/Enterprise/EnterpriseDetail", //公司详情
    EnterpriseRelation: "/api/Enterprise/EnterpriseRelation", //公司关联
    EnterpriseFinancialReport: "/api/Enterprise/EnterpriseFinancialReport", //公司财务指标
    EnterpriseFinancialReportYearly: "/api/Enterprise/EnterpriseFinancialReportYearly", //公司财务指标年度
    EnterpriseProductStructure: "/api/Enterprise/EnterpriseProductStructure", //公司产品结构
    EnterpriseAreaStructure: "/api/Enterprise/EnterpriseAreaStructure", //领域分布结构
    Clinical: "/api/Enterprise/Clinical", //获取临床信息
    RDAnalysis: "/api/Enterprise/RDAnalysis", //研发获批分析
    DownloadSalesAndForecast: "/api/Disease/DownloadSalesAndForecast", //药品下载获取key
    DownloadSalesAndForecastHyperlink: "/api/Disease/DownloadSalesAndForecastHyperlink", //药品下载
    DownloadCompanySales: "/api/Disease/DownloadCompanySales", //公司下载获取key
    DownloadCompanySalesHyperlink: "/api/Disease/DownloadCompanySalesHyperlink", //公司下载
    CompanySales: "/api/Enterprise/CompanySales", //公司实力（中国/全球销售分析）
    CompanySalesPercentage: "/api/Enterprise/CompanySalesPercentage", //公司销售占比
    CompanyYield: "/api/Enterprise/CompanyYield", //公司产量占比
    CompanyEvent: "/api/Enterprise/CompanyEvent", //公司大事记
    CompanyStock: "/api/Enterprise/CompanyStock", //公司股票月线
    Disease: "/api/Enterprise/Disease", //获取疾病列表
    Phase: "/api/Enterprise/Phase", //获取阶段列表
    Target: "/api/Enterprise/Target", //获取靶点列表
    Company: "/api/Enterprise/Company", //获取公司列表
    Country: "/api/Enterprise/Country", //获取国家列表
    DrugForm: "/api/Enterprise/DrugForm", //获取阶段列表
    CommonName: "/api/DictItem/ReplaceRiskAnalysisCommonName", //获取药物列表
    EnterpriseCommonName: "/api/Enterprise/CommonName", //获取药物列表
    EnterpriseAnnualReportTag: "/api/Enterprise/EnterpriseAnnualReportTag", //获取企业年报
    DownloadEnterpriseDataTable: "/api/Enterprise/DownloadEnterpriseDataTable", //数据表下载
    DownloadCreateTokenClinical: "/api/Enterprise/DownloadCreateTokenClinical", //临床试验分析
  },
  // 申报
  Declare: {
    RecommendedWord: "/api/DictItem/RecommendedWords", //检索条件枚举字典
    DeclareCategory: "/api/DictItem/DeclareCategory",
    GetAssessReason: "/api/DictItem/PriorityReasons", //获取优先审评理由
    GetUrgentReasons: "/api/DictItem/ClinicalUrgentReasons", //临床急需品种理由
    GetRegisterClassification: "/api/DictItem/RegistrationTypeDictTree", //获取注册分类二级联动数据
    GetAdministrationRoute: "/api/DictItem/AdministrationRoutes", //获取给药途径数据
    GetdosageData: "/api/DictItem/DrugFormDictLevel", //获取剂型数据
    GetTherapeuticFieldPHIIC: "/api/DictItem/TreatmentTypeDictTree", //治疗领域PHIIC二级联动数据接口
    GetTherapeuticFieldAtc: "/api/DictItem/AtcTypeDictTree", //治疗领域ATC多级联动数据接口
    GetTechnologyLabel: "/api/DictItem/HotLabel", //获取热门标签数据
    QueryTableData: "/api/ResearchDevelopment/ResearchDevelopment", //查询表格数据
    QueryTableDataDownload: "/api/ResearchDevelopment/DownloadResearchDevelopmentDataTable", //下载表格数据
    GetBasicData: "/api/ResearchDevelopment/GetDetail", //基础信息获取
    GetTimeLine: "/api/ResearchDevelopment/GetTimeline", //受理时光轴获取
    ResearchDevelopmentSameVariety: "/api/ResearchDevelopment/ResearchDevelopmentSameVariety", //数字弹出框的表格
    DownloadResearchDevelopmentSameVarietyDataTable: "/api/ResearchDevelopment/DownloadResearchDevelopmentSameVarietyDataTable", //数字弹出框的表格下载
  },
  // 上市
  Product: {
    GetLicenseNos: "/api/product/GetLicenseNos", //批准文号
    GetPatentNos: "/api/product/GetPatentNos", //专利号
    GetAllHotLabel: "/api/product/GetAllHotLabel", //热门标签
    HospitalDepartment: "/api/product/HospitalDepartment", //处方科室
    GetDetailData: "/api/Market/GetTableList", //表格数据的获取
    DownloadGetDetailData: "/api/Market/DownloadDataTable", //表格数据的下载
    CheckDownloadFile: "/api/Market/CheckDownloadFile", //文件下载的判断
    DownloadFile: "/api/Market/DownloadFile", //文件下载
    GetTotalNumber: "/api/Market/GetTotalNumber", //获取全部结果数量
    GetPartTotalNumber: "/api/Market/GetPartTotalNumber", //获取筛选的各部分结果数量
    DownloadSameApprovalNumber: "/api/Product/DownloadSameApprovalNumber", //同品种批文数列表下载
    DownloadSameEnterprisesNumber: "/api/Product/DownloadSameEnterprisesNumber", //同品种企业数列表下载
    DownloadSameEvaluationEnterprisesNumber: "/api/Product/DownloadSameEvaluationEnterprisesNumber", //通过一致性评价企业数列表下载
    SamePatentNumber: "/api/Product/SamePatentNumber", //登记专利数列表
    DownloadSamePatentNumber: "/api/Product/DownloadSamePatentNumber", //登记专利数列表下载
    PulldownCategory: "/api/Product/PulldownCategory", //下拉框选择项
    MedicalInsurance: "/api/Product/MedicalInsurance", //医保目录
    PurchaseVarieties: "/api/Product/PurchaseVarieties", //带量采购品种
    CountingBulletBox: "/api/Product/CountingBulletBox", //列表数字点击展示数据
    GetDrugNameData: "/api/Product/GetDrugNameData", //药品名称枚举
    GetCompanyData: "/api/Product/GetCompanyData", //公司/持证商多选列表
    AdministrationRoutes: "/api/Product/AdministrationRoutes", // 给药途径数据获取
    DrugFormDictLevel: "/api/Product/DrugFormDictLevel", //剂型数据的获取
    GetDrugTtype: "/api/Product/DrugType", //药品类型
    SimplifyConsistencyEvaluation: "/api/Product/GetBEListType", //豁免或简化一致性评价品种
    RecommendedWords: "/api/Product/RecommendedWords", //检索条件枚举字典
    ShowCountOpenData: "api/Market/ShowCountOpenData", //数字弹框接口
    AutoComplete: "api/Product/AutoComplete", // 下拉框联想查询
  },
  //上市新版
  Market: {
    GetTableList: "/api/Market/GetTableList", // 查询列表
    ShowCountOpenData: "/api/Market/ShowCountOpenData", //数字弹框接口
    RecommendedWords: "/api/Market/RecommendedWords", //检索条件枚举字典
    GetFilterOptions: "/api/Market/GetFilterOptions", //筛选条件下拉框
    DownloadTableData: "/api/Market/DownloadDataTable", // 表格数据的下载
    CheckDownloadFile: "/api/Market/CheckDownloadFile", // 文件下载的判断
    GetTotalNumber: "/api/Market/GetTotalNumber", //获取全部结果数量
    GetBEListType: "/api/Market/GetBEListType ", //获取豁免或简化一致性评价品种
  },
  // 临床
  Clinical: {
    GetTableList: "/api/Clinical/GetTableList", // 查询列表
    ShowCountOpenData: "/api/Clinical/ShowCountOpenData", //数字弹框接口
    RecommendedWords: "/api/Clinical/RecommendedWords", //检索条件枚举字典
    GetFilterOptions: "/api/Clinical/GetFilterOptions", //筛选条件下拉框
    GetAllViewDataCount: "/api/Clinical/GetAllViewDataCount", // tab上的数字
    GetBasicInfo: "/api/Clinical/GetBasicInfo", // 详情页-基本信息
    GetTimeAxis: "/api/Clinical/GetTimeAxis", // 详情页- 时光轴
    GetDesignGroup: "/api/Clinical/GetDesignGroup", // 详情页-试验设计分组
    GetTrialIntroduction: "/api/Clinical/GetTrialIntroduction", // 详情页-试验介绍
    GetEndTarget: "/api/Clinical/GetEndTarget", // 详情页-终点指标
    GetSubjectStandard: "/api/Clinical/GetSubjectStandard", // 详情页-受试者标准
    GetParticipateInstitution: "/api/Clinical/GetParticipateInstitution", // 详情页-参与机构
    GetArticle: "/api/Clinical/GetArticle", // 详情页-文献
    DownloadTableData: "/api/Clinical/DownloadTableData", // 表格数据的下载
    CheckDownloadFile: "/api/Clinical/CheckDownloadFile", // 文件下载的判断
    DownloadFile: "/api/Clinical/DownloadFile", // 文件下载
  },
  // 医保目录
  MedicareCatalog: {
    GetTableList: "/api/MedicareCatalog/GetTableList", // 查询列表
    MedicalInsuranceLevel: "/api/MedicareCatalog/MedicalInsuranceLevel", // 查询医保层级
    ShowCountOpenData: "/api/MedicareCatalog/getModalTableByCellclick", //数字弹框接口
    RecommendedWords: "/api/MedicareCatalog/RecommendedWords", //检索条件枚举字典
    GetFilterOptions: "/api/MedicareCatalog/GetFilterOptions", //筛选条件下拉框
    GetTimeAxis: "/api/MedicareCatalog/getModalTimelineByCellClick", // 详情页- 时光轴
    DownloadTableData: "/api/MedicareCatalog/DownloadDataTable", // 表格数据的下载
    CheckDownloadFile: "/api/MedicareCatalog/CheckDownloadFile", // 文件下载的判断
    DownloadFile: "/api/MedicareCatalog/DownloadFile", // 文件下载
  },
  // 基药目录
  EssentialDrug: {
    GetTableList: "/api/EssentialDrug/GetTableList", // 查询列表
    EssentialDrugLevel: "/api/EssentialDrug/EssentialDrugLevel", // 查询医保层级
    ShowCountOpenData: "/api/EssentialDrug/getModalTableByCellclick", //数字弹框接口
    RecommendedWords: "/api/EssentialDrug/RecommendedWords", //检索条件枚举字典
    GetFilterOptions: "/api/EssentialDrug/GetFilterOptions", //筛选条件下拉框
    GetTimeAxis: "/api/EssentialDrug/getModalTimelineByCellClick", // 详情页- 时光轴
    DownloadTableData: "/api/EssentialDrug/DownloadDataTable", // 表格数据的下载
    CheckDownloadFile: "/api/EssentialDrug/CheckDownloadFile", // 文件下载的判断
    DownloadFile: "/api/EssentialDrug/DownloadFile", // 文件下载
  },
  // 全球新药
  GlobalNewDrug: {
    GetTableList: "/api/GlobalNewDrug/GetTableList", // 查询列表
    EssentialDrugLevel: "/api/GlobalNewDrug/EssentialDrugLevel", // 查询医保层级
    ShowCountOpenData: "/api/GlobalNewDrug/getModalTableByCellclick", //数字弹框接口
    RecommendedWords: "/api/GlobalNewDrug/RecommendedWords", //检索条件枚举字典
    GetFilterOptions: "/api/GlobalNewDrug/GetFilterOptions", //筛选条件下拉框
    GetTimeAxis: "/api/GlobalNewDrug/getModalTimelineByCellClick", // 详情页- 时光轴
    DownloadTableData: "/api/GlobalNewDrug/DownloadTableData", // 表格数据的下载
    CheckDownloadFile: "/api/GlobalNewDrug/CheckDownloadFile", // 文件下载的判断
    DownloadFile: "/api/GlobalNewDrug/DownloadFile", // 文件下载
    getDetailBaseInfo: "/api/GlobalNewDrug/baseInfo",
    getDetailProgress: "/api/GlobalNewDrug/progress",
    getDetailEvent: "/api/GlobalNewDrug/event",
    getDetailCircle: "/api/GlobalNewDrug/clinicalTrials",
    getDetailIntroduce: "/api/GlobalNewDrug/detail",
    getTranslateArray: "/api/GlobalNewDrug/TranslateContent", // 详情翻译
  },
  // 原辅包
  Material: {
    GetTableList: "/api/RawMaterial/GetTableList", // 查询列表
    ShowCountOpenData: "/api/RawMaterial/ShowCountOpenData", //数字弹框接口
    RecommendedWords: "/api/RawMaterial/RecommendedWords", //检索条件枚举字典
    GetFilterOptions: "/api/RawMaterial/GetFilterOptions", //筛选条件下拉框
    DownloadTableData: "/api/RawMaterial/DownloadTableData", // 表格数据的下载
    CheckDownloadFile: "/api/RawMaterial/CheckDownloadFile", // 文件下载的判断
  },
  // 参比制剂目录
  ReferListedDrug: {
    GetTableList: "/api/ReferenceListedDrug/GetTableList", // 查询列表
    RecommendedWords: "/api/ReferenceListedDrug/RecommendedWords", // 检索条件枚举字典
    GetFilterOptions: "/api/ReferenceListedDrug/GetFilterOptions", // 筛选条件下拉框
    DownloadTableData: "/api/ReferenceListedDrug/DownloadTableData", // 表格数据的下载
    CheckDownloadFile: "/api/ReferenceListedDrug/CheckDownloadFile", // 文件下载的判断
    GetNumberByBatch: "/api/ReferenceListedDrug/GetNumberByBatch", // 根据批次号查询编号
  },
  // 一致性评价
  Equivalence: {
    GetTableList: "/api/Equivalence/GetTableList", // 查询列表
    ShowCountOpenData: "/api/Equivalence/ShowCountOpenData", //数字弹框接口
    RecommendedWords: "/api/Equivalence/RecommendedWords", //检索条件枚举字典
    GetFilterOptions: "/api/Equivalence/GetFilterOptions", //筛选条件下拉框
    GetAllViewDataCount: "/api/Equivalence/GetAllViewDataCount", // tab上的数字
    DownloadTableData: "/api/Equivalence/DownloadTableData", // 表格数据的下载
  },
  //招标
  DrugBidding: {
    GetTableList: "/api/DrugBidding/GetTableList", // 查询列表
    ShowCountOpenData: "/api/DrugBidding/ShowCountOpenData", //数字弹框接口
    RecommendedWords: "/api/DrugBidding/RecommendedWords", //检索条件枚举字典
    GetFilterOptions: "/api/DrugBidding/GetFilterOptions", //筛选条件下拉框
    GetAllViewDataCount: "/api/DrugBidding/GetAllViewDataCount", // tab上的数字
    DownloadTableData: "/api/DrugBidding/DownloadDataTable", // 表格数据的下载
    BiologicDrugClassification: "/api/DrugBidding/BiologicDrugClassification",
    Tcm: "/api/DrugBidding/Tcm",
    TcmOfDisease: "/api/DrugBidding/TcmOfDisease",
  },
  //美国NDC
  AmericaNDC: {
    GetTableList: "/api/NationalDrugCode/GetTableList", // 查询列表
    RecommendedWords: "/api/NationalDrugCode/RecommendedWords", //检索条件枚举字典
    GetFilterOptions: "/api/NationalDrugCode/GetFilterOptions", //筛选条件下拉框
    DownloadTableData: "/api/NationalDrugCode/DownloadTableData", // 表格数据的下载
  },
  // 企业年报
  AnnualReports: {
    GetTableList: "/api/AnnualReports/GetTableList", // 查询列表
    RecommendedWords: "/api/AnnualReports/RecommendedWords", //检索条件枚举字典
    GetFilterOptions: "/api/AnnualReports/GetFilterOptions", //筛选条件下拉框
    DownloadTableData: "/api/AnnualReports/DownloadTableData", // 表格数据的下载
    CheckDownloadFile: "/api/AnnualReports/CheckDownloadFile", //文件下载的判断
    DownloadFile: "/api/AnnualReports/DownloadFile", //文件下载
  },
  //临床路径
  ClinicalPathway: {
    GetTableList: "/api/ClinicalPathway/GetTableList", // 查询列表
    GetFilterOptions: "/api/ClinicalPathway/GetFilterOptions", //筛选条件下拉框
    DownloadTableData: "/api/ClinicalPathway/DownloadTableData", // 表格数据的下载
    CheckDownloadFile: "/api/ClinicalPathway/CheckDownloadFile", //文件下载的判断
    DownloadFile: "/api/ClinicalPathway/DownloadFile", //文件下载
  },
  //临床路径
  TreatmentGuide: {
    GetTableList: "/api/TreatmentGuide/GetTableList", // 查询列表
    GetFilterOptions: "/api/TreatmentGuide/GetFilterOptions", //筛选条件下拉框
    DownloadTableData: "/api/TreatmentGuide/DownloadTableData", // 表格数据的下载
    CheckDownloadFile: "/api/TreatmentGuide/CheckDownloadFile", //文件下载的判断
    DownloadFile: "/api/TreatmentGuide/DownloadFile", //文件下载
  },
  //招标分析
  DrugBiddingAnalysis: {
    DrugBiddingAnalysis: "/api/drugBiddingAnalysis/analysis", // 查询分析图表
    GetAnalysisIndicators: "/api/drugBiddingAnalysis/getAnalysisIndicators", // 查询列表
    GetTableList: "/api/drugBiddingAnalysis/GetTableList", // 查询列表
    ShowCountOpenData: "/api/drugBiddingAnalysis/ShowCountOpenData", //数字弹框接口
    RecommendedWords: "/api/drugBiddingAnalysis/RecommendedWords", //检索条件枚举字典
    GetFilterOptions: "/api/drugBiddingAnalysis/GetFilterOptions", //筛选条件下拉框
    SecondSearchFilter: "/api/drugBiddingAnalysis/secondSearchFilter", //二次筛选条件下拉框
    GetAllViewDataCount: "/api/drugBiddingAnalysis/GetAllViewDataCount", // tab上的数字
    BiologicDrugClassification: "/api/drugBiddingAnalysis/BiologicDrugClassification",
    Tcm: "/api/drugBiddingAnalysis/Tcm",
    TcmOfDisease: "/api/drugBiddingAnalysis/TcmOfDisease",
    GetSpanCount: "/api/drugBiddingAnalysis/GetSpanCount",
    DownloadAnalysis: "/api/drugBiddingAnalysis/DownloadDrugBiddingAnalysisHyperlink", // 数据的下载
    GetDownloadKey: "/api/drugBiddingAnalysis/DownloadDataChart",
    GetIssuedYears: "/api/drugBiddingAnalysis/GetIssuedYears", // 获取已发布年份范围
  },
  //专利
  GlobalDrugPatents: {
    GetTableList: "/api/GlobalDrugPatents/GetTableList", // 查询列表
    BasicInformation: "/api/GlobalDrugPatents/BasicInformation", //数字弹框接口
    RecommendedWords: "/api/GlobalDrugPatents/RecommendedWords", //检索条件枚举字典
    GetFilterOptions: "/api/GlobalDrugPatents/GetFilterOptions", //筛选条件下拉框
    GetPatentsNos: "/api/GlobalDrugPatents/GetPatentsNos", //
    DownloadTableData: "/api/GlobalDrugPatents/DownloadTableData", // 表格数据的下载
    GetTimeLine: "/api/GlobalDrugPatents/GetTimeLine",
    SameFamilyReference: "/api/GlobalDrugPatents/SameFamilyReference",
    CountriesLegalStatus: "/api/GlobalDrugPatents/CountriesLegalStatus",
    LegalEvents: "/api/GlobalDrugPatents/LegalEvents",
    PatentFamily: "/api/GlobalDrugPatents/PatentFamily",
    SaveFamilyOrder: "/api/GlobalDrugPatents/SaveFamilyOrder",
    GlobalDrugPatentCount: "/api/GlobalDrugPatents/GlobalDrugPatentCount",
  },
  // 申报复用
  Registration: {
    GetTableList: "/api/ResearchDevelopment/GetTableList", // 查询列表
    ShowCountOpenData: "/api/ResearchDevelopment/ShowCountOpenData", //数字弹框接口
    RecommendedWords: "/api/ResearchDevelopment/RecommendedWords", //检索条件枚举字典
    GetFilterOptions: "/api/ResearchDevelopment/GetFilterOptions", //筛选条件下拉框
    GetAllViewDataCount: "/api/ResearchDevelopment/GetAllViewDataCount", // tab上的数字
    DownloadTableData: "/api/ResearchDevelopment/DownloadTableData", // 表格数据的下载
  },
  //个人中心
  Auth: {
    Login: "/api/auth/login",
    LoginWx: "/api/auth/loginWx",
    Logout: "/api/auth/logout",
    SendVerificationCodeByMobile: "/api/auth/sendVerificationCodeByMobile",
    SendVerificationCodeByMail: "/api/auth/sendVerificationCodeByMail",
    VerifyCodeForRegister: "/api/auth/verifyCodeForRegister",
    Register: "/api/auth/register",
    VerifyMailCodeForRetrievePwd: "/api/auth/verifyMailCodeForRetrievePwd",
    VerifyMobileCodeForRetrievePwd: "/api/auth/verifyMobileCodeForRetrievePwd",
    RetrievePwd: "/api/auth/retrievePwd",
    ChangePwd: "/api/auth/changePwd",
    Ping: "/api/auth/ping",
    IsUserOrAdmin: "/api/auth/is-user-or-admin",
    IsUser: "/api/auth/is-user",
    IsAdmin: "/api/auth/is-admin",
    IsCheckIp: "/api/auth/is-check-ip",
    UserProvince: "/api/auth/userProvince",
    SetLockTab: "/api/auth/setLockTab",
    loginWx: "/api/auth/loginWx", //微信的登录
    bindWx: "/api/auth/bindWx", //微信的绑定
    unbindWx: "/api/auth/unbindWx", //微信的解绑
  },
  //菜单
  Menu: {
    List: "/api/menu/List", //获取菜单权限列表
  },
  //场景
  One: {
    RecommendedWords: '/api/One/RecommendedWords',
    RisingTarget: "/api/One/RisingTarget", //获取新兴靶点研发热度
    RisingTargetTreatmentType: "/api/One/RisingTargetTreatmentType", //选择靶点类别的显示筛选列表
    ApprovedAreasMap: "/api/One/ApprovedAreasMap", //药品获批领域地图
    ExploreImitateDrug: "/api/One/ExploreImitateDrug", //挖掘仿药老品种的柱状图
    ExploreImitateDrugDataTable: "/api/One/ExploreImitateDrugDataTable", //挖掘仿药老品种的表格
    DrugFormTree: "/api/DictItem/DrugFormTree", //挖掘仿药老品种的表格
    CommonName: "/api/One/CommonName", //获取通用名列表
    DrugFormOfCommonName: "/api/One/DrugFormQuery", //获取剂型列表
    EnterpriseQuery: "/api/One/EnterpriseQuery", //获取公司列表
    GetContrastProductGroupList: "/api/One/GetContrastProductGroupList", //获取产品分组列表
    OffContrastProduct: "/api/One/OffContrastProduct", //删除产品
    RestoreContrastProduct: "/api/One/RestoreContrastProduct", //还原产品
    RemoveContrastProduct: "/api/One/RemoveContrastProduct", //永久删除产品
    GetContrastProduct: "/api/One/GetContrastProduct", //获取产品详细信息
    SaveContrastProduct: "/api/One/SaveContrastProduct", //产品的保存
    ProductContrastAnalysisDataTable: "/api/One/ProductContrastAnalysisDataTable", //基本信息对比的表格
    SalesContrast: "/api/One/SalesContrast", //销售额对比
    DepartmentContrast: "/api/One/DepartmentContrast", //科室分布
    DrugTreatmentTypeSales: "/api/One/DrugTreatmentTypeSales", //同小类销售额
    ReplaceRiskAnalysis: "/api/One/ReplaceRiskAnalysis", //同类药品
    ExploreImitateDrugForm: "/api/One/ExploreImitateDrugForm", //挖掘仿药老品种的表格
    CollectionAnalysisDataTable: "/api/One/CollectionAnalysisDataTable", //集采放量拉动分析的表格
    MedicalInsuranceAnalysisDataTable: "/api/One/MedicalInsuranceAnalysisDataTable", //集采放量拉动分析的表格
    RegisteredType: "/api/One/RegisteredType", //注册分类
    InnovateDrugDataTable: "/api/One/InnovateDrugDataTable", //国产创新药列表
    DiseaseOfInnovateDrug: "/api/One/DiseaseOfInnovateDrug", //获取中国最高阶段适应症列表
    InnovateDrugDetail: "/api/One/InnovateDrugDetail", //对标分析
    HotAreasRdPanorama: "/api/One/HotAreasRdPanorama", //热门领域研发全景
    GetCollectRound: "/api/DictItem/CollectRound", //集采轮次
    Company: "/api/One/Company", //公司列表
    EnterpriseEvent: "/api/One/EnterpriseEvent", //公司大事件
    EnterpriseStock: "/api/One/EnterpriseStock", //目标公司大事件
    EnterpriseFinancialReportYearly: "/api/One/EnterpriseFinancialReportYearly", //公司财务指标年度
    EnterpriseDetail: "/api/One/EnterpriseDetail", //参数企业Id，返回bool ，true：上市，false：非上市
    DownloadCreateTokenProductContrastAnalysis: "/api/One/DownloadCreateTokenProductContrastAnalysis", //产品信息的下载
    DownloadCreateTokenMedicalInsuranceAnalysis: "/api/One/DownloadCreateTokenMedicalInsuranceAnalysis", //
    DownloadCreateTokenCollectionAnalysis: "/api/One/DownloadCreateTokenCollectionAnalysis", //
    MedicalInsuranceAnalysisYear: "/api/One/MedicalInsuranceAnalysisYear", //医保谈判年份
    CollectionAnalysisYear: "/api/One/CollectionAnalysisYear", //集采年份
  },
  //one 医保拉动分析
  OneMarketInsurance: {
    RecommendedWords: "/api/One/RecommendedWords", //检索条件枚举字典
    GetTableList: "/api/One/VolumeGrowthinMedicalinsuranceAnalysisDataTable", // 查询列表
    DownloadTableData: "/api/One/DownloadTokenMedicalInsuranceVolumeGrowthAnalysis", // 表格数据的下载 link
    CheckDownloadFile: "/api/OneMarketInsurance/CheckDownloadFile", //文件下载的判断
    DownloadFile: "/api/One/DownloadHyperlinkMedicallnsuranceVolumeGrowthAnalysis", //文件下载
  },
  //one 集采拉动分析
  OneMarketCentralized: {
    RecommendedWords: "/api/One/RecommendedWords", //检索条件枚举字典
    GetTableList: "/api/One/concentrativePurchasingAnalysisDataTable", // 查询列表

    DownloadTableData: "/api/One/DownloadTokenconcentrativePurchasingAnalysis", // 表格数据的下载
    CheckDownloadFile: "/api/One/DownloadHyperlinkConcentrativePurchasingAnalysis", //文件下载的判断
    DownloadFile: "/api/OneMarketCentralized/DownloadFile", //文件下载
  },
  //公共
  Util: {
    GetDownloadLimit: "/api/UserSetting/GetDownloadLimit", //下载请求code值
    GetMarketDictionaryJson: "/api/dictservice/market_dictionary.json", //获取上市模块json
    GetDrugBiddingDictionaryJson: "/api/dictservice/drug_bidding_dictionary.json", //获取招标模块json
    GetGlobalDrugpatentsDictionaryJson: "/api/dictservice/global_drugpatents_dictionary.json", //获取专利模块json
    GetClinicalDictionaryJson: "/api/dictservice/clinical_dictionary.json", //获取临床模块json
    GetGlobalNewDrugDictionaryJson: "/api/dictservice/global_new_drug_dictionary.json", //获取新药模块json
    GetNDCDictionaryJson: "/api/dictservice/ndc_dictionary.json", //获取NDC模块json
    GETANNUALREPORTSDICTIONARYJSON: "/api/dictservice/ndc_dictionary.json", //获取NDC模块json
    GetClinicalPathwayDictionaryJson: "/api/dictservice/clinical_pathway_dictionary.json", //获取临床指南模块json
    GetTreatGuideJson: "/api/dictservice/disease_treat_guide_dictionary.json", //获取疾病指南模块json
    GetGeneralDictionaryJson: "/api/dictservice/general_dictionary.json", //获取通用模块json
    GetMedicineDictionaryJson: "/api/dictservice/medicine_dictionary.json", //获取通用药物模块json
    GetMedicalInsuranceInsuranceDictionaryJson: "/api/dictservice/medical_insurance_dictionary.json", //获取通用医保模块json
    GetBasicDrugDictionaryJson: "/api/dictservice/basic_drug_dictionary.json", //获取通用基药模块json
  },
  Custom: {
    GetCustomTableColumns: "/api/UserSetting/GetUserSelectedColumns", // 获取可见列筛选
    GetUserSelectedColumnsMultiple: "/api/UserSetting/GetUserSelectedColumnsMultiple", // 获取可见列筛选-一次性返回
    SavCustomTableColumns: "/api/UserSetting/SaveUserSelectColumns", // 保存可见列筛选
  },
}
export default ajaxApi
