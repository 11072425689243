import Home from "./home"
import Target from "./target"
import Drug from "./drug"
import Disease from "./disease"
import Enterprise from "./enterprise"
import Declare from "./declare"
import Product from "./product"
import Market from "./market"
import Auth from "./auth"
import One from "./one"
import Clinical from "./clinical"
import MedicareCatalog from "./medicareCatalog"
import EssentialDrug from "./essentialDrug"
import Material from "./material"
import ReferListedDrug from "./referListedDrug"
import GlobalNewDrug from "./globalNewDrug"
import Equivalence from "./equivalence"
import DrugBidding from "./drugBidding"
import AmericaNDC from "./americaNDC"
import AnnualReports from "./annualReports"
import GlobalDrugPatents from "./globalDrugPatents"
import DrugBiddingAnalysis from "./drugBiddingAnalysis"
import Registration from "./registration"
import User from "./user"
import ClinicalPathway from "./clinicalPathway"
import TreatmentGuide from "./treatmentGuide"
import OneMarketInsurance from "./oneMarketInsurance"
import OneMarketCentralized from "./oneMarketCentralized"
export default {
  Home: Home,
  Target: Target,
  Drug: Drug,
  Disease: Disease,
  Enterprise: Enterprise,
  Declare: Declare,
  Product: Product,
  Market: Market,
  Auth: Auth,
  One: One,
  Clinical,
  MedicareCatalog,
  Material,
  ReferListedDrug,
  EssentialDrug,
  Equivalence,
  DrugBidding,
  AmericaNDC,
  AnnualReports,
  GlobalDrugPatents,
  DrugBiddingAnalysis,
  Registration,
  GlobalNewDrug,
  User,
  ClinicalPathway,
  TreatmentGuide,
  OneMarketInsurance,
  OneMarketCentralized
}
