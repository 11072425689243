/**
 * zyg
 * 2020/12/15
 */
import types from "../../../type"
import axios from "../../../../http"
import ajaxApi from "../../../../config/request"
let state = {
  commonNameId: 9, //药物ID
  durgAnalysis: {}, //药物分析
  domesticSalesAnalysis: {}, //中国药物销售分析
  globalSalesAnalysis: {}, //全球药物销售分析
  enterpriseAnalysis: {}, //公司分析
  enterpriseProductStructure: [], //公司产品结构
  enterpriseAreaStructure: {}, //领域分布结构
  enterpriseSalesAnalysis: {}, //药品销售分析使用通用名查企业
  globaldiseaseAnalysis: {}, //全球疾病阶段分析
  domesticdiseaseAnalysis: {}, //全球疾病阶段分析
  domestictargetAnalysis: {}, //全球药物靶点阶段分析
  globaltargetAnalysis: {}, //全球药物靶点阶段分析
  countryRDMapAnalysis: {}, //全球药物全国研究分析
  drugRDAnalysis: {}, //药品研发获取分析
  drugDetail: {}, //药品详情
  companyChinaRDAnalysis: [], //研发获批分析-按公司中国-按公司全球-按疾病中国-按疾病全球
  companyGlobalRDAnalysis: [], //按公司全球
  diseaseChinaRDAnalysis: [], //按疾病中国
  diseaseGlobalRDAnalysis: [], //按疾病全球
  salesAndForecast: {}, //销售总额分析与预测
  potentialMarket: {}, //市场潜力分析
  chinaCompanySales: {}, //公司实力
  globalCompanySales: {}, //公司实力
  clinical: {}, //获取临床信息
  drugFormSales: {}, //剂型实力
  replaceRiskAnalysis: {}, //代替风险
  departmentAnalysis: {}, //科室分布
  patientFlow: {}, //患者流
  adverseReactions: {}, //不良反应报告
  disease: {}, //获取疾病列表
  phase: {}, //获取阶段列表
  target: {}, //获取靶点列表
  company: {}, //获取公司列表
  country: {}, //获取阶段列表
  drugFormJointCommonName: {},
  drugType: {},
  biologicalDrugClassification: {},
  treatmentType: {},
  DrugRDAnalysis: {}, //公司数据表
  commonNameChinaRDAnalysis: {}, //研发获批分析-按药物中国-按药物全球-按疾病中国-按疾病全球
  commonNameGlobalRDAnalysis: {}, //按药物全球
  companyDiseaseChinaRDAnalysis: {}, //按疾病中国
  companyDiseaseGlobalRDAnalysis: {}, //按疾病全球
  companySalesPercentage: {}, //公司销售占比分析
  companyYield: {}, //公司产量分析
  enterpriseDetail: {}, //公司详情基础信息
  enterpriseRelation: {}, //公司详情 公司关联
  enterpriseFinancialReport: {}, //公司详情公司财务指标
  enterpriseFinancialReportYearly: {}, //公司详情公司财务指标年度
  enterpriseCompanyEvent: {}, //公司大事件
  enterpriseCompanyStock: [], //公司k线图
  enterpriseAnnualReportTag: [], //公司年报标签
}

let actions = {
  //药物分析
  [types.ACTIONS_GET_ENTERPRISE_DURGANALYSIS]: ({ commit, state }, value) => {
    let url = ajaxApi.Enterprise.DurgAnalysis
    let params = {
      ...value,
      // ...state.searchForm,
      // pageSize: state.PrizePagination.pageSize,
      // pageNum: state.PrizePagination.pageNum,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          commit(types.MUTATION_SET_ENTERPRISE_DURGANALYSIS, res.data)
          return Promise.resolve()
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //中国药物销售分析
  [types.ACTIONS_GET_ENTERPRISE_DOMESTICSALESANALYSIS]: ({ commit, state }, value) => {
    let url = ajaxApi.Enterprise.DomesticSalesAnalysis
    let params = {
      ...value,
      // ...state.searchForm,
      // pageSize: state.PrizePagination.pageSize,
      // pageNum: state.PrizePagination.pageNum,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          commit(types.MUTATION_SET_ENTERPRISE_DOMESTICSALESANALYSIS, res.data)
          return Promise.resolve()
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //全球药物销售分析
  [types.ACTIONS_GET_ENTERPRISE_GLOBALSALESANALYSIS]: ({ commit, state }, value) => {
    let url = ajaxApi.Enterprise.GlobalSalesAnalysis
    let params = {
      ...value,
      // ...state.searchForm,
      // pageSize: state.PrizePagination.pageSize,
      // pageNum: state.PrizePagination.pageNum,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          commit(types.MUTATION_SET_ENTERPRISE_GLOBALSALESANALYSIS, res.data)
          return Promise.resolve()
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },

  //药品销售分析使用通用名查企业 options为dispatch传入参数
  // [types.ACTIONS_GET_ENTERPRISESALESANALYSIS]: ({ commit, state },options) => {
  //   let url = ajaxApi.Drug.EnterpriseSalesAnalysis;
  //   let params = {
  //     drugId: options,
  //   };

  //   return axios
  //     .post(url, params)
  //     .then((res) => {
  //       if (res.code === 200) {
  //         commit(types.MUTATION_SET_ENTERPRISESALESANALYSIS, res.data);
  //         return Promise.resolve();
  //       } else {
  //         return Promise.resolve();
  //       }
  //     })
  //     .catch((e) => {
  //       return Promise.reject(e);
  //     });
  // },

  //疾病分析 options为是否为全球 true/false
  [types.ACTIONS_GET_ENTERPRISE_DISEASEANALYSIS]: ({ commit, state }, value) => {
    let url = ajaxApi.Enterprise.DiseaseAnalysis
    let params = {
      ...value,
      // ...state.searchForm,
      // pageSize: state.PrizePagination.pageSize,
      // pageNum: state.PrizePagination.pageNum,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          if (value.isGlobal) {
            commit(types.MUTATION_SET_ENTERPRISE_GLOBALDISEASEANALYSIS, res.data)
          } else {
            commit(types.MUTATION_SET_ENTERPRISE_DOMESTICDISEASEANALYSIS, res.data)
          }

          return Promise.resolve()
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },

  //靶点分析 options为是否为全球 true/false
  [types.ACTIONS_GET_ENTERPRISE_TARGETANALYSIS]: ({ commit, state }, value) => {
    let url = ajaxApi.Enterprise.TargetAnalysis
    let params = {
      ...value,
      // ...state.searchForm,
      // pageSize: state.PrizePagination.pageSize,
      // pageNum: state.PrizePagination.pageNum,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          if (value.isGlobal) {
            commit(types.MUTATION_SET_ENTERPRISE_GLOBALTARGETANALYSIS, res.data)
          } else {
            commit(types.MUTATION_SET_ENTERPRISE_DOMESTICTARGETANALYSIS, res.data)
          }

          return Promise.resolve()
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //研发地图 全球药物全国研究分析
  [types.ACTIONS_GET_ENTERPRISE_COUNTRYRDMAPANALYSIS]: ({ commit, state }, value) => {
    let url = ajaxApi.Enterprise.CountryRDMapAnalysis
    let params = {
      ...value,
      // ...state.searchForm,
      // pageSize: state.PrizePagination.pageSize,
      // pageNum: state.PrizePagination.pageNum,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          commit(types.MUTATION_SET_ENTERPRISE_COUNTRYRDMAPANALYSIS, res.data)
          return Promise.resolve()
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },

  //公司数据表下载
  [types.ACTIONS_GET_ENTERPRISE_DOWNLOADENTERPRISERDANALYSIS]: (store, value) => {
    let url = ajaxApi.Enterprise.DownloadEnterpriseDataTable
    const { commit, dispatch, state, rootState } = store
    let filterData = rootState.Target.filterSortCondition
    let params = {
      pageSize: value.pageSize,
      currentPage: value.currentPage,
      orderBy: value.orderBy,
      isOrderDesc: value.isOrderDesc,
      ...filterData,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          return res
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },

  //  //获取药物类型
  //  [types.ACTIONS_GET_DRUGTYPE]: ({ commit, state }) => {
  //    debugger
  //   let url = ajaxApi.Drug.DrugType;
  //   let params = {

  //   }
  //   return axios
  //     .post(url, params)
  //     .then((res) => {
  //       if (res.code === 200) {
  //         commit(types.MUTATION_SET_DRUGTYPE, res.data);
  //         return res.data;
  //       } else {
  //         return res.message;
  //       }
  //     })
  //     .catch((e) => {
  //       return Promise.reject(e);
  //     });
  // },

  //药物详情 销售总额分析与预测

  //药物详情 代替风险
  //  [types.ACTIONS_GET_REPLACERISKANALYSIS]: ({ commit, state },options) => {
  //    let url = ajaxApi.Drug.ReplaceRiskAnalysis;
  //    let params = {
  //      "commonNameId": options,
  //      "drugformId": 1
  //    };
  //    return axios
  //      .post(url, params)
  //      .then((res) => {
  //        if (res.code === 200) {
  //          commit(types.MUTATION_SET_REPLACERISKANALYSIS, res.data);
  //          return Promise.resolve();
  //        } else {
  //          return Promise.resolve();
  //        }
  //      })
  //      .catch((e) => {
  //        return Promise.reject(e);
  //      });
  //  },
  //药物详情 科室分析
  //  [types.ACTIONS_GET_DEPARTMENTANALYSIS]: ({ commit, state },options) => {
  //    let url = ajaxApi.Drug.DepartmentAnalysis;
  //    let params = {
  //      "commonNameId": options,
  //      "drugformId": 1
  //    };
  //    return axios
  //      .post(url, params)
  //      .then((res) => {
  //        if (res.code === 200) {
  //          commit(types.MUTATION_SET_DEPARTMENTANALYSIS, res.data);
  //          return Promise.resolve();
  //        } else {
  //          return Promise.resolve();
  //        }
  //      })
  //      .catch((e) => {
  //        return Promise.reject(e);
  //      });
  //  },
  //  //药物详情 患者流页面
  //  [types.ACTIONS_GET_PATIENTFLOW]: ({ commit, state },options) => {
  //    let url = ajaxApi.Drug.PatientFlow;
  //    let params = {
  //      "commonNameId": 9,
  //      "drugformId": 1
  //    };
  //    return axios
  //      .post(url, params)
  //      .then((res) => {
  //        if (res.code === 200) {
  //          commit(types.MUTATION_SET_PATIENTFLOW, res.data);
  //          return Promise.resolve();
  //        } else {
  //          return Promise.resolve();
  //        }
  //      })
  //      .catch((e) => {
  //        return Promise.reject(e);
  //      });
  //  },
  //数据表 药品研发获取分析
  [types.ACTIONS_GET_ENTERPRISE_DRUGRDANALYSIS]: (store, value) => {
    let url = ajaxApi.Enterprise.DrugRDAnalysis
    const { commit, dispatch, state, rootState } = store
    let filterData = rootState.Target.filterSortCondition
    if (value.orderBy == null || value.orderBy == undefined) {
      value.orderBy = "researchDrugOfGlobal"
      value.isOrderDesc = true
    }
    let params = {
      // ...state.searchForm,
      // pageSize: state.PrizePagination.pageSize,
      // pageNum: state.PrizePagination.pageNum,
      pageSize: value.pageSize,
      currentPage: value.currentPage,
      orderBy: value.orderBy,
      isOrderDesc: value.isOrderDesc,
      ...filterData,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          commit(types.MUTATION_SET_ENTERPRISE_DRUGRDANALYSIS, res.data)
          return Promise.resolve()
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //产品线分析
  [types.ACTIONS_GET_ENTERPRISE_DRANALYSIS]: ({ commit, state }, value) => {
    let url = ajaxApi.Enterprise.RDAnalysis
    let EnterpriseProductStructureUrl = ajaxApi.Enterprise.EnterpriseProductStructure
    let EnterpriseAreaStructureUrl = ajaxApi.Enterprise.EnterpriseAreaStructure
    let params = filterObj(value)
    let type = value.type
    let enterpriseProductStructure
    let enterpriseAreaStructure
    //产品结构
    // axios.post(EnterpriseProductStructureUrl,params)
    // .then((res)=>{
    //   if (res.code === 200) {
    //     enterpriseProductStructure=res.data
    //     state[type].enterpriseProductStructure=res.data;

    //   }
    // })
    // .catch((e) => {
    //   return Promise.reject(e);
    // });
    //领域分布
    // axios.post(EnterpriseAreaStructureUrl,params)
    // .then((res)=>{
    //   if (res.code === 200) {
    //     enterpriseAreaStructure=res.data
    //     state[type].enterpriseAreaStructure=res.data
    //   }
    // })
    // .catch((e) => {
    //   return Promise.reject(e);
    // });
    // state[type].enterpriseProductStructure=enterpriseProductStructure
    // state[type].enterpriseAreaStructure=enterpriseAreaStructure
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          state[type] = res.data
          return Promise.resolve()
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //产品结构分析ACTIONS_GET_ENTERPRISE_ENTERPRISEPRODUCTSTRUCTURE
  [types.ACTIONS_GET_ENTERPRISE_ENTERPRISEPRODUCTSTRUCTURE]: ({ commit, state }, value) => {
    let url = ajaxApi.Enterprise.EnterpriseProductStructure
    // debugger
    let params = filterObj(value)
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          return res
        } else {
          return res.message
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //领域分布ACTIONS_GET_ENTERPRISE_ENTERPRISEAREASTRUCTURE
  [types.ACTIONS_GET_ENTERPRISE_ENTERPRISEAREASTRUCTURE]: ({ commit, state }, value) => {
    let url = ajaxApi.Enterprise.EnterpriseAreaStructure
    let params = filterObj(value)
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          return res
        } else {
          return res.message
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //获取公司列表
  [types.ACTIONS_GET_ENTERPRISE_COMPANY]: ({ commit, state }, value) => {
    let url = ajaxApi.Enterprise.Company
    let params = value
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          return res.data
        } else {
          return res.message
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //获取疾病列表
  [types.ACTIONS_GET_ENTERPRISE_DISEASE]: ({ commit, state }, value) => {
    let url = ajaxApi.Enterprise.Disease
    let params = value
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          return res.data
        } else {
          return res.message
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //获取剂型列表
  [types.ACTIONS_GET_ENTERPRISE_DRUGFORM]: ({ commit, state }, value) => {
    let url = ajaxApi.Enterprise.DrugForm
    let params = value
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          return res.data
        } else {
          return res.message
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //获取靶点列表
  [types.ACTIONS_GET_ENTERPRISE_TARGET]: ({ commit, state }, value) => {
    let url = ajaxApi.Enterprise.Target
    let params = value
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          return res.data
        } else {
          return res.message
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //获取药物列表
  [types.ACTIONS_GET_ENTERPRISE_COMMONNAME]: ({ commit, state }, value) => {
    let url = ajaxApi.Enterprise.EnterpriseCommonName
    let params = value
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          return res.data
        } else {
          return res.message
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //获取国家列表
  [types.ACTIONS_GET_ENTERPRISE_COUNTRY]: ({ commit, state }, value) => {
    let url = ajaxApi.Enterprise.Country
    let params = value
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          return res.data
        } else {
          return res.message
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },

  //药物详情 公司实力
  [types.ACTIONS_GET_ENTERPRISE_COMPANYSALES]: ({ commit, state }, options) => {
    let url = ajaxApi.Enterprise.CompanySales + `?id=${options.id}`
    let params = {
      ...options,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          if (options.isGlobal) {
            commit(types.MUTATION_SET_ENTERPRISE_GLOBALCOMPANYSALES, res.data)
          } else {
            commit(types.MUTATION_SET_ENTERPRISE_CHINACOMPANYSALES, res.data)
          }
          return Promise.resolve()
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //公司详情 公司销售占比
  [types.ACTIONS_GET_ENTERPRISE_COMPANYSALESPERCENTAGE]: ({ commit, state }, options) => {
    let url = ajaxApi.Enterprise.CompanySalesPercentage + `?enterpriseId=${options}`
    let params = {
      enterpriseId: options,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          state.companySalesPercentage = res.data
          return Promise.resolve()
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //公司详情 公司产量分析
  [types.ACTIONS_GET_ENTERPRISE_COMPANYYIELD]: ({ commit, state }, options) => {
    let url = ajaxApi.Enterprise.CompanyYield
    let params = options
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          state.companyYield = res.data
          return Promise.resolve()
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //公司详情 基础信息
  [types.ACTIONS_GET_ENTERPRISE_ENTERPRISEDETAIL]: ({ commit, state }, options) => {
    let url = ajaxApi.Enterprise.EnterpriseDetail + `?enterpriseId=${options}`
    let params = { enterpriseId: options }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          state.enterpriseDetail = res.data
          return Promise.resolve()
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //公司详情 公司关联
  [types.ACTIONS_GET_ENTERPRISE_ENTERPRISERELATION]: ({ commit, state }, options) => {
    let url = ajaxApi.Enterprise.EnterpriseRelation + `?enterpriseId=${options}`
    let params = { enterpriseId: options }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          res.data.item = res.data.children
          state.enterpriseRelation = res.data
          return Promise.resolve()
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //公司详情 公司财务指标
  [types.ACTIONS_GET_ENTERPRISE_ENTERPRISEREFINANCIALREPORT]: ({ commit, state }, options) => {
    let url = ajaxApi.Enterprise.EnterpriseFinancialReport
    let params = options
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          state.enterpriseFinancialReport = res.data
          return Promise.resolve()
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //公司详情 公司财务指标年度
  [types.ACTIONS_GET_ENTERPRISE_ENTERPRISEREFINANCIALREPORTYEARLY]: ({ commit, state }, options) => {
    let url = ajaxApi.Enterprise.EnterpriseFinancialReportYearly
    let params = options
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          return res.data
          state.enterpriseFinancialReportYearly = res.data
          // return Promise.resolve();
        } else {
          return res.message
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //公司详情 公司企业年报
  [types.ACTIONS_GET_ENTERPRISE_ENTERPRISEANNUALREPORTTAG]: ({ commit, state }, options) => {
    let url = ajaxApi.Enterprise.EnterpriseAnnualReportTag + `?enterpriseId=${options.enterpriseId}`
    let params = options
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          state.enterpriseAnnualReportTag = res.data
          return Promise.resolve()
        } else {
          return res.message
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //公司大事件
  [types.ACTIONS_GET_ENTERPRISE_COMPANYEVENT]: ({ commit, state }, options) => {
    let url = ajaxApi.Enterprise.CompanyEvent
    let params = options
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          return res.data
          state.enterpriseCompanyEvent = res.data
          return Promise.resolve()
        } else {
          return res.message
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //公司K线图
  [types.ACTIONS_GET_ENTERPRISE_COMPANYSTOCK]: ({ commit, state }, options) => {
    let url = ajaxApi.Enterprise.CompanyStock + `?enterpriseId=${options.id}&market=${options.market}`
    let params = options
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          return res.data
          state.enterpriseCompanyStock = res.data
          return Promise.resolve()
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
}
let getters = {
  [types.GETTERS_ENTERPRISE_DURGANALYSIS]: (state) => {
    return state.durgAnalysis
  },
  [types.GETTERS_ENTERPRISE_DOMESTICSALESANALYSIS]: (state) => {
    return state.domesticSalesAnalysis
  },
  [types.GETTERS_ENTERPRISE_GLOBALSALESANALYSIS]: (state) => {
    return state.globalSalesAnalysis
  },
  [types.GETTERS_ENTERPRISE_GLOBALDISEASEANALYSIS]: (state) => {
    return state.globaldiseaseAnalysis
  },
  [types.GETTERS_ENTERPRISE_DOMESTICDISEASEANALYSIS]: (state) => {
    return state.domesticdiseaseAnalysis
  },
  [types.GETTERS_ENTERPRISE_DOMESTICTARGETANALYSIS]: (state) => {
    return state.domestictargetAnalysis
  },
  [types.GETTERS_ENTERPRISE_GLOBALTARGETANALYSIS]: (state) => {
    return state.globaltargetAnalysis
  },
  [types.GETTERS_ENTERPRISE_COUNTRYRDMAPANALYSIS]: (state) => {
    return state.countryRDMapAnalysis
  },
  [types.GETTERS_ENTERPRISE_DRUGRDANALYSIS]: (state) => {
    return state.DrugRDAnalysis
  },
  [types.GETTERS_ENTERPRISE_COMMONNAMECHINARDANALYSIS]: (state) => {
    return state.commonNameChinaRDAnalysis
  },
  [types.GETTERS_ENTERPRISE_COMMONNAMEGLOBALRDANALYSIS]: (state) => {
    return state.commonNameGlobalRDAnalysis
  },
  [types.GETTERS_ENTERPRISE_COMPANYDISEASECHINARDANALYSIS]: (state) => {
    return state.companyDiseaseChinaRDAnalysis
  },
  [types.GETTERS_ENTERPRISE_COMPANYDISEASEGLOBALRDANALYSIS]: (state) => {
    return state.companyDiseaseGlobalRDAnalysis
  },
  [types.GETTERS_ENTERPRISE_CHINACOMPANYSALES]: (state) => {
    return state.chinaCompanySales
  },
  [types.GETTERS_ENTERPRISE_GLOBALCOMPANYSALES]: (state) => {
    return state.globalCompanySales
  },
  [types.GETTERS_ENTERPRISE_COMPANYSALESPERCENTAGE]: (state) => {
    return state.companySalesPercentage
  },
  [types.GETTERS_ENTERPRISE_COMPANYYIELD]: (state) => {
    return state.companyYield
  },
  [types.GETTERS_ENTERPRISE_ENTERPRISEDETAIL]: (state) => {
    return state.enterpriseDetail
  },
  [types.GETTERS_ENTERPRISE_ENTERPRISERELATION]: (state) => {
    return state.enterpriseRelation
  },
  [types.GETTERS_ENTERPRISE_ENTERPRISEREFINANCIALREPORT]: (state) => {
    return state.enterpriseFinancialReport
  },
  [types.GETTERS_ENTERPRISE_ENTERPRISEREFINANCIALREPORTTEARLY]: (state) => {
    return state.enterpriseFinancialReportYearly
  },
  [types.GETTERS_ENTERPRISE_COMPANYEVENT]: (state) => {
    return state.enterpriseCompanyEvent
  },
  [types.GETTERS_ENTERPRISE_COMPANYSTOCK]: (state) => {
    return state.enterpriseCompanyStock
  },
  [types.GETTERS_ENTERPRISE_ACTIONS_GET_ENTERPRISE_ENTERPRISEANNUALREPORTTAG]: (state) => {
    return state.enterpriseAnnualReportTag
  },
}

let mutations = {
  [types.MUTATION_SET_COMMONNAMEID]: (state, payload) => {
    state.commonNameId = payload
  },
  [types.MUTATION_SET_ENTERPRISE_DURGANALYSIS]: (state, payload) => {
    state.durgAnalysis = { ...state.durgAnalysis, ...payload }
  },
  [types.MUTATION_SET_ENTERPRISE_DOMESTICSALESANALYSIS]: (state, payload) => {
    state.domesticSalesAnalysis = {
      ...state.domesticSalesAnalysis,
      ...payload,
    }
  },
  [types.MUTATION_SET_ENTERPRISE_GLOBALSALESANALYSIS]: (state, payload) => {
    state.globalSalesAnalysis = { ...state.globalSalesAnalysis, ...payload }
  },
  [types.MUTATION_SET_ENTERPRISESALESANALYSIS]: (state, payload) => {
    state.enterpriseSalesAnalysis = {
      ...state.enterpriseSalesAnalysis,
      ...payload,
    }
  },
  [types.MUTATION_SET_ENTERPRISEANALYSIS]: (state, payload) => {
    state.enterpriseAnalysis = { ...state.enterpriseAnalysis, ...payload }
  },
  [types.MUTATION_SET_ENTERPRISE_DOMESTICDISEASEANALYSIS]: (state, payload) => {
    state.domesticdiseaseAnalysis = {
      ...state.domesticdiseaseAnalysis,
      ...payload,
    }
  },
  [types.MUTATION_SET_ENTERPRISE_GLOBALDISEASEANALYSIS]: (state, payload) => {
    state.globaldiseaseAnalysis = {
      ...state.globaldiseaseAnalysis,
      ...payload,
    }
  },
  [types.MUTATION_SET_ENTERPRISE_DOMESTICTARGETANALYSIS]: (state, payload) => {
    state.domestictargetAnalysis = {
      ...state.domestictargetAnalysis,
      ...payload,
    }
  },
  [types.MUTATION_SET_ENTERPRISE_GLOBALTARGETANALYSIS]: (state, payload) => {
    state.globaltargetAnalysis = { ...state.globaltargetAnalysis, ...payload }
  },
  [types.MUTATION_SET_ENTERPRISE_COUNTRYRDMAPANALYSIS]: (state, payload) => {
    state.countryRDMapAnalysis = { ...state.countryRDMapAnalysis, ...payload }
  },
  [types.MUTATION_SET_DRUGRDANALYSIS]: (state, payload) => {
    state.drugRDAnalysis = { ...state.drugRDAnalysis, ...payload }
  },
  [types.MUTATION_SET_RDANALYSIS]: (state, payload) => {
    state.rDAnalysis = { ...state.rDAnalysis, ...payload }
  },
  [types.MUTATION_SET_DRUGDETAIL]: (state, payload) => {
    state.drugDetail = { ...state.drugDetail, ...payload }
  },
  [types.MUTATION_SET_SALESANDFORECAST]: (state, payload) => {
    state.salesAndForecast = { ...state.salesAndForecast, ...payload }
  },
  [types.MUTATION_SET_POTENTIALMARKET]: (state, payload) => {
    state.potentialMarket = { ...state.potentialMarket, ...payload }
  },
  [types.MUTATION_SET_ENTERPRISE_CHINACOMPANYSALES]: (state, payload) => {
    state.chinaCompanySales = { ...state.chinaCompanySales, ...payload }
  },
  [types.MUTATION_SET_ENTERPRISE_GLOBALCOMPANYSALES]: (state, payload) => {
    state.globalCompanySales = { ...state.globalCompanySales, ...payload }
  },
  [types.MUTATION_SET_DRUGFORMSALES]: (state, payload) => {
    state.drugFormSales = { ...state.drugFormSales, ...payload }
  },
  [types.MUTATION_SET_DEPARTMENTANALYSIS]: (state, payload) => {
    state.departmentAnalysis = { ...state.departmentAnalysis, ...payload }
  },
  [types.MUTATION_SET_REPLACERISKANALYSIS]: (state, payload) => {
    state.replaceRiskAnalysis = { ...state.replaceRiskAnalysis, ...payload }
  },
  [types.MUTATION_SET_PATIENTFLOW]: (state, payload) => {
    state.patientFlow = { ...state.patientFlow, ...payload }
  },
  [types.MUTATION_SET_CLINICAL]: (state, payload) => {
    state.clinical = { ...state.clinical, ...payload }
  },
  [types.MUTATION_SET_ADVERSEREACTIONS]: (state, payload) => {
    state.adverseReactions = { ...state.adverseReactions, ...payload }
  },
  [types.MUTATION_SET_DRUGFORMJOINTCOMMONNAME]: (state, payload) => {
    state.drugFormJointCommonName = {
      ...state.drugFormJointCommonName,
      ...payload,
    }
  },
  [types.MUTATION_SET_DRUGFORMJOINTCOMMONNAME]: (state, payload) => {
    state.drugFormJointCommonName = {
      ...state.drugFormJointCommonName,
      ...payload,
    }
  },
  [types.MUTATION_SET_DRUGTYPE]: (state, payload) => {
    state.drugType = { ...state.drugType, ...payload }
  },
  [types.MUTATION_SET_BIOLOGICALDRUGCLASSIFICATION]: (state, payload) => {
    state.biologicalDrugClassification = {
      ...state.biologicalDrugClassification,
      ...payload,
    }
  },
  [types.MUTATION_SET_TREATMENTTYPE]: (state, payload) => {
    state.treatmentType = { ...state.treatmentType, ...payload }
  },
  [types.MUTATION_SET_COUNTRY]: (state, payload) => {
    state.country = { ...state.country, ...payload }
  },
  [types.MUTATION_SET_ENTERPRISE_DRUGRDANALYSIS]: (state, payload) => {
    state.DrugRDAnalysis = payload
  },
}

function filterObj(value) {
  let obj = {}
  Object.keys(value).forEach((item) => {
    obj[item] = ["[]", "0"].includes(JSON.stringify(value[item])) ? null : value[item]
  })
  return obj
}
export default {
  module: "Enterprise",
  state,
  actions,
  getters,
  mutations,
}
