import Breadcrumb from "../breadcrumb/index.vue"
import AuthShow from "../auth/auth.vue" //无权限的弹窗
import DevelopShow from "../auth/developing.vue" //无权限的弹窗
import LoadingData from "../content/loadingData.vue"
const components = [Breadcrumb,  AuthShow, DevelopShow, LoadingData]
const BaseComponents = {
  install: (Vue) => {
    components.map((item) => {
      Vue.component(item.name, item)
    })
  },
}

export default BaseComponents
