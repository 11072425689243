import * as GlobalGetters from "./getters.global"
import * as GlobalActions from "./actions.global"
import * as GlobalMutations from "./mutations.global"

let types = {
  ...{},
  ...GlobalActions,
  ...GlobalGetters,
  ...GlobalMutations,
}

export default types
