<template>
  <div class="breadcrumb">
    <div class="breadcrumb_block">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item v-for="(item, index) in breadList" :key="index"
          ><span @click="toPath(item)">{{ item.title }}</span></el-breadcrumb-item
        >
      </el-breadcrumb>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex"
import types from "../../type"
export default {
  name: "Breadcrumb",
  data() {
    return {}
  },
  props: {},
  components: {},
  created() {},
  mounted() {},
  watch: {},
  computed: {
    ...mapGetters({
      breadList: types.GETTERS_GLOBAL_BREADLIST,
    }),
  },
  methods: {
    toPath(item) {
      if (item.tableList) {
        this.$store.commit(types.MUTATION_GLOBAL_VIEWTYPE, item.tableList)
      } else {
        return
      }
    },
  },
}
</script>
<style scoped>
.breadcrumb {
  padding: 14px;
  line-height: 40px;
  border-bottom: 1px solid #e3e9f5;
}
::v-deep.el-breadcrumb__item:last-child .el-breadcrumb__inner {
  color: #ff6101;
}
</style>
