import Vue from "vue"
import Router from "vue-router"
const Index = () => import("../views/index") //主页
const Disease = () => import("../views/Disease") //疾病
const Home = () => import("../views/Home") //首页
const Personal = () => import("../views/Personal") //个人中心页面
const Login = () => import("../views/login/Login") //登录
const Wechat = () => import("../views/login/wechat") //登录
const Wechat2 = () => import("../views/login/wechat2") //登录
const Register = () => import("../views/login/register") //注册
const forgetMima = () => import("../views/login/forgetMima") //忘记密码
const NotFound = () => import("@/views/errorPages/NotFound") //404
const Target = () => import("../views/Target") //靶点
const Medicine = () => import("../views/Medicine") //药品
const Company = () => import("../views/Company") //公司
const Declare = () => import("../views/Declare") //申报
const Product = () => import("../views/Product") //上市
const Clinical = () => import("../views/Clinical") //临床
const MedicareCatalog = () => import("../views/MedicareCatalog") //医保
const EssentialDrug = () => import("../views/EssentialDrug") //基药
const DrugBidding = () => import("../views/DrugBidding") //药品招标
const GlobalDrugPatents = () => import("../views/GlobalDrugPatents") //药品招标
const DrugBiddingAnalysis = () => import("../views/DrugBiddingAnalysis") //药品招标分析
const GlobalNewDrug = () => import("../views/GlobalNewDrug") //全球新药
const Material = () => import("../views/Material") //原辅包
const ReferListedDrug = () => import("../views/ReferListedDrug") // 参比制剂目录
const Equivalence = () => import("../views/Equivalence") //一致性评价进度
const TargetDetail = () => import("../components/TargetDetail") //靶点详情页面
const DiseaseDetail = () => import("../components/DiseaseDetail") //疾病详情页面
const CompanyDetail = () => import("../components/CompanyDetail") //公司详情页面
const registrationDetail = () => import("../components/registrationDetail") //申报详情页面
const medicineDetail = () => import("../components/medicineDetail") //药品详情页面
const AmericaNDC = () => import("../views/AmericaNDC") //美国NDC药品
const AnnualReports = () => import("../views/AnnualReports") //企业年报
const ClinicalPathway = () => import("../views/ClinicalPathway") //临床用药路径
const TreatmentGuide = () => import("../views/TreatmentGuide") //临床用药路径
const oneMarketComparison = () => import("../components/oneModules/marketAnalysis/productComparison") //one里面的市场分析的产品对比模块
const oneMarketInsurance = () => import("../views/OneMarketInsurance") //one里面的市场分析的医保放量拉动分析模块
const oneMarketCentralized = () => import("../views/OneMarketCentralized") //one里面的市场分析的集采放量拉动分析
const oneTargetCompany = () => import("../components/oneModules/investmentMerger/targetCompany") //one里面的投资并购工具目标公司大事件
const oneTargetSimulationMerge = () => import("../components/oneModules/investmentMerger/companySimulationMerge") //one里面的投资并购的公司模拟合并
const oneTargetValuationInnovative = () => import("../components/oneModules/investmentMerger/valuationInnovative") //one里面的投资并购的国产创新药估值
const oneRAndDmapApproved = () => import("../components/oneModules/rAndDProject/mapApprovedAreas") //one里面的研发立项的药品获批领域地图
const oneRAndDImitativeMedicine = () => import("../components/oneModules/rAndDProject/diggingImitativeMedicine") //one里面的研发立项的挖掘仿药老品种
const oneRAndDEmergingTargets = () => import("../components/oneModules/rAndDProject/emergingTargets") //one里面的研发立项的新兴靶点研发热度
const oneRAndDhotAreas = () => import("../components/oneModules/rAndDProject/hotAreasRdPanorama") //one里面的新兴靶点研发热度
const clinicalDetail = () => import("../components/content/clinicalContent/clinicalDetail") // 临床详情页
const globalNewDrugDetail = () => import("../components/content/globalNewDrugContent/detail") // 全球新药详情页
const globalDrugPatentsDetail = () => import("../components/content/globalDrugPatentsContent/detail") // 全球新药详情页
const updateLog = () => import("../views/updateLog") // 更新日志

let routersCollect = [
  { name: "Disease", compont: Disease },
  { name: "Home", compont: Home },
  { name: "NotFound", compont: NotFound },
  { name: "Personal", compont: Personal },
  { name: "Login", compont: Login },
  { name: "Wechat", compont: Wechat },
  { name: "Wechat2", compont: Wechat2 },
  { name: "Register", compont: Register },
  { name: "forgetMima", compont: forgetMima },
  { name: "Target", compont: Target },
  { name: "Medicine", compont: Medicine },
  { name: "Company", compont: Company },
  { name: "Registration", compont: Declare },
  { name: "Market", compont: Product },
  { name: "Clinical", compont: Clinical },
  { name: "MedicareCatalog", compont: MedicareCatalog },
  { name: "DrugBidding", compont: DrugBidding },
  { name: "AmericaNDC", compont: AmericaNDC },
  { name: "ClinicalPathway", compont: ClinicalPathway },
  { name: "TreatmentGuide", compont: TreatmentGuide },
  { name: "GlobalDrugPatents", compont: GlobalDrugPatents },
  { name: "DrugBiddingAnalysis", compont: DrugBiddingAnalysis },
  { name: "EssentialDrug", compont: EssentialDrug },
  { name: "GlobalNewDrug", compont: GlobalNewDrug },
  { name: "RawMaterial", compont: Material },
  { name: "ReferListedDrug", compont: ReferListedDrug },
  { name: "Equivalence", compont: Equivalence },
  { name: "TargetDetail", compont: TargetDetail },
  { name: "DiseaseDetail", compont: DiseaseDetail },
  { name: "CompanyDetail", compont: CompanyDetail },
  { name: "registrationDetail", compont: registrationDetail },
  { name: "medicineDetail", compont: medicineDetail },
  { name: "oneMarketComparison", compont: oneMarketComparison },
  { name: "oneMarketInsurance", compont: oneMarketInsurance },
  { name: "oneMarketCentralized", compont: oneMarketCentralized },
  { name: "oneTargetCompany", compont: oneTargetCompany },
  { name: "oneTargetSimulationMerge", compont: oneTargetSimulationMerge },
  {
    name: "oneTargetValuationInnovative",
    compont: oneTargetValuationInnovative,
  },
  { name: "oneRAndDmapApproved", compont: oneRAndDmapApproved },
  { name: "oneRAndDImitativeMedicine", compont: oneRAndDImitativeMedicine },
  { name: "oneRAndDEmergingTargets", compont: oneRAndDEmergingTargets },
  { name: "oneRAndDhotAreas", compont: oneRAndDhotAreas },
  { name: "clinicalDetail", compont: clinicalDetail },
  { name: "globalNewDrugDetail", compont: globalNewDrugDetail },
  { name: "globalDrugPatentsDetail", compont: globalDrugPatentsDetail },
  { name: "updateLog", compont: updateLog },
  { name: "annualReports", compont: AnnualReports },
]
let childrenRoutes = routersCollect.map((item) => {
  if (
    item.name !== "Home" &&
    item.name !== "Login" &&
    item.name !== "Register" &&
    item.name !== "forgetMima" &&
    item.name !== "Wechat" &&
    item.name !== "Wechat2" &&
    item.name !== "globalNewDrugDetail" &&
    item.name !== "globalDrugPatentsDetail" &&
    item.name !== "updateLog" &&
    item.name !== "NotFound"
  ) {
    return {
      path: `/${item.name}`,
      name: item.name,
      component: item.compont,
      meta: { requireAuth: true },
    }
  } else {
    return {
      path: `/${item.name}`,
      name: item.name,
      component: item.compont,
      meta: { requireAuth: false },
    }
  }
})
childrenRoutes.push({
  path: "/",
  name: "home",
  component: Home,
  redirect: "/home",
})
let routes = [
  {
    path: "*",
    name: "index",
    component: Index,
    children: childrenRoutes,
  },
  // {
  //   path: "/login",
  //   name: "Login",
  //   component: Login,
  // },
  // {
  //   path: "/",
  //   name:'home',
  //   component:Home
  // },
]

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}
Vue.use(Router)
const router = new Router({
  base: "/",
  mode: "history", //注，因为微信登录跳转问题，所以从mode改为history
  routes: routes,
})
export default router
