import types from "@/type"
import ajaxApi from "../../../../config/request"
import { post } from "@/utils/request"
let state = {
  activeTab: {
    // 激活tab页
    type: 1, // 默认按品规+企业查看
    moduleName: "按品规+企业查看",
  },
  filterParams: {
    // 左侧筛选条件
  },
  // 筛选条件回显
  echoParams: [],
}
let actions = {
  // 查询表格数据
  [types.ACTIONS_DRUGBIDDING_GETTABLEDATA]: (store, params) => {
    let url = ajaxApi.DrugBidding.GetTableList
    return post(url, params)
  },
  // 导出表格数据
  [types.ACTIONS_DRUGBIDDING_DOWNLOADTABLEDATA]: (store, params) => {
    let url = ajaxApi.DrugBidding.DownloadTableData
    return post(url, params)
  },
  // 模糊查询下拉框
  [types.ACTIONS_DRUGBIDDING_RECOMMENDEDWORDS]: (store, params) => {
    let url = ajaxApi.DrugBidding.RecommendedWords
    return post(url, params)
  },
  // select下拉框查询
  [types.ACTIONS_DRUGBIDDING_GETOPTIONSLIST]: (store, params) => {
    let url = ajaxApi.DrugBidding.GetFilterOptions
    return post(url, params)
  },
  // 点击数字弹框查询
  [types.ACTIONS_DRUGBIDDING_SHOWCOUNTOPENDATA]: (store, params) => {
    let url = ajaxApi.DrugBidding.ShowCountOpenData
    return post(url, params)
  },
  // 查询数量
  [types.ACTIONS_DRUGBIDDING_GETTOTALNUMBER]: (store, params) => {
    let url = ajaxApi.DrugBidding.GetAllViewDataCount
    return post(url, params)
  },
  [types.ACTIONS_DRUGBIDDING_BIOLOGICDRUGCLASSIFICATION]: (store, params) => {
    let url = ajaxApi.DrugBidding.BiologicDrugClassification
    return post(url, params)
  },
  [types.ACTIONS_DRUGBIDDING_TCM]: (store, params) => {
    let url = ajaxApi.DrugBidding.Tcm
    return post(url, params)
  },
  [types.ACTIONS_DRUGBIDDING_TCMOFDISEASE]: (store, params) => {
    let url = ajaxApi.DrugBidding.TcmOfDisease
    return post(url, params)
  },
}
let getters = {
  // 激活tab页参数
  [types.GETTERS_DRUGBIDDING_ACTIVETAB]: (state) => {
    return state.activeTab
  },
  [types.GETTERS_DRUGBIDDING_FILTERPARAMS]: (state) => {
    return state.filterParams
  },
  [types.GETTERS_DRUGBIDDING_ECHOPARAMS]: (state) => {
    return state.echoParams
  },
}
let mutations = {
  // 激活tab页参数
  [types.MUTATION_DRUGBIDDING_ACTIVETAB]: (state, payload) => {
    state.activeTab = {
      ...state.activeTab,
      ...payload,
    }
  },
  [types.MUTATION_DRUGBIDDING_FILTERPARAMS]: (state, payload) => {
    if (!payload) {
      state.filterParams = {}
    } else if (payload === 'reset') {
      state.filterParams = {}
    } else {
      state.filterParams = {
        ...state.filterParams,
        ...payload,
      }
    }
  },
  [types.MUTATION_DRUGBIDDING_ECHOPARAMS]: (state, payload) => {
    state.echoParams = payload
  },
}

export default {
  module: "DrugBidding",
  state,
  actions,
  getters,
  mutations,
}
