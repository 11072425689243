var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "breadcrumb" }, [
    _c(
      "div",
      { staticClass: "breadcrumb_block" },
      [
        _c(
          "el-breadcrumb",
          { attrs: { "separator-class": "el-icon-arrow-right" } },
          _vm._l(_vm.breadList, function (item, index) {
            return _c("el-breadcrumb-item", { key: index }, [
              _c(
                "span",
                {
                  on: {
                    click: function ($event) {
                      return _vm.toPath(item)
                    },
                  },
                },
                [_vm._v(_vm._s(item.title))]
              ),
            ])
          }),
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }