import types from "../../../type"
import axios from "../../../../http"
import ajaxApi from "../../../../config/request"
import { compareAndDelete } from "@/utils/event"
import filterForm from "../../../components/content/LeftFilter/market/form"
let state = {
  activeTab: {
    // 激活tab页
    type: 1, // 默认按登记号查看
    moduleName: "按登记号查看",
  },
  filterParams: {
    // 左侧筛选条件
  },
  // 筛选条件回显
  echoParams: [],
  drugType: {},
  drugOptions: {},
  dosageOptions: {},
  insuranceOption: {},
  enterpriseOption: {},
  simplifyOption: {},
  purchaseOption: {},
  countOption: {},
  chinaScreenCondition: [], //筛选数据对象
  chinaFilterSortCondition: {}, //页面传参的数据
  chinaChangescreenCondition: {
    status: 0,
    deletecode: "",
  },
  columnsDataApprovalNo: {
    list: [],
    total: 0,
  },
  columnsDataProduct: {
    list: [],
    total: 0,
  },
  columnsDataCommonName: {
    list: [],
    total: 0,
  },
  columnsDataApplicantHolder: {
    list: [],
    total: 0,
  },
  //数据表数据
  showCountOpenData: [],
  // 上市激活tab页
  activeTabAndSubTab: {
    // 默认中国上市药品
    tab: {
      mainType: 2,
      moduleName: "中国上市",
      moduleType: "chinaCondition",
    },
    // 默认按商品查看
    subTab: { subType: 1, moduleName: "按商品查看" },
  },
}
let actions = {
  // 批准文号
  [types.ACTIONS_PRODUCT_GETLICENSENOS]: ({ commit, state }, value) => {
    let url = ajaxApi.Product.GetLicenseNos + `?word=${value.word}`
    if (value.word == "") {
      return
    }
    let params = {
      ...value,
    }
    return axios
      .get(url, params)
      .then((res) => {
        if (res.code === 200) {
          return res.data
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  // 专利号
  [types.ACTIONS_PRODUCT_GETPATENTNOS]: ({ commit, state }, value) => {
    let url = ajaxApi.Product.GetPatentNos + `?word=${value.word}`
    if (value.word == "") {
      return
    }
    let params = {
      ...value,
    }
    return axios
      .get(url, params)
      .then((res) => {
        if (res.code === 200) {
          let arrData = []
          if (res.data && res.data.length > 0) {
            arrData = res.data.map((item, index) => {
              return {
                name: item,
                value: index,
              }
            })
            return arrData
          }
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  // 热门标签
  [types.ACTIONS_PRODUCT_GETALLHOTLABEL]: ({ commit, state }, value) => {
    let url = ajaxApi.Product.GetAllHotLabel
    return axios
      .get(url)
      .then((res) => {
        if (res.code === 200) {
          let arrData = []
          if (res.data) {
            arrData = res.data.map((item) => {
              return {
                value: item.id,
                name: item.label,
              }
            })
            return { data: arrData }
          }
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  // 处方科室
  [types.ACTIONS_PRODUCT_HOSPITALDEPARTMENT]: ({ commit, state }, value) => {
    let url = ajaxApi.Product.HospitalDepartment
    return axios
      .get(url)
      .then((res) => {
        if (res.code === 200) {
          return res
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  // 枚举
  [types.ACTIONS_PRODUCT_RECOMMENDEDWORDS]: ({ commit, state }, value) => {
    let url = ajaxApi.Product.RecommendedWords
    if (value.word == "") {
      return
    }
    let params = {
      ...value,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          return res.data
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  // 药品名称枚举
  [types.ACTIONS_PRODUCT_GETDRUGNAMEDATA]: ({ commit, state }, value) => {
    let url = ajaxApi.Product.GetDrugNameData + `?word=${value.word}`
    if (value.word == "") {
      return
    }
    let params = {
      ...value,
    }
    return axios
      .get(url, params)
      .then((res) => {
        if (res.code === 200) {
          return res.data
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  // 公司多选数据
  [types.ACTIONS_PRODUCT_GETCOMPANYDATA]: ({ commit, state }, value) => {
    let url = ajaxApi.Product.GetCompanyData + `?word=${value.word}`
    if (value.word == "") {
      return
    }
    let params = {
      ...value,
    }
    return axios
      .get(url, params)
      .then((res) => {
        if (res.code === 200) {
          return res.data
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //   药品类型
  [types.ACTIONS_PRODUCT_GETDRUGTYPE]: ({ commit, state }, value) => {
    let url = ajaxApi.Product.GetDrugTtype
    return axios
      .post(url)
      .then((res) => {
        if (res.code === 200) {
          let arrData = []
          if (res.data && res.data.content) {
            arrData = res.data.content.map((item) => {
              return {
                value: item.id,
                name: item.name,
              }
            })
          }
          commit(types.MUTATION_PRODUCT_GETDRUGTYPE, { data: arrData })
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //   下拉框选择项
  [types.ACTIONS_PRODUCT_PULLDOWNCATEGORY]: ({ commit, state }, params) => {
    let url = ajaxApi.Product.PulldownCategory
    return axios
      .get(url, { params })
      .then((res) => {
        if (res.code === 200) {
          return res
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  // 下拉框联想查询
  [types.ACTIONS_PRODUCT_AUTOCOMPLETE]: ({ commit, state }, params) => {
    const url = ajaxApi.Product.AutoComplete
    return axios.post(url, params).then((res) => {
      if (res.code === 200) {
        return res.data
      }
    })
  },
  // 给药途径
  [types.ACTIONS_PRODUCT_ADMINISTRATIONROUTES]: ({ commit, state }, value) => {
    let url = ajaxApi.Product.AdministrationRoutes
    if (value == "") {
      return
    }
    let params = {
      ...value,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          commit(types.MUTATION_PRODUCT_ADMINISTRATIONROUTE, res)
          return res
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  // 获取剂型数据
  [types.ACTIONS_PRODUCT_DOSAGEDATA]: ({ commit, state }, value) => {
    let url = ajaxApi.Product.DrugFormDictLevel + `?level=1`
    return axios
      .post(url)
      .then((res) => {
        if (res.code === 200) {
          commit(types.MUTATION_PRODUCT_DOSAGEDATA, res)
          return res
        } else {
          return res.message
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },

  // 豁免或简化一致性评价品种
  [types.ACTIONS_PRODUCT_SIMPLIFYCONSISTENCYEVALUATION]: ({ commit, state }, value) => {
    let url = ajaxApi.Product.SimplifyConsistencyEvaluation
    return axios
      .get(url)
      .then((res) => {
        if (res.code === 200) {
          commit(types.MUTATION_PRODUCT_SIMPLIFYCONSISTENCYEVALUATION, res)
          return res
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  // 数字
  [types.ACTIONS_PRODUCT_GETTOTALNUMBER]: (store, value) => {
    const { commit, dispatch, state, rootState } = store
    let filterData = rootState.Market.filterParams
    filterData = Object.assign(
      {},
      filterData
      // rootState.Product.chinaFilterSortCondition
    )

    let params = {
      ...filterData,
    }
    let _params = {
      commonCondition: {}, chinaCondition: {}, americaCondition: {}, emaCondition: {}, globalCondition: {}, hmaCondition: {}, japanCondition: {}
    }
    _params.commonCondition = compareAndDelete(filterForm[0].formItem, params.commonCondition)
    _params.globalCondition = compareAndDelete(filterForm[1].formItem, params.globalCondition)
    _params.chinaCondition = compareAndDelete(filterForm[2].formItem, params.chinaCondition)
    _params.americaCondition = compareAndDelete(filterForm[3].formItem, params.americaCondition)
    _params.emaCondition = compareAndDelete(filterForm[4].formItem, params.emaCondition)
    _params.hmaCondition = compareAndDelete(filterForm[5].formItem, params.hmaCondition)
    _params.japanCondition = compareAndDelete(filterForm[6].formItem, params.japanCondition)
    let url = ajaxApi.Product.GetTotalNumber
    return axios
      .post(url, _params)
      .then((res) => {
        if (res.code === 200) {
          return res.data
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  // 数字
  [types.ACTIONS_PRODUCT_GETPARTTOTALNUMBER]: (store, value) => {
    const { commit, dispatch, state, rootState } = store
    let filterData = rootState.Market.filterParams

    // filterData = Object.assign(
    //   {},
    //   filterData,
    //   rootState.Product.chinaFilterSortCondition
    // );

    let params = {
      ...value,
      ...filterData,
    }
    let url = ajaxApi.Product.GetPartTotalNumber
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          return res.data
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //表格数据的获取
  [types.ACTIONS_PRODUCT_GETDETAILDATA]: (store, value) => {
    let url = ajaxApi.Product.GetDetailData
    const { commit, dispatch, state, rootState } = store
    if (value.orderBy == null || value.orderBy == undefined) {
      value.orderBy = "approvalDate"
      value.isOrderDesc = true
    }
    let filterData = rootState.Market.filterParams

    filterData = Object.assign({}, filterData, rootState.Product.chinaFilterSortCondition)
    let _params = {
      commonCondition: {}, chinaCondition: {}, americaCondition: {}, emaCondition: {}, globalCondition: {}, hmaCondition: {}, japanCondition: {}
    }
    _params.commonCondition = compareAndDelete(filterForm[0].formItem, filterData.commonCondition)
    _params.globalCondition = compareAndDelete(filterForm[1].formItem, filterData.globalCondition)
    _params.chinaCondition = compareAndDelete(filterForm[2].formItem, filterData.chinaCondition)
    _params.americaCondition = compareAndDelete(filterForm[3].formItem, filterData.americaCondition)
    _params.emaCondition = compareAndDelete(filterForm[4].formItem, filterData.emaCondition)
    _params.hmaCondition = compareAndDelete(filterForm[5].formItem, filterData.hmaCondition)
    _params.japanCondition = compareAndDelete(filterForm[6].formItem, filterData.japanCondition)
    let params = {
      pageSize: value.pageSize,
      currentPage: value.currentPage,
      orderBy: value.orderBy,
      isOrderDesc: value.isOrderDesc,
      mainType: value.mainType,
      subType: value.subType,
      filterParam: { ..._params, mainType: value.mainType }
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          switch (value.subType) {
            case 1:
              state.columnsDataApprovalNo = res.data.list
                ? res.data
                : {
                  list: [],
                  total: 0,
                }
              break
            case 2:
              state.columnsDataProduct = res.data.list
                ? res.data
                : {
                  list: [],
                  total: 0,
                }
              break
            case 3:
              state.columnsDataCommonName = res.data.list
                ? res.data
                : {
                  list: [],
                  total: 0,
                }
              break
            case 4:
              state.columnsDataApplicantHolder = res.data.list
                ? res.data
                : {
                  list: [],
                  total: 0,
                }
              break
          }
          //commit(types.MUTATION_SET_DECLARE_COLUMNSDATA, res.data);
          return res
        } else {
          return res
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  //数据表下载数据
  [types.ACTIONS_PRODUCT_DOWNLOADGETDETAILDATA]: (store, value) => {
    let url = ajaxApi.Market.DownloadTableData
    const { commit, dispatch, state, rootState } = store
    let filterData = rootState.Market.filterParams
    filterData = Object.assign({}, filterData, rootState.Product.chinaFilterSortCondition)
    let params = {
      pageSize: value.pageSize,
      currentPage: value.currentPage,
      orderBy: value.orderBy,
      isOrderDesc: value.isOrderDesc,

      subType: value.subType,
      filterParam: {
        ...filterData,
        mainType: value.mainType,
      },
      // ...filterData,
    }
    return axios
      .post(url, params)
      .then((res) => {
        if (res.code === 200) {
          return res
        } else {
          return Promise.resolve()
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  // 文件下载判断是否为空
  [types.ACTIONS_PRODUCT_CHECKDOWNLOADFILE]: (store, value) => {
    let url = ajaxApi.Market.CheckDownloadFile
    let params = {
      ...value,
    }
    return axios
      .post(url, params)
      .then((res) => {
        return res
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  // 文件下载
  [types.ACTIONS_PRODUCT_DOWNLOADFILE]: (store, value) => {
    let url = ajaxApi.Product.DownloadFile
    let params = {
      ...value,
      need: true,
    }
    return axios
      .post(url, params)
      .then((res) => { })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  // 列表数字点击展示数据
  [types.ACTIONS_PRODUCT_SHOWCOUNTOPENDATA]: (store, value) => {
    let url = ajaxApi.Product.ShowCountOpenData
    const { commit, dispatch, state, rootState } = store

    // let filterData = rootState.Target.filterSortCondition;

    // let params = {
    //   mainType: value.mainType,
    //   subType:  Number(value.subType),
    //   showCountCondition: value.showCountCondition,
    //   ...filterData,
    // };
    return axios
      .post(url, value)
      .then((res) => {
        if (res.code === 200) {
          commit(types.MUTATION_PRODUCT_SHOWCOUNTOPENDATA, res)
          return res
        } else {
          return res
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
}
let getters = {
  [types.GETTERS_MARKET_FILTERPARAMS]: (state) => {
    return state.filterParams
  },
  [types.GETTERS_PRODUCT_ECHOPARAMS]: (state) => {
    return state.echoParams
  },
  [types.GETTERS_PRODUCT_CHINASCREENCONDITION]: (state) => {
    return state.chinaScreenCondition
  },
  [types.GETTERS_PRODUCT_CHINACHANGESCREENCONDITION]: (state) => {
    return state.chinaChangescreenCondition
  },
  [types.GETTERS_PRODUCT_CHINAFILTERSORTCONDITION]: (state) => {
    return state.chinaFilterSortCondition
  },
  // 公司/持证商多选列表
  [types.GETTERS_PRODUCT_GETCOMPANYDATA]: (state) => {
    return state.enterpriseOption
  },
  // 药品类型
  [types.GETTERS_PRODUCT_GETDRUGTYPE]: (state) => {
    return state.drugType
  },
  // 给药途径
  [types.GETTERS_PRODUCT_ADMINISTRATIONROUTE]: (state) => {
    return state.drugOptions
  },
  // 剂型
  [types.GETTERS_PRODUCT_DOSAGEDATA]: (state) => {
    return state.dosageOptions
  },
  // 医保目录
  [types.GETTERS_PRODUCT_MEDICALINSURANCE]: (state, payload) => {
    return state.insuranceOption
  },
  // 豁免或简化一致性评价品种
  [types.GETTERS_PRODUCT_SIMPLIFYCONSISTENCYEVALUATION]: (state, payload) => {
    return state.simplifyOption
  },
  // 带量采购品种
  [types.GETTERS_PRODUCT_PURCHASEVARIETIES]: (state, payload) => {
    return state.purchaseOption
  },
  // 数字弹窗
  [types.GETTERS_PRODUCT_SHOWCOUNTOPENDATA]: (state, payload) => {
    return state.showCountOpenData
  },
  // 激活tab页参数
  [types.GETTERS_PRODUCT_TABANDSUBTAB]: (state) => {
    return state.activeTabAndSubTab
  },
}
let mutations = {
  [types.MUTATION_SET_PRODUCT_CHINASCREENCONDITION]: (state, payload) => {
    state.chinaScreenCondition = payload
  },
  [types.MUTATION_SET_PRODUCT_CHINACHANGESCREENCONDITION]: (state, payload) => {
    state.chinaChangescreenCondition = payload
  },
  [types.MUTATION_SET_PRODUCT_CHINAFILTERSORTCONDITION]: (state, payload) => {
    state.chinaFilterSortCondition = payload
  },
  // 公司/持证商多选列表
  [types.MUTATION_PRODUCT_GETCOMPANYDATA]: (state, payload) => {
    state.enterpriseOption = {
      ...state.enterpriseOption,
      ...payload,
    }
  },
  // 药品类型
  [types.MUTATION_PRODUCT_GETDRUGTYPE]: (state, payload) => {
    state.drugType = {
      ...state.drugType,
      ...payload,
    }
  },
  // 给药途径
  [types.MUTATION_PRODUCT_ADMINISTRATIONROUTE]: (state, payload) => {
    state.drugOptions = {
      ...state.drugOptions,
      ...payload,
    }
  },
  // 剂型
  [types.MUTATION_PRODUCT_DOSAGEDATA]: (state, payload) => {
    state.dosageOptions = {
      ...state.dosageOptions,
      ...payload,
    }
  },
  // 医保目录
  [types.MUTATION_PRODUCT_MEDICALINSURANCE]: (state, payload) => {
    state.insuranceOption = {
      ...state.insuranceOption,
      ...payload,
    }
  },
  // 豁免或简化一致性评价品种
  [types.MUTATION_PRODUCT_SIMPLIFYCONSISTENCYEVALUATION]: (state, payload) => {
    state.simplifyOption = {
      ...state.simplifyOption,
      ...payload,
    }
  },
  // 带量采购品种
  [types.MUTATION_PRODUCT_PURCHASEVARIETIES]: (state, payload) => {
    state.purchaseOption = {
      ...state.purchaseOption,
      ...payload,
    }
  },
  // 数字弹窗
  [types.MUTATION_PRODUCT_SHOWCOUNTOPENDATA]: (state, payload) => {
    state.showCountOpenData = {
      ...state.showCountOpenData,
      ...payload,
    }
  },
  // 激活tab页参数
  [types.MUTATION_PRODUCT_TABANDSUBTAB]: (state, payload) => {
    state.activeTabAndSubTab = {
      ...state.activeTabAndSubTab,
      ...payload,
    }
  },
  [types.MUTATION_PRODUCT_ECHOPARAMS]: (state, payload) => {
    state.echoParams = payload
  },
}

export default {
  module: "Product",
  state,
  actions,
  getters,
  mutations,
}
