export default {
  inserted: (el, binding) => {
    setTimeout(() => {
      const parentNode = getParentByClassName(el, binding.value)
      if (parentNode === -1) {
        // throw new Error(`${el}没有匹配${binding.value}类名的父节点`)
        return
      }
      const parentWidth = parentNode.clientWidth
      el.style.maxWidth = parentWidth - 50 + "px"
    })
  },
}

function getParentByClassName(self, className) {
  let parentNode = self.parentNode
  let time = 0
  while (parentNode && className) {
    if (Array.from(parentNode.classList).includes(className)) {
      return parentNode
    }
    if (time > 10) return -1
    time++
    parentNode = parentNode.parentNode
  }
}
