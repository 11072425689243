import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import ElementUI from "element-ui"
import "../theme/index.css"
import BaseComponents from "../src/components/breadcrumb"
// import "./utils/posthog"
import "xe-utils"
import VXETable from "vxe-table"
import "../config/icon"
import "vxe-table/lib/index.css"
import "vxe-table/lib/style.css"
import "./assets/icon/iconfont.css"
import "vue-popperjs/dist/vue-popper.css"
import "./assets/element-variables.scss"
import "./main.css"
import smilesDrawer from "smiles-drawer"
import Echarts from "echarts" //  引入Echarts
import Directive from "./directive"
import phiiccomponents from "phiiccomponents"
import { open, mergeObjects } from "@/utils/index"
import { eventTrack } from "@/utils/eventTrack"
import phiicui from "phiic-ui"
// import phiicui from "phiic-ui/packages/main"
import "phiic-ui/lib/main/index.css"
Vue.use(phiicui)
import "core-js"
import CustomDialog from "./components/customComponents/dialogPlugin"
import PhiicLog from "phiic-log"


Vue.config.productionTip = false
Vue.use(CustomDialog)
const bus = new Vue()
Vue.prototype.bus = bus
Vue.prototype.$open = open
Vue.prototype.$eventTrack = eventTrack
Vue.prototype.$mergeObjects = mergeObjects
Vue.use(Directive)

//全局路由监听
router.beforeEach((to, from, next) => {
  let token = localStorage.getItem("token")
  if (to.meta.requireAuth) {
    if (token) {
      next()
    } else {
      ElementUI.Message({
        message: "登录状态失效，请登录！",
        type: "error",
      })
      localStorage.setItem("token", "")
      localStorage.setItem("username", "")
      localStorage.setItem("realName", "")
      localStorage.setItem("userid", "")
      localStorage.setItem("isSetCheckIpHeader", "")
      localStorage.setItem("customers", "")
      next({ path: "/Login" })
    }
  } else {
    next()
  }
})

Vue.prototype.echarts = Echarts
Vue.use(Echarts)
Vue.use(VXETable)
Vue.use(BaseComponents)
require("./assets/index.less")
Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.use(smilesDrawer)
Vue.use(phiiccomponents)
Vue.use(PhiicLog, { website: "PharmaOne", env: "uat" }) // 埋点、监控

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app")
