import { apiMaps } from "@/utils"
const { Market } = apiMaps
export default [
  {
    label: "综合检索",
    name: "commonCondition",
    formItem: [
      {
        type: "medicine",
        name: "药品",
        key: "drug",
        rst: 'CommonName',
        value: {
          isAccurate: false,
          echo: [],
          params: {
            drugName: "",
            drugList: {},
          },
        },
        config: {
          url: Market.RecommendedWords,
          multiple: true,
          query: { type: 102 },
          tip: "可搜索通用名、产品名、商品名。产品的定义是通用名+剂型，比如：尼莫西平片。",
          checkAttr: [],
        },
      },
      {
        type: "multipleCompany",
        name: "公司/持证商",
        key: "company",
        rst: 'Enterprise',
        value: {
          echo: [],
          isAccurate: false,
          params: {
            name: "",
            id: [],
            isIncludeChildren: true
          },
        },
        config: {
          query: { type: 103 }, // 查询条件
          url: Market.RecommendedWords,
          multiple: true,
          checkAttr: [{ value: true, name: "isIncludeChildren", label: "含子公司" }],
        },
      },
      {
        type: "indication",
        name: "标准适应症",
        key: "standardIndications",
        rst: 'Disease',
        value: {
          echo: [],
          isAccurate: false,
          params: {
            isIncludeChildren: true, // 子适应症
            isMultiple: false, // 多适应症
            indicationName: "", // 输入框查询
            indicationList: [], // 弹框树形选择
          },
        },
        className: "p-disease",
        config: {
          query: { type: 114 },
          tip: "1. 勾选“含子适应症”后，输入适应症的所有下层适应症也被纳入匹配范围，例如：输入“乳腺癌”，也会匹配“三阴性乳腺癌”。\n \n2. 多选模式下，默认以“含子适应症”形式搜索。\n \n3. 勾选“多适应症”后，多个适应症同时匹配。例如：选择“乳腺癌”和“宫颈癌”，返回结果里的临床适应症同时包括“乳腺癌”和“宫颈癌”。 ",
        },
      },
      {
        type: "target",
        name: "靶点",
        key: "target",
        rst: 'Target',
        value: {
          echo: [],
          isAccurate: false,
          params: {
            isMultiple: false, //是否多靶点
            isMutation: true, //含靶点突变
            targetName: "", //输入内容
            targetCombination: [], //靶点ids
          },
        },
        className: "p-target",
        config: {
          query: { type: 115 },
        },
      },
      {
        type: "autocomplete",
        name: "批准文号",
        key: "approvalNo",
        rst: 'ApprovalNo',
        value: {
          echo: [],
          isAccurate: false,
          params: {
            id: null, // 精确查询
            name: "", // 模糊查询
          },
        },
        config: {
          url: Market.RecommendedWords,
          query: { type: 10001 }, // 查询条件
          tip: "各个国家或地区上市药品库的药品唯一编码。比如：中国上市药品的批准文号或者注册证号、美国上市药品的申请号等。",
        },
      },
      {
        type: "select",
        name: "药品类型",
        key: "drugType",
        options: [],
        value: {
          params: [],
          echo: [],
        },
        config: {
          filterable: true,
        },
      },
      {
        type: "dialogFilterTree",
        name: "热门标签",
        key: "hotLabel",
        classname: "dialogFilterTree",
        options: [],
        value: {
          echo: [],
          params: {
            isMultiple: false, //是否多适应症
            name: "", //输入内容
            ids: [], //热门标签ids
          },
        },
        config: {
          title: "热门标签",
          redioConfig: [
            {
              name: "单标签",
              tip: "项目关联至少一个所选的热门标签，即选项间关系为“或”。 ",
            },
            {
              name: "多标签",
              tip: "项目同时关联所选的热门标签，即选项间关系为“和”。 ",
            },
          ],
          checkStrictly: false,
        },
      },
      {
        type: "treatmentType",
        name: "治疗领域",
        key: "treatmentType",
        value: {
          echo: [],
          params: {
            treatmentList: [],
            type: 1,
          },
        },
        config: {
          // tip: "试验组用药的治疗领域。",
        },
      },
      {
        type: "select",
        name: "是否处方药",
        key: "isPrescription",
        options: [],
        value: {
          params: [],
          echo: [],
        },
        config: {
          filterable: true,
        },
      },
      {
        type: "select",
        name: "市场状态",
        key: "marketStatus",
        options: [],
        value: {
          params: '',
          echo: [],
        },
        config: {
          multiple: false,
          filterable: true,
          tip: "根据官网存在状态（比如：中国NMPA、日本PMDA等）或者官网特有字段（比如：美国FDA的市场状态、欧盟EMA的授权状态等）推测的。",
        },
      },
      {
        type: "select",
        name: "给药途径",
        key: "administrationRoute",
        options: [],
        value: {
          params: [],
          echo: [],
        },
        config: {
          filterable: true,
        },
      },
      {
        type: "select",
        name: "剂型",
        key: "dosageForm",
        options: [],
        value: {
          params: [],
          echo: [],
        },
        config: {
          filterable: true,
        },
      },
      {
        type: "specification",
        name: "规格",
        key: "specification",
        value: {
          params: {
            id: "",
            name: "",
          },
          echo: [],
        },
        config: {},
      },
      {
        type: "input",
        name: "适应症描述",
        key: "indication",
        value: {
          echo: [],
          params: "",
        },
        config: {
          tip: "来自官方公布信息中的适应症描述。包括：说明书、获批公告等。",
        },
      },
      {
        type: "select",
        name: "说明书及审评报告等文件",
        key: "instructions",
        options: [],
        value: {
          params: [],
          echo: [],
        },
        config: {
          filterable: true,
        },
      },
      {
        type: "select",
        name: "儿童用药类型",
        key: "childrenTypes",
        options: [],
        value: {
          params: [],
          echo: [],
        },
        config: {
          filterable: true,
        },
      },
      {
        type: "dateRange",
        name: "最近批准日期",
        key: "recentlyApprovedDate",
        value: {
          echo: [],
          params: {
            start: "",
            end: "",
          },
        },
        config: {
          tip: "同一批准文号的最近一次批准日期。",
        },
      },
      {
        type: "dateRange",
        name: "最早上市日期",
        key: "arliestMarketDate",
        value: {
          echo: [],
          params: {
            start: "",
            end: "",
          },
        },
        config: {
          tip: "同商品可追溯的最早上市日期。如果有确定的新旧批准文号对应关系，会使用旧批文推算最早批准日期。 ",
        },
      },
      {
        type: "dateRange",
        name: "最近更新日期",
        key: "recentlyUpdateDate",
        value: {
          echo: [],
          params: {
            start: "",
            end: "",
          },
        },
        config: {
          tip: "数据源（比如：NMPA、FDA等）的最近一次信息更新日期。",
        },
      },
    ],
  },
  {
    label: "全球药品筛选",
    name: "globalCondition",
    formItem: [
      {
        type: "select",
        name: "上市国家或地区",
        key: "marketCountries",
        options: [],
        value: {
          echo: [],
          params: [],
        },
        config: {},
      },
      {
        type: "listed",
        name: "上市区域",
        key: "listed",
        value: {
          echo: [],
          params: {
            domesticListed: false, //仅国内上市
            foreignListed: false, //仅国外上市
            overseasListed: false, //中国药企海外上市
          },
        },
      },
    ],
  },
  {
    label: "中国药品筛选",
    name: "chinaCondition",
    formItem: [
      {
        type: "select",
        name: "国产/进口",
        key: "domestic",
        options: [
          { id: 1, name: "国产" },
          { id: 2, name: "进口" },
        ],
        value: {
          echo: [],
          params: '',
        },
        config: {
          multiple: false,
        },
      },
      {
        type: "selectInput",
        name: "生产商/分包装商",
        key: "companyType",
        options: [
          { id: 1, name: "生产商" },
          { id: 2, name: "分包装商" },
        ],
        value: {
          isAccurate: false,
          echo: [],
          params: {
            selectItem: [],
            isIncludeChildren: false,
            id: "",
            name: "",
          },
        },
        config: {
          url: Market.RecommendedWords,
          query: { type: 103 },
          checkAttr: [{ value: false, name: "isIncludeChildren", label: "含子公司" }],
        },
      },
      {
        type: "selectSelect",
        name: "国内公司所在省市",
        key: "provinceAndCity",
        options: [
          { id: 1, name: "持证商" },
          { id: 2, name: "生产商" },
        ],
        options2: [
          // { id: 1, name: "甲类" },
          // { id: 2, name: "乙类" },
        ],
        value: {
          echo: [],
          params: {
            selectItem: [],
            id: [],
          },
        },
        config: {
          hasIsIncludeChildren: false,
          isProvince: true,
          filterable: true,
        },
      },
      {
        type: "selectInput",
        name: "公司地址",
        key: "companyAddress",
        options: [
          { id: 1, name: "持证商" },
          { id: 2, name: "生产商" },
          { id: 3, name: "分包装商" },
        ],
        value: {
          isAccurate: false,
          echo: [],
          params: {
            selectItem: [],
            isIncludeChildren: false,
            id: "",
            name: "",
          },
        },
        config: {
          checkAttr: [],
        },
      },
      {
        type: "selectInput",
        name: "原批准文号/分包装批准文号",
        key: "approvalNumber",
        options: [
          { id: 1, name: "原批准文号" },
          { id: 2, name: "分包装批准文号" },
        ],
        value: {
          isAccurate: false,
          echo: [],
          params: {
            selectItem: [],
            isIncludeChildren: false,
            id: "",
            name: "",
          },
        },
        config: {
          checkAttr: [],
        },
      },
      {
        type: "input",
        name: "分包装规格",
        key: "packagingSpecification",
        value: {
          echo: [],
          params: "",
        },
        config: {},
      },
      {
        type: "dateRange",
        name: "有效期截止日",
        key: "expirationDate",
        value: {
          echo: [],
          params: {
            start: "",
            end: "",
          },
        },
        config: {
          tip: "部分药品使用了推测日期。",
        },
      },
      {
        type: "registrationType",
        name: "新药注册分类（推测）",
        key: "registrationType",
        options: [],
        value: {
          echo: [],
          params: [],
        },
        config: {
          hasParentNodeName: true,
        },
      },

      {
        type: "select",
        name: "基药目录",
        key: "basicDrugCatalog",
        options: [],
        value: {
          echo: [],
          params: [],
        },
        config: {},
      },
      {
        type: "registrationType",
        name: "医保目录",
        key: "medicalInsurance",
        options: [],
        value: {
          echo: [],
          params: [],
        },
        config: {
          hasParentNodeName: true,
          keepRootOnly: true
        },
      },
      {
        type: "select",
        name: "是否过评批文",
        key: "evaluatedVarieties",
        options: [],
        value: {
          echo: [],
          params: [],
        },
        config: {},
      },
      {
        type: "registrationType",
        name: "豁免或简化一致性评价品种",
        key: "simplifyVarieties",
        options: [],
        value: {
          echo: [],
          params: [],
        },
        config: {
          hasParentNodeName: true,
        },
      },
      {
        type: "select",
        name: "参比制剂",
        key: "referencePreparation",
        options: [
          { id: 1, name: "是" },
          { id: 0, name: "否" },
        ],
        value: {
          echo: [],
          params: '',
        },
        config: {
          multiple: false,
        },
      },
      {
        type: "numberRange",
        name: "同品种批文数",
        key: "approvalCount",
        value: {
          echo: [],
          params: {
            start: "",
            end: "",
          },
        },
        config: {
          tip: "同药品通用名+剂型的中国有效批文数。",
          quickSelectOptions: [[0, 3], [3, 10], [10, 20], [20]],
        },
      },
      {
        type: "numberRange",
        name: "同品种企业数",
        key: "companyCount",
        value: {
          echo: [],
          params: {
            start: "",
            end: "",
          },
        },
        config: {
          tip: "同药品通用名+剂型中国有效批文的企业数。企业按上市许可持有人统计，若上市许可持有人为空，则将生产企业视作上市许可持有人统计。",
          quickSelectOptions: [[0, 3], [3, 10], [10, 20], [20]],
        },
      },
      {
        type: "select",
        name: "加快上市通道",
        key: "accelerationChannel",
        options: [],
        value: {
          echo: [],
          params: [],
        },
        config: {},
      },
      {
        type: "select",
        name: "国家带量采购",
        key: "groupPurchaseBatch",
        options: [],
        value: {
          echo: [],
          params: [],
        },
        config: {},
      },
      {
        type: "select",
        name: "处方科室类别",
        key: "deptOfPrescription",
        options: [],
        value: {
          echo: [],
          params: [],
        },
        config: {},
      },
      // {
      //   type: "select",
      //   name: "鼓励仿制药名录",
      //   key: "encourageImitationListBatch",
      //   options: [],
      //   value: {
      //     echo: [],
      //     params: [],
      //   },
      //   config: {

      //   },
      // },
      {
        type: "selectInput",
        name: "是否公开专利信息",
        key: "isOPI",
        options: [
          { id: 1, name: "是" },
          { id: 0, name: "否" },
        ],
        value: {
          isAccurate: false,
          echo: [],
          params: {
            selectItem: [],
            id: "",
            name: "",
          },
        },
        config: {
          url: Market.RecommendedWords,
          query: { type: 10101 },
          checkAttr: [],
          placeholder: "请输入专利号",
        },
      },
      {
        type: "dateRange",
        name: "专利保护到期时间",
        key: "EPDate",
        value: {
          echo: [],
          params: {
            start: "",
            end: "",
          },
        },
      },
    ],
  },
  {
    label: "美国药品筛选",
    name: "americaCondition",
    formItem: [
      {
        type: "select",
        name: "申请类型",
        key: "applicationType",
        options: [],
        value: {
          echo: [],
          params: [],
        },
        config: {},
      },
      {
        type: "select",
        name: "申请途径",
        key: "applicationChannel",
        options: [],
        value: {
          echo: [],
          params: [],
        },
        config: {},
      },
      {
        type: "select",
        name: "新药递交类型",
        key: "NDAType",
        options: [],
        value: {
          echo: [],
          params: [],
        },
        config: {},
      },
      {
        type: "select",
        name: "监管机构",
        key: "regulatoryAuthority",
        options: [],
        value: {
          echo: [],
          params: [],
        },
        config: {},
      },
      {
        type: "select",
        name: "治疗等效性代码",
        key: "therapeuticEquivalenceCode",
        options: [],
        value: {
          echo: [],
          params: [],
        },
        config: {},
      },
      {
        type: "select",
        name: "参比/标准制剂",
        key: "referenceStandard",
        options: [],
        value: {
          echo: [],
          params: [],
        },
        config: {},
      },
      {
        type: "select",
        name: "橙皮书/紫皮书",
        key: "orangeOrPurpleBook",
        options: [],
        value: {
          echo: [],
          params: [],
        },
        config: {},
      },
      {
        type: "select",
        name: "孤儿药",
        key: "usaOrphanDrug",
        options: [
          { id: 1, name: "是" },
          { id: 0, name: "否" },
        ],
        value: {
          echo: [],
          params: '',
        },
        config: {
          multiple: false,
        },
      },
      {
        type: "select",
        name: "加速上市通道",
        key: "usaAccelerationChannel",
        options: [],
        value: {
          echo: [],
          params: [],
        },
        config: {},
      },
      {
        type: "select",
        name: "预批准",
        key: "tentativeApproval",
        options: [
          { id: 1, name: "是" },
          { id: 0, name: "否" },
        ],
        value: {
          echo: [],
          params: '',
        },
        config: {
          multiple: false,
        },
      },
      {
        type: "select",
        name: "首仿药",
        key: "firstGeneric",
        options: [
          { id: 1, name: "是" },
          { id: 0, name: "否" },
        ],
        value: {
          echo: [],
          params: '',
        },
        config: {
          multiple: false,
        },
      },
      {
        type: "selectInput",
        name: "是否公开专利信息",
        key: "usaIsOPI",
        options: [
          { id: 1, name: "是" },
          { id: 0, name: "否" },
        ],
        value: {
          isAccurate: false,
          echo: [],
          params: {
            selectItem: [],
            id: "",
            name: "",
          },
        },
        config: {
          url: Market.RecommendedWords,
          query: { type: 10201 },
          checkAttr: [],
          placeholder: "请输入专利号",
        },
      },
      {
        type: "dateRange",
        name: "专利保护到期时间",
        key: "protectionPeriod",
        value: {
          echo: [],
          params: {
            start: "",
            end: "",
          },
        },
      },
      {
        type: "select",
        name: "独占权信息",
        key: "patentInformation",
        options: [
          { id: 1, name: "有" },
          { id: 0, name: "无" },
        ],
        value: {
          echo: [],
          params: '',
        },
        config: {
          multiple: false,
        },
      },
      {
        type: "autocomplete",
        name: "参考原研",
        key: "researchAndDevelopment",
        value: {
          echo: [],
          isAccurate: false,
          params: {
            id: null, // 精确查询
            name: "", // 模糊查询
          },
        },
        config: {
          url: Market.RecommendedWords,
          query: { type: 10202 }, // 查询条件
        },
      },
      {
        type: "selectInput",
        name: "生物制品许可",
        key: "biologicsLicenseApplication",
        options: [],
        value: {
          isAccurate: false,
          echo: [],
          params: {
            selectItem: [],
            id: "",
            name: "",
          },
        },
        config: {
          url: Market.RecommendedWords,
          query: { type: 10203 },
          checkAttr: [],
        },
      },
      {
        type: "select",
        name: "生物制品包装",
        key: "biologicsPackaging",
        options: [],
        value: {
          echo: [],
          params: [],
        },
        config: {},
      },
      {
        type: "numberRange",
        name: "同品种申请数",
        key: "applicationCount",
        value: {
          echo: [],
          params: {
            start: "",
            end: "",
          },
        },
        config: {},
      },
      {
        type: "numberRange",
        name: "同品种企业数",
        key: "usaCompanyCount",
        value: {
          echo: [],
          params: {
            start: "",
            end: "",
          },
        },
        config: {},
      },
    ],
  },
  {
    label: "欧盟EMA药品筛选",
    name: "emaCondition",
    formItem: [
      {
        type: "select",
        name: "授权状态",
        key: "authorizationStatus",
        options: [],
        value: {
          echo: [],
          params: [],
        },
        config: {},
      },
      {
        type: "select",
        name: "孤儿药",
        key: "emaOrphanDrug",
        options: [
          { id: 1, name: "是" },
          { id: 0, name: "否" },
        ],
        value: {
          echo: [],
          params: '',
        },
        config: {
          multiple: false,
        },
      },
      {
        type: "select",
        name: "加速上市通道",
        key: "emaAccelerationChannel",
        options: [],
        value: {
          echo: [],
          params: [],
        },
        config: {},
      },
      {
        type: "select",
        name: "额外监测",
        key: "extraTest",
        options: [
          { id: 1, name: "是" },
          { id: 0, name: "否" },
        ],
        value: {
          echo: [],
          params: '',
        },
        config: {
          multiple: false,
        },
      },
      {
        type: "dateRange",
        name: "拒绝日期",
        key: "refusalDate",
        value: {
          echo: [],
          params: {
            start: "",
            end: "",
          },
        },
      },
    ],
  },
  {
    label: "欧盟HMA药品筛选",
    name: "hmaCondition",
    formItem: [
      {
        type: "select",
        name: "申请类别1",
        key: "applicationType1",
        options: [],
        value: {
          echo: [],
          params: [],
        },
        config: {},
      },
      {
        type: "select",
        name: "申请类别2",
        key: "applicationType2",
        options: [],
        value: {
          echo: [],
          params: [],
        },
        config: {},
      },
      {
        type: "select",
        name: "申请类别3",
        key: "applicationType3",
        options: [],
        value: {
          echo: [],
          params: [],
        },
        config: {},
      },
      {
        type: "select",
        name: "申请类别4",
        key: "applicationType4",
        options: [],
        value: {
          echo: [],
          params: [],
        },
        config: {},
      },
      {
        type: "select",
        name: "申请类别5",
        key: "applicationType5",
        options: [],
        value: {
          echo: [],
          params: [],
        },
        config: {},
      },
      {
        type: "select",
        name: "参考成员国",
        key: "referenceMemberStates",
        options: [],
        value: {
          echo: [],
          params: [],
        },
        config: {
          filterable: true,
        },
      },
      {
        type: "select",
        name: "相关成员国",
        key: "relatedMemberStates",
        options: [],
        value: {
          echo: [],
          params: [],
        },
        config: {
          filterable: true,
        },
      },
      {
        type: "select",
        name: "持证商所在国",
        key: "countryOfResidence",
        options: [],
        value: {
          echo: [],
          params: [],
        },
        config: {
          filterable: true,
        },
      },
    ],
  },
  {
    label: "日本药品筛选",
    name: "japanCondition",
    formItem: [
      {
        type: "select",
        name: "审查类别",
        key: "reviewCategory",
        options: [],
        value: {
          echo: [],
          params: [],
        },
        config: {},
      },
      {
        type: "select",
        name: "孤儿药",
        key: "japanOrphanDrug",
        options: [
          { id: 1, name: "是" },
          { id: 0, name: "否" },
        ],
        value: {
          echo: [],
          params: '',
        },
        config: {
          multiple: false,
        },
      },
      {
        type: "select",
        name: "加速上市通道",
        key: "japanAccelerationChannel",
        options: [],
        value: {
          echo: [],
          params: [],
        },
        config: {},
      },
      {
        type: "autocomplete",
        name: "许可或承认番号",
        key: "recognitionNumber",
        value: {
          echo: [],
          isAccurate: false,
          params: {
            id: null, // 精确查询
            name: "", // 模糊查询
          },
        },
        config: {
          url: Market.RecommendedWords,
          query: { type: 10501 }, // 查询条件
        },
      },
    ],
  },
]
