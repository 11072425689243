import types from "@/type"
import ajaxApi from "../../../../config/request"
import { post } from "@/utils/request"
let state = {
  activeTab: {
    // 激活tab页
    type: 1, // 默认按登记号查看
    moduleName: "按登记号查看",
  },
  filterParams: {
    // 左侧筛选条件
  },
  // 筛选条件回显
  echoParams: [],
}
let actions = {
  // 查询表格数据
  [types.ACTIONS_CLINICAL_GETTABLEDATA]: (store, params) => {
    let url = ajaxApi.Clinical.GetTableList
    return post(url, params)
  },
  // 导出表格数据
  [types.ACTIONS_CLINICAL_DOWNLOADTABLEDATA]: (store, params) => {
    let url = ajaxApi.Clinical.DownloadTableData
    return post(url, params)
  },
  // 文件下载前的校验
  [types.ACTIONS_CLINICAL_CHECKDOWNLOADFILE]: (store, params) => {
    let url = ajaxApi.Clinical.CheckDownloadFile
    return post(url, params)
  },
  // 单个文件下载
  [types.ACTIONS_CLINICAL_DOWNLOADFILE]: (store, params) => {
    let url = ajaxApi.Clinical.DownloadFile
    return post(url, params)
  },
  // 模糊查询下拉框
  [types.ACTIONS_CLINICAL_RECOMMENDEDWORDS]: (store, params) => {
    let url = ajaxApi.Clinical.RecommendedWords
    return post(url, params)
  },
  // select下拉框查询
  [types.ACTIONS_CLINICAL_GETOPTIONSLIST]: (store, params) => {
    let url = ajaxApi.Clinical.GetFilterOptions
    return post(url, params)
  },
  // 点击数字弹框查询
  [types.ACTIONS_CLINICAL_SHOWCOUNTOPENDATA]: (store, params) => {
    let url = ajaxApi.Clinical.ShowCountOpenData
    return post(url, params)
  },
  // 查询数量
  [types.ACTIONS_CLINICAL_GETTOTALNUMBER]: (store, params) => {
    let url = ajaxApi.Clinical.GetAllViewDataCount
    return post(url, params)
  },
  // 详情页-基本信息
  [types.ACTIONS_CLINICAL_BASICINFO]: (store, params) => {
    let url = ajaxApi.Clinical.GetBasicInfo
    return post(url, params)
  },
  // 详情页-时光轴
  [types.ACTIONS_CLINICAL_TIMEAXIS]: (store, params) => {
    let url = ajaxApi.Clinical.GetTimeAxis
    return post(url, params)
  },
  // 详情页-试验分组
  [types.ACTIONS_CLINICAL_TRIALGROUP]: (store, params) => {
    let url = ajaxApi.Clinical.GetDesignGroup
    return post(url, params)
  },
  // 详情页-试验介绍
  [types.ACTIONS_CLINICAL_TRIALINTRODUCTION]: (store, params) => {
    let url = ajaxApi.Clinical.GetTrialIntroduction
    return post(url, params)
  },
  // 详情页-终点指标
  [types.ACTIONS_CLINICAL_ENDTARGET]: (store, params) => {
    let url = ajaxApi.Clinical.GetEndTarget
    return post(url, params)
  },
  // 详情页-受试者标准
  [types.ACTIONS_CLINICAL_SUBJECTSTANDARD]: (store, params) => {
    let url = ajaxApi.Clinical.GetSubjectStandard
    return post(url, params)
  },
  // 详情页-参与机构
  [types.ACTIONS_CLINICAL_PARTICIPATECOMPANY]: (store, params) => {
    let url = ajaxApi.Clinical.GetParticipateInstitution
    return post(url, params)
  },
  // 详情页-文献摘要
  [types.ACTIONS_CLINICAL_ARTICLE]: (store, params) => {
    let url = ajaxApi.Clinical.GetArticle
    return post(url, params)
  },
}
let getters = {
  // 激活tab页参数
  [types.GETTERS_CLINICAL_ACTIVETAB]: (state) => {
    return state.activeTab
  },
  [types.GETTERS_CLINICAL_FILTERPARAMS]: (state) => {
    return state.filterParams
  },
  [types.GETTERS_CLINICAL_ECHOPARAMS]: (state) => {
    return state.echoParams
  },
}
let mutations = {
  // 激活tab页参数
  [types.MUTATION_CLINICAL_ACTIVETAB]: (state, payload) => {
    state.activeTab = {
      ...state.activeTab,
      ...payload,
    }
  },
  [types.MUTATION_CLINICAL_FILTERPARAMS]: (state, payload) => {
    if (!payload) {
      state.filterParams = {}
    } else {
      state.filterParams = {
        ...state.filterParams,
        ...payload,
      }
    }
  },
  [types.MUTATION_CLINICAL_ECHOPARAMS]: (state, payload) => {
    state.echoParams = payload
  },
}

export default {
  module: "Clinical",
  state,
  actions,
  getters,
  mutations,
}
