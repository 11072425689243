<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
import { mixin } from "./mixin"
import { flattenArray } from "@/utils"
export default {
  name: "App",
  mixins: [mixin],
  async mounted() {
    // 发版
    const menuList = (await this.$store.dispatch("ACTIONS_GET_MENU_LIST")) || []
    // 判断首先进入的页面是否在权限范围内，不在的话跳转到home页面
    const limitMenuList = flattenArray(menuList)
      .filter((e) => e.auth === false && e.routerPath)
      .map((e) => e.routerPath)
    // 和当前路由匹配，如果不在权限范围内，跳转到home页面
    const targetMenu = limitMenuList.find((e) => e === this.$route.fullPath.replace("/", ""))
    if (targetMenu && targetMenu !== "home") {
      location.href = "/home"
    }
    this.$store.dispatch("ACTIONS_GET_TABLE_COLUMNS")

    // 创建 Performance Observer 实例
    const observer = new PerformanceObserver((list) => {
      // 遍历性能条目
      list.getEntries().forEach((entry) => {
        // 输出性能条目的相关信息
        // document.getElementById('app').innerHTML += `
        //   <div>性能指标名称：${entry.name}</div>
        //   <div>性能指标类型：${entry.entryType}</div>
        //   <div>性能指标开始时间：${entry.startTime}</div>
        //   <div>性能指标持续时间：${entry.duration}</div>
        //   <hr>
        // `;
      })
    })

    // 配置 Performance Observer，观察资源性能条目
    observer.observe({ entryTypes: ["resource"] })
  },
}
</script>

<style lang="scss">
html {
  height: 100%;
}
body {
  height: 100%;
  overflow-x: auto;
}
#app {
  text-align: center;
  color: #2c3e50;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  min-width: 1440px;
}
.patentStyle {
  min-width: 134px;
  width: auto !important;
}
</style>
