import types from "../type"
const getters = {
  user: (state) => state.user.user,
  hasLogin: (state) => state.user.login,
  breadList: (state) => state.breadList,
  viewType: (state) => state.viewType,

  [types.GETTERS_GLOBAL_BREADLIST]: (state) => {
    const breadList = state.breadList
    return breadList.map((item) => {
      if (item.title) {
        return item
      }
    })
  },
  [types.GETTERS_MENU_LIST]: (state) => {
    const menu =state.menuList.filter(e=>e.title!=="ONE")
    const ONE = state.menuList.filter(e=>e.title==="ONE")[0]||{}
    return { menu, ONE }
  },
  [types.GETTERS_GLOBAL_VIEWTYPE]: (state) => {
    return state.viewType
  },
  [types.GETTERS_GLOBAL_TABBAR]: (state) => {
    return state.tabBar
  },
  [types.GETTERS_GLOBAL_TABLIST]: (state) => {
    return state.tabBarList
  },
  [types.GETTERS_GLOBAL_LOADING]: (state) => {
    return state.loading
  },
  [types.GETTERS_GLOBAL_TIPMODLUS]: (state) => {
    return state.tipModlus
  },
}
export default getters
