import types from "../type"

let mutations = {
  [types.MUTATION_SET_MENU_LIST]: (state, payload) => {
    state.menuList = payload
  },
  [types.MUTATION_GLOBAL_BREADLIST]: (state, payload) => {
    state.breadList = payload
  },
  [types.MUTATION_GLOBAL_VIEWTYPE]: (state, payload) => {
    state.viewType = payload
  },
  [types.MUTATTON_GLOBAL_TABBAR]: (state, payload) => {
    state.tabBar = payload
  },
  [types.MUTATTON_GLOBAL_LOADING]: (state, payload) => {
    state.loading = payload
  },
  [types.MUTATTON_GLOBAL_TIPMODLUS]: (state, payload) => {
    state.tipModlus = payload
  },
  [types.MUTATION_EVENT]: () => {},
}

export default mutations
