<template>
  <div class="loginIn">
    <!-- 修改密码的弹窗 -->
    <el-dialog title="" :visible.sync="authShow.show" width="600px" :modal="true" :modal-append-to-body="true" center @close="close">
      <div style="height: 100px" class="noAuthTip">您暂无该模块使用权限，请联系工作人员！</div>
      <div style="height: 70px" class="noAuthTipTex">联系电话：17702150319，邮箱：marketing@pharmadl.com</div>
      <!-- <button class="applyTrial" @click="applyTrial">申请试用</button>  -->
    </el-dialog>

    <el-dialog title="" :visible.sync="applyShow.show" width="600px" :modal="true" :modal-append-to-body="true" center @close="closeApply">
      <div class="FindMimaImg">
        <img src="@/assets/img/success.png" alt="" />
      </div>
      <div class="noAuthTip applyTrialTip">试用申请提交成功，我们会尽快联系您</div>
      <button class="applyTrial applyTrialButton" @click="applyTrialTip">确定</button>
    </el-dialog>
  </div>
</template>
<script>
import { mapGetters } from "vuex"
import types from "../../type"
export default {
  name: "AuthShow",
  components: {},
  computed: {
    ...mapGetters({
      authShow: types.GETTERS_AUTHSHOW,
      applyShow: types.GETTERS_APPLYSHOW,
    }),
  },
  data() {
    return {}
  },
  mounted() {},
  created() {
    //this.$store.commit(types.MUTATION_SET_AUTHSHOW,{show:true});
  },
  methods: {
    close() {
      this.$store.commit(types.MUTATION_SET_AUTHSHOW, { show: false })
    },
    closeApply() {
      this.$store.commit(types.MUTATION_SET_APPLYSHOW, { show: false })
    },
    applyTrial() {
      this.$store.commit(types.MUTATION_SET_AUTHSHOW, { show: false })
      this.$store.commit(types.MUTATION_SET_APPLYSHOW, { show: true })
    },
    applyTrialTip() {
      this.$store.commit(types.MUTATION_SET_APPLYSHOW, { show: false })
    },
  },
}
</script>
<style lang="less">
.loginIn {
  .el-tabs__nav {
    text-align: center;
    width: 100%;
  }
  .el-tabs__nav-wrap::after,
  .el-tabs__active-bar {
    display: none;
  }
  .el-tabs__item {
    font-weight: bold;
    font-size: 18px;

    color: #a0a8b9;
  }
  .el-tabs__item.is-active {
    border-bottom: 4px solid#FF6101;
    color: #ff6101;
    padding: 0 0px;
    margin: 0 20px;
  }

  .el-dialog__header {
    border-top: 1px solid #ff6101;
  }
  .el-dialog__title {
    font-size: 16px;

    font-weight: bold;
    color: #3c3f45;
  }
  .el-input {
    width: 300px;
  }
  .el-input__inner {
    border: none;
    border-radius: 0;
    padding-left: 15px;
    border-bottom: 1px solid #e3e9f5;
  }
  .el-form-item__content {
    margin-left: 30px !important;
    left: -10px;
  }
  .el-form-item__error {
    margin-left: 24px;
  }
  .iconShow {
    position: relative;
    left: 10px;
    z-index: 1;
  }
  .goLogin {
    font-size: 12px;

    font-weight: 400;
    color: #626d85;
    opacity: 1;
    width: auto;
    border: none;
  }
  .goLogin:hover {
    background: none;
  }
  .submitSure {
    width: 286px;
    margin: 0 auto;
    opacity: 1;
    border-radius: 2px;
    margin-right: 18px;
    margin-left: 18px;
  }
  .sendSms {
    width: 116px;
    height: 32px;
    background: #e7ecf5;
    border: 1px solid #d8e1f0;
    opacity: 1;
    border-radius: 2px;
    font-size: 12px;

    font-weight: 400;
    line-height: 8px;
    color: #7f8ca8;
    opacity: 1;
    text-align: center;
    padding: 0;
  }
  .wchartLogin {
    text-align: center;
    height: 16px;
    font-size: 12px;

    font-weight: 400;
    line-height: 16px;
    color: #626d85;
  }
  .dimensionalCode {
    margin: 0 auto;
    width: 170px;
    height: 170px;
    line-height: 170px;
    text-align: center;
    border: 2px solid #cbcfd1;
  }
  .newUser {
    font-size: 14px;
    color: #626d85;
    text-align: right;
    padding-right: 40px;
    margin-top: 0;
  }
  .forgetMima {
    text-align: right;
    font-size: 12px;

    font-weight: 400;
    color: #a0a8b9;
    opacity: 1;
    margin: 0;
    padding-right: 30px;
    position: relative;
    top: -20px;
    cursor: pointer;
  }
  .findWay {
    width: 192px;
    height: 42px;
    line-height: 42px;
    background: #e7ecf5;
    margin: 0 auto;
    margin-bottom: 22px;
    color: #677491;
    text-align: center;
    cursor: pointer;
  }
  .findWay:hover {
    background: #ff6101;
    color: #fff;
  }

  .returnLogin {
    float: left;
    margin-left: 37px;
    cursor: pointer;
  }
  .noAuthTip {
    width: 418px;
    height: 29px;
    font-size: 22px;

    font-weight: bold;
    line-height: 29px;
    color: #ff6101;
    opacity: 1;
    text-align: center;
    margin: 0 auto;
  }
  .noAuthTipTex {
    width: 418px;
    height: 29px;
    font-size: 14px;

    line-height: 29px;
    color: #ff6101;
    opacity: 1;
    text-align: center;
    margin: 0 auto;
  }
  .el-dialog__body {
    text-align: center;
  }
  .applyTrial {
    width: 189px;
    height: 46px;
    background: #ff6101;
    opacity: 1;
    border-radius: 3px;
    font-size: 18px;

    line-height: 24px;
    color: #ffffff;
    opacity: 1;
    border: none;
    margin: 0 auto;
    margin-top: 45px;
    margin-bottom: 30px;
    cursor: pointer;
  }
  .applyTrialTip {
    font-size: 14px;

    font-weight: bold;
    line-height: 10px;
    color: #3c3f45;
    margin-top: 15px;
    margin-bottom: 10px;
  }
  .applyTrialButton {
    width: 140px;
    height: 36px;
    margin-top: 15px;
    font-size: 14px;
    margin-bottom: 0px;
  }
  .FindMimaImg img {
    width: 45px;
    height: auto;
    margin-top: 16px;
  }
}
body .v-modal {
  display: block !important;
  z-index: 88 !important;
  background: #fff;
  opacity: 0.8;
}
</style>
