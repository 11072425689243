import axios from "axios"
import store from "../src/store"
import router from "../src/router"
import types from "../src/type"
import { BaseUrl, AjaxSuccessCode, AjaxUnLoginCode, ErrorCode, NoPower, NoErrorToastRquest, NotFound } from "../config"
// 基础配置
// console.log("环境：：" + process.env.VUE_APP_ENV, "url:" + BaseUrl)
axios.defaults.baseURL = BaseUrl
axios.defaults.headers.post["Content-Type"] = "application/json"
axios.defaults.timeout = 180000
var requestCount = 0
// 拦截器
axios.interceptors.request.use((config) => {
  if (config.data && config.data.need) {
    config["responseType"] = "blob"
  }
  store.commit("MUTATTON_GLOBAL_LOADING", false)
  if (!config.withoutToken) {
    const token = localStorage.getItem("token")
    const isSetCheckIpHeader = localStorage.getItem("isSetCheckIpHeader")
    if (isSetCheckIpHeader) {
      config.headers["access-location-custom"] = isSetCheckIpHeader
    }
    config.headers = {
      ...config.headers,
      ...{ token: token },
    }
  }
  if (config.method === "post") {
    config.data = JSON.stringify(config.data)
  }
  return config
})
axios.interceptors.response.use(
  (res) => {
    const {
      data: { code, message, diagnosticMessage },
      headers,
    } = res
    let _url = res.config.url
    if (AjaxUnLoginCode.includes(Number(code))) {
      requestCount = 0
      localStorage.setItem("token", "")
      localStorage.setItem("username", "")
      localStorage.setItem("realName", "")
      localStorage.setItem("userid", "")
      localStorage.setItem("isSetCheckIpHeader", '')
      localStorage.setItem("customers", '')
      router.push("/Login")
    } else if (AjaxSuccessCode.includes(Number(code))) {
      if (requestCount === 0) {
        document.body.removeAttribute("class")
      }
      // 请求成功
      return res.data
    } else if (ErrorCode.includes(Number(code))) {
      if (requestCount === 0) {
        //如果接口在不提示错误信息白名单，直接返回
        if (isArrayItemIncluded(NoErrorToastRquest, _url)) { return }
        if (_url.toLowerCase().includes("home")) {
          return
        }
        if (code == 500) {
          //弹出框
          store.commit("MUTATTON_GLOBAL_TIPMODLUS", {
            tipShow: true,
            tipMessage: res.data.message ? res.data.message : "验证失败，请重新验证",
          })
        } else {
          if (_url.toLowerCase().includes("home")) {
            return
          }
          //弹出框
          store.commit("MUTATTON_GLOBAL_TIPMODLUS", {
            tipShow: true,
            tipMessage: "验证失败，请重新验证",
          })
        }
        document.body.removeAttribute("class")
      }
      // 请求失败
      return res.data
    } else if (NoPower.includes(Number(code))) {
      //没有权限403
      store.commit(types.MUTATION_SET_AUTHSHOW, { show: true })
    } else if (headers["content-type"].indexOf("application/octet-stream") > -1) {
      //文件下载
      if (res.data) {
        // 创建blob对象，解析流数据
        const blob = new Blob([res.data])
        const a = document.createElement("a")
        // 兼容webkix浏览器，处理webkit浏览器中href自动添加blob前缀，默认在浏览器打开而不是下载
        const URL = window.URL || window.webkitURL
        // 根据解析后的blob对象创建URL 对象
        const herf = URL.createObjectURL(blob)
        // 下载链接
        a.href = herf
        //let index1=JSON.parse(res.config.data).fileUrl.lastIndexOf("\.");    //获取-后边的字符串
        // 下载文件名,如果后端没有返回，可以自己写a.download = '文件.pdf'
        //a.download =  JSON.parse(res.config.data).type+"."+JSON.parse(res.config.data).fileUrl.substring(index1,JSON.parse(res.config.data).fileUrl.length)
        a.download = JSON.parse(res.config.data).name
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
        // 在内存中移除URL 对象
        window.URL.revokeObjectURL(herf)
      }
    }
  },
  (error) => {
    const { response } = error
    if (NotFound.includes(Number(response.status))) {
      //404
      router.push("/NotFound")
    }
    document.body.removeAttribute("class")
    var originalRequest = response.config
    if (response.code == "ECONNABORTED" && response.message.indexOf("timeout") != -1 && !originalRequest._retry) {
      requestCount--
      originalRequest._retry = true
    }
  }
)
function isArrayItemIncluded(arr, str) {
  return arr.some(item => str.includes(item));
}
export default axios
